import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { getImage, getAvatarImage } from "../utils/image-utils";
import {
  getProfile,
  getChildren,
  isParent,
  getActor,
  isTeacher,
  getGrade,
  getName,
  getEmail
} from "../utils/profile-utils";
import AddChildModal from "./Parent/AddChildModal";
import "../containers/CreateChallenge/CreateChallenge.css";

const ProfileNavigation = ({ active }) => {
  const [isShowing, setIsShowing] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  // console.log('ProfileNavigation window size ' + window.innerWidth);
  if (!showMenu && window.innerWidth > 480) {
    toggleMenu();
  }

  function toggle() {
    setIsShowing(!isShowing);
  }

  function toggleMenu() {
    setShowMenu(!showMenu);
    // console.log('ProfileNavigation hideMenu? ' + showMenu);
  }

  let children = null;
  let actor = getActor();
  if (isParent()) {
    children = getChildren();
  }
  let grade = null;
  if (isTeacher()) {
    grade = getGrade();
  }

  // console.log('ProfileNavigation actor: ' + actor);
  return (
    <div>
      <div className="menu-target" onClick={() => toggleMenu()}>
        <img src={getImage("menu-button.svg")} alt="menu" />
      </div>
      {isShowing && (
        <div className="adult-page">
          <AddChildModal onClose={toggle} />
        </div>
      )}
      <div className={"main-pro " + (showMenu ? "active" : "")}>
        <div className="main-profile-inner">
          <div className="just-profile">
            <NavLink to={"/" + actor + "/profile"}>
              <img src={getAvatarImage(getProfile())} alt="" />
            </NavLink>
            <div className="just-pro-text">
              <h4>
                <NavLink to={"/" + actor + "/profile"}>{getName()}</NavLink>
              </h4>
              <p>{getEmail()}</p>
            </div>
          </div>
          <div className="profile-grade">
            <ul>
              <li className={active ==="notifications" ? "active" : ""}>
                <img src={getImage("notification-icon-adult.svg")} alt="" />
                <span>
                  <NavLink to={"/" + actor + "/notifications"}>
                    Notification
                  </NavLink>
                </span>
              </li>
              {children &&
                children.map(function(c) {
                  return (
                    <li
                      key={c.userid}
                      className={active === c.userid ? "active" : ""}
                    >
                      <NavLink to={"/" + actor + "/child/" + c.userid}>
                        <img src={getAvatarImage(c)} alt={c.userid} />
                      </NavLink>
                      <NavLink to={"/" + actor + "/child/" + c.userid}>
                        {c.name}
                      </NavLink>

                      {/* <div className="pro-menu-dot">
                  <span />
                  <span />
                  <span />
                </div> */}
                    </li>
                  );
                })}
              {grade && (
                <li className={active === "grade" ? "active" : ""}>
                  <img src={getImage("grade-icon.svg")} alt="" />
                  <NavLink to={"/teacher/grade"}>Grade {grade}</NavLink>
                  <div className="pro-menu-dot">
                    <span />
                    <span />
                    <span />
                  </div>
                </li>
              )}
            </ul>

            {grade && (
              <div className="create-grade">
                <a href="">
                  <span>+</span> CREATE CLASS
                </a>
              </div>
            )}
            {children && (
              <div className="create-grade">
                <a onClick={toggle}>
                  <span>+</span> ADD CHILD
                </a>
              </div>
            )}
            <ul className="setting-option">
              <li />
              {/* <li className={active === 'settings' ? 'active' : ''}>
                <img src={getImage('settings-icon-adult.png')} alt="" />
                <NavLink to={'/' + actor + '/settings'}>Settings</NavLink>

                <div className="pro-menu-dot">
                  <span />
                  <span />
                  <span />
                </div>
              </li> */}
              <li className={active === "about" ? "active" : ""}>
                <img src={getImage("about-icon-adult.svg")} alt="" />
                <NavLink to={"/" + actor + "/about"}>About</NavLink>

                {/* <div className="pro-menu-dot">
                  <span />
                  <span />
                  <span />
                </div> */}
              </li>
              {/* <li className={active === 'help' ? 'active' : ''}>
                <img src={getImage('help-icon-adult.png')} alt="" />
                <NavLink to={'/' + actor + '/help'}>Help</NavLink>

                <div className="pro-menu-dot">
                  <span />
                  <span />
                  <span />
                </div>
              </li> */}
              <li className={active === "privacy" ? "active" : ""}>
                <img src={getImage("help-icon-adult.png")} alt="" />
                <NavLink to={"/" + actor + "/privacy"}>Privacy Policy</NavLink>

                {/* <div className="pro-menu-dot">
                  <span />
                  <span />
                  <span />
                </div> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNavigation;
