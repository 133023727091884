import React, { Component } from "react";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";
import "../../containers/Login/Login.css";
import Progress from "../progress/Progress";

import axios from "axios";

import { config } from "../../Constants";
import { getImage } from "../../utils/image-utils";
import { getToken, updateProfileFromResponse } from "../../utils/profile-utils";
import Toast from "../Student/Toast/Toast";

class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      token: getToken()
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    this.setState(prevState => ({
      // files: prevState.files.concat(files),
      files: files
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file) {
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(config.url.API_BASE_URL + "/api/profile/upload", formData, {
        headers: {
          Authorization: this.state.token,
          ContentType: "multipart/form-data"
        },
        onUploadProgress: progressEvent =>
          console.log("Upload progressEvent: " + progressEvent.loaded)
      })
      .then(response => {
        // console.log(response);
        if (response.data.object.profile) {
          updateProfileFromResponse(response.data.object.profile);
        }
        this.props.toggleUpload();
      })
      .catch(error => {
        console.log(error);
        this.setState({
          toastText: "Error uploading file: " + error.response.data.details
        });
      });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src="baseline-check_circle_outline-24px.svg"
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          className="cursor-pointer"
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          className="cursor-pointer"
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }

  render() {
    const { toastText } = this.state;
    return (
      <div id="overlay-container">
        {toastText && <Toast text={toastText} />}
        <div className="settings-wrapper">
          <div className="settings-box">
            <a href="#" onClick={() => this.props.toggleUpload()}>
              <img
                className="close"
                src={getImage("Group-2208.png")}
                alt="close"
              />
            </a>
            <h1>Upload Picture</h1>
            <div className="Card">
              <div className="Upload">
                <div className="Content">
                  <div>
                    <Dropzone
                      onFilesAdded={this.onFilesAdded}
                      disabled={
                        this.state.uploading || this.state.successfullUploaded
                      }
                    />
                  </div>
                  <div className="Files">
                    {this.state.files.map(file => {
                      return (
                        <div key={file.name} className="Row">
                          <span className="Filename">{file.name}</span>
                          {this.renderProgress(file)}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="Actions login-button">
                  {this.renderActions()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    // return (

    // );
  }
}

export default Upload;
