import { displayLessonName } from "./display-utils";
import { getProgressImage } from "./student-utils";

export function aggregate(lessonReport, lesson) {
  // console.log('aggregate: ' + JSON.stringify(lessonReport));
  const { lessonType, reportedLessonProgress } = lesson;
  let displayReport = {
    mustStart: false,
    showIncomplete: false,
    showAccuracy: false,
    score: 0,
    correctAnswers: 0,
    starImage: "saga-map-01.png"
  };

  if (lessonReport) {
    let { totalQuestions, attemptedQuestions, correctAnswers } = lessonReport;
    attemptedQuestions = attemptedQuestions > lesson.reportedLessonProgress
      ? attemptedQuestions
      : lesson.reportedLessonProgress;
    // TODO show class motivator status on pending status
    if (attemptedQuestions === 0) {
      displayReport.mustStart = true;
    } else if (attemptedQuestions < totalQuestions) {
      displayReport.showIncomplete = true;
      displayReport.correctAnswers = correctAnswers;
    } else {
      displayReport.showAccuracy = true;
      // Tutorials should be scored at 100% if all slided have been reviewed
      // TODO move this logic to backend
      if (lessonType === "FLASH_CARDS") {
        displayReport.score = 100;
        displayReport.correctAnswers = attemptedQuestions;
      } else {
        displayReport.score = lessonReport.accuracy;
        displayReport.correctAnswers = 0;
        if (correctAnswers) {
          displayReport.correctAnswers = correctAnswers;
        } else if (lessonReport.questionActivity) {
          displayReport.correctAnswers = lessonReport.questionActivity.filter(
            q => q.correct === true
          ).length;
        }
      }
      if (displayReport.correctAnswers) {
        displayReport.starImage = starImage(
          displayReport.correctAnswers,
          totalQuestions,
          true
        );
      }
    }
    // console.log('aggregated totalQuestions: ' + totalQuestions);
  } else {
    displayReport.mustStart = true;
  }
  // console.log('aggregated displayReport: ' + JSON.stringify(displayReport));
  return displayReport;
}

export function starImage(correctAnswers, totalQuestions, saga) {
  let startDigit = Math.round((correctAnswers * 7) / totalQuestions);
  // console.log('Star digit: ' + startDigit);
  if (saga) {
    return "saga-map-0" + startDigit + ".png";
  } else {
    return "challenge-star-0" + startDigit + ".png";
  }
}
