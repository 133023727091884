// Constants.js
const prod = {
  url: {
    API_BASE_URL: "",
  },
  class: {
    PRACTICE_PAGE_CLASS: "choose-page",
    STUDENT_PAGE_CLASS: "student-page",
    STUDENT_QUESTION_CLASS: "question-page",
    ADULT_PAGE_CLASS: "adult-page",
    CHALLENGE_PAGE_CLASS: "calander-page",
  },
};

const dev = {
  url: {
    // API_BASE_URL: '/'
    API_BASE_URL: "http://localhost:8080",
    // API_BASE_URL: 'http://www.gyan.io'
    // API_BASE_URL: 'https://enigmatic-citadel-60318.herokuapp.com'
  },
  class: {
    PRACTICE_PAGE_CLASS: "choose-page",
    STUDENT_PAGE_CLASS: "student-page",
    STUDENT_QUESTION_CLASS: "question-page",
    ADULT_PAGE_CLASS: "adult-page",
    CHALLENGE_PAGE_CLASS: "calander-page",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;

export const LOGIN_LOADING_IMAGES = [
  { image: "ajax-loader.gif", width: 400, repeat: false },
];

export const CHILD_LOADING_IMAGES = [
  { image: "giphy.gif", width: 300, repeat: false },
  { image: "waiting.gif", width: 300, repeat: false },
  { image: "rocket.gif", width: 300, repeat: false },
  // { image: 'donut.gif', repeat: true },
  // { image: 'stars.gif', repeat: true },
  // { image: 'burger.gif', repeat: true },
  // { image: 'wheel.gif', repeat: false },
];

export const ADULT_LOADING_IMAGES = [
  // { image: 'loader.gif', width: 200, repeat: false },
  { image: "ajax-loader.gif", width: 400, repeat: false },
];

export const SOON_LOADING_IMAGES = [
  { image: "coming-soon.png", width: 400, repeat: false },
];

export const SUBJECT_STYLES = {
  COMMON_CORE_MATH: {
    icon: "np_math.svg",
    backgroundColor: "#2CD89D",
    topicBackgroundColor: "rgba(44, 216, 157, 0.5)",
    barColor: "#2cd89d"
  },
  MATH: {
    icon: "np_math.svg",
    backgroundColor: "#2CD89D",
    topicBackgroundColor: "rgba(44, 216, 157, 0.5)",
  },
  SCIENCE: {
    icon: "np_science.svg",
    backgroundColor: "#BD55EB",
    topicBackgroundColor: "rgba(189, 85, 235, 0.5)",
  },
  SCIENCE_REVIEW: {
    icon: "np_science.svg",
    backgroundColor: "#BD55EB",
    topicBackgroundColor: "rgba(189, 85, 235, 0.5)",
  },
  SOCIAL_STUDIES: {
    icon: "np_history.svg",
    backgroundColor: "#19B3FC",
    topicBackgroundColor: "rgba(25, 179, 252, 0.5)",
  },
  TRAVEL: {
    icon: "np_history.svg",
    backgroundColor: "#19B3FC",
    topicBackgroundColor: "rgba(25, 179, 252, 0.5)",
  },
  MINDFULNESS: {
    icon: "np_history.svg",
    backgroundColor: "#19B3FC",
    topicBackgroundColor: "rgba(25, 179, 252, 0.5)",
  },
  ENGLISH: {
    backgroundColor: "#4D65DB",
  },
};

export const LESSON_TYPE_STYLE = {
  FLASH_CARDS: {
    icon: "np_knowledge.svg",
    backgroundColor: "#19B3FC",
    typeBackgroundColor: "rgba(25, 179, 252, 0.1)",
  },
  MULTIPLE_CHOICE_QUESTIONS: {
    icon: "icon-topic.svg",
    backgroundColor: "#2dcb70",
    typeBackgroundColor: "rgba(45, 203, 112, 0.1)",
  },
  NUMERIC_QUESTIONS: {
    icon: "icon-topic.svg",
    backgroundColor: "#bd54ea",
    typeBackgroundColor: "rgba(189, 84, 234, 0.1)",
  },
  SPIRAL_REVIEW: {
    icon: "np_spiral.svg",
    backgroundColor: "#4d64db",
    typeBackgroundColor: "rgba(77, 100, 219, 0.1)",
  },
};
