import React, { useState } from "react";

import "react-circular-progressbar/dist/styles.css";

import "./../../Teacher/RecentChallenges.css";
import "./../../../components/Parent/ChildChallenges.css";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import ProgressCard from "./ProgressCard";
import RecommendationCard from "./RecommendationsCard";

const RecommendationsCarousel = ({ recommendations, childProfile, recommendationsCards, preview, assign, confirmAssign }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1100 },
      items: 2.5,
    },
    tablet: {
      breakpoint: { max: 1100, min: 900 },
      items: 1.5,
    },
    mobile: {
      breakpoint: { max: 900, min: 446 },
      items: 1,
    },
    mobile1: {
      breakpoint: { max: 446, min: 0 },
      items: 1,
    }
  };

  return (
    <div class="col-md-7 col-lg-9 d-none d-md-block">
      {/* <div class="card-scroller pb-2 pt-0 pl-1 pt-1"> */}
      {/* <div class="row card-spiral-row flex-nowrap"> */}
      {/* <Carousel responsive={responsive}> */}
      {recommendations &&
        recommendationsCards.map((c, index) => {
          return (
            <RecommendationCard
              key={index} 
              report={c.recommendations}
              topicMetadata={c.topicMetadata}
              child={childProfile}
              recommendation={c}
              preview={preview}
              assign={assign}
              confirmAssign={confirmAssign}
            />
          );
        })}
      {/* </Carousel> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default RecommendationsCarousel;
