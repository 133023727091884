import React, { Component } from 'react';

import { config, ADULT_LOADING_IMAGES } from '../../Constants';
import { StickyContainer, Sticky } from 'react-sticky';
import { reportActions, report } from '../../utils/report-utils';
import Header from '../../components/Teacher/DashboardHeader';
import RecentChallenges from '../../components/Teacher/RecentChallenges';
import HomeworkPicker from '../../components/Teacher/HomeworkPicker';
import GraspWidget from '../../components/Teacher/GraspWidget/GraspWidget';
import SubjectPicker from '../../components/Teacher/SubjectPicker';
import ChallengeHistoryGraph from '../../components/Teacher/ChallengeHistoryGraph';
import ShowProblem from '../../components/Student/LessonContent/ShowProblem';
import {
  getClassReport,
  loadReportCardsByHomework,
  loadReportCardsBySubject,
  homeworkBySubject,
} from '../../utils/teacher-dashboard-utils';
import { getServerVersion } from '../../utils/profile-utils';
import Loader from '../../components/Loader';
import requireAuthentication from '../../components/AuthenticatedComponent';

class TeacherDashboard extends Component {
  // default State object
  state = {
    subject: '',
    loading: true,
  };

  report = {};

  constructor(props) {
    super(props);

    console.log(props);

    this.state.anchor = this.props.location.hash.replace('#', '');
    this.report.pageName = 'Teacher Dashboard #' + this.state.anchor;

    this.changeSubject = this.changeSubject.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
    this.report.action = reportActions.LOAD_PAGE;
    console.log('Sending report: ' + JSON.stringify(this.report));
    report(this.report);
    getClassReport(this.loadData);
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
  }

  loadData(reports, classroom, subject) {
    const { anchor } = this.state;
    const pickedSubject = anchor ? anchor : subject;

    const lessonReports = loadReportCardsByHomework(reports);

    // For scrolling arrows
    const firstCard =
      lessonReports && lessonReports.length > 0
        ? lessonReports[0].reference
        : null;
    const lastCard =
      lessonReports && lessonReports.length > 1
        ? lessonReports[lessonReports.length - 1].reference
        : null;

    console.log('Homework serverVersion: ' + getServerVersion());
    console.log(pickedSubject + ' derived from ' + anchor + ' OR ' + subject);
    const subjects = reports.subject ? Object.keys(reports.subject) : null;
    const lessons = subjects ? reports.subject[pickedSubject] : null;
    const homework = lessons ? homeworkBySubject(reports, lessons) : null;

    const newState = Object.assign({}, this.state, {
      firstCard: firstCard,
      lastCard: lastCard,
      reports: reports,
      subject: pickedSubject,
      subjects: subjects,
      lessonReports,
      subjectReports: loadReportCardsBySubject(lessonReports, pickedSubject),
      homework: homework,
      loading: false,
    });

    // store the new state object in the component's state
    this.setState(newState);
  }

  changeSubject(subject) {
    console.log('TeacherDashboard changeSubject ' + subject);
    const { reports, lessonReports } = this.state;
    const lessons = reports.subject[subject];
    const homework = homeworkBySubject(reports, lessons);
    this.setState({
      subject: subject,
      subjectReports: loadReportCardsBySubject(lessonReports, subject),
      homework: homework,
    });
  }

  render() {
    const {
      reports,
      subject,
      subjects,
      lessonReports,
      subjectReports,
      homework,
      firstCard,
      lastCard,
      loading,
    } = this.state;

    console.log(
      loading + ' <== Loading ---- TeacherDashboard reder state: ' + subject
    );

    return (
      <div>
        <Header teacher={true} />
        {homework && homework.length > 0 && (
          <RecentChallenges
            className="clear"
            lessonReports={lessonReports}
            firstCard={firstCard}
            lastCard={lastCard}
          />
        )}
        <StickyContainer className="challenge-analysis">
          <div className="summary-area">
            <Sticky>
              {({
                style,

                // the following are also available but unused in this example
                isSticky,
                wasSticky,
                distanceFromTop,
                distanceFromBottom,
                calculatedHeight,
              }) => (
                <header style={style}>
                  {reports && (
                    <SubjectPicker
                      subjects={subjects}
                      subject={this.state.subject}
                      onChange={this.changeSubject}
                    />
                  )}
                </header>
              )}
            </Sticky>

            {loading && (
              <Loader
                image={
                  ADULT_LOADING_IMAGES[
                    Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
                  ]
                }
              />
            )}
            {homework && (
              <HomeworkPicker
                homework={homework}
                subject={this.state.subject}
                index={0}
              />
            )}
          </div>
          {homework && subjectReports && (
            <ChallengeHistoryGraph
              lessonReports={subjectReports}
              subject={this.state.subject}
            />
          )}

          {homework && (
            <GraspWidget
              lessonReports={homework}
              showReportsByTopic={true}
              isClassReport={true}
              reportPath={'/teacher/report/lesson/'}
            />
          )}
        </StickyContainer>
        <ShowProblem report={this.report} />
      </div>
    );
  }
}

export default requireAuthentication(TeacherDashboard);
