import React, { Component } from "react";
// import { Chat, Channel, ChannelList, Window } from 'stream-chat-react';
// import { StreamChat } from 'stream-chat';

// import { getImage } from '../../utils/image-utils';

import { SOON_LOADING_IMAGES } from "../../Constants";

import "./../Dashboard/Dashboard.css";
import "./ParentLessonReport.css";
import "./../LessonReport/LessonReport.css";
import "./ParentProfile.css";
import Header from "../../components/Header";
// import ProfileNavigation from '../../components/ProfileNavigation';
import Loader from "../../components/Loader";

class ParentMessages extends Component {
  // default State object
  state = {
    profile: ""
  };

  // chatClient;

  constructor(props) {
    super(props);

    console.log(props);

    this.state.profile = JSON.parse(localStorage.getItem("profile"));
    // this.setupChat();

    console.log(this.state.profile);
    // This binding is necessary to make `this` work in the callback
    this.state.token = localStorage.getItem("token");
    this.state.profile = JSON.parse(localStorage.getItem("profile"));
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    // let filters = {
    //   type: 'messaging',
    //   members: { $in: [this.state.profile.userid] },
    // };
    // let sort = { last_message_at: -1 };
    // const profile = this.state.profile;
    return (
      <div>
        <div className="wiggles">
          <Loader
            image={
              SOON_LOADING_IMAGES[
                Math.floor(Math.random() * SOON_LOADING_IMAGES.length)
              ]
            }
          />
        </div>
        <Header title="Messages" />

        <div class="profile-area">
          <div class="sk-container">
            <div class="profiles-main-warpp">
              {/* <ProfileNavigation active={'Messages'} /> */}

              <div class="right-content-warp">
                <div class="right-content-inner">
                  <div class="help-content" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ParentMessages;
