import axios from 'axios';
import { getToken, logout, updateProfileFromResponse } from './profile-utils';

export function getCall(url, callback, errorCallback) {
  const token = getToken();
  if (token) {
    axios
      .get(url, {
        headers: { Authorization: token },
      })
      .then(response => {
        callback(response);
        try {
          if (response.data.object.profile) {
            updateProfileFromResponse(response.data.object.profile);
          }
        } catch (error) {
          console.log(error);
        }
      })
      .catch(error => {
        console.log(JSON.stringify(error));
        if (error && error.response) {
          if (error.response.status === 401) {
            logout();
          }
        }
        if (errorCallback) {
          errorCallback(error);
        }
      });
  }
  // TODO user must login again if token is missing
} 

export function postCall(url, body, callback, errorCallback) {
  const token = getToken();
  if (token) {
    axios
      .post(url, body, {
        headers: { Authorization: token },
      })
      .then(response => {
        callback(response);
        try {
          if (response.data.object.profile) {
            updateProfileFromResponse(response.data.object.profile);
          }
        } catch (error) {
          console.log(error);
        }
      })
      .catch(error => {
        console.log(JSON.stringify(error));
        if (error && error.response) {
          if (error.response.status === 401) {
            logout();
          }
        }
        if (errorCallback) {
          errorCallback(error);
        }
      });
  }
  // TODO user must login again if token is missing
} 
