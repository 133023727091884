import React from 'react';
import Header from '../components/Header';
import ProfileNavigation from '../components/ProfileNavigation';
import {
  getNotifications,
  getUserid,
  getChild,
  getProfile,
  getToken,
} from '../utils/profile-utils';

import {
  assignLesson,
  refreshNotifications,
  sendClap,
} from '../utils/notification-utils';
import requireAuthentication from '../components/AuthenticatedComponent';
import { reportActions, report } from '../utils/report-utils';

import { ADULT_LOADING_IMAGES } from '../Constants';
import Loader from '../components/Loader';
import { getImage } from '../utils/image-utils';
import Moment from 'react-moment';

class Notifications extends React.Component {
  state = {
    loading: true,
  };
  report = {};

  constructor(props) {
    super(props);

    if (!getProfile() || !getToken()) {
      this.props.history.push('/logout');
    }

    this.report.pageName = 'Adult Notifications';
    this.report.userid = getUserid();

    this.state.notifications = getNotifications();

    console.log(props);
  }

  componentDidMount() {
    this.report.action = reportActions.LOAD_PAGE;
    // console.log('Sending report: ' + JSON.stringify(this.report));
    report(this.report);
    const boundFunction = function(response) {
      // console.log(response);
      this.setState({
        notifications: response,
        loading: false,
      });
    }.bind(this);
    refreshNotifications(boundFunction, function(error) {
      console.error(error);
    });
  }

  render() {
    const { notifications, loading } = this.state;
    // console.log('Notifications: ' + JSON.stringify(notifications));
    return (
      <div>
        <Header title="Notifications" />
        {loading && (
          <Loader
            image={
              ADULT_LOADING_IMAGES[
                Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
              ]
            }
          />
        )}{' '}
        <div className="profile-area">
          <div className="sk-container">
            <div className="profiles-main-warpp">
              <ProfileNavigation active={'notifications'} />
              <div className="right-content-warp">
                <div className="right-content-inner">
                  <div className="help-content">
                    <div className="help-title">
                      <h4>Notifications </h4>
                    </div>

                    {notifications && (
                      <div className="notification-text">
                        {notifications.map(function(notification) {
                          const duration =
                            new Date().getTime() - notification.firstActivity <
                            1000 * 60 * 60 * 24;

                          const child = getChild(notification.from);
                          const childName = child ? child.name : '';
                          let image = 'low-performar-notification.png';
                          let name =
                            'Notification ' + notification.notificationType;
                          let message = '';
                          let lessonName = '';
                          let assign = false;
                          let clap = false;
                          if (notification.notificationType === 'LEVEL_UP') {
                            name = 'Level up';
                            image = 'levelup-notification.png';
                            message = notification.lessonKey;
                            clap = true;
                          }
                          if (
                            notification.notificationType ===
                            'ASSIGN_CHALLENGE_REQUEST'
                          ) {
                            image = 'pending-notification.png';
                            name = 'Assignment Request';
                            lessonName = notification.lessonName
                              ? notification.lessonName
                              : notification.lessonKey;
                            message =
                              childName +
                              ' requested ' +
                              lessonName +
                              ' assignment.';
                            assign = true;
                          }
                          let dismissed = true;
                          if (notification.notificationStatus === 'DISMISSED') {
                            assign = false;
                            dismissed = false;
                            clap = false;
                          }

                          return (
                            <div
                              key={notification.eventId}
                              className={
                                'notification-item ' +
                                (dismissed ? 'active-notification-item' : '')
                              }
                            >
                              <div className="setting-i tm">
                                <div className="left modal-left-text">
                                  <img src={getImage(image)} alt="" />
                                </div>
                                <div className="setting-text">
                                  <h4>{name}</h4>
                                  {message && <p>{message}</p>}
                                  <p>
                                    {' '}
                                    {duration && (
                                      <Moment fromNow>
                                        {notification.firstActivity}
                                      </Moment>
                                    )}
                                    {!duration && (
                                      <Moment format="MMM Do YYYY">
                                        {notification.firstActivity}
                                      </Moment>
                                    )}{' '}
                                    {childName && <span>• {childName}</span>}
                                  </p>
                                </div>
                                <div className="setting-on">
                                  <span className="notification-action">
                                    {assign && (
                                      <div
                                        className="cursor-pointer"
                                        onClick={() =>
                                          assignLesson(notification)
                                        }
                                      >
                                        Assign
                                      </div>
                                    )}
                                    {clap && (
                                      <div
                                        className="cursor-pointer"
                                        onClick={() => sendClap(notification)}
                                      >
                                        Clap
                                      </div>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* {notifications && (<div className="profile-info">
                          <ul id="tabs" className="siblings">
                            {notifications.map(function(notification) {
                              let assign = true;
                              if (
                                notification.notificationStatus === 'DISMISSED'
                              ) {
                                assign = false;
                              }

                              return (
                                <li
                                  id="tabs"
                                  class={
                                    'siblings ' +
                                    notification.notificationType.toLowerCase()
                                  }
                                >
                                  <div>
                                    <p className="created">
                                      {notification.from}{' '}
                                      {notification.notificationType}{' '}
                                      {notification.lessonKey}
                                    </p>
                                    <span className="bold-text">
                                      {assign && (
                                        <div
                                          onClick={() =>
                                            assignLesson(notification)
                                          }
                                        >
                                          Assign
                                        </div>
                                      )}
                                      {!assign && (
                                        <div>
                                          {notification.notificationStatus}
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                          </div>
                      )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default requireAuthentication(Notifications);
