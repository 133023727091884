import React, { Component } from "react";

import Header from "../../components/Header";
import ProfileNavigation from "../../components/ProfileNavigation";
import { isParent, getChildren, getSubjects } from "../../utils/profile-utils";
import requireAuthentication from "../../components/AuthenticatedComponent";
import "react-datepicker/dist/react-datepicker.css";

import { getAvatarImage, getImage } from "../../utils/image-utils";
import {
  updateChildProfile,
  updateChildPassword
} from "../../utils/request-utils";
import { checkText } from "../../utils/validation-utils";
import EditChildForm from "../../components/Parent/Forms/EditChildForm";
import { displaySubject } from "../../utils/display-utils";

class Child extends React.Component {
  state = {
    editMode: false
  };

  constructor(props) {
    super(props);

    console.log(props);

    this.goBack = this.goBack.bind(this);
    this.save = this.save.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.getChild = this.getChild.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    // console.log("Child componentDidMount " + this.props.match.params.child);
    this.getChild();
  }

  componentDidUpdate() {
    // console.log("Child componentDidUpdate " + this.props.match.params.child);
    this.getChild();
  }

  getChild() {
    // console.log("Child getChild " + this.props.match.params.child);
    const childName = this.props.match.params.child;
    if (!this.state.childName || childName !== this.state.childName) {
      let child = null;
      if (isParent()) {
        child = getChildren().filter(c => c.userid === childName)[0];
      }
      this.setState({
        childName: childName,
        child: child,
        name: child.name,
        birthday: child.birthday,
        grade: child.grade,
        editMode: false
      });
    }
  }

  changeEditMode = () => {
    console.log("toggle edit from: " + this.state.editMode);
    this.setState({
      editMode: !this.state.editMode
    });
  };

  save(child) {
    console.log("Saving edit: ");
    const boundFunction = function() {
      window.location.reload();
    };
    const errorFunction = function(error) {
      console.log("BIG ERROR " + error);
      this.setState({
        error: error
      });
    }.bind(this);
    updateChildProfile(child, boundFunction, errorFunction);
  }

  changePassword(child) {
    console.log("Saving edit: ");
    const boundFunction = function() {
      window.location.reload();
    };
    const errorFunction = function(error) {
      this.setState({
        error: error
      });
    }.bind(this);
    updateChildPassword(child, boundFunction, errorFunction);
  }

  render() {
    const { editMode, child } = this.state;
    const showstats = false;
    const active = child && child.userid ? child.userid : "";
    return (
      <div>
        <Header title="Profile" />
        <div className="profile-area">
          <div className="sk-container">
            <div className="profiles-main-warpp">
              <ProfileNavigation active={active} />
              <div className="error-text">{this.state.error}</div>
              {child && (
                <div className="right-content-warp">
                  <div className="right-content-inner">
                    <div className="help-content">
                      <div className="help-title">
                        <h4>{child.name}</h4>
                      </div>
                      <div className="alessandra-text">
                        <div className="staric-list">
                          <ul>
                            <li className="active">
                              <a href="">PROFILE</a>
                            </li>
                            {/* <li>
                              <a href="">ACHIEVEMENTS</a>
                            </li>
                            <li>
                              <a href="">STATISTICS</a>
                            </li> */}
                          </ul>
                        </div>
                        {editMode && (
                          <EditChildForm
                            child={child}
                            onSave={this.save}
                            onClose={this.changeEditMode}
                          />
                        )}
                        {!editMode && (
                          <div
                            className="profile-info"
                            style={{ padding: 30 + "px" }}
                          >
                            <span className="right">
                              <img
                                src={getImage("edit-icon.png")}
                                onClick={this.changeEditMode}
                                alt="edit"
                                style={{ width: 36 + "px" }}
                              />
                            </span>
                            <div className="pro-pic hide-edit">
                              <span className="left">
                                <img src={getAvatarImage(child)} alt="" />
                              </span>
                            </div>
                            <div className="pro-info">
                              <div className="single-pro-info">
                                <span>AVATAR</span>
                                <h4>{child.userid}</h4>
                              </div>
                              <div className="single-pro-info">
                                <span>BIRTHDAY</span>
                                <h4>
                                  {child.birthday > 0 &&
                                    new Intl.DateTimeFormat("en-US", {
                                      month: "long",
                                      day: "2-digit",
                                      year: "numeric"
                                    }).format(child.birthday)}
                                  {child.birthday <= 0 && "--"}
                                </h4>
                              </div>
                              <div className="single-pro-info">
                                <span>SCHOOL</span>
                                <h4>{child.school ? child.school : "--"}</h4>
                              </div>
                            </div>
                            <div className="pro-tags">
                              <span>GRADE</span>
                              <div className="pr-tag">
                                <button>GRADE {child.grade}</button>
                              </div>
                            </div>
                            <div className="pro-tags">
                              <span>SUBJECTS</span>
                              <div className="pr-tag">
                                {/* <button>MATH</button>
                                <button>SCIENCE</button> */}
                                {getSubjects().map(s => (
                                  <button>{displaySubject(s)}</button>
                                ))}
                              </div>
                            </div>
                            <div className="single-pro-info" />
                            <div className="single-pro-info">
                              <span>Change password</span>
                              <EditChildForm
                                child={child}
                                changePassword={this.changePassword}
                                hideCancel="hideCancel"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default requireAuthentication(Child);
