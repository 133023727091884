import React, { PropTypes } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { getImage } from "../../../utils/image-utils";

const SubjectChallengeTypes = ({
  subjectMetrics,
  learningMetrics,
  testingMetrics,
}) => {
  const learningCardinality = learningMetrics? learningMetrics.lessonCardinality: 0;
  const testingCardinality = testingMetrics ? testingMetrics.lessonCardinality: 0;
  // const lessonCardinality = subjectMetrics.lessonCardinality;
  const totalCardinality = learningCardinality + testingCardinality;
  const learningPercentage = new Intl.NumberFormat("en-US", {maximumFractionDigits: 0,}).format((learningCardinality * 100) / totalCardinality);
  const testingPercentage = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0, }).format((testingCardinality * 100) / totalCardinality);

  console.log("SubjectChallengeTypes learningCardinality: " + learningCardinality);
  console.log("SubjectChallengeTypes testingCardinality: " + testingCardinality);
  console.log("SubjectChallengeTypes totalCardinality: " + totalCardinality);
  console.log("SubjectChallengeTypes learningPercentage: " + learningPercentage);
  console.log("SubjectChallengeTypes testingPercentage: " + testingPercentage);

  return (
    <div class="col-lg-6 mt-3">
      <div class="sk-card">
        <div class="card-body p-md-4 p-3">
          <h3 class="text-gray">Score summary</h3>
          <div class="row text-center justify-content-between">
            <div class="col-md-5 col-sm-5 my-3">
              <div class="pie-cart my-auto text-center">
                <CircularProgressbarWithChildren
                  value={testingPercentage}
                  maxValue={100}
                  text={`${""}`}
                  strokeWidth="5"
                  styles={buildStyles({
                    textColor: "rgb(108, 124, 147)",
                    pathColor: "#ae97ff", // yellow #F5C818
                    trailColor: "#feae91", // red #F86456
                  })}
                >
                  <span>{totalCardinality} Challenges</span>
                </CircularProgressbarWithChildren>
              </div>
            </div>

            <div class="col-md-7 col-sm-5 my-auto">
              <div class="col-sm-12 py-2 chart-label text-center">
                <div class="strip-count-subject">{learningPercentage}%</div>
                <div class="learning-label">Learning</div>
                <div class="strip-count-subject mt-4">{testingPercentage}%</div>
                <div class="testing-label">Testing</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectChallengeTypes;
