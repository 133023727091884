import React from 'react';
import PropTypes from 'prop-types';

// import './../LessonContent/ChallengeComplete.css';
import './ErrorHandler.css';

import { getImage } from '../../../utils/image-utils';

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }

  render() {
    return (
      <div id="error-overlay-container">
        <div class="error-area">
          <div class="error-area-inner">
            <div class="sk-container">
              <div class="error-txt">
                <img src={getImage('connection-error.svg')} alt="error"/>
                <h4>{this.props.errorTitle}</h4>
                <p>{this.props.errorMessage}</p>
                <a onClick={() => this.props.onAction()}>Request Access</a>
                <a class="black btn" onClick={() => this.props.onClose()}>
                  CANCEL
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ErrorHandler.propTypes = {
  onRetry: PropTypes.func,
};

export default ErrorHandler;
