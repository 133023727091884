import React, { useEffect, useState, useRef } from "react";
import uniqueId from "react-html-id";
import Moment from "react-moment";

import { getImage } from "../../../utils/image-utils";
import "./NotificationsWidget.css";
import { clearNotifications } from "../../../utils/notification-utils";
import ShowProblem from "../LessonContent/ShowProblem";

const NotificationsWidget = ({ notifications, onClose, getUniqueId }) => {
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click do nothing
      return;
    }
    // outside click close card
    onClose();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const dismiss = e => {
    clearNotifications();
    onClose();
  };

  console.log("ProfileCard notifications# : " + notifications);

  const showNotifications = notifications && notifications.length > 0;
  const hasNewNotifications = notifications
    ? notifications.filter(n => n.notificationStatus === "CREATED").length > 0
    : false;
  return (
    <div id="overlay-container">
      <div className="settings-wrapper">
        <div ref={node} className="settings-box">
          <a onClick={() => onClose()}>
            <img
              id={getUniqueId("notifications-close")}
              className="close"
              src={getImage("Group-2208.png")}
            />
          </a>
          <h1>Notifications</h1>
          {showNotifications && (
            <div
              id={getUniqueId("notifications-messages")}
              className="settings-box-options notification-box-options"
            >
              <ul>
                {notifications.map((n, index) => {
                  let message = n.lessonKey;
                  if (n.notificationType.toLowerCase() === "applause") {
                    message = n.lessonKey + " clapped for you.";
                  }
                  if (n.notificationType.toLowerCase() === "complete_request") {
                    message =
                      "You have received a request to complete " + n.lessonName;
                  }
                  const duration =
                    new Date().getTime() - n.firstActivity <
                    1000 * 60 * 60 * 24;
                  return (
                    <li
                      id={getUniqueId("notifications-message-" + index)}
                      className={n.notificationType.toLowerCase()}
                    >
                      <div>
                        <p className={n.notificationStatus.toLowerCase()}>
                          {message}
                        </p>
                        <span className="bold-text">
                          {duration && (
                            <Moment fromNow>{n.firstActivity}</Moment>
                          )}
                          {!duration && (
                            <Moment format="MMM Do YYYY">
                              {n.firstActivity}
                            </Moment>
                          )}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {!showNotifications && (
            <div
              id={getUniqueId("notifications-no-messages")}
              className="settings-box-options"
            >
              <p>No pending notifications.</p>
            </div>
          )}
          {showNotifications && hasNewNotifications && (
            <center>
              <button
                id={getUniqueId("notifications-button")}
                className="dismiss-button"
                onClick={dismiss}
              >
                I Know!
              </button>
            </center>
          )}
        </div>
      </div>
      <ShowProblem report={{ pageName: "notifications" }} />
    </div>
  );
};

export default NotificationsWidget;
