import React, { useRef, useEffect, useState } from "react";
import { getImage } from "../../utils/image-utils";

const ConfirmAssignement = ({ assignees }) => {
    console.log("ConfirmAssignement assignees: " + assignees);
  return (
    <div id="overlay-container">


          <div
      class="modal fade parent-dashboard show"
            id="assignChallengeCreate"
            tabindex="-1"
            role="dialog"
            aria-labelledby="assignChallengeCreateLabel"
            style={{ paddingRight: "15px", display: "block" }}
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header justify-content-center py-4">
            <h5 class="modal-title my-sm-2" id="assignChallengeCreateLabel">
              Successfully assigned
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body py-4">
                  <div class="row no-gutters my-3 challenge-row justify-content-center text-center">
                    <div class="col-sm-8">
                      <div class="text-center mb-5">
                        <img
                          src={getImage("np_test.svg")}
                          class="img-fluid"
                    alt="confirm"
                        />
                      </div>
                      <h3>
                        Yay !! Challenge created and assigned successfully{" "}
                      </h3>
                    </div>

                    <div class="col-sm-8 text-center mt-4">
                      <a
                                              href={"/parent/create/challenge/" + assignees}
                        class="btn btn-shadow btn-lg mx-1 my-2 px-sm-5 px-4 btn-primary"
                      >
                        Create another challenge
                      </a>
                      <div class="mt-1">
                                              <a href={"/parent/recommendations/" + assignees}  class="btn-text-purple btn-lg">
                          Back To Dashboard
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default ConfirmAssignement;
