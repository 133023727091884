import React, { useRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getImage } from "../../utils/image-utils";
import { getChildren } from "../../utils/profile-utils";

const PickChallengeDate = ({ assignees, topicKey, lessonKey, finalize, dismiss }) => {
  const [assigneeName, setAssigneeName] = useState(null);
  const [dueDate, setDueDate] = useState(null);

  var someDate = new Date();
  var numberOfDaysToAdd = 7;
  someDate.setDate(someDate.getDate() + numberOfDaysToAdd); 
  
  const node = useRef();

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click do nothing
      return;
    }
    // outside click close card
    dismiss();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const createChallenge = () => {
    const assigneeNameValue = assigneeName ? assigneeName : assignees;
    const dueDateValue = dueDate ? dueDate : someDate;
    finalize(assigneeNameValue, dueDateValue);
  };

  const handleChildChange = (event) => {
    console.log("handleChildChange child: " + event.target.value);
    setAssigneeName(event.target.value);
  }

  return (
    <div ref={node} 
            class="modal fade show parent-dashboard"
            id="assignChallengeCreate"
            tabindex="-1"
            role="dialog"
            aria-labelledby="assignChallengeCreateLabel"
            style={{ paddingRight: "15px", display: "block" }}
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header justify-content-center py-4">
                  <h5 class="modal-title my-sm-2" id="assignChallengeNextLabel">
                    Assign Challenge
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" onClick={dismiss}>
                      ×
                    </span>
                  </button>
                </div>
                <div class="modal-body py-4">
                  <div class="row no-gutters my-3 challenge-row justify-content-center text-center">
                    <div class="col-sm-8">
                      <h3>Challenge should be submitted by:</h3>
                      <div class="input-group input-group-calendar mb-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            <img
                              src={getImage("Calendar.svg")}
                              alt="Calendar"
                            />
                          </span>
                        </div>
                        <DatePicker
                          className="form-control"
                          value={new Intl.DateTimeFormat("en-US", {
                              month: "long",
                              day: "2-digit",
                              year: "numeric"
                          }).format(dueDate ? dueDate : someDate)}
                          selected={dueDate ? dueDate : someDate}
                          onChange={setDueDate}
                          minDate={new Date()}
                        />
                      </div>
                      <p>
                        <small>
                          By default challenge submitted date is 7 days from
                          date of assigning
                        </small>
                      </p>
                    </div>

                    <div class="col-sm-8 mt-5">
                      <h3>You are assigning the challenge to </h3>
                      <select
                        class="btn text-normal btn-secondary"
                        value={assigneeName ? assigneeName : assignees}
                        onChange={handleChildChange}
                      >
                        {getChildren().map((c) => (
                          <option value={c.userid}>{c.name}</option>
                        ))}
                      </select>
                    </div>

                    <div class="col-sm-8 d-flex flex-wrap justify-content-center mt-4">
                      <button
                        class="btn btn-lg mx-1 my-2 px-sm-5 px-4 btn-default"
                  onClick={dismiss} style={{width: "140px"}}
                      >
                        BACK
                      </button>
                      <button
                        data-toggle="modal"
                        data-target="#assignChallengeCreate"
                        class="btn btn-lg mx-1 my-2 px-sm-5 px-4 btn-primary"
                  onClick={() => createChallenge()} style={{ width: "140px" }}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  );
};

export default PickChallengeDate;
