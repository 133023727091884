import React, { Component } from "react";

import Header from "../components/Header";
import ProfileNavigation from "../components/ProfileNavigation";
import { getImage } from "../utils/image-utils";

const PrivacyPolicy = () => {
  return (
    <div className="right-content-warp">
      <div className="right-content-inner">
        <div className="help-content">
          <div className="help-title">
            <h4>Privacy Policy</h4>
          </div>

          <div className="modal-bodyy">
            <div className="modal-right-text">
              <p>Effective Date: February, 2020 </p>
              <br />
              <h4>
                About this Privacy Policy and Our Commitment to Your Privacy
              </h4>
              <p>
                SmartKids.io (“SmartKids”, “we”, “us”, “our”) as fully owned and
                operated by Gyan.io LLC provides solutions and services
                (collectively, the “Services”) that may, among other things,
                provide a platform for educators, parents and children to
                improve and enhance the learning experience. We provide this
                privacy policy (“Privacy Policy”) to outline the information
                SmartKids.io collects from users of the Services (“Users”,
                “you”) and how we may use and disclose this information (the
                “Information”). It also describes the choices available to you
                regarding our use of your Information and how you can manage and
                update this Information. All solutions and services that are
                governed by this Privacy Policy will include a link to and/or
                copy of this Privacy Policy on the home page of the applicable
                website. Some online services offered by or affiliated with
                SmartKids.io may be governed by a separate privacy policy.
              </p>
              <br />
              <h4>
                Your Acknowledgment of the Applicability of this Privacy Policy
                by Using the Services
              </h4>
              <p>
                By using the Services, or any other website, online service or
                mobile application where this Privacy Policy is posted, you
                acknowledge that we will collect, use, disclose, and store
                Information as described in this Privacy Policy.
              </p>
              <br />
              <h4>Solutions or Services Covered by this Privacy Policy</h4>
              <p>
                We may reference certain types or groupings of SmartKids.io
                solutions or services in this Privacy Policy where we believe
                information about those solutions or services might help you
                better understand our data collection and use practices related
                to those solutions or services.{" "}
              </p>
              <br />
              <p>
                Our product(s) and service(s) covered by this Privacy Policy
                include, without limitation:{" "}
              </p>
              <br />
              <p>
                <li>
                  SmartKids.io parent portal that is accessible via an online
                  sign on that provides a parent full visibility and control of
                  their child’s progress. The platform allows for reports,
                  bidirectional communication which is monitored and stored and
                  assignment of new challenges. The management tools of the
                  platform provide actionable insights and real time feedback to
                  parents.
                </li>
                <li>
                  SmartKids.io Teacher portal that allows a teacher full control
                  and visibility of a classroom via an online sign on. The
                  teacher has access to aggregate reports, benchmarks and
                  individual performance metrics for each student in the
                  classroom. The platform allows for bidirectional communication
                  which is monitored and stored and assignments of new
                  challenges.
                </li>
                <li>
                  SmartKids.io Child portal allows a student to complete
                  assigned challenges, homework and practice sessions via an
                  online sign on. The platform allows for bidirectional
                  communication which is monitored and stored.{" "}
                </li>
              </p>
              <br />
              <h4>The Information We Collect</h4>
              <br />
              <p>
                We will collect Information in a variety of ways, including
                directly from you, through automatic means, or from third
                parties. The Information we collect may be obtained in the
                following ways:
              </p>
              <br />
              <h4>Information You Provide to Us</h4>
              <br />
              <p>
                <u>SmartKids.io Website</u>
              </p>
              <br />
              <p>
                SmartKids.io is the sole owner of Information collected on its
                company website. We will not sell, share, or rent this
                Information to others in ways different from what is disclosed
                in this Privacy Policy. SmartKids.io collects Information from
                our users at several different points on our website. Our
                website offers several opportunities for visitors to contact us.
                These “contact” forms are often linked to our sales and support
                teams and require users to give contact information such as
                name, phone number, email address, etc. We use this contact
                information to send users information about our company.
              </p>
              <br />
              <p>
                We will never share, sell, or rent your personal information
                with third parties for their promotional use. Occasionally, we
                enter into contracts with third parties so that they can assist
                us in servicing you. The contracts prohibit them from using any
                of your personal information for their own purposes, and they
                are required to maintain the confidentiality of the information
                we provide to them.
              </p>
              <br />
              <p>
                Our web site provides links to third-party websites, such as
                those of our partners and clients. While on these sites,
                SmartKids.io or its partners may collect information about you.
                Because SmartKids.io does not control the information policies
                or practices of these third parties, you should review their
                privacy policies to learn more about how they collect and use
                personally identifiable information.
              </p>
              <br />
              <p>
                <u>SmartKids.io Solutions/Services</u>
              </p>
              <br />
              <p>
                When you use the Solutions/Services, e.g., where you are
                actively responding to a homework challenge or assigning a
                challenge, etc., you may provide certain information directly to
                us.
              </p>
              <br />
              <p>
                <b>Student Information.</b> Once registered, a School may
                provide information about its students, such as student names or
                other identifiers, passwords, e-mail address for the student or
                the student's parent or legal guardian, and educational level
                and topic of study. Personally identifiable information
                collected from or about students, along with other information
                associated with that personally identifiable information is "
                <b>Student Data.</b>" The School may elect to provide user names
                or identifiers which are not readily identifiable to anyone
                outside of the School community in lieu of a full student name,
                at its discretion. Additionally, some Schools may elect to use a
                single-sign on (SSO) service rather than usernames and passwords
                to authenticate student access.
              </p>
              <br />
              <p>
                <b>Parent and Child Information.</b> When a parent or guardian
                ("Parent") registers for an account on our Service or
                corresponds with us, we may collect personal information such as
                a name, e-mail address, payment information, username and
                password. We may also collect information about the child(ren)
                authorized by the Parent to use the Service through the Parent's
                account, including a profile name for the child user and
                selection of a profile avatar. Each child user profile must be
                associated with a Parent account, and children cannot access the
                Service without the Parent first signing in to the Parent
                account with the Parent's sign in credentials.
              </p>
              <br />
              <p>
                <b>Learning and Activity Information.</b> During the course of
                providing the Service, we collect information about your use of
                the Service, as well as any information that you submit to the
                Service, such as answers to questions. In addition, we may ask
                you for personal information at other times, such as when you
                contact our technical support team, send us an e-mail, complete
                a survey or otherwise communicate with SmartKids.io.
              </p>
              <br />
              <h4>Information that is Automatically Collected</h4>
              <br />
              <p>
                We and third parties may use automated means to collect
                information about you, your computer or other device that you
                used to access the Services, and your use of the Services. These
                automated means include common technologies such as cookies or
                similar technologies (see below for an overview on cookies and
                tokens). These technologies may help us analyze usage trends
                through analytics, track user geolocation for providing
                custom-tailored services, etc.
              </p>
              <br />
              <h4>Cookies and Tokens</h4>
              <br />
              <p>
                Cookies are files that websites install in your computer or
                other Internet-connected device to uniquely identify your
                browser or to store information or settings on your device. Our
                Services may use HTTP cookies, HTML5 cookies, Flash cookies and
                other types of local storage (such as browser-based or
                plugin-based local storage). We use cookies to personalize the
                content you see on our platform. Most web browsers can be set to
                disable the use of cookies. However, if you disable cookies, you
                may not be able to access functionality on our website.
                Additionally, tokens are used primarily for authentication of
                valid users and granting appropriate authorization into a
                web-based application, thus protecting users’ privacy. In
                conjunction, cookies and tokens (collectively, “Tracking
                Technologies”) also may be used for administering the Services,
                including without limitation, to remember users’ settings, to
                contact you about the Services, and to improve our internal
                operations and the contents of our solutions/services.
              </p>
              <br />
              <h4>Web Logs</h4>
              <br />
              <p>
                In conjunction with the gathering of data through cookies, web
                servers may log records such as your device type, operating
                system type, device advertising identifier, browser type,
                domain, and other system settings, as well as the language your
                system uses and the country and time zone where your device is
                located. The web server logs also may record the address of the
                web page that referred you to our Solutions/Services, the IP
                address (and associated city and state or province for the IP
                address) of the device you use to connect to the Internet, etc.
              </p>
              <br />
              <h4>
                Additional Technologies on SmartKids.io and Other Web Sites
              </h4>
              <br />
              <p>
                {" "}
                SmartKids.io may contain additional technologies including
                social media features, such as the Facebook button, and widgets
                or interactive mini-programs that run on the website. These
                features may collect your Internet Protocol address, which page
                you are visiting on the smartkids.io website, and may set a
                cookie to enable the feature to function properly. For a
                description on how social media services and other third-party
                platforms handle your information, please refer to their
                respective privacy policies and terms of use.
              </p>
              <br />
              <p>
                On smartkids.io and other websites, we may also allow
                third-party service providers to use cookies and other
                technologies to collect information and to track browsing
                activity over time and across third-party websites such as web
                browsers used to read our websites, which websites are referring
                traffic or linking to the websites, and to deliver targeted
                advertisements to you on third party websites. We do not control
                these third-party technologies and their use is governed by the
                privacy policies of third parties using such technologies. For
                more information about third-party advertising networks and
                similar entities that use these technologies, see{" "}
                <u>
                  <a href="http://www.aboutads.info/consumers">
                    http://www.aboutads.info/consumers
                  </a>
                </u>
                , and to opt-out of such ad networks’ and services’ advertising
                practices, go to{" "}
                <u>
                  <a href="http://www.aboutads.info/choices">
                    http://www.aboutads.info/choices
                  </a>
                </u>{" "}
                and{" "}
                <u>
                  <a href="http://www.networkadvertising.org/choices">
                    http://www.networkadvertising.org/choices
                  </a>
                </u>
                . Once you click the link, you may choose to opt-out of such
                advertising from all participating advertising companies or only
                advertising provided by specific advertising companies. We do
                not control these opt-out choices.
              </p>
              <br />
              <p>
                <b>Mobile Application Technologies</b>
              </p>
              <br />
              <p>
                Our mobile applications may also collect information specific to
                use of your mobile device, such as unique device identifiers,
                etc.
              </p>
              <br />
              <p>
                <b>How We Use Information Collected</b>
              </p>
              <br />
              <p>
                {" "}
                We and third-party vendors acting on our behalf, may use the
                Information that we collect through our Solutions/Services for a
                variety of purposes, including to:
              </p>
              <br />
              <p>
                <b>To provide and maintain the Platform.</b> We use the
                information we collect to deliver the Platform to you. For
                example, we need to use your information to process payments,
                personalize learning curriculum, provide Schools, Parents and
                students with information and reports about student and child
                performance and use of the Platform, respond to inquiries and
                provide customer support.
              </p>
              <br />
              <p>
                <b> To improve, personalize, and develop the Platform.</b> We
                use information to tailor the content and information that we
                may send or display to users, to offer personalized content and
                instructions, and to otherwise personalize your experience while
                using the Platform, including on various devices you may use to
                access the Platform. We collect statistics to better understand
                how users access and use our Platform, monitor the effectiveness
                of our Platform, detect usage patterns, and to diagnose or fix
                technology issues. We also use information to demonstrate the
                effectiveness of the Platform and perform research, and to
                develop, support, and improve our Platform and other educational
                products and services.
              </p>
              <br />
              <p>
                <b>To communicate with you.</b> We use your information to
                provide transactional notifications for certain activities
                relating to your use of our Platform. For example, we may send
                e-mail notifications when a user completes an activity, to
                provide receipt for payment or other subscription notices. From
                time to time, we may send periodic promotional or informational
                emails to School or Parent users. We do not use Student Data to
                send marketing communications, and we do not send marketing
                communications to student or child users. You may opt-out of
                certain communications (e.g., marketing or certain notifications
                about your use of the Platform) by following the opt-out
                instructions contained in the email.
              </p>
              <br />
              <p>
                <b>
                  To promote safety and security and respond to legal processes.
                </b>{" "}
                We use information to promote the safety and security of our
                Platform, our uses and other third parties. For example, we may
                use the information to authenticate users, facilitate secure
                payments, detect and prevent fraud and other harmful activities,
                respond to legal requests or claims, and enforce our terms and
                policies.
              </p>
              <br />
              <p>
                {" "}
                Except where prohibited by law, and only to the extent permitted
                by SmartKids.io agreements with applicable SmartKids.io
                partners, we may also use your Information in connection with
                any other services.
              </p>
              <br />
              <h4>
                Creation and Use of Combined Data, De-Identified Data, and
                Aggregated Data
              </h4>
              <br />
              <p>
                {" "}
                As permitted by law and by SmartKids.io’s agreements with
                applicable SmartKids partners, SmartKids, and any third-party
                vendors acting on SmartKids’s behalf, may aggregate and
                de-identify your Information and/or combine your Information
                with other information maintained or available to SmartKids and
                use or disclose such information as follows:
              </p>
              <br />
              <p>
                <li>
                  We may use aggregated or combined data to communicate with you
                  about our solutions and services and disclose such aggregated
                  or combined data to SmartKids Partners in connection with
                  providing the Services.{" "}
                </li>
                <li>
                  We may also use and disclose de-identified data, de-identified
                  aggregated data, and/or de-identified combined data for any
                  business purpose, which may include, without limitation,
                  statistical and marketing purposes, including, but not limited
                  to, maintenance, reporting, analysis, fraud prevention,
                  general market reports and/or improving our solutions and
                  services.
                </li>
              </p>
              <br />
              <h4> How We Protect Your Information</h4>
              <br />
              <p>
                The security of your Information is important to us. We follow
                security best practices and work with multiple security
                standards to protect the sensitive information submitted to us
                from loss, interference, misuse, unauthorized access,
                disclosure, alteration or destruction, both during transmission
                and once we receive it. These safeguards vary based on the
                sensitivity of the Information that we collect, process and
                store and the current state of technology. No method of
                transmission over the Internet, or method of electronic storage,
                is 100% secure, however. Therefore, we cannot guarantee its
                absolute security. We also maintain procedures to help ensure
                that such data is reliable for its intended use and is accurate,
                complete and current. If you have any questions about data
                security and integrity on our Services, you can contact us at{" "}
                <a
                  href="mailto:compliance@smartkids.io?Subject=Privacy%20policy"
                  target="_top"
                >
                  {" "}
                  compliance@smartkids.io{" "}
                </a>
                .
              </p>
              <br />
              <p>
                Maintaining the security of your personal information also
                requires your cooperation and involvement. For your protection,
                remember to sign out of all accounts before closing your
                browser. There is a sign out link available on top right corner
                of the Platform. After signing out, make sure to close all
                browser windows. In addition, do not use the "Remember" feature
                if you are signing in to your account from a computer that other
                persons may have access to. This is to ensure that others cannot
                access your personal information if you share a computer with
                someone else or are using a public computer.
              </p>
              <br />
              <p>
                Please keep your password secure. If you have forgotten your
                password, you may request a new one by contacting
                <a
                  href="mailto:help@smartkids.io?Subject=Privacy%20policy"
                  target="_top"
                >
                  {" "}
                  help@smartkids.io{" "}
                </a>
                . A new password will be sent to the e-mail address you
                specified during registration.
              </p>
              <br />
              <p>
                If you have any concerns about the security of your account or
                the security of the Service, we ask that you report your concern
                to us immediately at
                <a
                  href="mailto:compliance@smartkids.io?Subject=Privacy%20policy"
                  target="_top"
                >
                  {" "}
                  compliance@smartkids.io{" "}
                </a>
                . While we are unable to respond to all security concerns, we
                appreciate your feedback and take all reported concerns
                seriously.
              </p>
              <br />
              <h4>How We Share Information</h4>
              <br />
              <p>
                We may disclose your Information in the following circumstances:
              </p>
              <br />
              <p>
                <li>
                  <b>Third parties with consent.</b> We share information with
                  consent and at the direction of Schools and Parents. For
                  example, a School or Parent may direct SmartKids to share
                  specific information with another individual, such as when a
                  teacher directs SmartKids to send a communication to a parent.
                  Similarly, we may share personal information with third
                  parties.
                </li>
                <li>
                  <b> Others within the School.</b> SmartKids may share
                  information collected from users associated with a School with
                  other users or persons designated by the School, such as
                  teachers and school administrators of that School.
                </li>
                <li>
                  <b>Vendors and Service Providers.</b> SmartKids may share
                  information with our trusted vendors, third party service
                  providers and individuals to provide services or products for
                  us or on our behalf, which may include analytics, hosting,
                  billing and marketing. Vendors and service providers who have
                  access to Student Data, if any, shall be contractually bound
                  to uphold privacy and confidentiality terms no less protective
                  than those provided herein.
                </li>
                <li>
                  <b> Safety, Security and Compliance with Law</b> – Your
                  Information and the contents of your communications through
                  the Services may be disclosed to third-parties as required by
                  law, such as to comply with a subpoena or similar legal
                  process, or when we reasonably believe disclosure is necessary
                  to protect our rights (including to enforce our Terms of
                  Service), protect your safety or the safety of others,
                  investigate fraud, report improper or unlawful activity, or
                  respond to a government request.{" "}
                </li>
                <li>
                  <b>Subsidiaries and Affiliated Companies</b> – Except where
                  prohibited by law or precluded by contract, we may share your
                  Information with any subsidiaries or other affiliated
                  companies under common control with SmartKids for the purposes
                  set out in this Privacy Policy.{" "}
                </li>
                <li>
                  <b> Sale, Merger or Similar Transaction</b> – We may share
                  your Information in connection with any proposed or actual
                  merger, reorganization, a sale of some or all our assets, a
                  financing or acquisition of all or a portion of our business
                  by another company, or to a receiver, trustee or successor in
                  interest in bankruptcy, in which case such company may
                  continue to process your Information as set forth in this
                  Privacy Policy (except where prohibited by law) or otherwise
                  with your consent.
                </li>
                <li>
                  <b> Aggregated/De-Identified Information</b>– We may create
                  and share aggregated/de-identified information about use of
                  the Services, such as by creating reports on usage trends.
                </li>
              </p>
              <br />
              <h4>Third-Party Websites and Services</h4>
              <br />
              <p>
                {" "}
                SmartKids does not display any targeted advertising on the
                Service. Our Services may contain links that enable you to visit
                or use other third-party websites, resources or programs.
                However, we do not have control over the other websites,
                resources, or programs that you choose to visit, so this Privacy
                Policy does not apply to Information collected or that you
                provide while visiting such other websites, resources, or
                programs. You should refer to the privacy policies, if any,
                applicable to the other websites, resources, or programs.
              </p>
              <br />
              <h4> Children</h4>
              <br />
              <p>
                {" "}
                SmartKids does not permit children under the age of 13 to create
                an account and does not knowingly collect personally identifying
                information from children under the age of 13 without the
                consent and at the direction of a Parent. Please contact us at
                <a
                  href="mailto:compliance@smartkids.io?Subject=Privacy%20policy"
                  target="_top"
                >
                  {" "}
                  compliance@smartkids.io{" "}
                </a>{" "}
                if you believe we have inadvertently collected information from
                a child under 13 without parental consent so that we may delete
                the information as soon as possible.
              </p>
              <br />
              <p>
                Parents who purchase a subscription to the SmartKids Service may
                set up a Child profile associated with the Parent's account so
                that children under 13 may access the Service under the Parent's
                supervision.
              </p>
              <br />
              <p>
                When SmartKids is used by a School in an educational setting,
                the School may authorize SmartKids to collect information from a
                child under 13.
              </p>
              <br />
              <h4>Our Data Retention Practices</h4>
              <br />
              <p>
                {" "}
                Information will be retained only for so long as reasonably
                necessary for the purposes set out above, considering criteria
                such as applicable rules on statute of limitations and at least
                the duration of your use of our Services. We may retain
                information for our internal business purposes, which may extend
                beyond the termination or cancellation of your subscription or
                user account. For example, we may retain certain data as
                necessary to prevent fraud or future abuse, for recordkeeping or
                other legitimate business purposes, or if required by law. We
                may also retain and use information which has been de-identified
                or aggregated such that it can no longer reasonably identify a
                particular individual. All retained personal information will
                remain subject to the terms of this Privacy Policy. Information
                may persist in copies made for backup and business continuity
                purposes for additional time. We will not be required to delete
                any information which has been de-identified or disassociated
                with personal identifiers such that the remaining information
                cannot reasonably be used to identify a particular individual.
              </p>
              <br />
              <p>
                <b>Student Data.</b>
                We will not knowingly retain Student Data beyond the time period
                required to support an educational purpose, unless authorized by
                a School or parent. We do not delete or de-identify any Student
                Data from an active student user account associated with a
                School except at the direction of the School. The School is
                responsible for maintaining current student rosters and
                identifying Student Data which the School no longer needs for an
                educational purpose by removing students from the school's
                master roster or by submitting a deletion request.
              </p>
              <br />
              <h4>Modifications to this Privacy Policy</h4>
              <br />
              <p>
                From time to time, we may update this Privacy Policy to reflect
                changes to our information practices. If we make changes to this
                Privacy Policy, they will be reflected in an updated Privacy
                Policy that will be posted on our website, and all changes will
                be effective upon such posting or upon any later date specified
                by us in writing. We encourage you to periodically review this
                page for the latest information on our privacy practices. You
                can determine when the Privacy Policy was last revised by
                referring to the “Effective Date” legend at the top of the
                Privacy Policy. By continuing to use the Services or any
                services following the effective date of any updated Privacy
                Policy, you understand and accept the applicability of the terms
                and conditions of such updated Privacy Policy.
              </p>
              <br />
              <h4>How to Contact Us</h4>
              <br />
              <p>
                Please read this Privacy Policy carefully. If you have questions
                or complaints regarding our Privacy Policy or privacy practices,
                please contact us at
                <a
                  href="mailto:compliance@smartkids.io?Subject=Privacy%20policy"
                  target="_top"
                >
                  {" "}
                  compliance@smartkids.io{" "}
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* <button className="accordion">Third-Party Notices</button> */}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
