import React from 'react';
import PropTypes from 'prop-types';

import { getImage } from '../../../utils/image-utils';

class AlphaKeyboard extends React.Component {

  render() {
    console.log('AlphaKeyboard render props: ' + JSON.stringify(this.props));
    const renderKeys = this.props.letters.map((e, i) => {
      return (
        <li>
          <a key={i} onClick={() => this.props.onLetter(e)}>
            {e}
          </a>
        </li>
      );
    });

    return (
      <div className="keybord-area keyboard-page">
        <div className="sk-container">
          <div className="keyboard">
            <ul>
              {renderKeys}
              <li>
                <a
                  className="cross"
                  onClick={() => this.props.clearLastLetter()}
                >
                  <img src={getImage('delete.svg')} alt="" />
                </a>
              </li>
              <li>
                <a
                  className="enter"
                  onClick={() => this.props.checkAnswer(this.props.answer)}
                >
                  <img src={getImage('checked.svg')} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

AlphaKeyboard.propTypes = {
  hasTutorial: PropTypes.bool,
  question: PropTypes.object.isRequired,
  checkAnswer: PropTypes.func,
  learn: PropTypes.func,
};

export default AlphaKeyboard;
