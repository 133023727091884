import React, { Component } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { getProfile, getToken, getSubjects } from "../../utils/profile-utils";
import { displaySubject } from "../../utils/display-utils";

class Step1 extends Component {
  // default State object
  state = {
    grade: null,
    subject: null,
    showNext: false,
  };

  constructor(props) {
    super(props);

    this.state.grade = props.grade;
    this.state.subject = props.subject;
    this.state.subjects = getSubjects();

    if (this.state.grade && this.state.subject) {
      this.state.showNext = true;
    }

    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
  }

  handleGradeChange(event) {
    const { subject } = this.state;
    let grade = event.target.value;
    let showNext = false;
    if (grade && grade > 0 && subject && subject.length > 0) {
      showNext = true;
    }
    this.setState({
      grade: grade,
      showNext: showNext,
    });
  }

  handleSubjectChange(event) {
    const { grade } = this.state;
    let subject = event.target.value.trim();
    let showNext = false;
    if (grade && grade > 0 && subject && subject.length > 0) {
      showNext = true;
    }
    this.setState({
      subject: subject,
      showNext: showNext,
    });
  }

  render() {
    const { grade, subject, gradeError, subjectError, subjects } = this.state;
    return (
      <div>
        <div className="manage-grade">
          <div className="subject-select">
            <span>Select Grade</span>
            <div className="pr-tag">
              <span>
                <select value={grade} onChange={this.handleGradeChange}>
                  <option value="">Pick grade</option>
                  {/* <option value="1">Grade 1</option> */}
                  <option value="2">Grade 2</option>
                  <option value="3">Grade 3</option>
                  <option value="4">Grade 4</option>
                  <option value="5">Grade 5</option>
                </select>
              </span>
              {gradeError && <span className="error-text">{gradeError}</span>}
            </div>
          </div>
        </div>
        <div className="subject-select">
          <span>Select Subject</span>
          <div className="pr-tag">
            <span>
              {subjects && (
                <select value={subject} onChange={this.handleSubjectChange}>
                  <option value=""> Pick a subject</option>

                  {subjects.map((c) => (
                    <option value={c}>{displaySubject(c)}</option>
                  ))}
                  <option value={"COMMON_CORE_MATH"}>
                    {displaySubject("COMMON_CORE_MATH")}
                  </option>
                </select>
              )}
            </span>
            {subjectError && <span className="error-text">{subjectError}</span>}
          </div>
          {this.state.showNext && (
            <div className="next-step-btn manage-btn">
              <a
                className="remove"
                onClick={() => this.props.nextStep(1, grade, subject)}
              >
                Next
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Step1;
