import React from 'react';
import uniqueId from 'react-html-id';

import axios from 'axios';

import { config } from '../../Constants';
import AdminHeader from "../../components/Admin/AdminHeader";

class Issues extends React.Component {
  state = {
    token: null,
    catalog: [],
    showCatalog: false,
  };

  constructor(props) {
    super(props);
    this.state.token = localStorage.getItem('token');

    this.loadCards = this.loadCards.bind(this);
    this.resolve = this.resolve.bind(this);
    this.pickLesson = this.pickLesson.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    this.loadCards();
  }

  loadCards() {
    axios
      .get(config.url.API_BASE_URL + '/api/problem/list', {
        headers: { Authorization: this.state.token },
      })
      .then(response => {
        console.log(response);
        this.setState({
          issues: response.data.object,
        });
      })
      .catch(error => console.log(error));
    axios
      .get(config.url.API_BASE_URL + '/api/admin/lesson/config', {
        headers: { Authorization: this.state.token },
      })
      .then(response => {
        console.log(response);
        this.setState({
          config: response.data.object,
        });
      })
      .catch(error => console.log(error));
  }

  resolve(eventId) {
    axios
      .get(config.url.API_BASE_URL + '/api/problem/resolve/' + eventId, {
        headers: { Authorization: this.state.token },
      })
      .then(response => {
        console.log(response);
        this.loadCards();
      })
      .catch(error => console.log(error));
  }

  pickLesson(lessonId) {
    this.props.history.push('/questions/' + lessonId);
  }

  render() {
    return (
      <div className="admin-page">
        <AdminHeader />
        <div>
          <table>
            {this.state.issues &&
              this.state.issues.map((c, index) => (
                <Issue
                  issue={c}
                  index={index}
                  resolve={this.resolve}
                  onClick={this.pickLesson}
                />
              ))}
          </table>
        </div>
      </div>
    );
  }
}

function Issue(props) {
  const date = Date(props.issue.timestamp);
  return (
    <tr className="clear">
      <td className="">{props.index + 1} &nbsp;</td>{' '}
      <td className="">
        <h5>{props.issue.comment}</h5> &nbsp;
      </td>
      <td className="">Page: {props.issue.pageName} &nbsp;</td>
      <td className="">Subject: {props.issue.subject} &nbsp;</td>
      <td className="" onClick={() => props.onClick(props.issue.lessonId)}>
        Lesson: <b>{props.issue.lessonId} &nbsp;</b>
      </td>
      <td className="">Question: {props.issue.question} &nbsp;</td>
      <td className="">{date.toString()}</td>
      <td className="">
        <b>{props.issue.userid}</b>
      </td>
      <td className="">
        <button
          onClick={() => {
            props.resolve(props.issue.eventId);
          }}
        >
          Resolve
        </button>
      </td>
    </tr>
  );
}

export default Issues;
