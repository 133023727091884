import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { StickyContainer, Sticky } from "react-sticky";

import { config, ADULT_LOADING_IMAGES } from "../../Constants";

import "./../Dashboard/Dashboard.css";
import "./ParentDashboard.css";

import DashboardHeader from "../../components/Teacher/DashboardHeader";
import ChildPicker from "../../components/Parent/Picker/ChildPicker";
import ChildChallenges from "../../components/Parent/ChildChallenges";
import ChallengeHistory from "../../components/Teacher/ChallengeHistory";
import GraspWidget from "../../components/Teacher/GraspWidget/GraspWidget";
import { reportActions, report } from "../../utils/report-utils";
import { requireAuthentication } from "../../components/AuthenticatedComponent";
import { getUserid, getChild } from "../../utils/profile-utils";
import Loader from "../../components/Loader";
import ChallengeHistoryGraph from "../../components/Teacher/ChallengeHistoryGraph";
import Recommendations from "../../components/Parent/Recommendations";
import ParentStorage from "./ParentStorage";

class ParentDashboard extends Component {
  // default State object
  state = {
    limitLessons: true,
    showList: true
  };

  report = {};

  constructor(props) {
    super(props);

    this.report.pageName = "Parent Dashboard";
    this.report.userid = getUserid();

    // this.changeChild = this.changeChild.bind(this);
    // this.changeSubject = this.changeSubject.bind(this);
    this.toggleLimit = this.toggleLimit.bind(this);
    this.toggleView = this.toggleView.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
    this.report.action = reportActions.LOAD_PAGE;
    report(this.report);
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
  }

  toggleLimit() {
    this.setState({
      limitLessons: !this.state.limitLessons
    });
  }

  toggleView() {
    console.log("ChildReportCard toggleView: " + this.state.showList);
    this.setState({
      showList: !this.state.showList
    });
  }

  render() {
    const {
      activeChild,
      subject,
      loading,
      recommendations,
      lessons,
      changeChild,
      changeSubject,
      syllabus
    } = this.props;
    const { limitLessons, showList } = this.state;
    console.log(limitLessons + " == limitLessons ? Render ParentDashboard subject: " + subject + " child " + activeChild);

    const recent = subject === "Recent" ? true : false;
    const activeChildProfile = getChild(activeChild);

    console.log("Active child is: " + activeChild);
    console.log("Active subject is: " + subject);

    const activeChildReports = !loading;

    // const lessons = this.getLessons(subject, subjects, activeChildReports);

    // console.log('======== ParentDashboard: render data ==========');
    // console.log('subject ' + subject);
    // console.log('subjects ' + subjects);
    // console.log('recent ' + recent);
    // console.log("ParentDashboard recommendations " + JSON.stringify(recommendations));

    let createChallengePath = "/create/challenge/";
    if (activeChild && activeChildProfile) {
      createChallengePath =
        "/create/challenge/" +
        activeChildProfile.grade +
        "/" +
        subject +
        "/" +
        activeChild;
    }
    return (
      <div>
        <DashboardHeader />
        <StickyContainer className="challenge-analysis">
          <div className="summary-area">
            <Sticky>
              {({
                style,
                // the following are also available but unused in this example
                isSticky,
                wasSticky,
                distanceFromTop,
                distanceFromBottom,
                calculatedHeight
              }) => (
                <header style={style}>
                  {activeChild && (
                    <ChildPicker
                      child={activeChild}
                      subject={subject}
                      onChangeChild={changeChild}
                      onChangeSubject={changeSubject}
                      createChallengePath={createChallengePath}
                    />
                  )}
                </header>
              )}
            </Sticky>
          </div>
          {loading && (
            <Loader
              image={
                ADULT_LOADING_IMAGES[
                  Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
                ]
              }
            />
          )}{" "}
          {recent && lessons && lessons.length > 0 && (
            <ChildChallenges
              className="clear"
              child={activeChildProfile}
              lessons={lessons}
            />
          )}
          {recent &&
            recommendations &&
            Object.keys(recommendations).map(key => {
              console.log("Loading recommendations: " + key);
              return (
                <Recommendations
                  subject={key}
                  // child is passed from grade. This information must be at topic
                  child={activeChildProfile}
                  recommendations={recommendations[key]}
                />
              );
            })}
          {activeChildReports && (!lessons || lessons.length <= 0) && (
            <div className="attentions-area spaced-container">
              <div className="sk-container">
                <div className="attention-box">
                  <h4>Lets Get Started</h4>
                  <div className="attention-text">
                    <div className="left-attention">
                      <p>
                        <span>
                          Create a challenge for{" "}
                          {activeChildProfile.name.split(" ")[0]}
                        </span>
                      </p>
                    </div>
                    <NavLink activeClassName="active" to={createChallengePath}>
                      <div className="right-attention">
                        <a href="">+ CREATE CHALLENGE</a>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeChildReports && !recent && lessons.length > 0 && (
            <div>
              <div className="spaced-container">
                {!showList && lessons && (
                  <ChallengeHistoryGraph
                    lessonReports={lessons}
                    subject={this.state.subject}
                    showListViewOption={true}
                    toggleView={this.toggleView}
                  />
                )}
                {showList && activeChildReports && (
                  <ChallengeHistory
                    homework={lessons}
                    classCount={this.state.classCount}
                    student={activeChild}
                    isClassReport={false}
                    child={activeChild}
                    limitLessons={limitLessons}
                    subject={subject}
                    homework={lessons}
                    toggleLimit={this.toggleLimit}
                  />
                )}
              </div>
              <div className="spaced-container">
                {activeChildReports && (
                  <GraspWidget
                    syllabus={syllabus}
                    lessonReports={lessons}
                    showReportsByTopic={true}
                    child={activeChild}
                    subject={subject}
                    reportPath={
                      "/parent/report/child/" + activeChild + "/lesson/"
                    }
                    isClassReport={false}
                  />
                )}
              </div>
            </div>
          )}
        </StickyContainer>
      </div>
    );
  }
}

export default requireAuthentication(ParentStorage(ParentDashboard));
