import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./style.css";
import "./style.css";
import "./responsive.css";
import "./main.css";
import "./main-reponsive.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login/Login";
import RequestAccess from "./containers/Login/RequestAccess";
import ResetPassword from "./containers/Login/ResetPassword";
import NewPassword from "./containers/Login/NewPassword";
import Logout from "./containers/Logout";
import StudentProfile from "./containers/Student/Profile/StudentProfile";
import Homework from "./containers/Student/Homework/Homework";
import Questions from "./containers/Student/Lesson/Questions";

import ChooseSubject from "./containers/Student/ChooseSubject/ChooseSubject";
import Challenges from "./containers/Student/Challenges/Challenges";
import Practice from "./containers/Student/Practice/Practice";
import Topic from "./containers/Student/Topic/Topic";
import ParentDashboard from "./containers/Parent/ParentDashboard";
import TeacherDashboard from "./containers/Teacher/TeacherDashboard";
import Grade from "./containers/Grade";
import ClassLessonReport from "./containers/Teacher/ClassLessonReport";
import ParentLessonReport from "./containers/Parent/ParentLessonReport";
import LessonHistoryReport from "./containers/Parent/LessonHistoryReport";
import ChildReportCard from "./containers/Parent/ChildReportCard";
import CreateChallenge from "./containers/CreateChallenge/CreateChallenge";
import Messages from "./containers/Student/Messages/Messages";
import Notfound from "./containers/NotFound/notfound";

import registerServiceWorker from "./registerServiceWorker";
import ParentProfile from "./containers/Parent/ParentProfile";
import Child from "./containers/Parent/Child";
import Settings from "./containers/Settings";
import About from "./containers/About";
import ParentAbout from "./containers/ParentAbout";
import Help from "./containers/Help";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";
import Schooling from "./containers/Schooling";
import ParentHelp from "./containers/ParentHelp";
import ParentPrivacy from "./containers/ParentPrivacy";
import Notifications from "./containers/Notifications";
import ParentMessages from "./containers/Parent/ParentMessages";

import Admin from "./containers/Admin/Admin";
import AdminCatalog from "./containers/Admin/AdminCatalog";
import AdminLessonReport from "./containers/Admin/AdminLessonReport";
import Issues from "./containers/Admin/Issues";
import Register from "./containers/Admin/Register";
import StudentHomework from "./containers/Admin/StudentHomework";
import StudentRoster from "./containers/Admin/StudentRoster";
import RescoreHomework from "./containers/Admin/RescoreHomework";
import AdminStudentReport from "./containers/Admin/AdminStudentReport";
import AdminRoute from "./Routes/AdminRoute";
import ParentPerformance from "./containers/Parent/ParentPerformance";
import SubjectPerformance from "./containers/Parent/SubjectPerformance";
import TopicPerformance from "./containers/Parent/TopicPerformance";
import ParentRecommendations from "./containers/Parent/ParentRecommendations";
import ParentChallengeHistory from "./containers/Parent/ParentChallengeHistory";
import ParentCreateChallenge from "./containers/CreateChallenge/ParentCreateChallenge";
import AdminAssignLesson from "./containers/Admin/AdminAssignLesson";

const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/about" component={About} />
      <Route path="/terms" component={Terms} />
      <Route path="/help" component={Help} />
      <Route path="/login" component={Login} />
      <Route path="/homeschool" component={Schooling} />
      <Route path="/access" component={RequestAccess} />
      <Route path="/reset" component={ResetPassword} />
      <Route path="/newPassword/:token" component={NewPassword} />
      <Route path="/challenges" component={Homework} />
      <Route path="/choose/:mode" component={ChooseSubject} />
      <Route path="/challenge/:subject" component={Challenges} />
      <Route path="/practice/:subject" component={Practice} />
      <Route path="/topic/:subject/:index/:topic" component={Topic} />
      <Route path="/logout" component={Logout} />
      <Route path="/profile" component={StudentProfile} />
      <Route path="/messages" component={Messages} />
      <Route exact path="/parent/create/challenge/:child" component={ParentCreateChallenge} />
      <Route exact path="/parent/create/challenge" component={ParentCreateChallenge} />
      <Route exact path="/create/challenge" component={CreateChallenge} />
      <Route
        exact
        path="/create/challenge/:grade"
        component={CreateChallenge}
      />
      <Route
        exact
        path="/create/challenge/:grade/:subject"
        component={CreateChallenge}
      />
      <Route
        exact
        path="/create/challenge/:grade/:subject/:child"
        component={CreateChallenge}
      />
      <Route path="/questions/:lesson/:id" component={Questions} />
      <Route path="/questions/:lesson" component={Questions} />
      <Route exact path="/progress" component={Challenges} />
      <Route exact path="/parent" component={ParentRecommendations} />
      <Route
        exact
        path="/parent/recommendations"
        component={ParentRecommendations}
      />
      <Route
        exact
        path="/parent/challenges/:child"
        component={ParentChallengeHistory}
      />
      <Route
        exact
        path="/parent/recommendations/:child"
        component={ParentRecommendations}
      />
      <Route
        exact
        path="/parent/recommendations/:child/:subject"
        component={ParentRecommendations}
      />
      <Route exact path="/parent/dashboard" component={ParentDashboard} />
      <Route
        exact
        path="/parent/dashboard/:child"
        component={ParentDashboard}
      />
      <Route
        exact
        path="/parent/dashboard/:child/:subject"
        component={ParentDashboard}
      />
      <Route exact path="/teacher" component={TeacherDashboard} />
      <Route
        path="/teacher/report/lesson/:lesson"
        component={ClassLessonReport}
      />
      <Route
        path="/teacher/report/child/:child/lesson/:lesson"
        component={ParentLessonReport}
      />
      <Route
        path="/parent/report/child/:child/lesson/:lesson"
        component={ParentLessonReport}
      />
      <Route
        path="/parent/performance/:child/:subject/:topic"
        component={TopicPerformance}
      />
      <Route
        path="/parent/performance/:child/:subject"
        component={SubjectPerformance}
      />
      <Route
        path="/parent/performance/:child"
        component={ParentPerformance}
      />
      <Route
        path="/teacher/report/child/:child/subject/:subject/history"
        component={LessonHistoryReport}
      />
      <Route path="/teacher/report/child/:child" component={ChildReportCard} />
      <Route path="/teacher/profile" component={ParentProfile} />
      <Route path="/parent/profile" component={ParentProfile} />
      <Route path="/teacher/about" component={ParentAbout} />
      <Route path="/parent/about" component={ParentAbout} />
      <Route path="/parent/privacy" component={ParentPrivacy} />
      <Route path="/teacher/grade" component={Grade} />
      <Route path="/teacher/help" component={ParentHelp} />
      <Route path="/parent/help" component={ParentHelp} />
      <Route path="/teacher/privacy" component={ParentPrivacy} />
      <Route path="/teacher/notifications" component={Notifications} />
      <Route path="/parent/notifications" component={Notifications} />
      <Route path="/teacher/settings" component={Settings} />
      <Route path="/parent/settings" component={Settings} />
      <Route path="/teacher/child/:name" component={ParentProfile} />
      <Route path="/parent/messages" component={ParentMessages} />
      <Route path="/parent/child/:child" component={Child} />
      <AdminRoute exact path="/admin" component={Admin} />
      <AdminRoute exact path="/admin/catalog/:grade/:standard/:subject/:topic" component={AdminCatalog} />
      <AdminRoute exact path="/admin/catalog/:grade/:standard/:subject" component={AdminCatalog} />
      <AdminRoute exact path="/admin/catalog/:grade/:standard" component={AdminCatalog} />
      <AdminRoute exact path="/admin/catalog/:grade" component={AdminCatalog} />
      <AdminRoute exact path="/admin/catalog" component={AdminCatalog} />
      <AdminRoute exact path="/admin/issues" component={Issues} />
      <AdminRoute exact path="/admin/register" component={Register} />
      <AdminRoute exact path="/admin/students" component={StudentRoster} />
      <AdminRoute exact path="/admin/assign/:child/:grade/:standard/:subject/:topic" component={AdminAssignLesson}/>
      <AdminRoute exact path="/admin/assign/:child/:grade/:standard/:subject" component={AdminAssignLesson}/>
      <AdminRoute exact path="/admin/assign/:child/:grade/:standard" component={AdminAssignLesson} />
      <AdminRoute exact path="/admin/assign/:child/:grade" component={AdminAssignLesson} />
      <AdminRoute exact path="/admin/assign/:child" component={AdminAssignLesson} />
      <AdminRoute exact path="/admin/assign" component={AdminAssignLesson} />
      <AdminRoute
        exact
        path="/admin/homework/:child"
        component={StudentHomework}
      />
      <AdminRoute
        exact
        path="/admin/rescore/:child"
        component={RescoreHomework}
      />
      <AdminRoute
        exact
        path="/admin/lesson/:lesson"
        component={AdminLessonReport}
      />
      <AdminRoute
        path="/admin/report/child/:child/lesson/:lesson"
        component={AdminStudentReport}
      />
      <Route component={Notfound} />
    </Switch>
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));
registerServiceWorker();
