import axios from 'axios';

import { config } from '../Constants';
import {
  getToken,
  getUserid,
  setNotifications,
  getName,
} from './profile-utils';
import { getCall } from './rest-utils';

export function refreshNotifications(callback, errorCallback) {
  const boundFunction = function(response) {
    // console.log(response);
    callback(response.data.object);
    setNotifications(response.data.object);
  };
  getCall(
    config.url.API_BASE_URL + '/api/notifications/user/' + getUserid(),
    boundFunction,
    errorCallback
  );
}
export function sendClap(notification) {
  console.log('ChildReportCard clap: ' + JSON.stringify(notification));
  const boundFunction = function(response) {
    dismissNotification(notification);
  };
  sendNotification(
    notification.from,
    getName(),
    notification.lessonName,
    'APPLAUSE',
    boundFunction
  );
}

export function assignLesson(notification) {
  const assignPath = '/api/homework/assign/parent/' + notification.from + '/';
  console.log(
    'Assign request will be sent to ' + assignPath + notification.lessonId
  );
  axios
    .get(config.url.API_BASE_URL + assignPath + notification.lessonId, {
      headers: { Authorization: getToken() },
    })
    .then(response => {
      dismissNotification(notification);
    })
    .catch(error => console.log(error));
}

export function dismissNotification(notification) {
  const assignPath =
    '/api/notifications/event/' + notification.eventId + '/status/DISMISSED';
  console.log('Notification dismiss request will be sent to ' + assignPath);
  axios
    .put(
      config.url.API_BASE_URL + assignPath,
      {},
      { headers: { Authorization: getToken() } }
    )
    .then(response => {
      console.log(response);
    })
    .catch(error => console.log(error));
}

export function markNotificationViewed(notification) {
  axios
    .put(
      config.url.API_BASE_URL +
        '/api/notifications/event/' +
        notification.eventId +
        '/status/viewed',
      {},
      {
        headers: { Authorization: getToken() },
      }
    )
    .then(response => {
      console.log(response);
    })
    .catch(error => console.log(error));
}

export function sendNotification(
  to,
  errorLessonId,
  errorLessonName,
  notificationType,
  callback
) {
  let assignPath = '/api/notifications/';
  console.log('notificaiton will be sent to ' + to + ' ' + notificationType);

  axios
    .post(
      config.url.API_BASE_URL + assignPath,
      {
        from: getUserid(),
        to: to,
        lessonId: errorLessonId,
        lessonName: errorLessonName,
        notificationType: notificationType,
      },
      { headers: { Authorization: getToken() } }
    )
    .then(response => {
      console.log(response);
      if (callback) {
        callback();
      }
    })
    .catch(error => console.log(error));
}

export function clearNotifications() {
  const token = localStorage.getItem('token');
  axios
    .put(
      config.url.API_BASE_URL + '/api/notifications/dismissAll',
      {},
      {
        headers: { Authorization: token },
      }
    )
    .then(response => {
      console.log(response);
      window.location.reload();
    })
    .catch(error => console.log(error));
}
