import React, { Component } from 'react';
import { logout } from '../utils/profile-utils';

class Logout extends Component {
  componentDidMount() {
    console.log(this.props);
    logout();
    this.props.history.push('/login');
  }

  render() {
    return (
      <div>
        <h1>Logout</h1>
      </div>
    );
  }
}

export default Logout;
