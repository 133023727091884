import React from "react";
import { NavLink } from "react-router-dom";

import "react-circular-progressbar/dist/styles.css";

import {
  displaySubject,
  showLeftArrow,
  showRightArrow
} from "../../utils/display-utils";

import "./../Teacher/RecentChallenges.css";
import "./../../components/Parent/ChildChallenges.css";
import { getImage } from "../../utils/image-utils";
import Toast from "../Student/Toast/Toast";
import { sendNotification } from "../../utils/notification-utils";

class ChildChallenges extends React.Component {
  state = {
    cards: [],
    showLeft: false,
    showRight: true,
    showError: false,
    child: null,
    toastText: null
  };

  constructor(props) {
    super(props);

    this.scrollingWrapper = React.createRef();

    this.state.firstCard = React.createRef();
    this.state.lastCard = React.createRef();

    // console.log("ChildChallenges props: " + JSON.stringify(props));
    this.scrollChange = this.scrollChange.bind(this);
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.getCards = this.getCards.bind(this);
    this.completeRequest = this.completeRequest.bind(this);
  }

  componentDidMount() {
    // not using properties directly. Need to track first and last card for scroll.
    this.setState({
      child: this.props.child,
      cards: this.getCards(this.props.lessons)
    });
  }

  componentDidUpdate() {
    if (this.state.child.userid !== this.props.child.userid) {
      // console.log("ChildChallenges componentDidUpdate child" + this.props.child);
      this.setState({
        child: this.props.child,
        cards: this.getCards(this.props.lessons),
        showRight: true
      });
    }
  }

  getCards(cards) {
    if (this.scrollingWrapper && this.scrollingWrapper.current) {
      const element = this.scrollingWrapper.current;
      element.scrollLeft = 0;
    }
    if (cards && cards.length > 0) {
      cards[0].ref = this.state.firstCard;
      cards[cards.length - 1].ref = this.state.lastCard;
    }
    return cards;
  }

  scrollChange() {
    const { firstCard, lastCard } = this.state;
    let showLeft = showLeftArrow(firstCard);
    let showRight = showRightArrow(lastCard);
    this.setState({
      showLeft: showLeft,
      showRight: showRight
    });
  }

  scrollLeft() {
    const element = this.scrollingWrapper.current;
    console.log("scrollLeft: " + element.scrollLeft);
    element.scrollLeft -= 100;
  }
  scrollRight() {
    const element = this.scrollingWrapper.current;
    // console.log('scrollRight: ' + element.scrollLeft);
    element.scrollLeft += 100;
    this.scrollChange();
  }

  completeRequest(lesson) {
    const { child } = this.state;
    console.log("ChildChallenges completeRequest: " + child.name);
    const boundFunction = function() {
      this.setState({
        toastText: "Request was sent to " + child.name
      });
    }.bind(this);
    sendNotification(
      child.userid,
      lesson.lessonKey,
      lesson.shortName,
      "COMPLETE_REQUEST",
      boundFunction
    );
  }

  render() {
    // console.log('ChildChallenges render props: ' + JSON.stringify(this.props.child));
    const { showLeft, showRight, cards, toastText } = this.state;
    const { child } = this.props;
    return (
      <div className="recent-chalng">
        {toastText && <Toast text={toastText} />}
        <div className="full-container">
          <div
            ref={this.scrollingWrapper}
            className="challange-warp scrolling-wrapper"
            onScroll={this.scrollChange}
          >
            {showLeft && (
              <img
                className="more-cards more-left"
                onClick={() => this.scrollLeft()}
                src={getImage("left-play-btn.png")}
                alt="scroll left"
              />
            )}
            {cards.map((c, index) => {
              if (index === 0) {
              }
              return (
                <LessonReportCard
                  key={index}
                  report={c}
                  child={child.userid}
                  completeRequest={this.completeRequest}
                />
              );
            })}
            {showRight && (
              <img
                className="more-cards more-right"
                onClick={() => this.scrollRight()}
                src={getImage("right-play-btn.png")}
                alt="scroll right"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function LessonReportCard(props) {
  // console.log("ChildChallenges LessonReportCard props: " + JSON.stringify(props));

  if (!props.report) {
    return <div />;
  }

  const {
    attemptedQuestions,
    totalQuestions,
    assignDate,
    lesson,
    ref,
    displayDateValue
  } = props.report;
  const { shortName, lessonKey, grade, subject } = lesson;

  // console.log(attemptedQuestions + ' <= attampted ChildChallenges > LessonReportCard total => : ' + totalQuestions);
  let percentage = 0;
  if (totalQuestions > 0) {
    percentage = (attemptedQuestions * 100) / totalQuestions;
  }
  // console.log('ChildChallenges > LessonReportCard percentage: ' + percentage);

  const complete = attemptedQuestions === totalQuestions ? true : false;
  return (
    <div ref={ref ? ref : null} className="single-clng-wrpp single-clng-2-warp">
      <div className="single-clng-item">
        <div className="clng-top-txt">
          <div className="clng-date clng-dy-up">
            <div className="clng-itm-tags">
              <span className="left-clg-itm">{displaySubject(subject)}</span>
              <span className="right-clg-itm">
                {" "}
                {new Intl.DateTimeFormat("en-US", {
                  month: "long",
                  day: "2-digit"
                }).format(displayDateValue)}
              </span>
            </div>
          </div>
        </div>
        <div className="recent-plants-txt">
          <ul>
            <li>{shortName}</li>
          </ul>
        </div>
        <div className="Participant">
          <h4>
            CHALLENGE SCORE{" "}
            <span>
              {attemptedQuestions}/{totalQuestions}
            </span>
          </h4>
          <div className="clng-skills-warp">
            <div className="skills-clng">
              <span
                className={complete ? "bg-grn" : "bg-bgn"}
                style={{ width: percentage + "%"}}
              />
            </div>
          </div>
        </div>
        <div className="view-txt alrady-sent">
          {attemptedQuestions > 0 && (
            <NavLink
              to={
                "/parent/report/child/" + props.child + "/lesson/" + lessonKey
              }
            >
              View Report
            </NavLink>
          )}
          {attemptedQuestions <= 0 && (
            <a
              onClick={() => props.completeRequest(lesson)}
              className="cursor-pointer"
            >
              Ask to complete
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

ChildChallenges.propTypes = {};

export default ChildChallenges;
