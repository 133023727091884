import React, { useState } from "react";

import "react-circular-progressbar/dist/styles.css";

import "./../../Teacher/RecentChallenges.css";
import "./../../../components/Parent/ChildChallenges.css";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import ProgressCard from "./ProgressCard";

const ProgressCarousel = ({ lessons, childProfile, showToast }) => {

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1100 },
      items: 2.5,
    },
    tablet: {
      breakpoint: { max: 1100, min: 900 },
      items: 1.5,
    },
    mobile: {
      breakpoint: { max: 900, min: 446 },
      items: 1,
    },
    mobile1: {
      breakpoint: { max: 446, min: 0 },
      items: 1,
    }
  };

  return (
    <div class="col-md-8 col-lg-9">
      {/* <div class="card-scroller flex-sm-nowrap d-sm-flex"> */}
      {/* <Carousel responsive={responsive}> */}
      {lessons &&
        lessons.length > 0 &&
        lessons.map((c, index) => {
          // console.log("ParentRecommendations render lesson: " + JSON.stringify(c));
          return (
            <ProgressCard
              key={index}
              report={c}
              child={childProfile}
              showToast={showToast}
            />
          );
        })}
      {/* </Carousel> */}
      {/* </div> */}
    </div>
  );
};

export default ProgressCarousel;