import React, { useEffect, useState, useRef } from 'react';
import { getImage } from '../../../utils/image-utils';
import './NotificationsWidget.css';
import { clearNotifications } from '../../../utils/notification-utils';
import { getAchievements } from '../../../utils/profile-utils';
import ShowProblem from '../LessonContent/ShowProblem';

const AchievementsWidget = ({ onClose }) => {
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click do nothing
      return;
    }
    // outside click close card
    onClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  console.log(
    'AchievementsWidget  achievements # : ' + JSON.stringify(getAchievements())
  );
  const achievements = getAchievements();
  return (
    <div id="overlay-container">
      <div className="achievements-wrapper">
        <h1>Achievements</h1>
        <div ref={node} className="settings-box">
          <a onClick={() => onClose()}>
            <img className="close" src={getImage('Group-2208.png')} />
          </a>

          <div className="achievement-box-options notification-box-options">
            <ul>
              {achievements && achievements.completedChallenges && (
                <li className="clear">
                  <div className="ach-image">
                    <img
                      src={getImage('challenge-completed.svg')}
                      alt="challenges completed"
                    />
                  </div>
                  <div className="arch-wrapper">
                    <div className="ach-content">
                      <span className="ach-title">Challenges Completed</span>
                      <span className="ach-score">
                        <span>{achievements.completedChallenges.value}</span> /{' '}
                        {achievements.completedChallenges.max}
                      </span>
                    </div>
                    <div className="clear achievements-progress">
                      <span
                        style={{
                          width:
                            (achievements.completedChallenges.value * 100) /
                              achievements.completedChallenges.max +
                            '%',
                        }}
                      />
                    </div>
                  </div>
                  <div className="ach-claim">
                    {/* <img src={getImage('claim-button.png')} alt="claim" /> */}
                  </div>
                </li>
              )}
              {achievements && achievements.correctQuestions && (
                <li className="clear">
                  <div className="ach-image">
                    <img
                      src={getImage('correct-answers.png')}
                      alt="challenges completed"
                    />
                  </div>
                  <div className="arch-wrapper">
                    <div className="ach-content">
                      <span className="ach-title">Correct Answers</span>
                      <span className="ach-score">
                        <span>{achievements.correctQuestions.value}</span> /{' '}
                        {achievements.correctQuestions.max}
                      </span>
                    </div>
                    <div className="clear achievements-progress">
                      <span
                        style={{
                          width:
                            (achievements.correctQuestions.value * 100) /
                              achievements.correctQuestions.max +
                            '%',
                        }}
                      />
                    </div>
                  </div>
                  <div className="ach-claim">
                    {/* <img src={getImage('claim-button.png')} alt="claim" /> */}
                  </div>
                </li>
              )}
              {achievements && achievements.practiceCount && (
                <li className="clear">
                  <div className="ach-image">
                    <img
                      src={getImage('practice-chapters.png')}
                      alt="challenges completed"
                    />
                  </div>
                  <div className="arch-wrapper">
                    <div className="ach-content">
                      <span className="ach-title">Practice Chapters</span>
                      <span className="ach-score">
                        <span>{achievements.practiceCount.value}</span> /{' '}
                        {achievements.practiceCount.max}
                      </span>
                    </div>
                    <div className="clear achievements-progress">
                      <span
                        style={{
                          width:
                            (achievements.practiceCount.value * 100) /
                              achievements.practiceCount.max +
                            '%',
                        }}
                      />
                    </div>
                  </div>
                  <div className="ach-claim">
                    {/* <img src={getImage('claim-button.png')} alt="claim" /> */}
                  </div>
                </li>
              )}
            </ul>
          </div>
          {!achievements && (
            <div className="settings-box-options">
              <p>No achievements to show.</p>
            </div>
          )}
        </div>
      </div>
      <ShowProblem report={{ pageName: 'achievements' }} />
    </div>
  );
};

export default AchievementsWidget;
