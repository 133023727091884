import React, { PropTypes } from "react";
import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import { getImage } from "../../utils/image-utils";

const ChallengeSummary = ({ metrics, lessonProgress }) => {
  const { overallMetrics, learningMetrics, testingMetrics } = metrics;

  let allAttempedLessons,
    allCompletedLessons,
    learningLessons,
    learningCompleted,
    testingLessons,
    testingCompleted;
  if (metrics) {
    allAttempedLessons = overallMetrics.lessonCardinality;
    allCompletedLessons = overallMetrics.completedLessons;
    learningLessons = learningMetrics.lessonCardinality;
    learningCompleted = learningMetrics.completedLessons;
    testingLessons = testingMetrics.lessonCardinality;
    testingCompleted = testingMetrics.completedLessons;
  }
  const { all, learning, testing } = lessonProgress;

  const inProgress = allAttempedLessons - allCompletedLessons;
  const learningInProgress = learningLessons - learningCompleted;
  const testingInProgress = testingLessons - testingCompleted;

  const allLessons = all > allAttempedLessons ? all : allAttempedLessons;
  const allLearningLessons = learning > learningLessons ? learning : learningLessons;
  const allTestingLessons = testing > testingLessons ? testing : testingLessons;

  const pending = allLessons - allAttempedLessons;
  let learningPending = allLearningLessons - learningLessons;
  let testingPending = allTestingLessons - testingLessons;
  if (pending !==  learningPending + testingPending) {
    if (learningPending > testingPending) {
      learningPending = pending - testingPending;
    } else {
      testingPending = pending - learningPending;
    }
  }
  
  const completePercentage = testingCompleted / (allCompletedLessons) * 100;
  const inProgressPercentage = (testingInProgress / inProgress) * 100;
  const pendingPercentage = (testingPending / pending) * 100;
  return (

    <div class="sk-card card-reset overflow-hidden">
      <div class="card-body px-md-4 px-2 py-4">
        <div class="d-sm-flex align-items-center mb-3 flex-wrap justify-content-between">
          <h3>Summary of challenges </h3>
        </div>
         <div class="row align-items-center">
          <div class="col-sm-12 py-2 chart-label d-flex justify-content-end">
            <div class="learning-label">Learning</div>
            <div class="testing-label ml-4">Testing</div>
          </div>
          <div class="col-md-3">
            <div class="pie-cart my-2 text-center">
              
              <CircularProgressbarWithChildren
                value={allCompletedLessons + inProgress}
            maxValue={allLessons}
            text={`${""}`}
            strokeWidth="5"
            styles={buildStyles({
              textColor: "rgb(108, 124, 147)",
              pathColor: "#F5C818",// yellow #F5C818
              trailColor: "#F86456" // red #F86456
            })}
          >

                  <CircularProgressbarWithChildren
                  value={allCompletedLessons}
                  maxValue={allLessons}
                strokeWidth="5.1"
                styles={buildStyles({
                  textColor: "rgb(108, 124, 147)",
                  pathColor: "#2CD89D", // green #2CD89D
                  trailColor: "rgb(221, 224, 229, 0)"
                })}
                ><span>{allLessons + " Total Challenge(s) "}</span>
          </CircularProgressbarWithChildren>
        </CircularProgressbarWithChildren>
            </div>
          </div>
          <div class="col-md-9">
            <div class="table-responsive">
              <table class="table table-borderless white-space text-gray table-middle">
                <tbody>
                  <tr>
                    <td>
                      <div class="dot dot-green"></div>{allCompletedLessons}
                              </td>
                    <td>Completed</td>
                    <td class="progress-summary">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: completePercentage + "%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                    <td
                      class="text-center"
                      width="100"
                      style={{ color: "#feae91" }}
                    >
                      {learningCompleted}
                              </td>
                    <td
                      class="text-center"
                      width="100"
                      style={{ color: "#ae97ff" }}
                    >
                      {testingCompleted}
                              </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="dot dot-yellow"></div>{inProgress}
                              </td>
                    <td class="text-left">In Progress</td>
                    <td class="progress-summary">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: inProgressPercentage + "%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                    <td
                      class="text-center"
                      width="100"
                      style={{ color: "#feae91" }}
                    >
                      {learningInProgress}
                              </td>
                    <td
                      class="text-center"
                      width="100"
                      style={{ color: "#ae97ff" }}
                    >
                      {testingInProgress}
                              </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="dot dot-red"></div>{pending}
                              </td>
                    <td class="text-left">Pending</td>
                    <td class="progress-summary">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: pendingPercentage + "%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                    <td
                      class="text-center"
                      width="100"
                      style={{ color: "#feae91" }}
                    >
                      {learningPending}
                              </td>
                    <td
                      class="text-center"
                      width="100"
                      style={{ color: "#ae97ff" }}
                    >
                      {testingPending}
                              </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeSummary;
