import React from 'react';
import { getImage } from '../utils/image-utils';

const Loader = ({ image }) => {
  return (
    <div id="overlay-container-clear" style={{ marginTop: 100 + 'px' }}>
      {image.repeat && (
        <div
          style={{
            width: '80vw',
            height: '80vh',
            backgroundImage: `url(${getImage(image.image)})`,
            backgroundRepeat: 'repeat',
          }}
        >
          <img
            src={getImage('transparent-pixel.png')}
            style={{ height: 300 + 'px' }}
            alt="loading"
          />
          {/* loader waiting rocket */}
        </div>
      )}
      {!image.repeat && (
        <div style={{ margin: '10% auto', width: 'max-content' }}>
          <img
            src={getImage(image.image)}
            style={{ width: image.width + 'px' }}
            alt="loading"
          />
          {/* loader waiting rocket */}
        </div>
      )}
    </div>
  );
};

export default Loader;
