import React from "react";
import { NavLink } from "react-router-dom";
import uniqueId from "react-html-id";

import { config, ADULT_LOADING_IMAGES } from "../../Constants";
import AdminHeader from "../../components/Admin/AdminHeader";
import Loader from "../../components/Loader";
import { getCall } from "../../utils/rest-utils";
import { getAvatarImage } from "../../utils/image-utils";

class StudentRoster extends React.Component {
  state = {
    loading: true
  };

  constructor(props) {
    super(props);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    const boundFunction = function (response) {
      console.log(response.data.object);
      this.setState({
        roster: response.data.object,
        loading: false
      });
    }.bind(this);

    getCall(config.url.API_BASE_URL + "/api/roster", boundFunction);
  }

  render() {
    const { loading, roster, homeworkReports } = this.state;
    console.log("homeworkReports " + homeworkReports);
    const style = { border: "1px solid black" };
    return (
      <div className="admin-page">
        <AdminHeader />
        {roster && (
          <div>
            <table>
              <tr>
                <th style={style}>#</th>
                <th style={style}>Avatar</th>
                <th style={style}>Name</th>
                <th style={style}>Grade</th>
                <th style={style}>Access</th>
                <th style={style}>Gender</th>
                <th style={style}>Score</th>
                <th style={style}>Attempts</th>
                <th style={style}>Family</th>
                <th style={style}>Class</th>
                {/* <th style={style}>Accuracy</th> */}
              </tr>
              {roster.map((c, index) => (
                <tr>
                  <td style={style}>{index + 1}</td>
                  <td style={style} className="techers-profiles">
                    <img src={getAvatarImage(c)} alt="" />
                  </td>
                  <td style={style}>
                    <NavLink to={"/admin/homework/" + c.userid}>
                      {c.name} ({c.email} {c.userid})
                    </NavLink>
                  </td>
                  <td style={style}>{c.grade}</td>
                  <td style={style}>{c.access}</td>
                  <td style={style}>{c.gender}</td>
                  <td style={style}>{c.score}</td>
                  <td style={style}>{c.birthday}</td>
                  <td style={style}>{c.familyId}</td>
                  <td style={style}>{c.classroomId}</td>
                  {/* <td style={style}>{JSON.stringify(c)}</td> */}
                </tr>
              ))}
            </table>
          </div>
        )}
        {loading && (
          <Loader
            image={
              ADULT_LOADING_IMAGES[
              Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
              ]
            }
          />
        )}{" "}
      </div>
    );
  }
}

export default StudentRoster;
