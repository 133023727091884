import React from "react";
import { mapLesson } from "./dashboard-utils";
import { getNumber } from "./display-utils";

export function loadActiveChildReports(reports, child) {
  return reports.childReports[child];
}

export function mapChildReport(childReport) { 
  let attempts = 0;
  let score = 0;
  let exposure = 0;
  let confidence = 0;
  let accuracy = 0;
  let totalQuestions = 0;
  let correctAnswers = 0;
  let attemptedQuestions = 0;
  let questionActivity = [];
  let reportDate = null;
  let derivedInsights = null;
  let recommendation = null;
  let range = "incomplete";
  if (childReport.homeworkReport) {
    const lessonReport = childReport.homeworkReport;
    attempts = lessonReport.attempts ? lessonReport.attempts : 0;
    score = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0
    }).format(lessonReport.score ? lessonReport.score : 0);
    exposure = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0
    }).format(lessonReport.exposure);
    confidence = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0
    }).format(lessonReport.confidence);
    accuracy = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0
    }).format(lessonReport.accuracy);
    derivedInsights = lessonReport.derivedInsights;
    recommendation = lessonReport.recommendation;

    if (recommendation) {
      console.log("Found Recommendations " + childReport);
    }

    range =
      score <= 0
        ? "incomplete"
        : score < 40
        ? "low"
        : score < 80
        ? "medium"
        : "high";

    attemptedQuestions = lessonReport.attemptedQuestions;
    totalQuestions = lessonReport.totalQuestions;
    questionActivity = lessonReport.questionActivity;
    correctAnswers = lessonReport.correctAnswers;
    try {
      reportDate = lessonReport.reportDate;
    } catch (e) {
      console.error(lessonReport.reportDate + " " + e);
      reportDate = lessonReport.reportDate;
    }
  } else {
    if (childReport.totalQuestions !== 0) {
      totalQuestions = childReport.totalQuestions;
    } else if (
      childReport.lesson.questions &&
      childReport.lesson.questions.length > 0
    ) {
      totalQuestions = childReport.lesson.questions.length;
    }
  }

  let averageTimeSpent = getNumber(childReport.averageTimeSpent, 900);

  const displayDateValue = reportDate ? reportDate : childReport.assignDate;
  const displayDate = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "2-digit",
    year: "numeric"
  }).format(displayDateValue);
  return {
    reference: React.createRef(),
    lesson: mapLesson(childReport.lesson),
    lessonId: childReport.lessonId,
    lessonMetadata: mapLesson(childReport.lessonMetadata),
    topicMetadata: childReport.topicMetadata, 
    score: score,
    scoreText: score + "%",
    assignDate: childReport.assignDate,
    displayDate: displayDate,
    displayDateValue: displayDateValue,
    totalQuestions: totalQuestions,
    correctAnswers: correctAnswers,
    range: range,
    attempts: attempts,
    exposure: exposure,
    confidence: confidence,
    accuracy: accuracy,
    attemptedQuestions: attemptedQuestions,
    averageTimeSpent: averageTimeSpent,
    questionActivity: questionActivity,
    reportDate: reportDate,
    complete: attemptedQuestions === totalQuestions,
    started: attemptedQuestions > 0,
    lessonReport: childReport.lessonReport,
    derivedInsights: derivedInsights,
    recommendation: recommendation
  };
}

export function mapDailyReport(childReport) {
  let attempts = 0;
  let score = 0;
  let exposure = 0;
  let confidence = 0;
  let accuracy = 0;
  let totalQuestions = 0;
  let correctAnswers = 0;
  let attemptedQuestions = 0;
  let questionActivity = [];
  let reportDate = null;
  let range = "incomplete";
  const lessonReport = childReport;
  attempts = lessonReport.attempts ? lessonReport.attempts : 0;
  score = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0
  }).format(lessonReport.score ? lessonReport.score : 0);
  exposure = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0
  }).format(lessonReport.exposure);
  confidence = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0
  }).format(lessonReport.confidence);
  accuracy = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0
  }).format(lessonReport.accuracy);

  range =
    score <= 0
      ? "incomplete"
      : score < 40
      ? "low"
      : score < 80
      ? "medium"
      : "high";

  attemptedQuestions = lessonReport.attemptedQuestions;
  totalQuestions = lessonReport.totalQuestions;
  questionActivity = lessonReport.questionActivity;
  correctAnswers = lessonReport.correctAnswers;
  try {
    reportDate = lessonReport.reportDate;
  } catch (e) {
    console.error(lessonReport.reportDate + " " + e);
    reportDate = lessonReport.reportDate;
  }
  const displayDateValue = reportDate ? reportDate : childReport.assignDate;
  const displayDate = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "2-digit",
    year: "numeric"
  }).format(displayDateValue);
  return {
    reference: React.createRef(),
    lesson: mapLesson(childReport.lesson),
    score: score,
    scoreText: score + "%",
    assignDate: childReport.assignDate,
    displayDate: displayDate,
    displayDateValue: displayDateValue,
    totalQuestions: totalQuestions,
    correctAnswers: correctAnswers,
    range: range,
    attempts: attempts,
    exposure: exposure,
    confidence: confidence,
    accuracy: accuracy,
    attemptedQuestions: attemptedQuestions,
    questionActivity: questionActivity,
    reportDate: reportDate,
    complete: attemptedQuestions === totalQuestions,
    started: attemptedQuestions > 0,
    lessonReport: childReport.lessonReport
  };
}

export function getActiveChildLessons(subject, subjects, activeChildReports) {
  let lessonIds, lessons;

  if (activeChildReports) {
    if (subject === "Recent") {
      lessonIds = activeChildReports.recent.map(c => {
        return c.value;
      });
    } else {
      const reports = activeChildReports.homeworkBySubject[subject];
      if (reports) {
        lessonIds = reports.map(c => {
          return c.value;
        });
      }
    }
    if (lessonIds) {
      lessons = lessonIds.map(c => {
        return activeChildReports.homework[c];
      });
    }
  }

  const changed = lessons ? lessons.map(l => mapChildReport(l)) : [];
  changed.sort((h1, h2) =>
    h2.displayDateValue > h1.displayDateValue ? 1 : -1
  );

  return changed;
}

export function getRecommendations(activeChildReports) {
  const recommendations = activeChildReports.recommendations;
  var dict = new Object();
  Object.keys(recommendations).forEach(function(key) {
    dict[key] = recommendations[key].map(i => {
      return {
        reference: React.createRef(),
        recommendations: mapLesson(i.value.lessonMetadata),
        topicMetadata: i.value.topicMetadata
      };
    });
  });
  return dict;
}
