import React from "react";
import ConnectionErrorHandler from "../../components/Student/ErrorHandler/ConnectionErrorHandler";
import ShowProblem from "../../components/Student/LessonContent/ShowProblem";
import {
  getFamilyReport,
  getChildLessons,
  getRecommendationsLessons
} from "../../services/ParentService";
import {
  getProfile,
  getToken,
  getChildren,
  getChild,
  getSubjects,
  isParent
} from "../../utils/profile-utils";
import {
  loadSyllabusWithGrade,
} from "../../utils/student-utils";

const ParentStorage = WrappedComponent => {
  class HOC extends React.Component {
    state = {
      showError: false,
      loading: true
    };

    constructor(props) {
      super(props);

      if (!isParent()) {
        this.props.history.push("/");
      }

      if (getChildren() && getChildren().length > 0) {
        const child = props.match.params.child;
        let childProfile = null;
        if (child) {
          childProfile = getChild(child)
        } 
        if (childProfile == null) {
          console.log("ParentStorage constructor: will pick first child");
          childProfile = getChildren()[0]
        }  
        this.state.activeChild = childProfile.userid;
      } else {
        this.props.history.push("/parent/profile");
      }
      // const anchorValue = this.props.location.hash.replace("#", "");
      // this.state.subject = anchorValue ? anchorValue : "Recent";
      this.state.subject = props.match.params.subject;
      this.state.topic = props.match.params.topic;
    }

    componentDidMount() {
      const { activeChild, subject } = this.state;
      getFamilyReport(false, this.loadData, this.showError);
      if (getSubjects().includes(subject)) {
        const grade = getChild(activeChild).grade;
        loadSyllabusWithGrade(
          activeChild,
          subject,
          grade,
          this.loadSyllabus,
          this.showError
        );
      }
    }

    loadSyllabus = syllabus => {
      const newState = Object.assign({}, this.state, {
        syllabus: syllabus.topic.topics
      });

      // store the new state object in the component's state
      this.setState(newState);
    };

    loadData = reports => {
      const { activeChild } = this.state;
      const children = getChildren();
      if (!children || children.length <= 0) {
        // parent may be new and has not added any children.
        // Send them to profile page to add children
        this.props.history.push("/parent/profile");
      } else {
        this.changeChild(
          activeChild ? activeChild : children[0].userid,
          reports
        );
      }
    };

    showError = error => {
      if (!getProfile() || !getToken()) {
        this.props.history.push("/logout");
      }
      if (error && error.response && error.response.status === 401) {
        this.props.history.push("/logout");
      }

      const newState = Object.assign({}, this.state, {
        showError: true,
        errorMessage: error.message,
        error: error,
        loading: false
      });

      // store the new state object in the component's state
      this.setState(newState);
    };

    retry = () => {
      getFamilyReport(false, this.loadData, this.showError);
      this.setState({
        showError: false
      });
    };

    changeChild = child => {
      const { activeChild, subject, topic } = this.state;
      if (child) {
        const subjectValue = subject ? subject : "Recent";
        console.log("changeChild ParentDashboard: " + child + " subject " + subjectValue);
        this.setState(
          {
            activeChild: child,
            recommendations: getRecommendationsLessons(child),
            lessons: getChildLessons(child, subjectValue),
            syllabus: null,
            subject: subjectValue,
            loading: false
          },
          () => {
            const pageUrl = window.location.href;
            // console.log("ParentStorage window location: " + pageUrl);
            if (pageUrl.includes("dashboard")) {
              console.log(" Parent storage dashboard: ");
              this.props.history.push(
                "/parent/dashboard/" + child + (subject ? "#" + subject : "")
              );
            } else if (pageUrl.includes("create/challenge")) {
              console.log(" Parent storage create/challenge: ");
              this.props.history.push(
                "/parent/create/challenge/" + child + (subject ? "#" + subject : "")
              );
            } else if (pageUrl.includes("challenges")) {
              console.log(" Parent storage challenges: ");
              this.props.history.push(
                "/parent/challenges/" + child + (subject ? "#" + subject : "")
              );
            } else if (pageUrl.includes("performance")) {
              console.log("Parent storage performance: ");
              if (subject && topic) {
                console.log(subject + " Parent storage performance: " + topic);
                this.props.history.push(
                  "/parent/performance/" + child + "/" + subject + "/" + topic
                );
              } else if (subject) {
                console.log(subject + " Parent storage performance: ");
                this.props.history.push("/parent/performance/" + child + "/" + subject);
              } else {
                this.props.history.push(
                  "/parent/performance/" + child
                );
              }
            } else {
              console.log(" Parent storage recommendations: ");
              this.props.history.push("/parent/recommendations/" + child);
            }
          }
        );

        if (getSubjects().includes(subject)) {
          const grade = getChild(child).grade;
          loadSyllabusWithGrade(
            child,
            subject,
            grade,
            this.loadSyllabus,
            this.showError
          );
        }
      }
    };

    render() {
      // console.log("ParentStorage lessons: " + JSON.stringify(this.state.lessons));
      return (
        <div>
          <WrappedComponent
            activeChild={this.state.activeChild}
            subject={this.state.subject}
            loading={this.state.loading}
            recommendations={this.state.recommendations}
            lessons={this.state.lessons}
            syllabus={this.state.syllabus}
            changeChild={this.changeChild}
            changeSubject={this.changeSubject}
            {...this.props}
          />
          <ShowProblem report={{ pageName: "Parent Dashboard" }} />
          {this.state.showError && (
            <ConnectionErrorHandler
              connectionError="true"
              onRetry={() => this.retry()}
              errorMessage={this.state.errorMessage}
            />
          )}
        </div>
      );
    }
  }

  return HOC;
};

export default ParentStorage;
