import React from "react";
import { NavLink } from "react-router-dom";
import DashboardHeader from "../Teacher/DashboardHeader";
import { isSuperAdmin } from "../../utils/profile-utils";

const AdminHeader = ({child}) =>  {
  return (
    <div className="sk-container">
      <DashboardHeader />
      <br />
      <li className="left">
        <NavLink activeClassName="active" to={"/admin"}>
          Home
        </NavLink>
      </li>
      {isSuperAdmin() && (
        <li className="left">
          <NavLink activeClassName="active" to={"/admin/issues"}>
            Issues
          </NavLink>
        </li>
      )}
      <li className="left">
        <NavLink activeClassName="active" to={"/admin/catalog"}>
          Catalog
        </NavLink>
      </li>
      {isSuperAdmin() && <li className="left">
        <NavLink activeClassName="active" to={"/admin/register"}>
          Register
        </NavLink>
      </li>}
      {isSuperAdmin() && <li className="left">
        <NavLink activeClassName="active" to={"/admin/homework/" + child}>
          Manage Homework
        </NavLink>
      </li>}
      {isSuperAdmin() && <li className="left">
        <NavLink activeClassName="active" to={"/admin/students"}>
          Student Roster
        </NavLink>
      </li>}
      {isSuperAdmin() && <li className="left">
        <NavLink activeClassName="active" to={"/admin/questions"}>
          ManageQuestions
        </NavLink>
      </li>}
      <li className="left">
        <NavLink activeClassName="active" to={"/logout"}>
          Logout
        </NavLink>
      </li>
    </div>
  );
}

export default AdminHeader;
