import React, { useState } from "react";
import { getImage, getLevelImage } from "../../../utils/image-utils";
import { ReportBase } from "istanbul-lib-report";
import { getNumber, displaySubject } from "../../../utils/display-utils";
import QuestionTime from "./QuestionTime";
import McqDisplay from "./McqDisplay";
import FlashcardDisplay from "./FlashcardDisplay";
import NumericDisplay from "./NumericDisplay";

const QuestionAnalysis = ({
  index,
  report,
  question,
  topicName,
  averageTimeSpent,
  level,
  preview
}) => {
  console.log("QuestionAnalysis::report: " + JSON.stringify(report));

  
  let questionType = question.questionType;

  // correct answer is not in provided answers incase of multiple attempts. This breaks display.
  let unique = [];
  if (
    report &&
    report.correct &&
    report.answers &&
    question &&
    question.options &&
    question.options.length > 0
  ) {
    report.answers.push(question.options[0]);
    unique = Array.from(new Set(report.answers));
  }

  if (report) {
    console.log("derivedInsights: " + report.derivedInsights);
  }

  let derivedInsights =
    report && report.derivedInsights
      ? report.derivedInsights.includes("RUSHED_QUESTION")
        ? "Student rushed to answer this question. Please review this question."
        : report.derivedInsights.includes("REVIEW_CONCEPT")
        ? "Student seems to be struggling with the concept. Please review this question."
        : report.derivedInsights
      : null;

  let isNumeric = false;
  let isFlashCard = false;
  let isMcq = false;
  if (
    questionType === "NUMERIC_QUESTIONS" ||
    (question.numericEntry !== null && question.numericEntry)
  ) {
    isNumeric = true;
  } else if (questionType === "FLASH_CARDS") {
    isFlashCard = true;
  } else {
    isMcq = true;
  }

    return (
      <div class="row mb-5 pb-md-3">
        <div class="col-md-9">
          <div class="question-section mb-0">
            <span class="ms-count">{index + 1}</span>

            {question && question.question && (
              <p dangerouslySetInnerHTML={{ __html: question.displayHtml ? question.displayHtml : question.question }} />
            )}

            {topicName && (
              <div class="topics mb-4">
                Topics: <span>{topicName}</span>
              </div>
            )}
            <h5>{isNumeric ? "DATA ENTRY QUESTION" : displaySubject(questionType)}</h5>

            {question.displayImage && (
              <p>
                <img className="question-image" src={question.displayImage} style={{ maxHeight: "20em", maxWidth: "80%" }} />
              </p>
            )}

            <h5>Skill: {question.skill} </h5>

            {isMcq && <McqDisplay question={question} report={report} />}

            {isFlashCard && <FlashcardDisplay question={question} report={report} />}
              
            {isNumeric && <NumericDisplay question={question} report={report} unique={unique}/>}
          </div>
        </div>
        {/* <!-- /.col-md-8 --> */}
        <div class="col-md-3 d-md-flex flex-column">
          <div class="total-attempts my-4 my-md-0 order-md-1 mt-md-auto">
            TOTAL ATTEMPTS: {report ? report.attempts : 0}
            {report && report.attempts > 3 && <div class="error">
                <img src={getImage("exclamtion-mark.png")} alt="mark"/> Too many attempts</div>}
          </div>

          <div class="score-level">
            <QuestionTime
              report={report}
              averageTimeSpent={averageTimeSpent}
              level={level}
            />

            {report &&
              report.derivedInsights &&
              report.derivedInsights.length > 0 && (
                <div
                  class="card-body notes mt-4 rounded-0 card-smart-kid d-flex flex-column"
                  style={{ backgroundColor: "#ffefe8;" }}
                >
                  <div class="bright-tag mb-4 pb-2 d-flex align-items-center align-top">
                    <div class="icon">
                      <img src={getImage("insight.svg")} alt="icon" />
                    </div>
                    <h6 class="font-weight-normal">{derivedInsights}</h6>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
};

export default QuestionAnalysis;

/*

 <div className="row question-row">
      <div className="col-md-1">
        <div className="Questions-no">
          <h2>{index + 1}</h2>
        </div>
      </div>
      <div className="col-md-7">
        <div className="Questions">
          <div>
            {question && question.question && (
              <p dangerouslySetInnerHTML={{ __html: question.question }} />
            )}
            {question.displayImage && (
              <p>
                <img className="question-image" src={question.displayImage} />
              </p>
            )}
          </div>
          {topicName && (
            <ul>
              <li>Topics:</li>
              <li>
                <a href="#">{topicName}</a>
              </li>
            </ul>
          )}
          {questionType && (
            <p className="upper">{questionType.replace(/_/g, " ")}</p>
          )}

          {questionType &&
            questionType !== "NUMERIC_QUESTIONS" &&
            question &&
            question.options &&
            question.options.map((o, index) => {
              let letter;
              if (questionType !== "FLASH_CARDS") {
                letter =
                  index == 0 ? "A" : index == 1 ? "B" : index == 2 ? "C" : "D";
              }
              return (
                <div className="Multiple">
                  {letter && (
                    <div
                      className={letter === "A" ? "correct options" : "options"}
                    >
                      <h3
                        className={
                          report && report.answers && report.answers.includes(o)
                            ? "selected "
                            : ""
                        }
                      >
                        {letter}
                      </h3>
                    </div>
                  )}
                  <div className={"Questions-text " + questionType}>
                    <p dangerouslySetInnerHTML={{ __html: o }} />
                  </div>
                </div>
              );
            })}
          {questionType && questionType === "NUMERIC_QUESTIONS" && (
            <div class="answer">
              {unique.map(o => {
                return (
                  <ul
                    className={
                      o === question.options[0] ? "red-gren" : "red-num"
                    }
                  >
                    <li>
                      <a href="#">{o}</a>
                    </li>
                  </ul>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="col-md-2">
        {!preview && (
          <QuestionTime
            report={report}
            averageTimeSpent={averageTimeSpent}
            level={level}
          />
        )}{" "}
        {preview && (
          <div className="timesd">
            <img
              className="lesson-level"
              src={getLevelImage(level)}
              alt="Difficulty level"
            />
            <p>Question Difficulty level</p>
          </div>
        )}
        {report && report.derivedInsights && report.derivedInsights.length > 0 && (
          <div className="mechine">
            <img className="img-fluid" src={getImage("insight.svg")} alt="" />
            <p>
              <b>Machine Learning</b> <br />
              {derivedInsights}
            </p>
          </div>
        )}
      </div>

      <div className="col-md-1"></div>
        <div className="col-md-7">
          {questionType && questionType !== "NUMERIC_QUESTIONS" && (
            <div className="currect">
              <p>
                CORRECT OPTIONS: <span>A</span>
              </p>
            </div>
          )}
          {questionType && questionType === "NUMERIC_QUESTIONS" && question.options && question.options.length > 0 && (
            <div className="currect">
              <p>
                CORRECT ANSWER{" "}
                <span class="numbers">{question.options[0]}</span>
              </p>
            </div>
          )}
        </div>
      {!preview && (
        <div className="col-md-2">
          <div className="total">
            <h4> TOTAL ATTEMPTS: {report ? report.attempts : 0}</h4>
          </div>
        </div>
      )}
    </div>


    */
