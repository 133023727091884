import React, { useEffect, useRef } from 'react';
import { getImage } from '../../utils/image-utils';

const ShowProgressInfo = ({ onClose }) => {
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click do nothing
      return;
    }
    // outside click close card
    onClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div id="overlay-container">
      <div ref={node} class="kids-performance-modal">
        <div class="modal-title-area">
          <div class="modal-title">
            <h4>High, Medium, Low Performance</h4>
          </div>
          <div class="close-modal">
            <img src={getImage('Close.png')} onClick={() => onClose()} alt="" />
          </div>
        </div>
        <div class="modal-bodyy">
          <div class="single-modal-content">
            <div class="modal-left-text">
              <img src={getImage('oval-1.png')} alt="" />
            </div>
            <div class="modal-right-text">
              <h4>Low Performance</h4>
              <p>
                Low performers are the ones who got less than 40% of the
                questions
              </p>
            </div>
          </div>
          <div class="single-modal-content">
            <div class="modal-left-text">
              <img src={getImage('oval-2.png')} alt="" />
            </div>
            <div class="modal-right-text">
              <h4>Medium Performance</h4>
              <p>
                Medium performers are the ones who got between 40-80% of the
                questions.
              </p>
            </div>
          </div>
          <div class="single-modal-content">
            <div class="modal-left-text">
              <img src={getImage('oval-3.png')} alt="" />
            </div>
            <div class="modal-right-text">
              <h4>High Performance</h4>
              <p>
                High performers are the ones who get more 80% of the questions
                right.
              </p>
            </div>
          </div>
          <div class="single-modal-content">
            <div class="modal-left-text">
              <img src={getImage('oval-0.png')} alt="" />
            </div>
            <div class="modal-right-text">
              <h4>Incomplete</h4>
              <p>
                Students who have partially completed or not started the
                challenge.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowProgressInfo;
