import React, { useEffect } from "react";

import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import SiteNavigation from "../components/SiteNavigation";
import { getImage } from "../utils/image-utils";
import PrivacyPolicy from "../components/PrivacyPolicy";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="wrapper" class="parent-home" style={{ background: "white" }}>
      <SiteHeader />

      <section className="ftco-section ftco-services">
        <div className="sk-container">
          <div className="row justify-content-center mb-5 pb-5">
            {/* <div className="col-md-7 text-center heading-section fadeInUp ftco-animated">
            </div> */}

            <div class="profiles-main-warpp">
              <SiteNavigation active="schooling" />
              <div class="right-content-warp">
                <div class="right-content-inner">
                  <div class="help-content">
                    <div class="help-title">
                      <h4>Online Pilot</h4>
                    </div>

                    <div class="modal-bodyy">
                      <div class="modal-right-text">
                        <img
                          style={{ width: "100%" }}
                          src={getImage("picturemessage_xc12udkd.lgg.png")}
                          alt="Online tutoring"
                        />
                      </div>
                    </div>
                  </div>

                  {/* <button class="accordion">Third-Party Notices</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SiteFooter />
    </div>
  );
};

export default Privacy;
