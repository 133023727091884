import React from "react";
import { getNumber, millisToMinutesAndSeconds } from "../../../utils/display-utils";
import { getImage, getLevelImage } from "../../../utils/image-utils";

const QuestionTime = ({
  index,
  report,
  question,
  averageTimeSpent,
  level,
  lessonType,
  preview
}) => {
  let timeSpent = getNumber((report && report.timeSpent && report.timeSpent > 0 ? report.timeSpent : 0) /1000);
  console.log(timeSpent + " QuestionAnalysis averageTimeSpent: " + averageTimeSpent);
  const slow = timeSpent > averageTimeSpent ? true : false;
  const jit = timeSpent === averageTimeSpent ? true : false;
  const fast = timeSpent < averageTimeSpent ? true : false;

  let correct = null;
  let unattempted = true;
  if (report) {
    unattempted = false;
    correct = report.correct ? report.correct : false;
  }

  let displayTime = null;
  if (report && report.timeSpent && report.timeSpent > 0) {
    timeSpent = report
      ? new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 0,
        }).format(timeSpent)
      : 0;
    displayTime = report
      ? millisToMinutesAndSeconds(
          report && report.timeSpent ? report.timeSpent : 0
        )
      : 0;
  }

  return (
    <React.Fragment>
      <div class="d-flex align-items-center mb-3">
        {correct && (
          <div class="mr-3" style={{ width: "48px" }}>
            <img src={getImage("correct-answer-green.svg")} alt="correct" />
          </div>
        )}
        {!correct && !unattempted && (
          <div>
            <div class="mr-3" style={{ width: "38px" }}>
              <img src={getImage("wrong-answer-red.svg")} alt="incorrect" />
            </div>
          </div>
        )}
        {unattempted && (
          <div class="mr-3" style={{ width: "48px" }}>
            <img src={getImage("unattempted.svg")} alt="unattempted" />
          </div>
        )}
        {timeSpent && (
          <div>
            {displayTime && <h6>{displayTime} Minutes spent</h6>}
            {!unattempted && fast && (
              <div class="speed" style={{ color: "#40d360" }}>
                <img src={getImage("fast.svg")} alt="icon" /> Lightning fast
              </div>
            )}
            {!unattempted && jit && (
              <div class="speed" style={{ color: "#57d1ff" }}>
                <img src={getImage("jit.svg")} alt="icon" /> Just in time
              </div>
            )}
            {!unattempted && slow && (
              <div class="speed" style={{ color: "#f43d6e" }}>
                <img src={getImage("slow.svg")} alt="icon" /> Little slow
              </div>
            )}
          </div>
        )}
      </div>

      <div class="mb-3 difficulty-level text-uppercase">
        <img
          className="lesson-level"
          src={getLevelImage(level)}
          alt="Difficulty level"
        />
        <span>Question Difficulty level</span>
      </div>
    </React.Fragment>

    //   {timeSpent ? (
    //     <div className="time">
    //       <p>{displayTime} Minutes spent</p>
    //       {!unattempted && fast && (
    //         <p className="fast">
    //           <img className="img-fluid" src={getImage("fast.svg")} alt="" />{" "}
    //           Lightning fast
    //         </p>
    //       )}
    //       {!unattempted && jit && (
    //         <p className="just">
    //           <img className="img-fluid" src={getImage("jit.svg")} alt="" />{" "}
    //           Just in time
    //         </p>
    //       )}
    //       {!unattempted && slow && (
    //         <p className="slow">
    //           <img className="img-fluid" src={getImage("slow.svg")} alt="" />{" "}
    //           Little slow
    //         </p>
    //       )}
    //       {unattempted && <p className="unat"> unattempted </p>}
    //     </div>
    //   ) : (
    //     // <!-- missing timespent -->
    //     <div className="time">
    //       <br />
    //       <br />
    //       <br />
    //     </div>
    //   )}
    //   <div className="timesd">
    //     <img
    //       className="lesson-level"
    //       src={getLevelImage(level)}
    //       alt="Difficulty level"
    //     />
    //     <p>Question Difficulty level</p>
    //   </div>
    // </div>
  );
};

export default QuestionTime;