import React from "react";
import Parents from "../../../components/Parent/Parents";
import Upload from "../../../components/upload/Upload";

import { config } from "../../../Constants";

import { getImage, getAvatarImage } from "../../../utils/image-utils";
import { reportActions, report } from "../../../utils/report-utils";
import ShowProblem from "../../../components/Student/LessonContent/ShowProblem";
import requireAuthentication from "../../../components/AuthenticatedComponent";
import {
  getProfile,
  getToken,
  getFamily,
  getClassroom,
  getGrade,
  getName,
  getBirthday
} from "../../../utils/profile-utils";
import "./StudentProfile.css";

class StudentProfile extends React.Component {
  // default State object
  state = {
    cards: [],
    showUpload: false
  };
  report = {};

  constructor(props) {
    super(props);

    if (!getProfile() || !getToken()) {
      this.props.history.push("/logout");
    }

    console.log(props);

    this.report.pageName = "Student Profile";

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.toggleUpload = this.toggleUpload.bind(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    console.log("Reporting action: " + reportActions.LOAD_PAGE);
    this.report.action = reportActions.LOAD_PAGE;
    console.log("Sending report: " + JSON.stringify(this.report));
    report(this.report);
    // loadHomework(this.loadData);
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
  }

  handleClick() {
    this.props.history.goBack();
  }

  toggleUpload() {
    this.setState({
      showUpload: !this.state.showUpload
    });
  }

  render() {
    const parents = getFamily().parents;
    const teacher =
      getClassroom() && getClassroom().teacher ? getClassroom().teacher : null;
    console.log("Profile showUpload? " + this.state.showUpload);
    return (
      <div id="sk-container">
        <div className="sk-container">
          <div className="left button-container">
            <img
              src={getImage("Component-2.png")}
              className="top-left-button"
              onClick={this.handleClick}
              alt="Back"
            />
          </div>
          <div className="profile-wrapper">
            <div className="avatar-image">
              <img src={getAvatarImage(getProfile())} alt="avatar" />
              <span
                className="sk-badge"
                alt="edit-avatar"
                onClick={() => this.toggleUpload()}
              />
            </div>
            <div className="profile-card">
              <div className="profile-name">
                <h1>{getName()}</h1>
              </div>
              <div className="profile-details-table">
                <div className="profile-details large">
                  <div className="profile-header">Grade</div>
                  <div className="header-value">{getGrade()}</div>
                </div>
                <div className="profile-details large">
                  <div className="profile-header">Birthday</div>
                  <div className="header-value">
                    {getBirthday() > 0 &&
                      new Intl.DateTimeFormat("en-US", {
                        month: "long",
                        day: "2-digit",
                        year: "numeric"
                      }).format(getBirthday())}
                    {getBirthday() <= 0 && "--"}
                  </div>
                </div>
                {/* <div className="profile-details">
                  <div className="profile-header">City</div>
                  <div className="header-value">Washington DC</div>
                </div> */}
              </div>
              {parents && (
                <div className="clear family">
                  <div className="family-wrapper">
                    <Parents parents={parents} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="clear school">
            {teacher && (
              <div className="profile-details">
                <img src={getImage(teacher.avatar)} alt="Avatar" />
                <div className="clear family-details">
                  <div className="profile-header">Teacher</div>
                  <div className="header-value">{teacher.name}</div>
                </div>
              </div>
            )}
            {/* <div className="profile-details">
                        <img src={Teacher} alt="Avatar"/>
                        <div className="clear family-details">
                            <div className="profile-header">Science Teacher</div>
                            <div className="header-value">Lilymont Charllie</div>
                        </div>
                    </div> */}
            {/* <div className="profile-details">
                        <img src={getImage('logo-170x172.png')} alt="Avatar"/>
                        <div className="clear family-details">
                            <div className="profile-header">School</div>
                            <div className="header-value">Amnity International</div>
                        </div>
                    </div> */}
          </div>
        </div>
        <ShowProblem report={this.report} />
        {this.state.showUpload && <Upload toggleUpload={this.toggleUpload} />}
      </div>
    );
  }
}

export default requireAuthentication(StudentProfile);
