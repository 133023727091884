import React, { useState } from "react";
import { getImage } from "../../../utils/image-utils";
import { getTopicMetadata } from "../../../services/ParentService";
import TopicRow from "../TopicRow";

const SubjectTopicSummary = ({
    childProfile,
    subjectStyle,
    topicAccuracy,
    topicExposure,
    lessonProgress,
    subject,
    topicMetrics,
    days,
}) => {

    // topicMetrics.forEach((t) => {
    //   console.log(subject + " SubjectTopicSummary topic: " + JSON.stringify(t));
    // });
    const topics = topicMetrics ? topicMetrics.filter(t => t && t.subject ? t.subject === subject: false) : [];

    return (

        <div class="sk-card mt-4 card-reset overflow-hidden">
            <div
                class="card-header p-md-4 p-3 d-block d-sm-flex strip-content text-white align-items-center"
                style={{ backgroundColor: "#a463f7" }}
            >
                <div class="strip-count my-sm-0 mb-2">{topics.length}</div>
                <div class="strip-info pl-sm-3 pl-2 flex-grow-1 my-sm-0 mb-2">
                    <h4>Topic attempted</h4>

                    <div class="d-flex si-bottom flex-wrap align-items-center">
                        <div class="strip-pill text-success mr-2">+ 20 New</div>
                        <strong>Compared to last week</strong>
                    </div>
                </div>
                {/* <div class="col-sm-6">
                    <div
                        class="btn-group float-right"
                        role="group"
                        aria-label="Basic example"
                    >
                        <button type="button" class="btn btn-white btn-lg"  style={{width: "7em", margin: "0px"}}>
                            Learning
                      </button>
                        <button
                            type="button"
                            onclick="window.location.href='interaction-L3-testing.html'"
                            class="btn btn-white btn-lg"  style={{width: "6em", margin: "0px"}}>
                            Testing
                      </button>
                        <button type="button" class="btn btn-white btn-lg active" style={{width: "5em", margin: "0px"}}>
                            Both
                      </button>
                    </div>
                </div> */}
            </div>
            <div
                class="card-body collapse show px-md-4 px-2 py-md-5 py-4"
                id="hideTopic"
            >
                <div class="challenge-listheading pb-md-3 mb-4 d-none d-md-block">
                    <div class="row">
                        <div class="col-lg-3 col-md-4">
                            <span>Topics</span>
                        </div>
                        <div class="col-lg-2 col-md-2 text-center">
                            <span>Accuracy</span>
                        </div>
                        <div class="col-lg-2 col-md-2 text-center">
                            <span>Exposure</span>
                        </div>
                        {/* <!-- /.col-lg-2 --> */}
                        <div class="col-lg-3 col-md-4">
                            <span>Challenges completed</span>
                        </div>
                        {/* <!-- /.col-lg-3 --> */}
                    </div>
                    {/* <!-- /.row --> */}
                </div>
                {/* <!-- /.challenge-listheading --> */}
                
                {topics.map((m) => {
                    console.log("SubjectTopicSummary topic: " + JSON.stringify(m));
                    let topicMetadata = null 
                    if (m && m.key) {
                        topicMetadata = getTopicMetadata(m.key);
                        return <TopicRow topicMetadata={topicMetadata} metrics={m} childProfile={childProfile}
                            topicId={m.key} />
                    }
                    return null;
                })}
            </div>
        </div>

    );
}

export default SubjectTopicSummary;