import React from 'react';

import { getImage } from '../../utils/image-utils';
import { displaySubject } from '../../utils/display-utils';

const TeacherHeader = ({ grade, subject, back }) => {
  return (
    <div className="s-responses-top-area">
      <div className="sk-container">
        <div className="back-btn">
          <a onClick={() => back()}>
            <img src={getImage('adult-arrow.svg')} alt="back" />
          </a>
        </div>
        <div className="s-response-title">
          <h1>Create Challenge</h1>
          <div className="response-tags">
            {grade && <li>GRADE {grade}</li>}
            <li>{displaySubject(subject)}</li>
          </div>
        </div>
      </div>
    </div>
  );
};

TeacherHeader.propTypes = {};

export default TeacherHeader;
