import React, { Component } from "react";

import "react-datepicker/dist/react-datepicker.css";
import {
  getChildren,
  isParent,
  isTeacher,
  getStudents,
} from "../../utils/profile-utils";
import TopicCard from "./TopicCard";
import { getImage } from "../../utils/image-utils";

class StepTopic extends Component {
  // default State object
  state = {
    step: 1,
    startDate: new Date(),
    showNext: false,
    catalog: null,
    topicId: null,
  };

  constructor(props) {
    super(props);

    this.state.catalog = props.catalog;
    this.state.startDate = props.startDate;
    this.state.assignees = props.assignees;
    this.state.topicId = props.topicId;

    if (isParent()) {
      this.state.children = getChildren();
    } else if (isTeacher()) {
      this.state.students = getStudents();
    }

    if (
      props.startDate &&
      props.assignees &&
      props.topicId &&
      props.topicId.length > 0
    ) {
      this.state.showNext = true;
    }

    this.handleTopicChange = this.handleTopicChange.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleTopicChange(topicId) {
    console.log("StepTopic handleTopicChange:" + topicId);
    // console.log("StepTopic handleTopicChange assignees:" + this.state.assignees);
    let showNext = false;
    if (topicId && topicId.length > 0) {
      showNext = true;
    }
    this.setState({
      topicId: topicId,
      showNext: showNext,
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const {
      startDate,
      topicId,
      assignees,
      dateError,
      lessonError,
      childError,
      children,
      students,
      catalog,
      showNext
    } = this.state;
    return (
      <main class="steps-container py-4">
        <div class="container container-minify">
          <div class="card border-0 card-shadow">
            <div class="card-body">
              <div class="steps_card-header pt-2 text-center">
                <h2>Select Topics</h2>
              </div>

              <div class="pt-1 pb-3">
                <div class="steps_card_topics mb-4">

                  <div class="topics-available pt-3 pb-4 px-3">
                    <h3 class="mb-0">Select from available topics</h3>

                    <div class="row">
                      {catalog &&
                        catalog.topics.map((t) => (
                          <TopicCard
                            topic={t}
                            active={topicId === t.id}
                            handleTopicChange={this.handleTopicChange}
                          />
                        ))}
                    </div>
                  </div>
                </div>

                {/* <div class="footer-btns d-flex steps-btns align-items-center">
                  <button
                    onClick={() => this.props.previousStep(-1)}
                    class="btn btn-lg text-uppercase btn-noshadow btn-default"
                  >
                    back
                  </button>
                  <button
                    onClick={() => topicId ? this.props.nextStep(topicId) : null}
                    className={
                      "btn btn-lg text-uppercase ml-auto " +
                      (topicId ? "btn-primary" : "disabled")
                    }
                  >
                    next
                  </button>
                </div> */}
              
              
              </div>
              <div class="d-flex sticky-bottom bg-white py-2 steps-btns align-items-center">
                <button onClick={() => this.props.previousStep(-1)} class="btn btn-lg text-uppercase btn-noshadow btn-default">back</button>
                <button onClick={() => topicId ? this.props.nextStep(topicId) : null}
                  class={"btn btn-lg text-uppercase ml-auto " +
                  (topicId ? "btn-primary" : "disabled")}>next</button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default StepTopic;
