import React from "react";
import { NavLink } from "react-router-dom";
import uniqueId from "react-html-id";

import { config, CHILD_LOADING_IMAGES } from "../../../Constants";

import { displaySubject } from "../../../utils/display-utils";
import { reportActions, report } from "../../../utils/report-utils";

import ProfileWidget from "../../../components/Student/ProfileWidget/ProfileWidget";
import ShowProblem from "../../../components/Student/LessonContent/ShowProblem";

import "./Challenges.css";

import { getImage } from "../../../utils/image-utils";
import Loader from "../../../components/Loader";
import requireAuthentication from "../../../components/AuthenticatedComponent";
import {
  getUserid,
  getToken,
  getProfile
} from "../../../utils/profile-utils";
import { getHomeworkHistory } from "../../../services/HomeworkService";

class Challenges extends React.Component {
  report = {};

  // default State object
  state = {
    cards: null
  };

  constructor(props) {
    super(props);

    if (!getProfile() || !getToken()) {
      this.props.history.push("/logout");
    }

    // console.log('PROPS: ' + JSON.stringify(props));
    this.state.subject = props.match.params.subject;

    this.report.pageName = "Challenges";
    this.report.userid = getUserid();
    this.report.subject = this.state.subject;

    // This binding is necessary to make `this` work in the callback
    this.loadData = this.loadData.bind(this);
    this.showError = this.showError.bind(this);
    this.clearHash = this.clearHash.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.STUDENT_PAGE_CLASS);
    getHomeworkHistory(
      false,
      this.state.subject,
      this.loadData,
      this.showError
    );
    // console.log('Reporting action: ' + reportActions.LOAD_PAGE);
    this.report.action = reportActions.LOAD_PAGE;
    // console.log('Sending report: ' + JSON.stringify(this.report));
    report(this.report);
    setTimeout(
      function() {
        getHomeworkHistory(
          true,
          this.state.subject,
          this.loadData,
          this.showError
        );
      }.bind(this),
      10000
    );
  }

  loadData(response, cards) {
    const newCards = cards;

    // create a new "State" object without mutating
    // the original State object.
    const newState = Object.assign({}, this.state, {
      cards: newCards,
      lessons: response
    });

    // store the new state object in the component's state
    this.setState(newState);
  }

  showError(error) {
    // create a new "State" object without mutating
    // the original State object.

    if (!getProfile() || !getToken()) {
      this.props.history.push("/logout");
    }

    if (error && error.response && error.response.status === 401) {
      this.props.history.push("/logout");
    }
    // console.log('ERROR + ' + JSON.stringify(error));

    const newState = Object.assign({}, this.state, {
      showError: true,
      errorMessage: error.message,
      error: error
    });

    // store the new state object in the component's state
    this.setState(newState);
  }

  clearHash() {
    const hash = this.props.location.hash;
    if (hash && hash.length > 0) {
      this.props.history.goBack();
    }
  }

  render() {
    // const cards = this.state.cards.filter(l => l.lessonReport != null);
    const cards = this.state.cards;

    // make a table
    const numberOfCards = cards ? cards.length : 0;
    let rows = 0;
    const COLUMNS = 3;
    let sections = 0;
    const SECTION_ROWS = 3;

    // console.log('Total cards: ' + numberOfCards + ' ' + (numberOfCards % COLUMNS));

    if (numberOfCards % COLUMNS === 0) {
      rows = numberOfCards / COLUMNS;
    } else {
      const num = numberOfCards + (COLUMNS - (numberOfCards % COLUMNS));
      // console.log('Total adjusted cards: ' + num);
      rows = num / COLUMNS;
    }

    // console.log('Total row: ' + rows + ' ' + (rows % SECTION_ROWS));

    if (rows % SECTION_ROWS === 0) {
      sections = rows / SECTION_ROWS;
    } else {
      const numRows = rows + (SECTION_ROWS - (rows % SECTION_ROWS));
      // console.log('Total adjusted rows: ' + numRows);
      sections = numRows / SECTION_ROWS;
    }
    // console.log('Total Cards: ' + numberOfCards);
    // console.log('Total sections: ' + sections);
    // console.log('Total rows: ' + rows);
    // console.log('Total columns: ' + COLUMNS);

    let moreRows = rows;

    let table = [];
    let i = 0;
    let j = 0;
    let k = 0;
    for (i = 0; i < sections; i++) {
      let section = [];
      let visibleRows = moreRows < SECTION_ROWS ? moreRows : SECTION_ROWS;
      // console.log('Total moreRows: ' + moreRows + ' ' + visibleRows);
      for (j = 0; j < visibleRows; j++) {
        let row = [];
        for (k = 0; k < COLUMNS; k++) {
          const index = j * COLUMNS + k + SECTION_ROWS * COLUMNS * i;
          // console.log('Card Index: ' + i + ' ' + j + ' ' + k + ' => ' + index);
          if (index < cards.length) {
            row[k] = cards[index];
          }
        }
        section[j] = row;
        moreRows--;
      }
      table[i] = section;
    }

    return (
      <div id="student-container">
        <div className="header-bar math-header">
          <div className="sk-container">
            <div className=" header-back">
              <NavLink to={"/choose/challenge"}>
                <img src={getImage("student-back.svg")} alt="back" />
              </NavLink>
            </div>
            <div className="header-meddle header-meddle-small">
              <h3>{displaySubject(this.state.subject)}</h3>
            </div>
            <ProfileWidget
              onDismiss={this.clearHash}
              locationHash={this.props.location.hash}
              history={this.props.history}
            />
          </div>
        </div>

        <ShowProblem report={this.report} />
        {!this.state.cards && (
          <Loader
            image={
              CHILD_LOADING_IMAGES[
                Math.floor(Math.random() * CHILD_LOADING_IMAGES.length)
              ]
            }
          />
        )}
        <div
          id={this.getUniqueId("challenge-item-area")}
          className="math-item-area"
        >
          <div className="sk-container">
            <div className="math-inner">
              {table.map((c, index) => (
                <ChallengeSection
                  key={index}
                  section={c}
                  sectionIndex={index}
                  uniqueId={this}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function ChallengeSection(props) {
  console.log(
    "ChallengeSection props: " + JSON.stringify(props.section.length)
  );
  // section={c} linkTo={c.type} sectionIndex={index}
  let twinceClass = "twince";
  if (props.section.length === 1) {
    twinceClass = "twince1";
  } else if (props.section.length === 2) {
    twinceClass = "twince2";
  }
  return (
    <div className={twinceClass}>
      {props.section.map((row, index) => (
        <ChallengeRow
          key={index}
          row={row}
          sectionIndex={props.sectionIndex}
          rowIndex={index}
          uniqueId={props.uniqueId}
        />
      ))}
    </div>
  );
}

function ChallengeRow(props) {
  // console.log('ChallengeRow props: ' + JSON.stringify(props.row));
  const float = props.rowIndex % 2 === 0 ? "left" : "right";
  return (
    <div className="clear math-item-wrapper">
      {props.row.map((l, index) => (
        <NavLink
          key={l.lessonId}
          id={props.uniqueId.getUniqueId(
            props.sectionIndex + "-challenge-row-" + props.rowIndex
          )}
          activeClassName="active"
          to={"/questions/" + l.lessonId}
        >
          <ChallengeCard
            lesson={l}
            index={index}
            float={float}
            uniqueId={props.uniqueId}
          />
        </NavLink>
      ))}
    </div>
  );
}

function ChallengeCard(props) {
  const { lesson, float, index } = props;
  const {
    lessonReport,
    displayReport,
    subject,
    name,
    points,
    pastDue
  } = lesson;
  // if (lesson) {
  //   console.log('ChallengeCard props: ' + JSON.stringify(lesson));
  // }

  let cardStyle = float + " single-math-item ";
  if (index % 3 === 1) {
    cardStyle = float + " single-math-item just-top";
  }

  return (
    <div className={cardStyle}>
      {displayReport.showIncomplete && (
        <div
          className={
            subject.toLowerCase() +
            " main-item mathpoint-item mathpoint-item-" +
            subject.toLowerCase()
          }
        >
          <div className="point">
            <span>+{points} pts</span>
          </div>
          <div className="item-list">
            <li id={props.uniqueId.nextUniqueId()}>{name}</li>
          </div>
          <div className="skill-btn skill-btn-incomplete">
            <p style={lesson.percentage} />
            <span>
              <strong id={props.uniqueId.nextUniqueId()}>
                {lessonReport.attemptedQuestions}
              </strong>
              <b id={props.uniqueId.nextUniqueId()}>
                {" "}
                / {lessonReport.totalQuestions}
              </b>{" "}
              <br />
              QUESTIONS DONE
            </span>
          </div>
          {pastDue && (
            <img
              className="mathicon"
              src={getImage("pending-icon.svg")}
              alt=""
            />
          )}
        </div>
      )}
      {displayReport.showAccuracy && (
        <div
          className={"single-math star-item star-item-" + subject.toLowerCase()}
        >
          <div className="item-list">
            <li id={props.uniqueId.nextUniqueId()}>{name}</li>
          </div>
          <div className="star-area">
            <img
              sid={props.uniqueId.nextUniqueId()}
              src={getImage(displayReport.starImage)}
              alt=""
            />
          </div>
          <div className="text-lock">
            <p>
              <strong id={props.uniqueId.nextUniqueId()}>
                {displayReport.correctAnswers}
              </strong>
              <b id={props.uniqueId.nextUniqueId()}>
                {" "}
                / {lessonReport.totalQuestions}
              </b>{" "}
              <br />
              <span>CORRECT RESPONSES</span>
            </p>
          </div>
        </div>
      )}
      {displayReport.mustStart && (
        <div
          className={
            "single-math main-item mathpoint-item mathpoint-item-" +
            subject.toLowerCase()
          }
        >
          <div className="point">
            <span>+{points} pts</span>
          </div>
          <div className="item-list">
            <li id={props.uniqueId.nextUniqueId()}>{name}</li>
          </div>
          <div className="skill-btn">
            <p>
              <span id={props.uniqueId.nextUniqueId()}>Pending Challenge</span>
            </p>
          </div>
          {pastDue && (
            <img
              id={props.uniqueId.nextUniqueId()}
              className="mathicon"
              src={getImage("pending-icon.svg")}
              alt=""
            />
          )}
        </div>
      )}
    </div>
  );
}

export default requireAuthentication(Challenges);
