import React, { Component } from "react";

import axios from "axios";


import { config } from "../../Constants";

import "./../Dashboard/Dashboard.css";
import "./CreateChallenge.css";
import "./Sidenav.css";

import TeacherHeader from "../../components/Teacher/TeacherHeader";

import "react-datepicker/dist/react-datepicker.css";
import requireAuthentication from "../../components/AuthenticatedComponent";
import {
  getChildren,
  isParent,
  isTeacher,
  getStudents,
  getProfile,
  getToken,
  getSubjects
} from "../../utils/profile-utils";
import { displaySubject, displayLessonName } from "../../utils/display-utils";
import Step1 from "../../components/CreateChallenge/Step1";
import Step2 from "../../components/CreateChallenge/Step2";
import Step3 from "../../components/CreateChallenge/Step3";

class CreateChallenge extends Component {
  // default State object
  state = {
    step: 0,
    startDate: new Date(),
    grade: null,
    subject: null,
    showNext: false,
    showCatalog: false,
    catalog: null,
    lessonKey: null,
    lessonName: null
  };

  constructor(props) {
    super(props);
    // console.log(props);

    if (!getProfile() || !getToken()) {
      this.props.history.push("/logout");
    }

    this.state.grade = props.match.params.grade;
    const subjects = getSubjects();
    if (props.match.params.subject) {
      this.state.subject = subjects.includes(
        props.match.params.subject.toUpperCase()
      )
        ? props.match.params.subject.toUpperCase()
        : null;
    }
    this.state.subjects = subjects;
    this.state.assignees = props.match.params.child;

    if (this.state.grade && this.state.subject) {
      this.state.showNext = true;
    }

    if (isParent()) {
      this.state.children = getChildren();
    } else if (isTeacher()) {
      this.state.students = getStudents();
    }

    this.previousStep = this.previousStep.bind(this);
    this.showCatalog = this.showCatalog.bind(this);
    this.pickLesson = this.pickLesson.bind(this);
    this.assignLesson = this.assignLesson.bind(this);
    this.goBack = this.goBack.bind(this);
    this.finalizeStep1 = this.finalizeStep1.bind(this);
    this.finalizeStep2 = this.finalizeStep2.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
    document.body.classList.add(config.class.CHALLENGE_PAGE_CLASS);

    console.log("Subjects: " + getProfile());
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.CHALLENGE_PAGE_CLASS);
  }

  finalizeStep1(index, grade, subject) {
    this.setState(
      {
        grade: grade,
        subject: subject,
        step: this.state.step + index,
        showNext: false,
        catalog: null,
        lessonKey: null,
        lessonName: null
      },
      () => this.showCatalog()
    );
  }

  finalizeStep2(index, date, topic, assignees) {
    console.log("Step 2 is done: " + date + " " + topic + " " + assignees);
    this.setState({
      startDate: date,
      topicKey: topic,
      assignees: assignees,
      step: this.state.step + index,
      showNext: false,
      lessonKey: null,
      lessonName: null
    });
  }

  previousStep(index) {
    const { step } = this.state;
    console.log(step + " CreateChallenge previousStep index: " + index);

    // go back
    this.setState({
      step: this.state.step + index,
      lesson: null,
      showNext: true
    });
  }

  assignLesson(lessonKey, role) {
    console.log("Assign request was created by " + role);
    let assignPath = "";
    if (role === "teacher") {
      assignPath = "/api/homework/assign/class/";
    }
    if (role === "parent") {
      assignPath = "/api/homework/assign/parent/" + this.state.assignees + "/";
    }

    console.log("Assign request will be sent to " + assignPath + lessonKey);

    axios
      .get(config.url.API_BASE_URL + assignPath + lessonKey, {
        headers: { Authorization: getToken() }
      })
      .then(response => {
        console.log(response);
        this.props.history.goBack();
      })
      .catch(error => console.log(error));
  }

  showCatalog() {
    const showCatalog = !this.state.showCatalog;
    if (showCatalog && !this.state.catalog) {
      axios
        .get(
          config.url.API_BASE_URL +
          "/api/lesson/catalog/grade/" +
          this.state.grade +
          "/subject/" +
          this.state.subject,
          { headers: { Authorization: getToken() } }
        )
        .then(response => {
          this.setState({
            catalog: response.data.object.data.topic
            // showCatalog: showCatalog
          });
        })
        .catch(error => console.log(error));
    }
    // else {
    //   this.setState({
    //     showCatalog: showCatalog
    //   });
    // }
    console.log("CreateChallenge showCatalog:" + this.state.showCatalog);
  }

  pickLesson(lessonKey, lessonName) {
    console.log("CreateChallenge pickLesson:" + lessonKey);
    console.log("CreateChallenge pickLesson assignees:" + this.state.assignees);
    let showNext = false;
    if (
      this.state.startDate &&
      this.state.assignees &&
      lessonKey &&
      lessonKey.length > 0
    ) {
      showNext = true;
    }
    this.setState(
      {
        lessonKey: lessonKey,
        lessonName: lessonName ? lessonName : lessonKey,
        showNext: showNext
      },
      () => {
        this.showCatalog();
      }
    );
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const {
      grade,
      subject,
      catalog,
      startDate,
      assignees,
      topicKey,
      step
    } = this.state;
    console.log("CreateChallenge: step " + step);
    console.log(
      "Step 2 is data: " + startDate + " " + topicKey + " " + assignees
    );
    return (
      <div>
        <TeacherHeader subject={subject} grade={grade} back={this.goBack} />
        <div className="create-challenge-modal center">
          <div className="modal-bodyy adult-editable">
            {step === 0 && (
              <Step1
                grade={grade}
                subject={subject}
                nextStep={this.finalizeStep1}
              />
            )}

            {step === 1 && catalog && (
              <Step2
                catalog={catalog}
                startDate={startDate}
                topicKey={topicKey}
                assignees={assignees}
                previousStep={this.previousStep}
                nextStep={this.finalizeStep2}
              />
            )}

            {step === 2 && catalog && (
              <Step3
                catalog={catalog}
                topicKey={topicKey}
                previousStep={this.previousStep}
                assignLesson={this.assignLesson}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default requireAuthentication(CreateChallenge);
