import React, { Component } from "react";

import { config } from "../../Constants";
import ReportHeader from "../../components/Parent/ReportHeader";
import HomeworkPicker from "../../components/Teacher/HomeworkPicker";
import GraspWidget from "../../components/Teacher/GraspWidget/GraspWidget";
import ClassChallengeQuestions from "../../components/Teacher/ClassChallengeQuestions";

import { reportActions, report, reportProblem } from "../../utils/report-utils";
import requireAuthentication from "../../components/AuthenticatedComponent";
import {
  getClassReportByLesson,
  mapClassReport
} from "../../utils/teacher-dashboard-utils";
import ShowProblem from "../../components/Student/LessonContent/ShowProblem";
import { getGrade } from "../../utils/profile-utils";
import { displaySubject } from "../../utils/display-utils";

class ClassLessonReport extends Component {
  // default State object
  state = {
    subject: ""
  };

  report = {};

  constructor(props) {
    super(props);

    console.log(props);
    this.state.lessonKey = props.match.params.lesson;

    this.report.pageName = "Teacher Lesson Report";
    this.report.lessonKey = this.state.lessonKey;

    this.goBack = this.goBack.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
    this.report.action = reportActions.LOAD_PAGE;
    console.log("Sending report: " + JSON.stringify(this.report));
    report(this.report);
    getClassReportByLesson(this.state.lessonKey, this.loadData);
  }

  loadData(response) {
    console.log(response);
    this.setState({
      reports: response,
      homeworkReports: Object.values(response.homeworkReports).map(c =>
        mapClassReport(c)
      )
    });
  }

  componentWillUnmount() {
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
  }

  sentProblem(comment) {
    let report = this.report;
    console.log("Reporting action: PROBLEM");
    report.action = "PROBLEM";
    report.comment = comment;
    reportProblem(report, this.state.token);
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    console.log(this.state);
    const { homeworkReports, lessonKey } = this.state;
    return (
      <div>
        {homeworkReports && (
          <ReportHeader
            // lessonName={shortName}
            subject={displaySubject(homeworkReports[0].lesson.subject)}
            // child={child}
            grade={getGrade()}
            // displayDate={reports.displayDate}
            // back={this.goBack}
            // complete={complete}
            lessonName={homeworkReports[0].lesson.name}
            displayDate={new Intl.DateTimeFormat("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric"
            }).format(homeworkReports[0].assignDate)}
            back={this.goBack}
          />
        )}
        <div className="spaced-container">
          {homeworkReports && (
            <HomeworkPicker
              lessonKey={lessonKey}
              homework={homeworkReports[0]}
              isSingleLesson={true}
            />
          )}
        </div>
        <div className="spaced-container">
          {homeworkReports && (
            <ClassChallengeQuestions reports={homeworkReports[0]} />
          )}
        </div>
        <div className="spaced-container">
          {homeworkReports && (
            <GraspWidget
              lessonReports={homeworkReports}
              showReportsByTopic={false}
              isClassReport={true}
            />
          )}
        </div>
        <ShowProblem report={this.report} />
      </div>
    );
  }
}

export default requireAuthentication(ClassLessonReport);
