import React from "react";

import { config } from "../../Constants";
import AdminHeader from "../../components/Admin/AdminHeader";
import "./Admin.css";
import {
  registerUser,
  resetPassword,
  welcomeEmail
} from "../../utils/request-utils";
import { getToken, getProfile } from "../../utils/profile-utils";

class Register extends React.Component {
  state = {
    token: null,
    addParent: true,
    addChild: false,
    resetPassword: false,
    sendWelcome: false
  };

  constructor(props) {
    super(props);

    if (!getToken() || !getProfile() || getProfile().access[0] !== "ADMIN") {
      this.props.history.push("/logout");
    }

    this.register = this.register.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.showAddParent = this.showAddParent.bind(this);
    this.showAddChild = this.showAddChild.bind(this);
    this.showResetPassword = this.showResetPassword.bind(this);
    this.reset = this.reset.bind(this);
    this.welcome = this.welcome.bind(this);
    this.showWelcome = this.showWelcome.bind(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.STUDENT_PAGE_CLASS);
  }

  register() {
    console.log("Trying to register user " + this.state.email);
    registerUser({
      name: this.state.name,
      email: this.state.email,
      gender: this.state.gender
    });
  }

  reset() {
    resetPassword({
      email: this.state.email,
      captcha: this.state.captcha
    });
  }

  welcome() {
    console.log("Trying to welcome user " + this.state.email);
    welcomeEmail({
      email: this.state.email
    });
  }

  handleNameChange(event) {
    console.log("Register handleNameChange:" + event);
    this.setState({
      name: event.target.value
    });
  }

  handleEmailChange(event) {
    console.log("Register handleEmailChange:" + event);
    this.setState({
      email: event.target.value
    });
  }

  handleGenderChange(event) {
    console.log("Register handleGenderChange:" + event);
    this.setState({
      gender: event.target.value
    });
  }

  showAddParent(event) {
    console.log("Register handleGenderChange:" + event);
    this.setState({
      addParent: true,
      addChild: false,
      resetPassword: false,
      sendWelcome: false
    });
  }

  showAddChild(event) {
    console.log("Register handleGenderChange:" + event);
    this.setState({
      addParent: false,
      addChild: true,
      resetPassword: false,
      sendWelcome: false
    });
  }

  showResetPassword(event) {
    console.log("Register handleGenderChange:" + event);
    this.setState({
      addParent: false,
      addChild: false,
      resetPassword: true,
      sendWelcome: false
    });
  }

  showWelcome(event) {
    console.log("Register showWelcome:" + event);
    this.setState({
      addParent: false,
      addChild: false,
      resetPassword: false,
      sendWelcome: true
    });
  }

  render() {
    return (
      <div className="sk-container admin-page" style={{ margin: 60 + "px" }}>
        <AdminHeader />
        <div className="clear">
          <div>
            <ul>
              <li onClick={() => this.showAddParent()}>Register Parent</li>
              <li onClick={() => this.showAddChild()}>Add Child</li>
              <li onClick={() => this.showResetPassword()}>Reset Password</li>
              <li onClick={() => this.showWelcome()}>Send Welcome</li>
            </ul>
          </div>
          {this.state.addParent && (
            <div>
              <div className="clear">
                <h1>Register Parent</h1>Name:
                <input
                  className="input"
                  type="text"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />
              </div>
              <div>
                Email:
                <input
                  className="input"
                  type="text"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
              </div>
              <div>
                Gender:
                <input
                  className="input"
                  type="text"
                  placeholder="Gender"
                  value={this.state.gender}
                  onChange={this.handleGenderChange}
                />
              </div>
              <button onClick={() => this.register()}>Register</button>
            </div>
          )}

          {this.state.addChild && (
            <div>
              <div className="clear">
                <h1>Add Child</h1>
                Name:
                <input
                  className="input"
                  type="text"
                  placeholder="Child's name"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />
              </div>
              <div>
                User Name:
                <input
                  className="input"
                  type="text"
                  placeholder="Avatar"
                  value={this.state.username}
                  onChange={this.handleNameChange}
                />
              </div>
              <div>
                Parent Email:
                <input
                  className="input"
                  type="text"
                  placeholder="Name"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
              </div>
              <div>
                Gender:
                <input
                  className="input"
                  type="text"
                  placeholder="Gender"
                  value={this.state.gender}
                  onChange={this.handleGenderChange}
                />
              </div>
              <button onClick={() => this.regster()}>Add Child</button>
            </div>
          )}

          {this.state.resetPassword && (
            <div>
              <div className="clear">
                <h1>Reset Password</h1>
                Email:
                <input
                  className="input"
                  type="text"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
              </div>
              <button onClick={() => this.reset()}>Reset Password</button>
            </div>
          )}

          {this.state.sendWelcome && (
            <div>
              <div className="clear">
                <h1>Reset Password</h1>
                Email:
                <input
                  className="input"
                  type="text"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
              </div>
              <button onClick={e => this.welcome()}>Send Welcome</button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Register;
