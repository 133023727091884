import React from 'react';
import PropTypes from 'prop-types';

import './RecentChallenges.css';
import LessonReportCard from './LessonReportCard';
import { getImage } from '../../utils/image-utils';
import { showLeftArrow, showRightArrow } from '../../utils/display-utils';

class RecentChallenges extends React.Component {
  state = {
    lessonReports: [],
    showLeft: false,
    showRight: true,
  };

  constructor(props) {
    super(props);
    console.log(props);
    this.scrollingWrapper = React.createRef();

    this.scrollChange = this.scrollChange.bind(this);
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);

    console.log(props.lessonReports);
  }

  scrollChange() {
    const { firstCard, lastCard } = this.props;
    let showLeft = showLeftArrow(firstCard);
    let showRight = showRightArrow(lastCard);
    this.setState({
      showLeft: showLeft,
      showRight: showRight,
    });
  }

  scrollLeft() {
    const element = this.scrollingWrapper.current;
    console.log('scrollLeft: ' + element.scrollLeft);
    element.scrollLeft -= 100;
    this.scrollChange();
  }
  scrollRight() {
    const element = this.scrollingWrapper.current;
    console.log('scrollRight: ' + element.scrollLeft);
    element.scrollLeft += 100;
    this.scrollChange();
  }

  render() {
    return (
      <div className="recent-challenges">
        <div className="full-container">
          <div className="sk-container">
            <div className="clng-title">
              <span>Recent Challenges</span>
            </div>
          </div>
          <div
            className="challenge-wrap scrolling-wrapper"
            ref={this.scrollingWrapper}
            onScroll={this.scrollChange}
          >
            {this.state.showLeft && (
              <img
                className="more-cards more-left"
                onClick={() => this.scrollLeft()}
                src={getImage('left-play-btn.png')}
                alt="scroll left"
              />
            )}
            {this.props.lessonReports.map(c => (
              <LessonReportCard key={c.lesson.key} report={c} />
            ))}
            {this.state.showRight && (
              <img
                className="more-cards more-right"
                onClick={() => this.scrollRight()}
                src={getImage('right-play-btn.png')}
                alt="scroll right"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

RecentChallenges.propTypes = {
  lessonReports: PropTypes.array.isRequired,
};

export default RecentChallenges;
