import React from "react";
import PropTypes from "prop-types";
import uniqueId from "react-html-id";

import ProfileCard from "../ProfileCard/ProfileCard";
import SettingsWidget from "../../../components/Student/Settings/SettingsWidget";
import NotificationsWidget from "../../../components/Student/Notifications/NotificationsWidget";

import "./ProfileWidget.css";
import { getAvatarImage } from "../../../utils/image-utils";
import {
  getServerVersion,
  getProfile,
  getNotifications,
  getScore,
  getNextLevelRange,
  getLevelIndex,
  showNotification,
  getNewNotificationsCount,
  getAchievements
} from "../../../utils/profile-utils";
import LevelNotification from "../Notifications/LevelNotifications";
import { markNotificationViewed } from "../../../utils/notification-utils";
import ApplauseNotifications from "../Notifications/ApplauseNotifications";
import AchievementsWidget from "../Notifications/AchievementsWidget";
import RequestComplete from "../ErrorHandler/RequestComplete";

class ProfileWidget extends React.Component {
  constructor(props) {
    super(props);

    const { locationHash } = props;

    let isToggleOn = false;
    let isSettingsToggleOn = false;
    let isNotificationsToggleOn = false;
    let isAchievementsToggleOn = false;
    // console.log('ProfileWidget location: ' + locationHash);
    if (locationHash) {
      const anchor = locationHash.replace("#", "");
      // console.log('ProfileWidget anchor: ' + anchor);
      if (anchor === "settings") {
        // console.log('ProfileWidget showing settings: ' + anchor);
        isSettingsToggleOn = true;
      } else if (anchor === "notifications") {
        // console.log('ProfileWidget showing notifications: ' + anchor);
        isNotificationsToggleOn = true;
      } else if (anchor === "achievements") {
        // console.log('ProfileWidget showing achievements: ' + anchor);
        isAchievementsToggleOn = true;
      }
    }
    const applicationVersion = process.env.REACT_APP_GIT_SHA;
    this.state = {
      isToggleOn: isToggleOn,
      isSettingsToggleOn: isSettingsToggleOn,
      isNotificationsToggleOn: isNotificationsToggleOn,
      isAchievementsToggleOn: isAchievementsToggleOn,
      applicationVersion: applicationVersion,
      mustShowLevelNotification: true,
      mustShowApplause: true,
      mustShowComplete: true
    };

    this.profileImage = React.createRef();
    this.profileCard = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.dismiss = this.dismiss.bind(this);
    this.openSettings = this.openSettings.bind(this);
    this.openNotifications = this.openNotifications.bind(this);
    this.openAchievements = this.openAchievements.bind(this);
    this.closeNotificationAlert = this.closeNotificationAlert.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  handleClick() {
    // console.log('ProfileWidget handleClick');
    const toggle = !this.state.isToggleOn;
    let right = 0;
    if (toggle) {
      // adjust profile card to center on profile profile image
      // console.log('ProfileWidget handleClick => adjust position');

      const image = this.profileImage.current.getBoundingClientRect();
      // const card = this.profileCard.current.getBoundingClientRect();
      // const y = window.innerHeight || document.documentElement.clientHeight;
      const x = window.innerWidth || document.documentElement.clientWidth;
      // console.log("Window x: " + x + " y: " + y);

      if (image) {
        // console.log('Profile image: ' + JSON.stringify(image));
        // console.log("Profile card: " + JSON.stringify(card));
        // right = (image.right);
        right = x - (image.x + image.width * 1.25);
        // console.log('Adjusted Profile card right padding: ' + right);
      }
    }
    this.setState({
      isToggleOn: toggle,
      isSettingsToggleOn: false,
      isNotificationsToggleOn: false,
      cardRight: right
    });
  }

  dismiss() {
    // console.log('ProfileWidget dismiss');
    this.setState({
      isSettingsToggleOn: false,
      isNotificationsToggleOn: false,
      isAchievementsToggleOn: false
    });
    if (this.props.onDismiss) {
      this.props.onDismiss();
    }
  }

  openSettings() {
    // console.log('ProfileWidget openSettings');
    this.handleClick();
    this.setState({
      isToggleOn: false,
      isSettingsToggleOn: true,
      isNotificationsToggleOn: false,
      isAchievementsToggleOn: false
    });
  }

  openNotifications() {
    // console.log('ProfileWidget openNotifications');
    this.handleClick();
    this.setState({
      isSettingsToggleOn: false,
      isNotificationsToggleOn: true,
      isAchievementsToggleOn: false
    });
  }

  openAchievements() {
    // console.log('ProfileWidget openNotifications');
    this.handleClick();
    this.setState({
      isSettingsToggleOn: false,
      isNotificationsToggleOn: false,
      isAchievementsToggleOn: true
    });
  }

  closeNotificationAlert(notification) {
    // console.log('ProfileWidget closeNotificationAlert ' + JSON.stringify(notification));
    if (notification) {
      // complete request must not be dismissed on cancel
      markNotificationViewed(notification);
    }
    if (
      notification &&
      notification.notificationType.toLowerCase() === "complete_request"
    ) {
      this.props.history.push("/questions/" + notification.lessonKey);
    } else {
      this.setState({
        mustShowLevelNotification: false,
        mustShowApplause: false,
        mustShowComplete: false
      });
    }
  }

  render() {
    const {
      isToggleOn,
      isNotificationsToggleOn,
      isSettingsToggleOn,
      isAchievementsToggleOn,
      applicationVersion,
      mustShowLevelNotification,
      mustShowApplause,
      mustShowComplete
    } = this.state;

    let needsUpdate = false;
    if (getServerVersion() && applicationVersion !== getServerVersion()) {
      needsUpdate = true;
    }

    const avatarImage = getAvatarImage(getProfile());
    // console.log('ProfileWidget score: ' + JSON.stringify(getScore()));
    // console.log('ProfileWidget getNextLevelRange: ' + JSON.stringify(getNextLevelRange()));
    const percentage = {
      width: (getScore() * 100) / getNextLevelRange() + "%"
    };

    let numberOfNotifications = getNewNotificationsCount();
    // console.log('ProfileWidget numberOfNotifications: ' + numberOfNotifications);
    // console.log('ProfileWidget percentage: ' + JSON.stringify(percentage));
    // console.log('ProfileWidget isToggleOn: ' + isToggleOn);
    // console.log('ProfileWidget isNotificationsToggleOn: ' + isNotificationsToggleOn);
    // console.log('ProfileWidget isSettingsToggleOn: ' + isSettingsToggleOn);
    // console.log(' ProfileWidget applicationVersion: ' + applicationVersion);
    // console.log(' ProfileWidget serverVersion: ' + serverVersion);
    // console.log(' ProfileWidget serverVersion needsUpdate: ' + needsUpdate);

    const showComplete =
      mustShowComplete && showNotification("COMPLETE_REQUEST");
    const showLevelUp =
      !showComplete &&
      mustShowLevelNotification &&
      showNotification("LEVEL_UP");
    // trying not to overlap notifications
    const showApplause =
      !showComplete &&
      !showLevelUp &&
      mustShowApplause &&
      showNotification("APPLAUSE");

    // console.log(mustShowLevelNotification + " ProfileWidget showLevelUp? : " + showLevelUp);
    return (
      <div className="sk-header-right">
        <div className="main-profile">
          <div
            className="pro-ic cursor-pointer"
            onClick={() => this.handleClick()}
          >
            <img
              ref={this.profileImage}
              id={this.getUniqueId("profile-picture")}
              src={avatarImage}
              alt="avatar"
            />
            {!numberOfNotifications && <div className="online" />}
            {numberOfNotifications > 0 && (
              <div className="notification-badge sk-badge">
                {numberOfNotifications}
              </div>
            )}
          </div>
          <div className="profile">
            <p className="pro-bg" style={percentage}>
              <div>
                <strong className="left" id={this.getUniqueId("profile-level")}>
                  Lv {getLevelIndex()}
                </strong>
                <span className="left" id={this.getUniqueId("profile-score")}>
                  {getScore()}/{getNextLevelRange()}
                </span>
                <b className="left">PTS</b>
              </div>
            </p>
          </div>
        </div>
        <div className="berger-menu">
          <div className="menubar" onClick={() => this.handleClick()}>
            <span />
            <span />
            <span />
          </div>
        </div>
        <div ref={this.profileCard}>
          {isToggleOn && (
            <ProfileCard
              cardRight={this.state.cardRight + "px"}
              notifications={numberOfNotifications}
              onSettings={this.openSettings}
              onNotifications={this.openNotifications}
              onAchievements={this.openAchievements}
              needsUpdate={needsUpdate}
              onClose={this.handleClick}
              getUniqueId={this.getUniqueId}
            />
          )}
        </div>
        <div id={this.getUniqueId("profile-settings")}>
          {isSettingsToggleOn && (
            <SettingsWidget onClose={this.dismiss} needsUpdate={needsUpdate} />
          )}
        </div>
        <div id={this.getUniqueId("profile-notifications")}>
          {isNotificationsToggleOn && (
            <NotificationsWidget
              notifications={getNotifications()}
              onClose={this.dismiss}
              getUniqueId={this.getUniqueId}
            />
          )}
        </div>
        <div id={this.getUniqueId("profile-notifications")}>
          {isAchievementsToggleOn && (
            <AchievementsWidget
              notifications={getAchievements()}
              onClose={this.dismiss}
            />
          )}
        </div>
        <div id={this.getUniqueId("level-notifications")}>
          {showLevelUp && (
            <LevelNotification onClose={this.closeNotificationAlert} />
          )}
        </div>
        <div id={this.getUniqueId("applause-notifications")}>
          {showApplause && (
            <ApplauseNotifications onClose={this.closeNotificationAlert} />
          )}
        </div>
        <div id={this.getUniqueId("complete-notifications")}>
          {showComplete && (
            <RequestComplete onClose={this.closeNotificationAlert} />
          )}
        </div>
      </div>
    );
  }
}

ProfileWidget.propTypes = {
  locationHash: PropTypes.string.isRequired
};

export default ProfileWidget;
