import React, { useState } from "react";

import { displayName } from "../../../utils/display-utils";
import { getName } from "../../../utils/profile-utils";
import { sendNotification } from "../../../utils/notification-utils";
import { getImage, getLevelImage } from "../../../utils/image-utils";
import Toast from "../../Student/Toast/Toast";

const LessonReportHeader = ({
  child,
  displayDate,
  lessonName,
  subject,
  complete,
  lessonCount,
  grade,
  back,
  level
}) => {
  const [isApplause, setApplause] = useState(false);
  const [toastText, setToastText] = useState(null);

  const clap = e => {
    console.log("ChildReportCard clap: " + child);
    setApplause(true);
    const boundFunction = function() {
      setApplause(false);
      setToastText("You clapped for " + child.name);
    };
    sendNotification(child.userid, getName(), "", "APPLAUSE", boundFunction);
  };

  const clapImage = isApplause ? "parent-clap-active.svg" : "parent-clap.svg";

  return (
    <header class="steps-header bg-white">
      <div class="container container-minify">
        <div class="d-flex align-items-start">
          <div class="back_btn">
            <a onClick={() => back()}>
              <img src={getImage("back.svg")} alt="icon" />
            </a>
          </div>
          <div class="steps-header-title">
            <h2>{displayName(lessonName)}</h2>
            <div class="steps-header-info d-flex flex-wrap align-items-center">
              <span>Difficulty level</span>
              <img
                className="lesson-level p-2"
                src={getLevelImage(level)}
                alt="Difficulty level"
              />{" "}
              {"   "}
              <span class="p-1">Grade {grade}</span>
              <span class="p-1">
                {subject} {displayDate && "•"}{" "}
                {displayDate ? displayDate : null}
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LessonReportHeader;


    // <div className="s-responses-top-area parent-report">
    //   {toastText && <Toast text={toastText} />}
    //   <div className="sk-container">
    //     <div className="back-btn">
    //       <a onClick={() => back()}>
    //         <img src={getImage("adult-arrow.svg")} alt="back" />
    //       </a>
    //     </div>
    //     <div className="s-response-title">
    //       <h1>{displayName(lessonName)}</h1>
    //       {!lessonCount && (
    //         <div className="response-tags">
    //           <li>
    //             Difficulty level
    //             <img
    //               className="lesson-level"
    //               src={getLevelImage(level)}
    //               alt="Difficulty level"
    //             />
    //           </li>
    //           <li>Grade {grade}</li>
    //           <li>
    //             {subject} {displayDate && "•"}{" "}
    //             {displayDate ? displayDate : null}
    //           </li>
    //         </div>
    //       )}
    //       {lessonCount && (
    //         <div className="response-tags">
    //           <li>SHOWING {lessonCount} CHALLENGES</li>
    //         </div>
    //       )}
    //     </div>
    //   </div>
    // </div>
