import React from "react";
import axios from "axios";
import uniqueId from "react-html-id";
import { NavLink } from "react-router-dom";

import { config, LOGIN_LOADING_IMAGES } from "../../Constants";

import "./Login.css";

import { getImage } from "../../utils/image-utils";
import ConnectionErrorHandler from "../../components/Student/ErrorHandler/ConnectionErrorHandler";
import { checkText } from "../../utils/validation-utils";
import {
  setProfile,
  setToken,
  setNotifications,
  logout
} from "../../utils/profile-utils";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import { getDueHomework } from "../../services/HomeworkService";

class Login extends React.Component {
  state = {
    username: "",
    password: "",
    token: null,
    showError: false,
    errorMessage: null,
    nameError: null,
    passwordError: null,
    showLoader: false
  };

  constructor(props) {
    super(props);

    // clear old profile token & notifications
    logout();
    const queryString = require("query-string");
    this.state.next = queryString.parse(props.location.search).next;
    console.log("Login next: " + JSON.stringify(this.state.next));

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideError = this.hideError.bind(this);

    this.checkName = this.checkName.bind(this);
    this.checkPassword = this.checkPassword.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value, nameError: null });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value, passwordError: null });
  }

  hideError() {
    this.setState({
      showError: false,
      errorMessage: ""
    });
  }

  checkName() {
    const error = checkText(this.state.username);
    let fieldError;
    if (!error) {
      fieldError = "Enter avatar or email";
    }
    this.setState({
      nameError: fieldError,
      showSubmit: error
    });
  }

  checkPassword() {
    const error = checkText(this.state.password);
    let fieldError;
    if (!error) {
      fieldError = "Enter password";
    }
    this.setState({
      passwordError: fieldError,
      showSubmit: error
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    // get our form data out of state
    const { username, password } = this.state;

    this.checkName();
    this.checkPassword();

    if (
      this.state.showSubmit &&
      !this.state.passwordError &&
      !this.state.nameError
    ) {
      this.setState(
        {
          showLoader: true
        },
        () =>
          axios
            .post(config.url.API_BASE_URL + "/login", { username, password })
            .then(result => {
              if (result.headers && result.data) {
                let token = result.headers.authorization;
                const displayProfile = result.data;
                const profile = displayProfile.adultProfile;
                if (!token) {
                  token = profile.token;
                }
                // create an array of cards only with relevant data
                // the original State object.
                setProfile(profile);
                setToken(token);
                setNotifications(displayProfile.notificationList);

                const path = this.state.next;
                if (profile.access[0] === "TEACHER") {
                  if (path && path.includes("teacher")) {
                    this.props.history.push(path);
                  } else {
                    this.props.history.push("/teacher");
                  }
                } else if (profile.access[0] === "PARENT") {
                  if (path && path.includes("parent")) {
                    this.props.history.push(path);
                  } else {
                    this.props.history.push("/parent");
                  }
                } else if (profile.access[0] === "ADMIN") {
                  this.props.history.push("/admin");
                } else if (profile.access[0] === "STUDENT") {
                  // preload homework....
                  getDueHomework(true);
                  if (path) {
                    this.props.history.push(path);
                  } else {
                    this.props.history.push("/challenges");
                  }
                }
              } else {
                this.setState({
                  nameError: "Invalid login information",
                  passwordError: "Invalid login information",
                  showLoader: false
                });
              }
            })
            .catch(error => {
              console.log(error);
              this.setState({
                showError: true,
                errorMessage: error.message,
                nameError: null,
                passwordError: null,
                showLoader: false
              });
            })
      );
    }
  }

  render() {
    const {
      username,
      nameError,
      password,
      passwordError,
      showError,
      errorMessage
    } = this.state;
    return (
      <div id="login">
        <div id="sk-container">
          <div className="smart-kids on-blue">
            <NavLink className="cursor-pointer" to="/">
              SmartKids.io
            </NavLink>
          </div>
          <div className="login-wrapper">
            <div className="left login-mascot">
              <img
                className="center"
                src={getImage("login-mascot@3x.png")}
                alt="mascot"
              />
            </div>
            <div className="login">
              <form onSubmit={this.handleSubmit}>
                <div className={nameError ? "enter error" : "enter"}>
                  <input
                    id={this.getUniqueId("login-username")}
                    className="input"
                    placeholder="AVATAR OR EMAIL"
                    onBlur={() => this.checkName()}
                    value={username}
                    onChange={this.handleUsernameChange}
                  />
                  {nameError && <span className="error-text">{nameError}</span>}
                </div>
                <div className={passwordError ? "enter error" : "enter"}>
                  <input
                    id={this.getUniqueId("login-password")}
                    className="input"
                    type="password"
                    placeholder="PASSWORD"
                    onBlur={() => this.checkPassword()}
                    value={password}
                    onChange={this.handlePasswordChange}
                  />
                  {passwordError && (
                    <span className="error-text">{passwordError}</span>
                  )}
                </div>
                <div className="enter-text">
                  <span className="right forgot-password-link cursor-pointer">
                    <NavLink to="/reset">Forgot Password</NavLink>
                  </span>
                </div>
                <div
                  id={this.getUniqueId("login-submit")}
                  className="login-button"
                >
                  <button className="cursor-pointer" type="submit">
                    LOGIN
                  </button>
                </div>
                <div>
                  <div className="highlight cursor-pointer">
                    <NavLink to="/access">Sign Up</NavLink>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {this.state.showLoader && (
            <Loader
              image={
                LOGIN_LOADING_IMAGES[
                  Math.floor(Math.random() * LOGIN_LOADING_IMAGES.length)
                ]
              }
            />
          )}
        </div>
        {showError && (
          <ConnectionErrorHandler
            connectionError="true"
            onRetry={() => this.hideError()}
            errorMessage={errorMessage}
          />
        )}
        <Footer />
      </div>
    );
  }
}

export default Login;
