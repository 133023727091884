import React, { Component } from "react";

import axios from "axios";

import HorizontalTimeline from "react-horizontal-timeline";
import { getImage } from "../../utils/image-utils";

import { config } from "../../Constants";

import "./../Dashboard/Dashboard.css";
import "./../Parent/ParentLessonReport.css";
import "./../LessonReport/LessonReport.css";

import ReportHeader from "../../components/Parent/ReportHeader";
import {
  isParent,
  getChild,
  isTeacher,
  getStudent,
  getActor,
  getToken
} from "../../utils/profile-utils";
import { getLessonReportForChild } from "../../utils/dashboard-utils";
import requireAuthentication from "../../components/AuthenticatedComponent";
import {
  mapChildReport,
  mapDailyReport
} from "../../utils/parent-dashboard-utils";
import GraspReport from "../../components/Teacher/GraspWidget/GraspReport";
import ShowProblem from "../../components/Student/LessonContent/ShowProblem";
import { sendNotification } from "../../utils/notification-utils";
import Toast from "../../components/Student/Toast/Toast";
import { displaySubject } from "../../utils/display-utils";

class AdminStudentReport extends Component {
  // default State object
  state = {
    subject: "",
    isRequest: false,
    toastText: null,
    reportDate: 0,
    value: 0,
    previous: 0
  };

  report = {};

  constructor(props) {
    super(props);

    console.log(props);
    this.state.lessonKey = props.match.params.lesson;
    this.state.childId = props.match.params.child;

    this.report.pageName = "Child Lesson Report - " + this.state.childId;
    this.report.lessonKey = this.state.lessonKey;

    console.log(
      "AdminStudentReport params: " +
        this.state.childId +
        " " +
        this.state.lessonKey
    );

    this.loadData = this.loadData.bind(this);
    this.goBack = this.goBack.bind(this);
    this.completeRequest = this.completeRequest.bind(this);
    this.setValue = this.setValue.bind(this);
    this.reassign = this.reassign.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
    getLessonReportForChild(
      this.state.childId,
      this.state.lessonKey,
      this.loadData
    );
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
  }

  loadData(childId, report) {
    let child = null;
    if (isParent()) {
      child = getChild(childId);
    }
    if (isTeacher()) {
      child = getStudent(childId);
    }
    let map = null;
    try {
      map = mapChildReport(report);
    } catch (error) {
      console.log(error);
    }
    // console.log('AdminStudentReport render state: ' + JSON.stringify(child));
    this.setState({
      reports: map,
      child: child
    });
  }

  goBack() {
    // after login back should not take you to login button
    this.props.history.push(`/` + getActor());
  }

  completeRequest() {
    const { reports, child } = this.state;
    const { lesson } = reports;
    console.log("ChildReportCard completeRequest: " + child);
    this.setState({
      isRequest: true
    });
    const boundFunction = function() {
      this.setState({
        isRequest: false,
        toastText: "Request was sent to " + child.name
      });
    }.bind(this);
    sendNotification(
      child.userid,
      lesson.lessonKey,
      lesson.shortName,
      "COMPLETE_REQUEST",
      boundFunction
    );
  }

  setValue(event) {
    this.setState({
      reportDate: event.target.value
    });
  }

  reassign(event) {
    console.log("Reassign request was created");
    let assignPath = "/api/homework/reassign/" + this.state.childId + "/";

    console.log(
      "Assign request will be sent to " + assignPath + this.state.lessonKey
    );

    axios
      .get(config.url.API_BASE_URL + assignPath + this.state.lessonKey, {
        headers: { Authorization: getToken() }
      })
      .then(response => {
        console.log(response);
        window.location.reload();
      })
      .catch(error => console.log(error));
  }

  render() {
    // console.log('AdminStudentReport render state: ' + JSON.stringify(this.state));
    const { reports, child, isRequest, toastText, reportDate } = this.state;
    console.log(
      "AdminStudentReport render reports: " + JSON.stringify(this.reports)
    );
    if (!reports) {
      return <span />;
    }
    const {
      lesson,
      attemptedQuestions,
      correctAnswers,
      totalQuestions,
      lessonReport,
      questionActivity
    } = reports;
    const {
      subject,
      name,
      shortName,
      lessonKey,
      key,
      grade,
      topicName,
      questions
    } = lesson;
    const dailyReports = lessonReport ? lessonReport.dailyReports : null;
    const dailyReport =
      dailyReports && dailyReports.length > reportDate
        ? dailyReports[reportDate]
        : null;
    if (dailyReport) {
      Object.assign(dailyReport, { lesson: lesson });
    }
    const mappedReport = dailyReport ? mapDailyReport(dailyReport) : null;

    console.log(
      "AdminStudentReport - dailyReports: " + JSON.stringify(dailyReports)
    );

    let complete = false;
    let percentage = {
      width: (correctAnswers * 100) / totalQuestions + "%"
    };
    if (attemptedQuestions === totalQuestions) {
      complete = true;
    }

    const timeSpent = dailyReport
      ? new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 0
        }).format(
          (dailyReport.sessionReport.endTime -
            dailyReport.sessionReport.startTime) /
            60000
        ) + " minutes"
      : 0;

    // const VALUES = ["Date 1", "Date 2", "Date 3"];
    const VALUES = dailyReport
      ? dailyReports.map(dr => dr.sessionReport.date)
      : [];
    // console.log("Time line values: " + VALUES);

    return (
      <div>
        {toastText && <Toast text={toastText} />}
        {reports && (
          <ReportHeader
            lessonName={shortName}
            subject={displaySubject(subject)}
            child={child}
            grade={grade}
            displayDate={reports.displayDate}
            back={this.goBack}
            complete={complete}
          />
        )}
        <div className="completed-response">
          <div className="sk-container">
            <div>
              <b>Assigned Date</b>{" "}
              {new Intl.DateTimeFormat("en-US", {
                month: "numeric",
                day: "2-digit",
                year: "2-digit"
              }).format(new Date(reports.assignDate))}
            </div>
            {dailyReport && (
              <div>
                {/* Bounding box for the Timeline */}
                <div
                  style={{ width: "90%", height: "100px", margin: "0 auto" }}
                >
                  <HorizontalTimeline
                    index={this.state.value}
                    indexClick={index => {
                      this.setState({
                        value: index,
                        previous: index,
                        reportDate: index
                      });
                    }}
                    getLabel={dateLabel => {
                      {
                        const value = new Intl.DateTimeFormat("en-US", {
                          month: "numeric",
                          day: "2-digit",
                          year: "2-digit"
                        }).format(Date.parse(dateLabel));
                        console.log(dateLabel + " => " + value);
                        return value;
                      }
                    }}
                    values={VALUES}
                  />
                </div>
              </div>
            )}

            {/* <div>
              <div>{dailyReport.sessionReport.date}</div>
              <div>{timeSpent}</div>
            </div> */}
            <div className="minimal">
              {reports && (
                <GraspReport
                  key={key}
                  report={mappedReport}
                  child={child}
                  isClassReport={false}
                  minimal={true}
                  reportPath={""}
                  isSub={false}
                />
              )}
            </div>
            <div className="completed-response-title">
              {child && (
                <div className="left-completed-response">
                  <h4>{child.name}’s Responses</h4>
                  {complete && <span>COMPLETED</span>}
                  {!complete && (
                    <span className="red-completed">INCOMPLETED</span>
                  )}
                </div>
              )}
              {complete && (
                <div className="right-completed-response">
                  <div className="correct-responses-indicator">
                    <div className="fill" style={percentage} />
                    <span>{correctAnswers}</span>/ {totalQuestions} CORRECT
                    RESPONSES
                  </div>
                  <h5 className="time-taken">Time taken: {timeSpent}</h5>
                </div>
              )}
              {!complete && (
                <div className="request-btn bg-red">
                  <a
                    className={isRequest ? "clap-badge" : ""}
                    onClick={() => this.completeRequest()}
                  >
                    Request to Complete ({totalQuestions - attemptedQuestions})
                  </a>
                </div>
              )}
              <div className="request-btn bg-red">
                <a
                  className={isRequest ? "clap-badge" : ""}
                  onClick={() => this.reassign()}
                >
                  Reassign ({totalQuestions - attemptedQuestions})
                </a>
              </div>
            </div>
            {dailyReports && (
              <div className="spaced-container">
                <div className="spaced-container">
                  {dailyReport.questionActivity &&
                    dailyReport.questionActivity.map((c, index) => {
                      const question = questions[c.index];
                      return (
                        <AdminQuestionReport
                          key={index}
                          report={c}
                          lessonKey={lessonKey}
                          lessonName={name}
                          subject={subject}
                          topicName={topicName}
                          question={question}
                          averageTimeSpent={dailyReport.averageTimeSpent / 1000}
                        />
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
        <ShowProblem report={this.report} />
      </div>
    );
  }
}

function AdminQuestionReport(props) {
  // console.log('QuestionReport props: ' + JSON.stringify(props));
  const subjectStyle = props.subject.toLowerCase() + "-indicator";
  const timeSpent = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0
  }).format(props.report.timeSpent / 1000);
  const fontColor = timeSpent > props.averageTimeSpent ? "red" : "green";
  return (
    <div className="complete-incomplete-area">
      <div className="single-com-item">
        <div className="left-comp-text">
          <h2>{props.report.index + 1}</h2>
          <div className="com-response-text">
            {/* <p>{props.report.question}</p> */}
            <p dangerouslySetInnerHTML={{ __html: props.question.question }} />
            {props.question.displayImage && (
              <p>
                <img src={props.question.displayImage} />
              </p>
            )}
            {props.report.answers && (
              <div>
                {props.report.answers.map(a => (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: a
                    }}
                  />
                ))}
              </div>
            )}
            {props.question.options && (
              <div>
                {props.question.options[0] && (
                  <b>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: props.question.options[0]
                      }}
                    />
                  </b>
                )}{" "}
                &nbsp;|&nbsp;
                {props.question.options[1] && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.question.options[1]
                    }}
                  />
                )}{" "}
                &nbsp;|&nbsp;
                {props.question.options[2] && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.question.options[2]
                    }}
                  />
                )}{" "}
                &nbsp;|&nbsp;
                {props.question.options[3] && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.question.options[3]
                    }}
                  />
                )}
              </div>
            )}
            {props.report.derivedInsights && (
              <p className="error-text">
                {props.report.derivedInsights.map(d => (
                  <span>{d}</span>
                ))}
              </p>
            )}
            <h5>
              <span className={"dot-complete " + subjectStyle} />
              {props.topicName}
              <br />
              Time taken: <span style={{ color: fontColor }}>
                {timeSpent}
              </span>{" "}
              Seconds
              <br />
              Attempts: {props.report.attempts}
            </h5>
          </div>
        </div>
        {props.report.correct && (
          <div className="right-comp-text">
            <button>
              <img src={getImage("correct-answer.svg")} alt="correct" />
            </button>
          </div>
        )}
        {!props.report.correct && props.report.attempts > 0 && (
          <div className="right-comp-text cross-btn">
            <button>
              <img src={getImage("wrong-answer.svg")} alt="correct" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default requireAuthentication(AdminStudentReport);
