import { isParent, isTeacher, isAdmin } from "./profile-utils";
import { func } from "prop-types";

const images = require.context("../assets/images", true);

export function getImage(imageName) {
  try {
    return images("./" + imageName);
  } catch (err) {
    console.error("Missing image: " + imageName);
  }
}

export function getLevelImage(level) {
  console.log("getLevelImage level: " + level);
  let levelImage = "report-level-easy.svg";
  if (level) {
    if (level.toUpperCase() === "HARD") {
      levelImage = "report-level-high.svg";
    } else if (level.toUpperCase() === "MEDIUM") {
      levelImage = "report-level-medium.svg";
    }
  }
  return getImage(levelImage);
}

export function getAvatarImage(profile) {
  // console.log("getAvatarImage: " + JSON.stringify(profile.access[0]));
  if (!profile) {
    // TODO do smomethign when profile is missing
  } else {
    if (profile.profileImage) {
      return profile.profileImage;
    }
    let avatarImage = "";
    if (profile.avatar) {
      avatarImage = getImage(profile.avatar);
    } else {
      if (
        profile.access[0] === "PARENT" ||
        profile.access[0] === "TEACHER" ||
        profile.access[0] === "admin"
      ) {
        if (profile.gender && profile.gender === "female") {
          avatarImage = getImage("profile_mom.png");
        } else {
          avatarImage = getImage("profile_dad.jpg");
        }
      } else {
        if (profile.gender && profile.gender === "female") {
          avatarImage = getImage("profile_girl.jpg");
        } else {
          avatarImage = getImage("profile_boy.jpg");
        }
      }
    }
    return avatarImage;
  }
}
