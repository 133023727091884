import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import './ChallengeComplete.css';
import { getImage } from '../../../utils/image-utils';

class QuitChallenge extends Component {
  render() {
    console.log('Render QuitChallenge');
    return (
      <div className="lessonFinished">
        <div className="quit-challenge-container">
          <div className="error-area">
            <div className="error-area-inner">
              <div className="sk-container">
                <div className="qcuit-challenge">
                  <img
                    src={getImage('sad-characted.svg')}
                    alt="quit challenge"
                  />
                  <div className="quit-box">
                    <h4>Quit Challenge</h4>
                    <p>Are you sure you want to quit the challenge</p>
                    <a onClick={this.props.onClose()}>QUIT</a>
                  </div>
                  <div className="cancle-btn">
                    <a onClick={this.props.onCancel()}>CANCEL</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

QuitChallenge.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};

export default QuitChallenge;
