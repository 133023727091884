import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { config } from '../../Constants';

import { StickyContainer, Sticky } from 'react-sticky';

import { reportActions, report, reportProblem } from '../../utils/report-utils';
import requireAuthentication from '../../components/AuthenticatedComponent';
import { getChildReport } from '../../utils/teacher-dashboard-utils';
import { getUserid, getStudent } from '../../utils/profile-utils';
import { mapChildReport } from '../../utils/parent-dashboard-utils';
import ChildReportHeader from '../../components/Parent/ChildReportHeader';
import PickSubject from '../../components/Parent/Picker/PickSubject';
import ChallengeHistoryGraph from '../../components/Teacher/ChallengeHistoryGraph';
import GraspWidget from '../../components/Teacher/GraspWidget/GraspWidget';
import ChallengeHistory from '../../components/Teacher/ChallengeHistory';
import { getImage } from '../../utils/image-utils';
import { displaySubject } from '../../utils/display-utils';

class ChildReportCard extends Component {
  // default State object
  state = {
    subject: '',
    showList: false,
  };

  report = {};

  constructor(props) {
    super(props);

    console.log(props);
    this.state.child = props.match.params.child;
    this.state.anchor = this.props.location.hash.replace('#', '');

    this.report.pageName = 'Teacher Lesson Report';
    this.report.userid = getUserid();
    this.report.lessonKey = this.state.lessonKey;

    this.goBack = this.goBack.bind(this);
    this.loadData = this.loadData.bind(this);
    this.changeSubject = this.changeSubject.bind(this);
    this.toggleView = this.toggleView.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
    this.report.action = reportActions.LOAD_PAGE;
    console.log('Sending report: ' + JSON.stringify(this.report));
    report(this.report);
    getChildReport(this.state.child, this.loadData);
  }

  loadData(response) {
    console.log(response);
    const subjects = Object.keys(response.homeworkBySubject);
    const anchor = this.props.location.hash.replace('#', '');
    const pickedSubject = anchor ? anchor : subjects[0];

    const homeworkReports = Object.values(response.homework).map(c =>
      mapChildReport(c)
    );
    homeworkReports.sort((h1, h2) =>
      h2.displayDateValue > h1.displayDateValue ? 1 : -1
    );
    const lessons = homeworkReports.filter(
      l => l.lesson.subject === pickedSubject
    );
    console.log('pickedSubject pickedSubject : ' + pickedSubject);
    this.setState({
      reports: response,
      homeworkReports: homeworkReports,
      subjects: subjects,
      subject: pickedSubject,
      lessons: lessons,
    });
  }

  componentWillUnmount() {
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
  }

  sentProblem(comment) {
    let report = this.report;
    console.log('Reporting action: PROBLEM');
    report.action = 'PROBLEM';
    report.comment = comment;
    reportProblem(report, this.state.token);
  }

  changeSubject(subject) {
    const { subjects, homeworkReports } = this.state;
    console.log('changeSubject ParentDashboard: ' + subject);
    const lessons = homeworkReports.filter(l => l.lesson.subject === subject);
    this.setState({
      subject: subject,
      lessons: lessons,
    });
  }

  toggleView() {
    console.log('ChildReportCard toggleView: ' + this.state.showList);
    this.setState({
      showList: !this.state.showList,
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    // console.log(this.state);
    const {
      lessons,
      homeworkReports,
      subjects,
      child,
      subject,
      showList,
    } = this.state;
    const childProfile = getStudent(child);
    console.log(' pickedSubject pickedSubject pickedSubject =>' + subject);
    return (
      <div>
        {homeworkReports && (
          <ChildReportHeader child={childProfile} back={this.goBack} />
        )}
        <div class="acctivement-area">
          <div class="sk-container">
            <div class="acctivement-warp">
              <h4>Recent Achievements</h4>
              <div class="acctivement">
                <div class="single-accivement">
                  <img src={getImage('challenge-completed.png')} alt="" />
                  <p>
                    Challenge <br /> Completed
                  </p>
                </div>
                <div class="single-accivement">
                  <img src={getImage('correct-responses-streak.png')} alt="" />
                  <p>
                    Correct Response <br />
                    Streak
                  </p>
                </div>
                <div class="single-accivement">
                  <img src={getImage('correct-answers.png')} alt="" />
                  <p>
                    Total Correct <br />
                    Responses
                  </p>
                </div>
                <div class="single-accivement">
                  <img
                    src={getImage('practice-chapters.png')}
                    alt="practice chapters"
                  />
                  <p>
                    Practice <br />
                    Chapters
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <StickyContainer>
          <Sticky>
            {({
              style,

              // the following are also available but unused in this example
              isSticky,
              wasSticky,
              distanceFromTop,
              distanceFromBottom,
              calculatedHeight,
            }) => (
              <header style={style} class="summary-sub-tab">
                {subjects && (
                  <div class="sk-container">
                    <div class="sub-tab-list">
                      {subjects.map(c => (
                        <li className={subject === c ? 'active' : ''}>
                          <a
                            href={'#' + subject}
                            onClick={() => {
                              this.changeSubject(c);
                            }}
                          >
                            {' '}
                            {displaySubject(c)}{' '}
                          </a>
                        </li>
                      ))}
                    </div>
                    <NavLink activeClassName="active" to={'/create/challenge'}>
                      <div class="tab-info-dot">
                        <div class="tab-menu-dot">
                          <span />
                          <span />
                          <span />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                )}
              </header>
            )}
          </Sticky>
          {/* <div class="attentions-area spaced-container">
            <div class="sk-container">
              <div class="attention-box">
                <h4>Needs Attention</h4>
                <div class="attention-text">
                  <div class="left-attention">
                    <p>
                      {childProfile.name.split(' ')[0]} is Lagging behind in{' '}
                      <span>
                        Operations, Numberline <br />
                        and Subtraction
                      </span>
                    </p>
                  </div>
                  <NavLink activeClassName="active" to={'/create/challenge'}>
                    <div class="right-attention">
                      <a href="">+ CREATE CHALLENGE</a>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div> */}
          <div class="spaced-container">
            {!showList && lessons && (
              <ChallengeHistoryGraph
                lessonReports={lessons}
                subject={this.state.subject}
                showListViewOption={true}
                toggleView={this.toggleView}
              />
            )}
            {showList && lessons && (
              <ChallengeHistory
                child={child}
                lessonLimit={5}
                subject={subject}
                homework={lessons}
                toggleView={this.toggleView}
              />
            )}
          </div>
          <div class="spaced-container">
            {homeworkReports && (
              <GraspWidget
                lessonReports={lessons}
                showReportsByTopic={true}
                child={child}
                subject={subject}
                reportPath={'/teacher/report/child/' + child + '/lesson/'}
                isClassReport={false}
              />
            )}
          </div>
        </StickyContainer>
      </div>
    );
  }
}

export default requireAuthentication(ChildReportCard);
