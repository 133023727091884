import React from "react";
import PropTypes from "prop-types";

import { isMuted } from "../../../utils/speech-utils";
import { displayName } from "../../../utils/display-utils";

import { getImage } from "../../../utils/image-utils";
import requireAuthentication from "../../AuthenticatedComponent";

const LessonHeader = ({
  lessonName,
  useSpeech,
  toggleMute,
  onClose,
  getUniqueId
}) => {
  let image = "music.svg";
  let mutedButtonClass = isMuted()
    ? "cursor-pointer stopTalking"
    : "cursor-pointer";
  if (!useSpeech) {
    mutedButtonClass = "cursor-pointer stopTalking";
    image = "pixel.png";
  }
  // console.log('LessonHeader numberOfQuestions' + this.props.numberOfQuestions);
  // console.log("LessonHeader cardIndex" + this.props.cardIndex);
  return (
    <div className="question-bar">
      <div className="sk-container">
        <div className="question-timing-bar">
          <div className="left music-bar custom-bar custom-bar-small">
            <img
              className={mutedButtonClass}
              src={getImage(image)}
              alt=""
              onClick={() => toggleMute()}
            />
          </div>
          <div className="timing-bar custom-bar">
            {/* <div className="start-time">
                  <img src={getImage("c2.png")} alt=""/>
                  <button>00:34</button>
                </div> */}
            <h1>{displayName(lessonName)}</h1>
          </div>
          <div className="right cross-bar custom-bar custom-bar-small">
            <img
              src={getImage("close-button.png")}
              className="cursor-pointer"
              onClick={() => onClose()}
              alt="close"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

LessonHeader.propTypes = {
  lessonName: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  toggleMute: PropTypes.func
};

export default requireAuthentication(LessonHeader);
