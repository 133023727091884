import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import uniqueId from "react-html-id";

import { config, CHILD_LOADING_IMAGES } from "../../../Constants";
import { reportActions, report } from "../../../utils/report-utils";
import { getImage } from "../../../utils/image-utils";
import {
  getProfile,
  getToken
} from "../../../utils/profile-utils";
import { requireAuthentication } from "../../../components/AuthenticatedComponent";

import "./Homework.css";
import Loader from "../../../components/Loader";
import ProfileWidget from "../../../components/Student/ProfileWidget/ProfileWidget";
import SubjectCard from "../../../components/Student/SubjectCard/SubjectCard";
import ShowProblem from "../../../components/Student/LessonContent/ShowProblem";
import ConnectionErrorHandler from "../../../components/Student/ErrorHandler/ConnectionErrorHandler";
import { showLeftArrow, showRightArrow } from "../../../utils/display-utils";
import { getDueHomework } from "../../../services/HomeworkService";

class Homework extends Component {
  report = {
    pageName: "Homework"
  };

  // default State object
  state = {
    cards: [],
    showLeft: false,
    showRight: false,
    showError: false,
    loading: true
  };
  scrollingWrapper = React.createRef();

  constructor(props) {
    super(props);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.STUDENT_PAGE_CLASS);
    // console.log("Reporting action: " + reportActions.LOAD_PAGE);
    this.report.action = reportActions.LOAD_PAGE;
    // console.log("Sending report: " + JSON.stringify(this.report));
    report(this.report);
    getDueHomework(false, this.loadData, this.showError);
    setTimeout(getDueHomework(true, this.loadData, this.showError), 10000);
  }

  clearHash = () => {
    const hash = this.props.location.hash;
    if (hash && hash.length > 0) {
      this.props.history.goBack();
    }
  };

  scrollChange = () => {
    const { firstCard, lastCard } = this.state;
    let showLeft = showLeftArrow(firstCard);
    let showRight = showRightArrow(lastCard);
    this.setState({
      showLeft: showLeft,
      showRight: showRight
    });
  };

  scrollLeft = () => {
    const element = this.scrollingWrapper.current;
    // console.log("scrollLeft: " + element.scrollLeft);
    element.scrollLeft -= 100;
  };
  scrollRight = () => {
    const element = this.scrollingWrapper.current;
    // console.log('scrollRight: ' + element.scrollLeft);
    element.scrollLeft += 100;
    this.scrollChange();
  };

  loadData = lessons => {
    const newCards = lessons;
    const firstCard = newCards && newCards.length > 0 ? newCards[0].ref : null;
    const lastCard =
      newCards && newCards.length > 1
        ? newCards[newCards.length - 1].ref
        : null;
    // console.log("Homework serverVersion: " + getServerVersion());

    let showRight = false;
    if (newCards && newCards.length > 0) {
      showRight = true;
    }

    // create a new "State" object without mutating
    // the original State object.
    const newState = Object.assign({}, this.state, {
      cards: newCards,
      firstCard: firstCard,
      lastCard: lastCard,
      showError: false,
      loading: false,
      showRight: showRight
    });

    // store the new state object in the component's state
    this.setState(newState);
  };

  showError = error => {
    // create a new "State" object without mutating
    // the original State object.

    if (!getProfile() || !getToken()) {
      this.props.history.push("/logout");
    }

    if (error && error.response && error.response.status === 401) {
      this.props.history.push("/logout");
    }
    // console.log("ERROR + " + JSON.stringify(error));

    const newState = Object.assign({}, this.state, {
      showError: true,
      errorMessage: error.message,
      error: error,
      loading: false
    });

    // store the new state object in the component's state
    this.setState(newState);
  };

  retry = () => {
    getDueHomework(true, this.loadData, this.showError);
    this.setState({
      showError: false
    });
  };

  render() {
    const {
      cards,
      showError,
      errorMessage,
      loading,
      showLeft,
      showRight
    } = this.state;
    // console.log("Homework Loading: " + loading);
    const date = new Date();

    // const version = process.env.REACT_APP_GIT_SHA;
    // const showVersionToast = version !== getServerVersion ? true : false;

    return (
      <div id="student-container">
        <div>
          {/* {showVersionToast && (<Toast text={'Please refresh your browser to update to the latest version. '}/>)} */}
          <div className="header-bar">
            <div className="sk-container">
              <div className="header-left">
                <h1 id={this.getUniqueId("challenge-header")}>
                  Today's Challenges
                </h1>
                <span
                  id={this.getUniqueId("challenge-date")}
                  className="bold-text"
                >
                  {new Intl.DateTimeFormat("en-US", {
                    weekday: "long",
                    month: "long",
                    day: "2-digit"
                  }).format(date)}
                </span>
              </div>
              <ProfileWidget
                onDismiss={this.clearHash}
                locationHash={this.props.location.hash}
                history={this.props.history}
              />
            </div>
          </div>
          <div className="skills-item-area">
            <div className="full-container">
              <div
                id={this.getUniqueId("challenge-scroll")}
                onScroll={this.scrollChange}
                ref={this.scrollingWrapper}
                className="all-skills-item scrolling-wrapper"
              >
                {loading && (
                  <Loader
                    image={
                      CHILD_LOADING_IMAGES[
                        Math.floor(Math.random() * CHILD_LOADING_IMAGES.length)
                      ]
                    }
                  />
                )}
                {cards.map(c => {
                  // console.log("Homework displayReport: " + JSON.stringify(c.displayReport));
                  return (
                    <SubjectCard
                      lesson={c}
                      key={c.lessonId}
                      // linkTo={c.type}
                      uniqueId={this}
                    />
                  );
                })}
                {showLeft && (
                  <img
                    className="more-cards more-left"
                    onClick={() => this.scrollLeft()}
                    src={getImage("arrow-left.png")}
                  />
                )}
                {showRight && (
                  <img
                    className="more-cards more-right"
                    onClick={() => this.scrollRight()}
                    src={getImage("arrow-right.png")}
                  />
                )}
              </div>
            </div>
          </div>
          <footer>
            <div className="sk-container">
              <div className="footer-btn">
                <NavLink to="/choose/challenge">
                  <button className="cursor-pointer">
                    <span>
                      <img src={getImage("challenge-icon.svg")} alt="" />
                      Challenges
                    </span>
                  </button>
                </NavLink>
                <NavLink to="/choose/practice">
                  <button className="cursor-pointer small">
                    <span>
                      <img src={getImage("practice-icon.svg")} alt="" />
                      Practice
                    </span>
                  </button>
                </NavLink>
              </div>
            </div>
            <ShowProblem report={this.report} />
          </footer>
        </div>
        {showError && (
          <ConnectionErrorHandler
            connectionError="true"
            onRetry={() => this.retry()}
            errorMessage={errorMessage}
          />
        )}

        <img src={getImage("home-character.svg")} alt="" className="mascot" />
      </div>
    );
  }
}

export default requireAuthentication(Homework);
