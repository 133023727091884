import React from "react";
import { NavLink } from "react-router-dom";

import { getImage, getAvatarImage } from "../../utils/image-utils";
import {
  getActor,
  getName,
  getProfile,
  getFirstName,
} from "../../utils/profile-utils";

const ParentHeader = ({ page }) => {
  return (
    <header class="header">
      <div class="container-fluid pl-sm-0 pl-sm-3 pl-lg-0">
        <div class="d-flex">
          <a href="." class="navbar-title d-none d-sm-block d-xl-none pl-3">
            {page} 
          </a>

          <div class="ml-sm-auto d-flex align-items-center">
            <NavLink
              activeClassName="active"
              to={"/" + getActor() + "/profile"}
            >
              <div class="user-pic mr-auto mr-sm-0">
                <img
                  src={getAvatarImage(getProfile())}
                  class="rounded-circle"
                  alt="pic"
                />
                <span class="d-none ml-1 d-sm-inline-block">
                  {getFirstName(getName())}
                </span>
              </div>
            </NavLink>

            {/* <button class="btn ml-4 order-1 order-sm-0 btn-reset p-0">
              <img src={getImage("GYAN__Messaging.svg")} alt="icons" />
            </button>
            <button class="btn ml-4 mr-md-3 btn-reset p-0">
              <img src={getImage("np_bell.svg")} alt="icons" />
            </button> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default ParentHeader;
