import React from "react";
import { NavLink } from "react-router-dom";

import { getImage } from "../../utils/image-utils";
import { getActor } from "../../utils/profile-utils";
import ShowProgressInfo from "./ShowProgressInfo";

class ChallengeHistory extends React.Component {
  state = {
    homework: [],
    showInfo: false
  };

  constructor(props) {
    super(props);
    // console.log(props);

    // console.log(props.reports);
    this.showInfo = this.showInfo.bind(this);
  }

  showInfo() {
    this.setState({
      showInfo: !this.state.showInfo
    });
  }

  render() {
    const {
      showAll,
      homework,
      toggleView,
      child,
      limitLessons,
      toggleLimit
    } = this.props;
    // console.log(subject +' => ************SELECTED props ' +JSON.stringify(this.props.students));
    // console.log('DISPLAY HOMEWORK ' + JSON.stringify(homework));
    const lessonLimit = limitLessons ? 5 : homework.length;
    const showMore = homework.length <= lessonLimit ? false : true;
    console.log(
      limitLessons +
        " == limitLessons ? Showing " +
        lessonLimit +
        " of " +
        homework.length
    );
    return (
      <div className="clng-history-area">
        <div className="sk-container">
          <div className="clng-history-waarp">
            {!showAll && (
              <div className="history-clng-title">
                <div className="summary-title">
                  <h4 className="left">Challenges History</h4>
                  <div className="right info-wrap">
                    <img
                      src={getImage("information-icon.svg")}
                      onClick={() => this.showInfo()}
                      alt="legend"
                    />
                  </div>
                </div>
                {toggleView && (
                  <div className="right-history-txt">
                    <button onClick={() => toggleView()}>
                      <img src={getImage("date-view-icon.svg")} alt="" />
                      DATE VIEW
                    </button>
                    <button className="with-bg">
                      <img src={getImage("list-view-icon-active.png")} alt="" />
                      LIST VIEW
                    </button>
                  </div>
                )}
              </div>
            )}
            <div className="history-table">
              <table>
                <tbody>
                  <tr>
                    <th>CHALLENGE</th>
                    <th>STATUS</th>
                    <th className="text-right">CORRECT RESPONSE</th>
                  </tr>
                  {homework.map((c, index) => {
                    const {
                      displayDate,
                      lesson,
                      attemptedQuestions,
                      totalQuestions,
                      range,
                      complete,
                      started
                    } = c;
                    const { shortName } = lesson;
                    // console.log('ChallengeHistory homework: ' + JSON.stringify(c));
                    if (index < lessonLimit) {
                      return (
                        <tr>
                          <td>
                            <div className="com-response-text history-com">
                              <NavLink
                                activeClassName="active"
                                to={
                                  "/" +
                                  getActor() +
                                  "/report/child/" +
                                  child +
                                  "/lesson/" +
                                  lesson.lessonKey
                                }
                              >
                                {" "}
                                <p>{shortName}</p>
                              </NavLink>
                              <h5>
                                <span className={"dot-complete " + range} />
                                {displayDate}
                              </h5>
                            </div>
                          </td>
                          <td>
                            {complete && (
                              <button className="Completed-btn">
                                COMPLETED
                              </button>
                            )}
                            {!complete && (
                              <button
                                className={
                                  "red-completed " +
                                  (started ? "" : "stopTalking")
                                }
                              >
                                {started ? "INCOMPLETE" : "PENDING"}
                              </button>
                            )}
                          </td>
                          {/* TODO put correct questions here */}
                          <td>
                            {attemptedQuestions > 0 && (
                              <h4>
                                {" "}
                                {attemptedQuestions}/{" "}
                                <span>{totalQuestions}</span>{" "}
                              </h4>
                            )}
                          </td>
                        </tr>
                      );
                    } else {
                      return <span />;
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {!showAll && (
            <div className={showMore ? "view-txt" : "view-txt extra"}>
              {showMore && <a onClick={() => toggleLimit()}>View More</a>}
              {!showMore && lessonLimit < homework.length && (
                <a onClick={() => toggleLimit()}>View Less</a>
              )}
            </div>
          )}
          {this.state.showInfo && <ShowProgressInfo onClose={this.showInfo} />}
        </div>
      </div>
    );
  }
}

ChallengeHistory.propTypes = {};

export default ChallengeHistory;
