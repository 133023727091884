import React, { Component } from "react";

import axios from "axios";

import { config } from "../../Constants";

import "./../Dashboard/Dashboard.css";
import "./CreateChallenge.css";
import "./Sidenav.css";

import "react-datepicker/dist/react-datepicker.css";
import requireAuthentication from "../../components/AuthenticatedComponent";
import {
  getChildren,
  isParent,
  isTeacher,
  getStudents,
  getProfile,
  getToken,
  getSubjects,
  getChild,
} from "../../utils/profile-utils";
import { displaySubject, displayLessonName } from "../../utils/display-utils";
import StepTopic from "../../components/CreateChallenge/StepTopic";
import { loadSyllabusWithGrade } from "../../utils/student-utils";
import { getHomeworkReport } from "../../services/ParentService";
import { getImage } from "../../utils/image-utils";
import CreateChallengeHeader from "../../components/CreateChallenge/CreateChallengeHeader";
import StepLesson from "../../components/CreateChallenge/StepLesson";
import ParentStorage from "../Parent/ParentStorage";
import StepSubject from "../../components/CreateChallenge/StepSubject";

class ParentCreateChallenge extends Component {
  // default State object
  state = {
    step: 0,
    startDate: new Date(),
    grade: null,
    subject: null,
    showNext: false,
    showCatalog: false,
    catalog: null,
    lessonId: null,
    lessonName: null,
  };

  constructor(props) {
    super(props);
    // console.log(props);

    if (!getProfile() || !getToken()) {
      this.props.history.push("/logout");
    }

    this.state.grade = props.match.params.grade;
    const subjects = getSubjects();
    if (props.match.params.subject) {
      this.state.subject = subjects.includes(
        props.match.params.subject.toUpperCase()
      )
        ? props.match.params.subject.toUpperCase()
        : null;
    }
    this.state.subjects = subjects;
    const assignees = props.match.params.child;
    if (assignees) {
      const child = getChild(assignees);
      if (child) {
        this.state.grade = child.grade;
      }
    }
    this.state.assignees = assignees;

    if (this.state.grade && this.state.subject) {
      this.state.showNext = true;
    }

    if (isParent()) {
      this.state.children = getChildren();
    } else if (isTeacher()) {
      this.state.students = getStudents();
    }

    this.previousStep = this.previousStep.bind(this);
    this.showCatalog = this.showCatalog.bind(this);
    this.pickLesson = this.pickLesson.bind(this);
    this.goBack = this.goBack.bind(this);
    this.finalizeStep1 = this.finalizeStep1.bind(this);
    this.finalizeStep2 = this.finalizeStep2.bind(this);
    this.finalizeTopic = this.finalizeTopic.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
    document.body.classList.add(config.class.CHALLENGE_PAGE_CLASS);

    console.log("Subjects: " + getProfile());
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.CHALLENGE_PAGE_CLASS);
  }

  finalizeStep1(index, grade, subject) {
    this.setState(
      {
        grade: grade,
        subject: subject,
        step: this.state.step + index,
        showNext: false,
        catalog: null,
        lessonId: null,
        lessonName: null,
      },
      () => this.showCatalog()
    );
  }

  finalizeStep2(index, date, topic, assignees) {
    console.log("Step 2 is done: " + date + " " + topic + " " + assignees);
    this.setState({
      startDate: date,
      topicId: topic,
      assignees: assignees,
      step: this.state.step + index,
      showNext: false,
      lessonId: null,
      lessonName: null,
    });
  }

  finalizeTopic(topicId) {
    const { catalog, assignees } = this.state;
    console.log("Finalize Topic is done: " + topicId);
    let lessons = null;
    let topic = catalog.topics.filter((t) => t.id === topicId);
    if (topic && topic.length > 0) {
      topic = topic[0];
      if (topic.lessonList) {
        lessons = topic.lessonList.map((l) => {
          const report = {
            lessonMetadata: l,
            report: assignees ? getHomeworkReport(assignees, l.id) : null,
          };
          return report;
        });
      }
    }

    this.setState({
      startDate: null,
      topicId: topicId,
      topic: topic,
      step: 2,
      showNext: false,
      lessonId: null,
      lessonName: null,
      lessons: lessons,
    });
  }

  previousStep(index) {
    const { step } = this.state;
    console.log(step + " ParentCreateChallenge previousStep index: " + index);

    // go back
    this.setState({
      step: this.state.step + index,
      lesson: null,
      showNext: true,
    });
  }

  showCatalog() {
    const { grade, subject, assignees } = this.state;
    const showCatalog = !this.state.showCatalog;
    if (showCatalog && !this.state.catalog) {
      loadSyllabusWithGrade(assignees, subject, grade, this.loadSyllabus, this.showError);
    }
    console.log("ParentCreateChallenge showCatalog:" + this.state.showCatalog);
  }

  loadSyllabus = (syllabus) => {
    const newState = Object.assign({}, this.state, {
      catalog: syllabus.topic,
    });

    // store the new state object in the component's state
    this.setState(newState);
  };

  pickLesson(lessonId, lessonName) {
    console.log("ParentCreateChallenge pickLesson:" + lessonId);
    console.log(
      "ParentCreateChallenge pickLesson assignees:" + this.state.assignees
    );
    let showNext = false;
    if (
      this.state.startDate &&
      this.state.assignees &&
      lessonId &&
      lessonId.length > 0
    ) {
      showNext = true;
    }
    this.setState(
      {
        lessonId: lessonId,
        lessonName: lessonName ? lessonName : lessonId,
        showNext: showNext,
      },
      () => {
        this.showCatalog();
      }
    );
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const {
      grade,
      subject,
      catalog,
      startDate,
      assignees,
      topicId,
      step,
      lessons,
      topic,
    } = this.state;
    console.log("ParentCreateChallenge: step " + step);
    console.log("Step 2 data: " + startDate + " " + topicId + " " + assignees);
    return (
      <div id="wrapper" className="parent-dashboard adult-page">
        <CreateChallengeHeader grade={grade} subject={subject} child={assignees} progress={step * 100 / 3} step={step}/>
        {step === 0 && (
          <StepSubject
            grade={grade}
            subject={subject}
            nextStep={this.finalizeStep1}
          />
        )}
        {step === 1 && catalog && (
          <StepTopic
            grade={grade}
            catalog={catalog}
            startDate={startDate}
            topicId={topicId}
            assignees={assignees}
            previousStep={this.previousStep}
            nextStep={this.finalizeTopic}
          />
        )}

        {step === 2 && catalog && (
          <StepLesson
            lessons={lessons}
            topic={topic}
            assignees={assignees}
            previousStep={this.previousStep}
          />
        )}
      </div>
    );
  }
}

export default requireAuthentication(ParentStorage(ParentCreateChallenge));
