import React, { Component } from "react";
import { CircularProgressbarWithChildren, CircularProgressbar, buildStyles } from "react-circular-progressbar";

import requireAuthentication from "../../components/AuthenticatedComponent";
import { getChild, getChildren, getUserid, getFirstName } from "../../utils/profile-utils";
import { report, reportActions } from "../../utils/report-utils";
import { config, ADULT_LOADING_IMAGES, SUBJECT_STYLES } from "../../Constants";
import Loader from "../../components/Loader";
import ParentStorage from "./ParentStorage";
import ParentHeader from "../../components/Parent/ParentHeader";
import SideNav from "../../components/Parent/SideNav";
import { getImage } from "../../utils/image-utils";
import { getChildDashboard, getLessonsForTopic, getTopicMetadata } from "../../services/ParentService";
import { displaySubject, getMinutesDisplay, getMinutesValue } from "../../utils/display-utils";
import TopicTimeSpent from "../../components/dashboards/topic/TopicTimeSpent";
import TopicMetrics from "../../components/dashboards/topic/TopicMetrics";
import TopicChallengeSummary from "../../components/dashboards/topic/TopicChallengeSummary";
import TopicChallenges from "../../components/dashboards/topic/TopicChallenges";

class TopicPerformance extends Component {
    state = {
        loading: false,
    };

    report = {};

    constructor(props) {
        super(props);

        const child = props.match.params.child;
        this.state.activeChild = child;
        this.state.childProfile = getChild(child);
        this.state.subject = props.match.params.subject;
        const topic = props.match.params.topic;
        console.log("Painting topic performance for " + topic);
         this.state.topic = topic;
         this.state.syllabus = props.syllabus;

        this.report.pageName = "Parent Dashboard ";
        this.report.userid = getUserid(topic);
    }
    
    componentDidMount() {

        document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
        document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
        document.body.classList.add(config.class.ADULT_PAGE_CLASS);
        this.report.action = reportActions.LOAD_PAGE;
        report(this.report);
    
          const { activeChild, subject, topic } = this.state;

          const boundFunction = function (response, lessonProgress) {
            // console.log("TopicPerformance: componentDidMount " + JSON.stringify(response));
            // console.log("TopicPerformance: componentDidMount lessonProgress: " + JSON.stringify(lessonProgress));

            const metrics = response;

            const topicMetrics =
              Object.keys(metrics.topicMetrics).map((m) => {
                const value = metrics.topicMetrics[m];
                // console.log(m + " TopicPerformance  topic: " + JSON.stringify(value));
                // console.log(m + (m === topic ? " TopicPerformance (PICK) " : " TopicPerformance (IGNORE) ") + topic);
                if (m && value && m === topic) {
                  // console.log(value.subject + " ####TopicPerformance filter topics: " + JSON.stringify(value));
                  value.key = m;
                  return value;
                }
                return null;
              });

            this.setState({
              metrics: metrics,
              subjectMetrics: metrics.subjectMetrics[subject.toUpperCase()],
              learningMetrics: metrics.learningSubjectMetrics[subject.toUpperCase()],
              testingMetrics: metrics.testingSubjectMetrics[subject.toUpperCase()],
              topicMetrics: topicMetrics,
              lessonProgress: lessonProgress,
              loading: false,
            });
          }.bind(this);
          const startDate = new Date();
          const endDate = new Date().setTime(
            startDate.getTime() - 90 * 60 * 60 * 24 * 1000
          );
          const range = {
            startDate: startDate,
            endDate: endDate,
            days: this.state.days,
          };
          getChildDashboard(activeChild, range, boundFunction);
  }

  // componentWillUpdate () {
  //   const { syllabus } = this.props;
  //   const { topicDescription, topic } = this.state; 
  //   if (syllabus && syllabus.length > 0 && !topicDescription) {
  //     let topicDescriptionValue = syllabus.filter(t => {
  //       console.log("componentWillUpdate topic topicDescription: " + JSON.stringify(t));
  //       return t.key === topic;
  //     });
  //     topicDescriptionValue = topicDescriptionValue && topicDescriptionValue.length > 0 ? topicDescriptionValue[0] : null;
  //     topicDescriptionValue = topicDescriptionValue ? topicDescriptionValue.description : null;
  //     this.setState({
  //       topicDescription: topicDescriptionValue
  //     });

  //   }
  // }

    render() {
        const { loading, activeChild, childProfile, metrics, subject, topic } = this.state;
        const { syllabus } = this.props;
        const subjectStyle = SUBJECT_STYLES[subject.toUpperCase()]

        const lessons = this.props.lessons ? this.props.lessons.filter(l => {
          // console.log(topic + " TopicPerformance  render lesson:  " + JSON.stringify(l));
          if (l.lesson && l.topicId === topic) return true
          return false;
        }) : null;

        const topicMetrics = metrics ? metrics.topicMetrics[topic] : null;
        const learningMetrics = metrics ? metrics.learningTopicMetrics[topic] : null;
        const testingMetrics = metrics ? metrics.testingTopicMetrics[topic] :  null;

        // console.log("TopicPerformance topicMetrics: " + JSON.stringify(topicMetrics));
        // console.log("####### TopicPerformance syllabus: " + JSON.stringify(syllabus));
        // console.log("####### TopicPerformance learningMetrics: " + JSON.stringify(learningMetrics));
        // console.log("####### TopicPerformance testingMetrics: " + JSON.stringify(testingMetrics));
        // console.log("####### TopicPerformance lessonProgress: " + JSON.stringify(lessonProgress));

        // const topicDescription = syllabus ? syllabus.filter(t => {
        //   console.log("topicDescription topic: " + JSON.stringify(t));
        //   return t.key === topic
        // })[0] : "";

        const topicMetadata = getTopicMetadata(topic);
        // console.log("TopicPerformance topicMetadata: " + JSON.stringify(topicMetadata));


        return (
          <div id="wrapper" className="parent-dashboard">
            <ParentHeader page={"Performance"} />
            <SideNav
              child={childProfile}
              children={getChildren()}
              page={"Performance"}
              navPath="performance"
            />

            <main class="page-content">
              <div class="tab-body pt-2 mt-4">
                <div class="container">
                  <div class="d-flex w-100 mb-3 align-items-center flex-wrap">
                    <ol class="breadcrumb p-0 bg-transparent mb-sm-0">
                      <li class="breadcrumb-item">
                        <a href={"/parent/performance/" + activeChild}>
                          {getFirstName(childProfile.name)}
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a
                          href={
                            "/parent/performance/" + activeChild + "/" + subject
                          }
                        >
                          {displaySubject(subject)}
                        </a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        {topicMetadata && topicMetadata.name
                          ? topicMetadata.name
                          : topic}
                      </li>
                    </ol>

                    <div class="ml-auto user-name">
                      <img
                        src={getImage("logo-small.svg")}
                        class="rounded-circle"
                        alt="image"
                      />
                      <span>INSIGHTS</span>
                    </div>
                  </div>

                  <div class="sk-card mt-4 bg-white card-reset overflow-hidden">
                    <div
                      class="card-header card-header-rounded p-md-4 p-3 text-white"
                      style={{ backgroundColor: subjectStyle.backgroundColor }}
                    >
                      <div class="row align-items-center">
                        <div class="col-sm-6">
                          <h2 class="text-white">Topic performance</h2>
                        </div>
                        {/* <div class="col-sm-6">
                          <div
                            class="btn-group float-right"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              type="button"
                              class="btn btn-white btn-lg"
                              style={{ width: "7em", margin: "0px" }}
                            >
                              Learning
                            </button>
                            <button
                              type="button"
                              onclick="window.location.href='interaction-L3-testing.html'"
                              class="btn btn-white btn-lg"
                              style={{ width: "6em", margin: "0px" }}
                            >
                              Testing
                            </button>
                            <button
                              type="button"
                              class="btn btn-white btn-lg active"
                              style={{ width: "5em", margin: "0px" }}
                            >
                              Both
                            </button>
                          </div>
                        </div> */}
                      </div>

                      <div class="row">
                        <div class="col-lg-6 mt-3">
                          <div class="sk-card card-collapse">
                            <div class="card-header d-flex">
                              <span>
                                {displaySubject(subject)}.Grade{" "}
                                {topicMetadata
                                  ? topicMetadata.grade
                                  : childProfile.grade}
                              </span>
                              <a
                                class="ml-auto"
                                data-toggle="collapse"
                                href="#card_collapse"
                                role="button"
                                aria-expanded="false"
                                aria-controls="card_collapse"
                              >
                                <img
                                  src={getImage("np_down_arrow.svg")}
                                  class="icon"
                                  alt="arrow"
                                />
                              </a>
                            </div>
                            <div
                              class="card-body collapse show"
                              id="card_collapse"
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: topicMetadata && topicMetadata.description ? topicMetadata.description : "",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <TopicTimeSpent
                          topicMetrics={topicMetrics}
                          learningMetrics={learningMetrics}
                          testingMetrics={testingMetrics}
                        />
                      </div>

                      <div class="row mb-3">
                        <TopicMetrics
                          topicMetrics={topicMetrics}
                          learningMetrics={learningMetrics}
                          testingMetrics={testingMetrics}
                        />
                        <TopicChallengeSummary
                          topicMetrics={topicMetrics}
                          lessons={lessons}
                        />
                      </div>
                    </div>
                    <TopicChallenges
                      topic={topic}
                      childProfile={childProfile}
                      lessons={lessons}
                    />
                  </div>
                </div>
              </div>
            </main>

            {loading && (
              <Loader
                image={
                  ADULT_LOADING_IMAGES[
                    Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
                  ]
                }
              />
            )}
          </div>
        );
    }
}

export default requireAuthentication(ParentStorage(TopicPerformance));