import React, { Component } from "react";

import { getAvatarImage, getImage, getLevelImage } from "../../utils/image-utils";

import { config } from "../../Constants";

import "./../Dashboard/Dashboard.css";
import "./ParentLessonReport.css";
import "./../LessonReport/LessonReport.css";

import {
  isParent,
  getChild,
  isTeacher,
  getStudent
} from "../../utils/profile-utils";
import { getLessonReportForChild } from "../../utils/dashboard-utils";
import requireAuthentication from "../../components/AuthenticatedComponent";
import {
  mapChildReport,
  mapDailyReport
} from "../../utils/parent-dashboard-utils";
import ShowProblem from "../../components/Student/LessonContent/ShowProblem";
import { sendNotification } from "../../utils/notification-utils";
import Toast from "../../components/Student/Toast/Toast";
import { displaySubject, getMinutesValue } from "../../utils/display-utils";
import LessonReportHeader from "../../components/Parent/LessonReport/LessonReportHeader";

import ParentAnalytics from "../../components/Parent/Analysis/ParentAnalytics";
import QuestionAnalysis from "../../components/Parent/Analysis/QuestionAnalysis";
import Attempts from "../../components/Parent/LessonReport/Attempts";

class ParentLessonReport extends Component {
  // default State object
  state = {
    subject: "",
    isRequest: false,
    toastText: null,
    reportDate: 0,
    value: 0,
    previous: 0
  };

  report = {};

  constructor(props) {
    super(props);

    console.log(props);
    this.state.lessonKey = props.match.params.lesson;
    this.state.childId = props.match.params.child;

    this.report.pageName = "Child Lesson Report - " + this.state.childId;
    this.report.lessonKey = this.state.lessonKey;

    console.log(
      "ParentLessonReport params: " +
        this.state.childId +
        " " +
        this.state.lessonKey
    );

    this.loadData = this.loadData.bind(this);
    this.goBack = this.goBack.bind(this);
    this.completeRequest = this.completeRequest.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
    getLessonReportForChild(
      this.state.childId,
      this.state.lessonKey,
      this.loadData
    );
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
  }

  loadData(childId, report) {
    let child = null;
    if (isParent()) {
      child = getChild(childId);
    }
    if (isTeacher()) {
      child = getStudent(childId);
    }
    let map = null;
    try {
      map = mapChildReport(report);
    } catch (error) {
      console.log(error);
    }
    console.log("ParentLessonReport render state: " + JSON.stringify(child));
    this.setState({
      reports: map,
      child: child
    });
  }

  goBack() {
    // after login back should not take you to login button
    // this.props.history.push(`/` + getActor());
    this.props.history.goBack();
  }

  completeRequest() {
    const { reports, child } = this.state;
    const { lesson } = reports;
    console.log("ChildReportCard completeRequest: " + child);
    this.setState({
      isRequest: true
    });
    const boundFunction = function() {
      this.setState({
        isRequest: false,
        toastText: "Request was sent to " + child.name
      });
    }.bind(this);
    sendNotification(
      child.userid,
      lesson.lessonKey,
      lesson.shortName,
      "COMPLETE_REQUEST",
      boundFunction
    );
  }

  setValue(event) {
    this.setState({
      reportDate: event.target.value
    });
  }

  render() {
    console.log(
      "ParentLessonReport render state: " + JSON.stringify(this.state)
    );
    const {
      reports,
      child,
      isRequest,
      toastText,
      reportDate,
      childId
    } = this.state;
    console.log(
      "ParentLessonReport render reports: " + JSON.stringify(this.reports)
    );
    if (!reports) {
      return <span />;
    }
    let { lesson, attemptedQuestions, correctAnswers, totalQuestions, lessonReport, questionActivity, } = reports;
    const {subject, name, shortName, lessonKey, key, grade, topicName, questions, level, lessonType } = lesson;

    const {averageTimeSpent} = lessonReport ? lessonReport : reports;
    // complete must be calculated based on lesson report
    let complete = false;
    if (attemptedQuestions === totalQuestions) {
      complete = true;
    }

    let mappedReport = null;
    const dailyReports = lessonReport ? lessonReport.dailyReports : null;
    const dailyReport = dailyReports && dailyReports.length > reportDate ? dailyReports[reportDate] : null;
    if (dailyReports) {
      Object.assign(dailyReport, { lesson: lesson });
      mappedReport = mapDailyReport(dailyReport);

      correctAnswers = mappedReport.correctAnswers;
      attemptedQuestions = mappedReport.attemptedQuestions;
    }
    const VALUES = dailyReports ? dailyReports.map(dr => dr.date) : [];
    console.log("Time line values: " + VALUES);

    let percentage = {
      width: (correctAnswers * 100) / totalQuestions + "%"
    };
    console.log("Time line averageTimeSpent: " + averageTimeSpent);

    return (
      <div id="wrapper" class="parent-dashboard">
        {toastText && <Toast text={toastText} />}
        {reports && (
          <LessonReportHeader
            lessonName={shortName}
            subject={displaySubject(subject)}
            child={child}
            grade={grade}
            displayDate={reports.displayDate}
            back={this.goBack}
            complete={complete}
            level={level}
          />
        )}
        <main class="pt-5">
          <ParentAnalytics
            avatar={getAvatarImage(child)}
            averageTimeSpent={averageTimeSpent}
            child={childId}
            reports={reports}
          />

          <div class="bg-white mt-5 py-5 border-top">
            <div class="container container-minify">
              <div class="section-heading mb-5">
                <h2>Question analysis</h2>
              </div>
              {/* <!-- /.section-heading --> */}

              {questions &&
                questions.map((question, index) => {
                  let c = questionActivity
                    ? questionActivity.filter((q) => index === q.index)
                    : null;
                  if (c.length > 0) {
                    c = c[0];
                  }
                  return (
                    <QuestionAnalysis
                      key={index}
                      index={index}
                      averageTimeSpent={averageTimeSpent/1000}
                      report={c}
                      question={question}
                      // dailyReport={dailyReport}
                      level={level}
                      type={lessonType}
                    />
                  );
                })}
            </div>
            {/* <!-- /.container --> */}
          </div>
          {/* <!-- /.bg-white --> */}

          <div class="container container-minify">
            <div class="py-4 text-right">
              <a
                href={"/parent/create/challenge/" + childId}
                class="btn btn-lg btn-large btn-shadow btn-primary"
              >
                Assign New Challenge
              </a>
            </div>
            {/* <!-- /.py-3 --> */}
          </div>
          {/* <!-- /.container --> */}
        </main>
        {/* <!-- /.page-content --> */}
      </div>
    );
  }
}

export default requireAuthentication(ParentLessonReport);

/**

 <div id="parent-lesson-report">
        {toastText && <Toast text={toastText} />}
        {reports && (
          <LessonReportHeader
            lessonName={shortName}
            subject={displaySubject(subject)}
            child={child}
            grade={grade}
            displayDate={reports.displayDate}
            back={this.goBack}
            complete={complete}
            level={level}
          />
        )}

        <ParentAnalytics
          avatar={getAvatarImage(child)}
          child={childId}
          reports={reports}
        />
        <section class="Question-analysis">
          <div class="sk-container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h2>Question wise analysis</h2>
                </div>
              </div>
            </div>
            {questions &&
              questions.map((question, index) => {
                let c = questionActivity
                  ? questionActivity.filter(q => index === q.index)
                  : null;
                if (c.length > 0) {
                  c = c[0];
                }
                return (
                  <QuestionAnalysis
                    key={index}
                    index={index}
                    averageTimeSpent={averageTimeSpent}
                    report={c}
                    question={question}
                    // dailyReport={dailyReport}
                    level={level}
                    type={lessonType}
                  />
                );
              })}
          </div>
        </section>

        <ShowProblem report={this.report} />
      </div>

 */
