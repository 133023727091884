import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// import './../LessonContent/ChallengeComplete.css';
import "../../../containers/Login/Login.css";

import { getImage } from "../../../utils/image-utils";

class ConnectionErrorHandler extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  render() {
    const { errorMessage, passwordMessage } = this.props;
    return (
      <div id="error-overlay-container">
        <div className="error-area">
          <div className="error-area-inner">
            <div className="sk-container">
              {errorMessage && (
                <div className="error-txt">
                  <img src={getImage("connection-error.svg")} />
                  <h4>Connection Error</h4>
                  <p>
                    There was an error processing your request. Please try again
                    later. If the issue persist please contact us at
                    support@smartkids.io ({errorMessage})
                  </p>
                  <a onClick={() => this.props.onRetry()}>RETRY</a>
                </div>
              )}
              {passwordMessage && (
                <div className="error-txt">
                  <img src={getImage("connection-error.svg")} />
                  <h4>Password Reset Error</h4>
                  <p>
                    Your password reset request has expired. To protect your
                    account please try resetting your password again. We
                    appologize for the inconvenience. ({passwordMessage})
                  </p>
                  <NavLink to={"/reset"}>Reset Password</NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConnectionErrorHandler.propTypes = {
  onRetry: PropTypes.func
};

export default ConnectionErrorHandler;
