import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { displaySubject } from '../../utils/display-utils';
import { getGrade, getClassCount } from '../../utils/profile-utils';

class SubjectPicker extends React.Component {
  state = {
    value: '',
    lesson: null,
    students: [],
  };

  constructor(props) {
    super(props);

    this.state.students = props.students;
    this.pickSubject = this.pickSubject.bind(this);
  }

  pickSubject(subject) {
    console.log('pickSubject in SubjectPicker ' + subject);
    if (subject !== this.props.subject) {
      this.props.onChange(subject);
    }
  }

  render() {
    const subjects = this.props.subjects;
    return (
      <div className="summary-main-tab">
        <div className="sk-container">
          <div className="clng-title">
            <span>Your Grades</span>
          </div>
          <div className="main-tabs-summary">
            <li className="active">Grade {getGrade()}</li>
            {/* <li>Grade 2</li>
                <li>Grade 3</li> */}
          </div>
        </div>
        <div className="summary-sub-tab">
          <div className="sk-container">
            <div className="sub-tab-list">
              {/* <li className="active">MATH 33</li>
                  <li>SCIENCE</li>
                  <li>ENGLISH</li> */}
              {subjects.map(c => (
                <Subject
                  key={c}
                  subject={c}
                  active={this.props.subject}
                  pickSubject={this.pickSubject}
                  classCount={this.props.classCount}
                />
              ))}
            </div>

            <div className="tab-info-dot">
              <NavLink activeClassName="active" to={'/create/challenge'}>
                <span />
                <span />
                <span />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function Subject(props) {
  const { subject, active } = props;
  // console.log(props);
  let activeClass = '';
  let classCount = '';
  if (active === subject) {
    activeClass = 'active';
    classCount = '(' + getClassCount() + ')';
  }
  return (
    <li className={activeClass}>
      <a
        href={'#' + subject}
        onClick={() => {
          console.log(subject);
          props.pickSubject(subject);
        }}
      >
        {displaySubject(subject)} {classCount}
      </a>
    </li>
  );
}

SubjectPicker.propTypes = {
  subjects: PropTypes.array.isRequired,
  subject: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SubjectPicker;
