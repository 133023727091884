import React from "react";
import { getImage } from "../../../utils/image-utils";
import { getToken, getUserId } from "../../../utils/profile-utils";
import { reportProblem } from "../../../utils/report-utils";

class Tutorial extends React.Component {
  state = {
    showProblem: false,
    answer: ""
  };

  constructor(props) {
    super(props);

    console.log(
      "Tutorial props: " +
        JSON.stringify(props.question.question.sampleSolution)
    );

    this.allowEntry = this.allowEntry.bind(this);
    this.dismiss = this.dismiss.bind(this);
  }

  dismiss() {
    this.setState({
      showProblem: false
    });
  }

  allowEntry(event) {
    // console.log('ShowProblem  New value: ' + event.target.value);
    // check enter to submit answer
    this.setState({ showProblem: true });
    // console.log('Changed: >>' + this.state.anwer + '<<');
  }

  render() {
    return (
      <div className="show-problem">
        {!this.state.showProblem && (
          <img
            className="tutorial-button"
            src={getImage("tutorial.svg")}
            onClick={this.allowEntry}
            alt="Report a problem"
          />
        )}
        {this.state.showProblem && (
          <div class="single-challenge-wrap centered-wrapper-problem single-tutorial">
            <div class="single-clng-item">
              <div class="clng-top-txt">
                <div class="challenge-date">
                  <div class="left date-text">
                    <p>Sample Solution</p>
                  </div>
                </div>
                <div className="challenge-score" onClick={() => this.dismiss()}>
                  <img src={getImage("Close.png")} alt="close" />
                </div>
              </div>
              <div class="recent-plants-txt">
                <img
                  className="sample-solution"
                  src={this.props.question.question.sampleSolution}
                  alt="sample solution"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Tutorial;
