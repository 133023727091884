import React, { useState } from "react";
import { getImage } from "../../../utils/image-utils";
import ShowDonutInfo from "../ShowDonutInfo";

const GraspWidgetHeader = () => {
  const [isShowing, setIsShowing] = useState(false);

  const showInfo = () => {
    setIsShowing(!isShowing);
  };

  return (
    <div>
      <div className="history-box-title">
        <h4>Grasp on Chapters</h4>
        <div className="info-wrap">
          <img
            src={getImage("information-icon.svg")}
            onClick={showInfo}
            alt="legend"
          />
        </div>
        <div className="chapter-list-warp">
          <div className="Chapters-list">
            <span>
              <img src={getImage("grasp-1.svg")} alt="" />
            </span>
            <span>
              <img src={getImage("grasp-2.svg")} alt="" />
            </span>
            <span>
              <img src={getImage("grasp-3.svg")} alt="" />
            </span>
            <span>
              <img src={getImage("grasp-4.svg")} alt="" />
            </span>
            <span>
              <img src={getImage("grasp-5.svg")} alt="" />
            </span>
            <span>
              <img src={getImage("grasp-6.svg")} alt="" />
            </span>
          </div>
        </div>
      </div>
      {isShowing && <ShowDonutInfo onClose={showInfo} />}
    </div>
  );
};

export default GraspWidgetHeader;
