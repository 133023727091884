import React from "react";
import { config } from "../Constants";
import { getCall, postCall } from "../utils/rest-utils";
import { getFamilyId } from "../utils/profile-utils";
import { mapChildReport } from "../utils/parent-dashboard-utils";
import { mapLesson } from "../utils/dashboard-utils";
import { getNumber, mapSubject } from "../utils/display-utils";

let _familyReport = null;
let _topicMetadata = [];

export function getFamilyReport(force, callback, errorCallback) {
  if (!force) {
    if (_familyReport && _familyReport.childReports) {
      console.log("Return cached homework.....");
      if (callback) {
        callback(_familyReport);
      }
      return;
    } else {
      const report = localStorage.getItem("familyReport");
      if (report) {
        console.log("Return homework from local storage.....");
        _familyReport = JSON.parse(report);
        if (callback) {
          callback(_familyReport);
        }
        return;
      }
    }
  }

  const boundFunction = function (response) {
    if (callback) {
      _familyReport = response.data.object.data;
      callback(_familyReport);
      if (_familyReport && _familyReport.childReports) {
        localStorage.setItem("familyReport", JSON.stringify(_familyReport));
      }
    }
  };
  getCall(
    config.url.API_BASE_URL + "/api/v2/parent/report/family/" + getFamilyId(),
    boundFunction,
    errorCallback
  );
}

export function clearFamilyReport() {
  localStorage.removeItem("familyReport");
  _familyReport = null;
  getFamilyReport(true, null, null);
}

export function getChildDashboard(child, range, callback) {
  console.log("getChildDashboard " + child);
  getFamilyReport(false, null, null);
  if (_familyReport) {
    const boundFunction = function (response) {
      console.log(response);
      const lessonProgress = getLessonProgress(child, range);
      if (callback !== null) {
        callback(response.data, lessonProgress);
      }
    };
    postCall(
      config.url.API_BASE_URL +
        "/api/v2/parent/child/" +
        child +
        "/performance/",
      {
        userid: child,
        startDate: range.startDate,
        days: 90,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      boundFunction,
      null
    );
  }
}

export function getLessonProgress(child, range) {
  while (!_familyReport) {
    getFamilyReport(false, null, null);
  }
  const homework = _familyReport.childReports[child].homework;
  let homeworkKeys = [];
  if (range) {
    console.log("startDate: " + range.startDate.getTime());
    console.log("endDate: " + range.endDate);
    homeworkKeys = Object.keys(homework).filter((key) => {
      const h = homework[key];
      _topicMetadata[h.topicKey] = h.topicMetadata;
      return h.assignDate > 0 && h.assignDate < range.startDate;
    });
  } else {
    homeworkKeys = Object.keys(homework);
  }

  const learning = homeworkKeys.filter((key) => homework[key].lessonMetadata.lessonType === "FLASH_CARDS");

  const subjects = homeworkKeys.map(key => mapSubject(homework[key].lessonMetadata.subject)).reduce(function (map, subject) {
    map[subject] = (map[subject] || 0) + 1;
    return map;
  }, Object.create(null));

  // const topics = homeworkKeys.map(key => homework[key].topicMetadata.key).reduce(function (map, topicKey) {
  //   map[topicKey] = (map[topicKey] ? map[topicKey] : []).push(topicKey);
  //   return map;
  // }, Object.create(null));

  // console.log("getLessonProgress homeworkKeys: " + homeworkKeys.length);
  const complete = homeworkKeys.filter((key) => homework[key].complete);
  const completeTesting = []; /*complete.filter(
    key => homework[key].lessonMetadata.lessonType !== "FLASH_CARDS"
  ); */
  const completeLearning = []; /*complete.filter(
    key => homework[key].lessonMetadata.lessonType === "FLASH_CARDS"
  ); */

  const inProgress = homeworkKeys.filter(
    (key) => !homework[key].complete && homework[key].score > 0
  );
  const inProgressTesting = []; /* inProgress.filter(
    key => homework[key].lessonMetadata.lessonType !== "FLASH_CARDS"
  ); */
  const inProgressLearning = []; /*inProgress.filter(
    key => homework[key].lessonMetadata.lessonType === "FLASH_CARDS"
  ); */

  const incomplete = homeworkKeys.filter(
    (key) => !homework[key].complete && homework[key].score <= 0
  );
  const incompleteTesting = incomplete.filter(
    (key) => homework[key].lessonMetadata.lessonType !== "FLASH_CARDS"
  );
  const incompleteLearning = incomplete.filter(
    (key) => homework[key].lessonMetadata.lessonType === "FLASH_CARDS"
  );

  let byTopic = {};
  let byTopicComplete = {};
  homeworkKeys.map((key) => {
    const h = homework[key];
    const topicKey = h.topicKey;
    // console.log("Topic key: " + topicKey);
    if (byTopic[topicKey] != null) {
      byTopic[topicKey] = byTopic[topicKey] + 1;
      // console.log("\t New value byTopic " + byTopic[topicKey]);
    } else {
      byTopic[topicKey] = 1;
      // console.log("\t Adding byTopic " + byTopic[topicKey]);
    }
    // console.log("Lesson type: " + h.lessonMetadata.lessonType);
    if (h.complete) {
      if (byTopicComplete[topicKey] != null) {
        byTopicComplete[topicKey] = byTopicComplete[topicKey] + 1;
        // console.log("\t New value byTopic " + byTopic[topicKey]);
      } else {
        byTopicComplete[topicKey] = 1;
        // console.log("\t Adding byTopicComplete " + byTopicComplete[topicKey]);
      }
    }
    // console.log(topicKey + " count " + JSON.stringify(byTopic) + " testing " + JSON.stringify(byTopicComplete));
  });

  let report = {
    all: Object.keys(homework).length,
    learning: learning.length,
    testing: Object.keys(homework).length - learning.length,
    subjects: subjects,
    // topics: topics,

    // =================================
    created: homeworkKeys.length,
    complete: complete.length,
    completeTesting: completeTesting.length,
    completeLearning: completeLearning.length,
    inProgress: inProgress.length,
    inProgressTesting: inProgressTesting.length,
    inProgressLearning: inProgressLearning.length,
    incomplete: incomplete.length,
    incompleteTesting: incompleteTesting.length,
    incompleteLearning: incompleteLearning.length,
    byTopic: byTopic,
    byTopicComplete: byTopicComplete,
    homeworkKeys: homeworkKeys,
  };
  // console.log("report: " + JSON.stringify(report));
  return report;
}

export function getLessonsForTopic(child, range, topicId) {
  while (!_familyReport) {
    getFamilyReport(false, null, null);
  }
  const homework = _familyReport.childReports[child].homework;
  if (range && range.startDate) console.log("startDate: " + range.startDate.getTime());
  if (range && range.endDate) console.log("endDate: " + range.endDate);
  const topicHomework = Object.keys(homework).map((key) => {
    const h = homework[key];
    if (h.topicId === topicId){
      if (!range) {
        return h;
      } else if (h.assignDate > 0 && h.assignDate < range.startDate) {
        return h;
      }
    }
    return null;
  });
  return topicHomework;
}

export function getChildLessons(child, subject) {
  console.log(child + " ParentService getChildLessons " + subject);
  const activeChildReports = _familyReport.childReports[child];
  let lessonIds, lessons;

  if (activeChildReports) {
    if (subject === "Recent") {
      lessonIds = activeChildReports.recent.map((c) => {
        return c.value;
      });
    } else {
      const reports = activeChildReports.homeworkBySubject[subject];
      if (reports) {
        lessonIds = reports.map((c) => {
          return c.value;
        });
      }
    }
    if (lessonIds) {
      lessons = lessonIds.map((c) => {
        return activeChildReports.homework[c];
      });
    }
  }

  // console.log("ParentService getChildLessons lessons:" + JSON.stringify(lessons));
  const changed = lessons
    ? lessons.map((l) => {
        _topicMetadata[l.topicId] = l.topicMetadata;
        const report = mapReport(l);
        // console.log("ParentService getLessonProgress report: " + report);
        return report;
      })
    : [];
  changed.sort((h1, h2) =>
    h2.displayDateValue > h1.displayDateValue ? 1 : -1
  );
  // getChildDashboard(child);
  return changed;
}

export function getRecommendationsLessons(child) {
  const activeChildReports = _familyReport.childReports[child];
  const recommendations = activeChildReports && activeChildReports.recommendations ? activeChildReports.recommendations : [];

  // console.log("ParentService getChildLessons recommendations:" + JSON.stringify(recommendations));
  var dict = new Object();
  Object.keys(recommendations).forEach(function (key) {
    dict[key] = recommendations[key].map((i) => {
      return {
        // reference: React.createRef(),
        recommendations: mapLesson(i.value.lessonMetadata),
        topicMetadata: i.value.topicMetadata,
        lessonMetadata: i.value.lessonMetadata
      };
    });
  });
  return dict;
}

export function getHomeworkReport(child, lessonId) {
  // console.log(child + " >>>>>>> getHomeworkReport " + lessonKey);
  // const boundFunction = function(familyReport) {
  //   const activeChildReports = familyReport.childReports[child];
  //   const report = activeChildReports.homework[lessonKey];
  //   return report ? mapReport(report) : null;
  // };

  if (_familyReport) {
    const activeChildReports = _familyReport.childReports[child];
    const report = activeChildReports
      ? activeChildReports.homework[lessonId]
      : null;
    // console.log(child + " getHomeworkReport " + report);
    return report ? mapReport(report) : null;
  } else {
    getFamilyReport(true, null, null);
  }
  return null;
}

export function getTopicMetadata(key) {
  return _topicMetadata[key.toLowerCase()];
}

export function getTopicName(key) {
  if (_topicMetadata[key] != null) {
    return _topicMetadata[key].name
      ? _topicMetadata[key].name
      : _topicMetadata[key].shortName;
  }
  return null;
}

export function getTopicSubject(key) {
  if (_topicMetadata[key] != null) {
    // console.log(key + " getTopicSubject topic: " + _topicMetadata[key].subject);
    return _topicMetadata[key].subject;
  }
  return null;
}

function mapReport(childReport) {
  // console.log("ParentService mapReport childReport:" + JSON.stringify(childReport));

  let attempts = 0;
  let score = 0;
  let exposure = 0;
  let confidence = 0;
  let accuracy = 0;
  let totalQuestions = 0;
  let correctAnswers = 0;
  let attemptedQuestions = 0;
  let questionActivity = [];
  let reportDate = null;
  let derivedInsights = null;
  let recommendation = null;
  let range = "incomplete";
  let complete = false;
  let inProgress = false;
  if (childReport) {
    const lessonReport = childReport;
    attempts = lessonReport.attempts ? lessonReport.attempts : 0;
    score = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
    }).format(lessonReport.score ? lessonReport.score : 0);
    exposure = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
    }).format(lessonReport.exposure);
    confidence = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
    }).format(lessonReport.confidence);
    accuracy = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
    }).format(lessonReport.accuracy);
    derivedInsights = lessonReport.derivedInsights;
    recommendation = lessonReport.recommendation;

    if (recommendation) {
      console.log("Found Recommendations " + childReport);
    }

    range =
      score <= 0
        ? "incomplete"
        : score < 40
        ? "low"
        : score < 80
        ? "medium"
        : "high";

    attemptedQuestions = lessonReport.attemptedQuestions;
    totalQuestions = lessonReport.totalQuestions;
    questionActivity = lessonReport.questionActivity;
    correctAnswers = lessonReport.correctAnswers;
    complete = attemptedQuestions > 0 && attemptedQuestions >= totalQuestions;
    inProgress = !complete && attemptedQuestions > 0;
    try {
      reportDate = lessonReport.reportDate;
    } catch (e) {
      console.error(lessonReport.reportDate + " " + e);
      reportDate = lessonReport.reportDate;
    }
  } else {
    if (childReport.totalQuestions !== 0) {
      totalQuestions = childReport.totalQuestions;
    } else if (
      childReport.lesson &&
      childReport.lesson.questions &&
      childReport.lesson.questions.length > 0
    ) {
      totalQuestions = childReport.lessonMetadata.numberOfQuestions;
    }
  }

  let averageTimeSpent = getNumber(childReport.averageTimeSpent, 900);

  const displayDateValue = reportDate ? reportDate : childReport.assignDate;
  const displayDate = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "2-digit",
    year: "numeric",
  }).format(displayDateValue);
  return {
    reference: React.createRef(),
    topicId: childReport.topicId,
    lesson: mapLesson(childReport.lessonMetadata),
    score: score,
    scoreText: score + "%",
    assignDate: childReport.assignDate,
    displayDate: displayDate,
    displayDateValue: displayDateValue,
    totalQuestions: totalQuestions,
    correctAnswers: correctAnswers,
    range: range,
    attempts: attempts,
    exposure: exposure,
    confidence: confidence,
    accuracy: accuracy,
    attemptedQuestions: attemptedQuestions,
    averageTimeSpent: averageTimeSpent,
    questionActivity: questionActivity,
    reportDate: reportDate,
    complete: attemptedQuestions === totalQuestions,
    started: attemptedQuestions > 0,
    lessonReport: childReport.lessonReport,
    derivedInsights: derivedInsights,
    recommendation: recommendation,
    complete: complete,
    inProgress: inProgress,
    pending: !complete && !inProgress,
  };
}
