import React from 'react';
import './Toast.css';
import Expire from './Expire';

const Toast = ({ text }) => {
  console.log('Toast showText: ' + text);

  return (
    <Expire delay={5000}>
      <div className="sk-toast">
        <span>{text}</span>
      </div>
    </Expire>
  );
};

export default Toast;
