import React from "react";
import uniqueId from "react-html-id";

import axios from "axios";

import { config } from "../../Constants";
import AdminHeader from "../../components/Admin/AdminHeader";
import { getToken, isSuperAdmin } from "../../utils/profile-utils";
import { getCall } from "../../utils/rest-utils";

class Admin extends React.Component {
  state = {
    token: null,
    catalog: [],
    showCatalog: false
  };

  constructor(props) {
    super(props);
    this.state.token = getToken();

    this.publish = this.publish.bind(this);
    this.handleChange = this.handleChange.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    const boundFunction = function(response) {
      this.setState({
        config: response.data.object
      });
    }.bind(this);
    getCall(
      config.url.API_BASE_URL + "/api/admin/lesson/config ",
      boundFunction
    );
  }

  publish(publishType) {
    if (isSuperAdmin()) {
      let queryPath = "";
      if (publishType === "Reports") {
        queryPath = "/api/admin/lesson/reports";
      } else if (publishType === "Content") {
        queryPath = "/api/v2/admin/content/publish";
      } else if (publishType === "Content_All") {
        queryPath = "/api/v2/admin/content/publishAll";
      } else if (publishType === "Lesson") {
        queryPath = "/api/v2/admin/content/publish/document/" + this.state.lessonId;
      }

      axios
        .get(config.url.API_BASE_URL + queryPath, {
          headers: { Authorization: this.state.token }
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => console.log(error));
    }
  }

  handleChange(event) {
    console.log("New value: " + event.target.value);
    this.setState({ lessonId: event.target.value });
    console.log("Changed: " + this.state.lessonId);
  }

  render() {
    return (
      <div className="admin-page">
        <AdminHeader />
        <div className="clear">
          <button onClick={() => this.publish("Content")}>Publish Delta</button>
          <button onClick={() => this.publish("Content_All")}>
            Publish All
          </button>
          <button onClick={() => this.publish("Reports")}>
            Publish Reports
          </button>
          <input
            id={this.getUniqueId("admin-lesson-slug")}
            className="input"
            type="text"
            placeholder="Lesson Id"
            value={this.state.lessonId}
            onChange={this.handleChange}
          />
          <button onClick={() => this.publish("Lesson")}>Publish Lesson</button>
        </div>
        <br />
        <br />
        <div>{JSON.stringify(this.state.config)}</div>
      </div>
    );
  }
}

export default Admin;
