import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import axios from "axios";

import { config } from "../../Constants";
import { getCall } from "../../utils/rest-utils";

import "react-circular-progressbar/dist/styles.css";
import { getToken, isSuperAdmin } from "../../utils/profile-utils";
import { getLevelImage } from "../../utils/image-utils";
import TopicCards from "../CreateChallenge/TopicCard";
import LessonCard from "../CreateChallenge/LessonCard";

class AdminCatalogComponent extends React.Component {
  state = {
    catalog: [],
  };

  constructor(props) {
    super(props);
    this.state.LoadingBar = props.LoadingBar;
    this.publish = this.publish.bind(this);
  }

  publish(id) {
    if (isSuperAdmin()) {
      this.state.LoadingBar.add(10);
      const queryPath = "/api/v2/admin/content/publish/document/" + id;
      console.log(queryPath);
      const boundFunction = function (response) {
        this.state.LoadingBar.complete();
        console.log(response);
        this.props.reload();
      }.bind(this);
      getCall(
        config.url.API_BASE_URL + queryPath,
        boundFunction,
        boundFunction
      );
    }
  }

  delete(id) {
    if (isSuperAdmin()) {
      this.state.LoadingBar.add(10);
      const queryPath = "/api/v2/admin/content/delete/document/" + id;
      console.log(queryPath);

      axios
        .get(config.url.API_BASE_URL + queryPath, {
          headers: { Authorization: getToken() },
        })
        .then((response) => {
          console.log(response);
          this.state.LoadingBar.complete();
        })
        .catch((error) => {
          console.log(error);
          this.state.LoadingBar.complete();
        });
    }
  }

  render() {
    const catalog = this.props.catalog;
    console.log("Catalog render catalog grade: " + catalog.grade);
    console.log("Catalog render catalog subject: " + catalog.subject);
    console.log("Catalog render catalog id: " + catalog.id);
    console.log("Catalog render catalog # topics: " + catalog.topics.length);
    let topics = [];
    if (this.props.topic) {
      topics = catalog.topics.filter((c) => c.id === this.props.topic);
      console.log("Catalog filter topics: " + JSON.stringify(topics));
    }
    const { shortName, name, id } = topics[0];
    const displayName = shortName ? shortName : name;
    return (
      <div>
        <h1>
          Grade {catalog.grade} > {catalog.subject} > {displayName}
        </h1>
        <h6>{shortName}</h6>
        <ul>
          {topics.map((c) => (
            <li>
              <button
                style={{ width: "min-content" }}
                onClick={() => this.publish(c.id)}
              >
                <h3 style={{ color: "red" }}>Republish &nbsp;&nbsp;</h3>
              </button>
              <button onClick={() => this.delete(c.id)}>
                <h3 style={{ color: "red" }}>Delete &nbsp;&nbsp;</h3>
              </button>
              <br /> (
              {c.productionReady ? "Topic is live" : "Topic not published"})
              <a
                className="right"
                href={
                  "https://mrgflufz.prismic.io/documents~b=working&c=published&l=en-us/" +
                  id
                }
              >
                <h3>Edit &nbsp;&nbsp;</h3>
              </a>
              {c.linkedTopics &&
                c.linkedTopics.map((lt) => (
                  <NavLink to={"../MATH/" + lt}>
                    <li style={{ color: "red" }}>{lt}&nbsp;&nbsp;</li>
                    <br />
                  </NavLink>
                ))}
              <CatalogTopic topic={c} onClick={this.props.onClick} />
              <p dangerouslySetInnerHTML={{ __html: c.comments }}></p>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

function CatalogTopic(props) {
  if (!props.topic || !props.topic.lessonList) {
    return <div className="clear topic-row" />;
  }
  console.log(
    props.topic.name +
      " CatalogTopic render props: " +
      props.topic.lessonList.length
  );
  return (
    <div className="clear topic-row">
      {/* {props.topic.shortName} */}
      <ul>
        {props.topic.lessonList.map((c) => {
          // console.log("CatalogTopic lesson: " + JSON.stringify(c.lessonMetadata));
          return (
            <li style={{ display: "contents" }}>
              <CatalogLesson lesson={c} onClick={props.onClick} />
              {/* <LessonCard lesson={{"lessonMetadata" : c}} active={false} topic={props.topic} /> */}
            </li>
          );
        })}
        <li>
          <TopicCards
            topic={props.topic}
            active={false}
            // handleTopicChange={this.publish}
          />
        </li>
      </ul>
    </div>
  );
}

function CatalogLesson(props) {
  if (!props.lesson) {
    return <div className="clear topic-row">Missing Lesson</div>;
  }
  const { lesson } = props;
  const {
    shortName,
    name,
    lessonType,
    availableForHomework,
    useQuestionsInTest,
    useSpeech,
    id,
    lessonKey,
    level,
    numberOfQuestions,
    skills,
    timeRequired
  } = lesson;
  console.log(JSON.stringify(lesson))
  const displayName = shortName ? shortName : name;
  return (
    <table className="clear">
      <tr>
        <td>
          <NavLink to={"/admin/lesson/" + id}>
            <div
              className={
                "left topic-row " + (availableForHomework ? "" : "error-text")
              }
            >
              {displayName} ({lessonType.substring(0, 1)}) (Questions # {numberOfQuestions}) (Time # {timeRequired} mins)
              {level && (
                <img
                  style={{ width: "100px", padding: "10px" }}
                  src={getLevelImage(level)}
                  alt="Difficulty level"
                />
              )}
              {useSpeech && <span class="character char">&#x1F508;</span>}
              {!useSpeech && <span>&#x1F507;</span>} &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="bold-text">
                {new Intl.DateTimeFormat("en-US", {
                  weekday: "long",
                  month: "long",
                  day: "2-digit",
                }).format(props.lesson.lastPublishDate)}
              </span> &nbsp;
              <span className="bold-text">
                Skills: {skills ? skills.join(", ") : "???"}
              </span>
            </div>
          </NavLink>
        </td>
        <td>
          {availableForHomework != useQuestionsInTest && (
            <span>
              <b>Fix lesson availability in Prismic</b>
            </span>
          )}
        </td>
        <td>
          <a
            href={
              "https://mrgflufz.prismic.io/documents~b=working&c=published&l=en-us/" +
              id
            }
          >
            <h3>Edit &nbsp;&nbsp;</h3>
          </a>
        </td>
        <td>
          <NavLink className="left" to={"/questions/" + id}>
            <h3>Student View</h3> &nbsp;&nbsp;&nbsp;&nbsp;
          </NavLink>
        </td>
      </tr>
    </table>
  );
}

AdminCatalogComponent.propTypes = {
  catalog: PropTypes.object.isRequired,
};

export default AdminCatalogComponent;
