import React, { useState } from "react";

const McqDisplay = ({ question, report }) => {

  console.log(JSON.stringify(report))

  return (
    <React.Fragment>
      <ol>
        {question &&
          question.options &&
          question.options.map((o, index) => {
            return (
              <li
                className={
                  report && report.answers && report.answers.includes(o)
                    ? index === 0
                      ? "correct"
                      : "wrong"
                    : ""
                }
              >
                <p dangerouslySetInnerHTML={{ __html: o }} />
              </li>
            );
          })}
      </ol>
      {question.options && question.options.length > 0 && (
        <div class="result pt-3">
          CORRECT ANSWER: <span class="grade pass">A</span>
        </div>
      )}
    </React.Fragment>
  );
};

export default McqDisplay;
