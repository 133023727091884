import React, { useEffect, useRef } from 'react';
import './NotificationsWidget.css';
import { getFirstNotification } from '../../../utils/profile-utils';
import ShowProblem from '../LessonContent/ShowProblem';
import { getImage } from '../../../utils/image-utils';

const ApplauseNotifications = ({ onClose }) => {
  const notification = getFirstNotification('APPLAUSE');
  const handleClick = e => {
    // inside click close card
    // outside click close card
    onClose(notification);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  console.log('LevelNotification level: ' + notification.lessonKey);
  return (
    <div className="lessonFinished">
      <div className="quit-challenge-container">
        <div className="error-area">
          <div className="error-area-inner">
            <div className="sk-container">
              <div className="qcuit-challenge">
                <img
                  className="applause"
                  src={getImage('clap-icon.svg')}
                  alt="Applause!"
                />
                <div>
                  <p className="applause-name">{notification.lessonKey}</p>
                  <p className="applause-text">clapped for you</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShowProblem report={{ pageName: 'applause' }} />
    </div>
  );
};

export default ApplauseNotifications;
