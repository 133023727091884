import React, { useState } from 'react';

import { displayName } from '../../utils/display-utils';
import { getName } from "../../utils/profile-utils";
import { sendNotification } from '../../utils/notification-utils';
import { getImage } from '../../utils/image-utils';
import Toast from '../Student/Toast/Toast';

const ReportHeader = ({
  child,
  displayDate,
  lessonName,
  subject,
  complete,
  lessonCount,
  grade,
  back,
}) => {
  const [isApplause, setApplause] = useState(false);
  const [toastText, setToastText] = useState(null);

  const clap = e => {
    console.log('ChildReportCard clap: ' + child);
    setApplause(true);
    const boundFunction = function() {
      setApplause(false);
      setToastText('You clapped for ' + child.name);
    };
    sendNotification(child.userid, getName(), '', 'APPLAUSE', boundFunction);
  };

  const clapImage = isApplause ? 'parent-clap-active.svg' : 'parent-clap.svg';

  return (
    <div className="s-responses-top-area">
      {toastText && <Toast text={toastText} />}
      <div className="sk-container">
        <div className="back-btn">
          <a onClick={() => back()}>
            <img src={getImage('adult-arrow.svg')} alt="back" />
          </a>
        </div>
        <div className="s-response-title">
          <h1>{displayName(lessonName)}</h1>
          {!lessonCount && (
            <div className="response-tags">
              <li>Grade {grade}</li>
              <li>
                {subject} • {displayDate}
              </li>
            </div>
          )}
          {lessonCount && (
            <div className="response-tags">
              <li>SHOWING {lessonCount} CHALLENGES</li>
            </div>
          )}
        </div>

        {complete && (
          <div className="welcome-page-right">
            <div className="message-box cursor-pointer" onClick={() => clap()}>
              <img
                className={isApplause ? 'clap-badge' : ''}
                src={getImage(clapImage)}
                alt=""
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportHeader;
