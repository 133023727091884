import React, { Component } from "react";

import Header from "../components/Header";
import ProfileNavigation from "../components/ProfileNavigation";
import requireAuthentication from "../components/AuthenticatedComponent";

class Settings extends Component {
  state = {};

  constructor(props) {
    super(props);

    console.log(props);

    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    const profile = JSON.parse(localStorage.getItem("profile"));
    let children = null;
    if (profile.family && profile.family.children) {
      children = profile.family.children;
    }
    let grade = null;
    if (profile.classroom) {
      grade = profile.grade;
    }

    this.setState({
      profile: profile,
      children: children,
      grade: grade
    });
  }

  render() {
    const { profile } = this.state;
    return (
      <div>
        <Header title="Settings" />
        <div className="profile-area">
          <div className="sk-container">
            <div className="profiles-main-warpp">
              {profile && <ProfileNavigation active={"settings"} />}
              <div className="right-content-warp">
                <div className="right-content-inner">
                  <div className="help-content">
                    <div className="help-title">
                      <h4>Settings</h4>
                    </div>
                    <div className="profile-setting">
                      <div className="setting-option">
                        <div className="setting-itm">
                          <div className="setting-text">
                            <h4>Notification</h4>
                            <p>Turn you notification off</p>
                          </div>
                          <div className="setting-on">
                            {/* <!-- Rounded switch --> */}
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider round" />
                            </label>
                          </div>
                        </div>
                        <div className="setting-itm">
                          <div className="setting-text">
                            <h4>Rate Us</h4>
                            <p>Tell others what you think</p>
                          </div>
                          <div className="setting-on">
                            <a className="arrow-left" />
                          </div>
                        </div>
                        <div className="setting-itm">
                          <div className="setting-text">
                            <h4>Send Feedback</h4>
                          </div>
                          <div className="setting-feedback">
                            <form action="">
                              <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="10"
                                placeholder="Describe your experience"
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default requireAuthentication(Settings);
