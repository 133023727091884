import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import PropTypes from 'prop-types';
import DayReportCard from './DayReportCard';
import { getImage } from '../../utils/image-utils';
import ShowProgressInfo from './ShowProgressInfo';

class ChallengeHistoryGraph extends React.Component {
  state = {
    graphWidth: 963,
    graphHeight: 347,
    activeIndex: -1,
    showInfo: false,
  };

  constructor(props) {
    super(props);
    this.graphWrapper = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.getData = this.getData.bind(this);
    this.showInfo = this.showInfo.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);

    const element = this.graphWrapper.current;
    console.log('graphWrapper clientWidth: ' + element.clientWidth);
    const graphWidth = element.clientWidth;
    const graphHeight =
      (element.clientWidth * this.state.graphHeight) / this.state.graphWidth;

    const scaleHeight = graphHeight - 35;
    var horizontalLines = [
      scaleHeight * 0,
      scaleHeight * 0.2,
      scaleHeight * 0.6,
      scaleHeight,
    ];
    const blue = 'rgb(87, 209, 255, 0.14)';
    const green = 'rgb(64, 211, 96, 0.14)';
    const yellow = 'rgb(255, 204, 0, 0.06)';
    const white = '#fff';
    const graphStyle = {
      maxHeight: scaleHeight + 'px',
      background:
        'linear-gradient(to bottom,' +
        blue +
        ', ' +
        blue +
        ' ' +
        horizontalLines[1] +
        'px,' +
        green +
        ' 1px, ' +
        green +
        ' ' +
        horizontalLines[2] +
        'px,' +
        yellow +
        ' 1px, ' +
        yellow +
        '  ' +
        horizontalLines[3] +
        'px,' +
        white +
        ' 1px, ' +
        white +
        '  ' +
        graphHeight +
        'px)',
      // background: "linear-gradient(to bottom, transparent 0%,transparent 50%,red 50%,red 100%)"
    };
    console.log('graphWrapper: ' + graphWidth + ' ' + graphHeight);
    console.log('graphWrapper: ' + JSON.stringify(graphStyle));
    this.setState({
      graphWidth: graphWidth,
      graphHeight: graphHeight,
      horizontalLines: horizontalLines,
      graphStyle: graphStyle,
      scaleHeight: scaleHeight,
    });
  }

  showInfo() {
    this.setState({
      showInfo: !this.state.showInfo,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  getData() {
    return this.props.lessonReports
      .filter(e => e.score > 0)
      .map(e => {
        return {
          name: new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: '2-digit',
          }).format(e.assignDate),
          score: Math.round(e.score),
          lessonReport: e,
        };
      });
  }

  handleClick = (cx, cy, index, payload) => {
    const data = this.getData();

    if (this.state.activeIndex < 0) {
      const element = this.graphWrapper.current.getBoundingClientRect();
      console.log(element);

      console.log(cx + ' CX handleClick CY ' + cy);
      console.log(index + ' of ' + data.length + ' index handleClick');
      let _cx = cy - 111 - this.state.scaleHeight;
      let _cy = cx - 111;
      if (index === 0) {
        console.log('move right');
        _cy += 111 - 36;
      }
      if (index === data.length - 1) {
        console.log('move left');
        _cy -= 111 - 36;
      }
      console.log(_cx + ' _CX adjusted _CY ' + _cy);
      this.setState({
        cx: _cx,
        cy: _cy,
        activeIndex: index,
        activeReport: payload.lessonReport,
      });
    }
  };

  clearClick = () => {
    if (this.state.activeIndex >= 0) {
      console.log(' clearClick ');
      this.setState({
        activeIndex: -1,
        activeReport: null,
      });
    }
  };

  render() {
    const data = this.getData();
    const { showListViewOption, toggleView } = this.props;

    const CustomizedDot = props => {
      const { cx, cy, stroke, payload, value, index } = props;

      // console.log(cx + " " + cy);
      const size = 72;
      return (
        <svg
          x={cx - 36}
          y={cy - 36}
          width={size}
          height={size}
          viewBox="0 0 72 72"
          onClick={() => this.handleClick(cx, cy, index, payload)}
        >
          <defs>
            <filter
              id="a"
              x="0"
              y="0"
              width={size}
              height={size}
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="3" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="3" result="b" />
              <feFlood floodOpacity="0.161" />
              <feComposite operator="in" in2="b" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="translate(9 6)">
            <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#a)">
              <rect
                width="54"
                height="54"
                rx="8"
                transform="translate(9 6)"
                fill="#fff"
              />
            </g>
            <g transform="translate(12 14)">
              <text
                transform="translate(0 20)"
                fill="#657d95"
                fontSize="20"
                fontFamily="Nunito-SemiBold, Nunito"
                fontWeight="600"
                letterSpacing="0.02em"
              >
                <tspan x="0" y="0">
                  {value}
                </tspan>
              </text>
              <text
                transform="translate(25 20)"
                fill="#657d95"
                fontSize="10"
                fontFamily="Nunito-Bold, Nunito"
                fontWeight="700"
              >
                <tspan x="0" y="0">
                  %
                </tspan>
              </text>
            </g>
          </g>
        </svg>
      );
    };

    return (
      <div className="challenge-history">
        <div className="sk-container">
          <div className="history-box">
            <div class="history-box-title">
              <h4>Challenges History</h4>
              <div class="info-wrap">
                <img
                  src={getImage('information-icon.svg')}
                  onClick={() => this.showInfo()}
                  alt="legend"
                />
              </div>
              <div class="tag-box">
                <div class="tag-box-duration">
                  <button>DAY</button>
                  <button>WEEK</button>
                  <button class="current">MONTHS</button>
                </div>
                {showListViewOption && (
                  <div class="right-history-txt">
                    <button class="with-bg">
                      <img src={getImage('date-view-icon-active.png')} alt="" />
                      DATE VIEW
                    </button>
                    <button onClick={() => toggleView()}>
                      <img src={getImage('list-view-icon.svg')} alt="" />
                      LIST VIEW
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div
              ref={this.graphWrapper}
              className="clear history-analytics"
              style={this.state.graphStyle}
            >
              <LineChart
                width={this.state.graphWidth}
                height={this.state.graphHeight}
                data={data}
                margin={{
                  top: 5,
                  right: 40,
                  left: 20,
                  bottom: 5,
                }}
                onClick={this.clearClick}
                className="chart"
              >
                <CartesianGrid
                  horizontalPoints={[this.state.scaleHeight]}
                  strokeDasharray="3 3"
                  horizontal="false"
                />
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[0, 100]} />
                <Tooltip cursor={false} contentStyle={{ display: 'none' }} />
                {/* <Legend /> */}
                <Line
                  type="linear"
                  dataKey="score"
                  stroke="#CFD7DB"
                  strokeWidth="3"
                  activeDot={false}
                  dot={<CustomizedDot />}
                />
              </LineChart>
              {this.state.activeReport && (
                <DayReportCard
                  report={this.state.activeReport}
                  style={{
                    top: this.state.cx + 'px',
                    left: this.state.cy + 'px',
                  }}
                />
              )}
            </div>
            <div className="history-chart-text">
              <h4>2019</h4>
              {/* <p>PINCH HERE FOR MONTH VIEW</p> */}
            </div>
          </div>
        </div>
        {this.state.showInfo && <ShowProgressInfo onClose={this.showInfo} />}
      </div>
    );
  }
}

ChallengeHistoryGraph.propTypes = {
  lessonReports: PropTypes.array.isRequired,
};

export default ChallengeHistoryGraph;
