import React, { PropTypes } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getImage } from "../../../utils/image-utils";
import { getTopicMetadata } from "../../../services/ParentService";
import { displaySubject } from "../../../utils/display-utils";

const TopicChallenges = ({ childProfile, lessons, topic }) => {
  return (
    <div
      class="card-body collapse show px-lg-5 px-3 py-lg-5 py-4"
      id="hideTopic"
    >
      <div class="mb-3 pb-2 pb-lg-3 text-uppercase">
        <h3>Challenge analysis</h3>
      </div>
      <div class="challenge-list-created">
        {lessons &&
          lessons.map((lesson) => {
            console.log("TopicChallenges #Lessons:  " + lessons.length);
            // console.log("TopicChallenges Lesson:  " + JSON.stringify(lesson));
            console.log("TopicChallenges Lesson topicId:  " + JSON.stringify(lesson.lesson.topicId));
            const l = lesson.lesson;
            const t = lesson.topicId ? getTopicMetadata(lesson.topicId) : null;
            if (lesson.topicId !== topic) {
              console.log("TopicChallenges Lesson:  " + JSON.stringify(lesson));
              console.log("TopicChallenges topicMetadata (IGNORE):  " + JSON.stringify(lesson.topicId));
              return null;
            }
            // console.log("TopicChallenges topicMetadata:  " + JSON.stringify(t));
            let dueDate = null;
            if (lesson && lesson.assignDate) {
              dueDate = new Intl.DateTimeFormat("en-US", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              }).format(lesson.assignDate);
            }
            if (l.topicId) {
              return (
                <div class="challenge-list-item challenge-completed">
                  <div class="d-flex mb-2 align-items-center">
                    <div class="text-muted text-extraBold text-uppercase">
                      <span class="indicator"></span> Grade{" "}
                      {t && t.grade ? t.grade : childProfile.grade}.{" "}
                      {displaySubject(t && t.subject ? t.subject : l.subject)}.{" "}
                      {dueDate}
                    </div>
                  </div>

                  <div class="row align-items-center">
                    <div class="col-lg-4 col-md-5 col-12">
                      <h3>{l.name ? l.name : l.shortName}</h3>
                      <p class="text-muted">{t ? t.name : ""}</p>
                    </div>
                    <div class="col-lg-5 mb-sm-0 mb-2 col-md-5 col-12">
                      <div class="d-flex mt-md-0 mt-3 text-center justify-content-around">
                        <div class="px-2">
                          <div class="graph-progress-sm w-50 m-auto">
                            <CircularProgressbar
                              value={lesson.accuracy ? lesson.accuracy : 0}
                              maxValue={100}
                              text={`${
                                new Intl.NumberFormat("en-US", {
                                  maximumFractionDigits: 0,
                                }).format(
                                  lesson.accuracy ? lesson.accuracy : 0
                                ) + "%"
                              }`}
                              strokeWidth="10"
                              styles={buildStyles({
                                textColor: "#657D95",
                                trailColor: "#fdeef5",
                                pathColor: "#b863fb",
                              })}
                            />
                          </div>
                          <div class="graph-progress-label mt-2">Accuracy</div>
                        </div>
                        <div class="px-2">
                          <div class="graph-progress-sm w-50 m-auto">
                            <CircularProgressbar
                              value={lesson.exposure ? lesson.exposure : 0}
                              maxValue={100}
                              text={`${
                                new Intl.NumberFormat("en-US", {
                                  maximumFractionDigits: 0,
                                }).format(
                                  lesson.exposure ? lesson.exposure : 0
                                ) + "%"
                              }`}
                              strokeWidth="10"
                              styles={buildStyles({
                                textColor: "#657D95",
                                trailColor: "#fdeef5",
                                pathColor: "#ff5fce",
                              })}
                            />
                          </div>
                          <div class="graph-progress-label mt-2">Exposure</div>
                        </div>
                        <div class="px-2">
                          <div class="graph-progress-sm w-50 m-auto">
                            <CircularProgressbar
                              value={lesson.score ? lesson.score : 0}
                              maxValue={100}
                              text={`${
                                new Intl.NumberFormat("en-US", {
                                  maximumFractionDigits: 0,
                                }).format(lesson.score ? lesson.score : 0) + "%"
                              }`}
                              strokeWidth="10"
                              styles={buildStyles({
                                textColor: "rgb(108, 124, 147)",
                                pathColor: "#4d65db",
                                trailColor: "rgb(221, 224, 229)",
                              })}
                            />
                          </div>
                          <div class="graph-progress-label mt-2">Score</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-2 col-12 mt-md-0 mt-3">
                      <div class="d-flex mt-md-0 mt-2 flex-wrap justify-content-md-end justify-content-center">
                        <a
                          href={
                            "/parent/report/child/" +
                            childProfile.userid +
                            "/lesson/" +
                            l.key
                          }
                          class="btn btn-lg ml-3 mb-2 btn-primary text-normal"
                        >
                          View Report
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
};
export default TopicChallenges;
