export function displaySubject(subject) {
  if (subject) {
    if (subject.includes("MATH")) {
        subject = "math"
    }
    // console.log("displaySubject subject: " + subject);
    return titleCase(subject.replace(/_/g, " "));
  }
  return "";
} 

export function displayLessonType(lessonType) {
  if (lessonType) {
    // console.log("displaySubject subject: " + subject);
    return lessonType.replace("CHOICE_QUESTIONS", "CHOICE").replace(/_/g, " ").toUpperCase();
  }
  return "";
}

export function displayLessonName(lesson) {
  if (!lesson) {
    return "";
  }
  console.log("displayLessonName lesson: " + lesson);
  // console.log("displayLessonName shortName: " + lesson.shortName);
  if (lesson.shortName && lesson.shortName.length > 3) {
    return lesson.shortName;
  }
  // console.log("displayLessonName name: " + lesson.name);
  return displayName(lesson.name);
}

export function mapSubject(subject) {
  if (subject === "COMMON_CORE_MATH") {
    return "MATH";
  }
  return subject;
}

export function displayName(name) {
  if (name) {
    if (name.startsWith("CONTENT")) {
      let newName = name.substr(name.indexOf(":") + 1, name.length).trim();
      if (newName.startsWith("CONTENT")) {
        newName = name.substr(name.indexOf(" ") + 1, name.length).trim();
      }
      if (newName.startsWith("-")) {
        newName = name.substr(name.indexOf("-") + 1, name.length).trim();
      }
      return newName;
    }
    if (name.startsWith("English")) {
      let newName = name.substr(name.indexOf("-") + 1, name.length).trim();
      return newName;
    }
  }
  return titleCase(name);
}

export function titleCase(string) {
  // console.log("titleCase: " + string);
  if (string) {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      // sentence[i] = sentence[i].ch.toUpperCase() + sentence[i].slice(1);
      sentence[i] = sentence[i].charAt(0).toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
  }
  return string;
}

export function sentenceCase(string) {
  console.log("titleCase: " + string);
  if (string) {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < 1; i++) {
      // sentence[i] = sentence[i].ch.toUpperCase() + sentence[i].slice(1);
      sentence[i] = sentence[i].charAt(0).toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
  }
  return string;
}

export function showLeftArrow(firstCard) {
  let showLeft = false;
  if (firstCard && firstCard.current) {
    const f_card = firstCard ? firstCard.current.getBoundingClientRect() : null;
    const y = window.innerHeight || document.documentElement.clientHeight;
    const x = window.innerWidth || document.documentElement.clientWidth;
    // console.log("Window x: " + x + " y: " + y);
    // if (f_card) console.log("First Card: " + JSON.stringify(f_card));

    if (f_card.x < 0) {
      // card is atlease partially out of screen
      showLeft = true;
    }
  }
  return showLeft;
}

export function showRightArrow(lastCard) {
  let showRight = true;
  if (lastCard && lastCard.current) {
    const l_card = lastCard ? lastCard.current.getBoundingClientRect() : null;
    const y = window.innerHeight || document.documentElement.clientHeight;
    const x = window.innerWidth || document.documentElement.clientWidth;
    // console.log("Window x: " + x + " y: " + y);
    // if (l_card) console.log("Last Card: " + JSON.stringify(l_card));

    let lastCardEnd = x * 10; // always show right arrow
    if (l_card) {
      lastCardEnd = l_card.x + l_card.width;
      // console.log("Last Card end: " + lastCardEnd);
    }

    if (lastCardEnd < x) {
      // card is completely in screen
      showRight = false;
    }
  }
  return showRight;
}

export function getNumber(num, defaultValue) {
  if (num) {
    if (typeof num == "number") {
      return num;
    } else if (Number.isInteger(num)) {
      parseInt(num);
    }
  }
  return defaultValue;
}

export function getMinutesValue(minutes) {
  if (isNaN(minutes) || !minutes) {
    return 0;
  }
  const value = minutes ? minutes / (1000 * 60) : 0;
  // console.log(minutes + " = minutes => " + value);
  return minutes > 0 ? value : 0;
}

export function getMinutesDisplay(millis) {
  if (isNaN(millis) || !millis) {
    return null;
  }
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  const value = minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  // console.log(millis + " = value => " + value);
  return minutes > 0 ? value : 0;
}

export function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}
