import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getImage } from "../../utils/image-utils";
import { displaySubject } from "../../utils/display-utils";

const TopicRow = ({ topicMetadata, metrics, childProfile, topicId }) => {
    // console.log("TopicRow topicMetadata: " + JSON.stringify(topicMetadata));
    // console.log("TopicRow metrics: " + JSON.stringify(metrics));
  return (
    <div class="challenge-list-item">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-sm-0 mb-2">
          <div class="subtitle text-gray mb-3 text-uppercase">
            {displaySubject(metrics ? metrics.subject : "")}. Grade{" "}
            {topicMetadata && topicMetadata.grade ? topicMetadata.grade : childProfile.grade}
          </div>
          <h3>
            {topicMetadata
              ? topicMetadata.name
                ? topicMetadata.name
                : topicMetadata.shortName
              : topicId}
          </h3>
        </div>
        <div class="col-lg-2 text-center ml-lg-auto mb-sm-0 mb-1 col-md-2 col-sm-3 col-6">
                  <div class="w-50 mx-auto">
            <CircularProgressbar
              value={metrics.accuracy}
              maxValue={100}
              text={`${
                new Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 0,
                }).format(metrics.accuracy) + "%"
              }`}
              strokeWidth="10"
              styles={buildStyles({
                textColor: "#657D95",
                trailColor: "#fdeef5",
                pathColor: "#b863fb",
              })}
            />
          </div>
        </div>
        <div class="col-lg-2 text-center ml-lg-auto mb-sm-0 mb-1 col-md-2 col-sm-3 col-6">
                  <div class="w-50 mx-auto">
            <CircularProgressbar
              value={metrics.exposure}
              maxValue={100}
              text={`${
                new Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 0,
                }).format(metrics.exposure) + "%"
              }`}
              strokeWidth="10"
              styles={buildStyles({
                textColor: "#657D95",
                trailColor: "#fdeef5",
                pathColor: "#ff5fce",
              })}
            />
          </div>
        </div>
        <div class="col-lg-3 d-flex align-items-center col-md-4 col-sm-6 mt-md-0 mt-3 col-6">
          <div class="progress mb-0 w-100">
            <div
              class="progress-bar"
              role="progressbar"
              style={{
                  width: ((metrics.completedLessons * 100) / metrics.lessonCardinality) + "%",
              }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="90"
            ></div>
          </div>
          <div class="progress-val ml-md-4 ml-2">
                      <span class="text-success">{metrics.completedLessons}</span>/
            {metrics.lessonCardinality}
          </div>
        </div>
        <div class="col-lg-2 col-md-12 text-right col-sm-6 mt-md-0 mt-3 col-6">
          <a
            href={
              "/parent/performance/" +
              childProfile.userid +
              "/" +
              metrics.subject +
              "/" +
              topicId
            }
            class="btn btn-rounded btn-purple"
          >
            See Analysis{" "}
            <img
              src={getImage("np_arrow_purple.svg")}
              class="icon-right"
              alt="icons"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopicRow;
