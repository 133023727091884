import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import axios from "axios";

import { config } from "../../../Constants";

import ProfileWidget from "../../../components/Student/ProfileWidget/ProfileWidget";

import "./Topic.css";
import { aggregate } from "../../../utils/aggregation-utils";
import { reportActions, report } from "../../../utils/report-utils";
import { displayName } from "../../../utils/display-utils";

import { getImage } from "../../../utils/image-utils";
import { fetchTopic, getTopic } from "../../../utils/student-utils";
import ErrorHandler from "../../../components/Student/ErrorHandler/ErrorHandler";
import requireAuthentication from "../../../components/AuthenticatedComponent";
import { getProfile, getToken } from "../../../utils/profile-utils";
import ShowProblem from "../../../components/Student/LessonContent/ShowProblem";

class Topic extends React.Component {
  // default State object
  state = {
    lessonList: [],
    showTop: false,
    showBottom: true
  };
  report = {};

  constructor(props) {
    super(props);

    if (!getProfile() || !getToken()) {
      this.props.history.push("/logout");
    }

    console.log(props);

    console.log("PROPS: " + JSON.stringify(props));
    this.state.subject = props.match.params.subject;
    this.state.topicId = props.match.params.topic;
    this.state.index = parseInt(props.match.params.index);

    this.state.profile = JSON.parse(localStorage.getItem("profile"));
    this.state.token = localStorage.getItem("token");
    this.topicScrollingWrapper = React.createRef();

    this.report.pageName = "Topic";
    this.report.userid = this.state.profile.userid;
    this.report.subject = this.state.subject;
    this.report.topicId = this.state.topicId;

    console.log(
      "READY TO RENDER LESSON DETAILS in Topic " +
        this.state.subject +
        " " +
        this.state.topicId
    );

    console.log(this.state.profile);
    // This binding is necessary to make `this` work in the callback
    this.onClose = this.onClose.bind(this);
    this.scrollChange = this.scrollChange.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
    this.scrollBottom = this.scrollBottom.bind(this);
    this.loadTopics = this.loadTopics.bind(this);
    this.showError = this.showError.bind(this);
    this.hideError = this.hideError.bind(this);
    this.requestAssignement = this.requestAssignement.bind(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
    fetchTopic(this.state.topicId, this.loadTopics, this.showError);
    console.log("Reporting action: " + reportActions.LOAD_PAGE);
    this.report.action = reportActions.LOAD_PAGE;
    console.log("Sending report: " + JSON.stringify(this.report));
    report(this.report);
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
  }

  scrollChange() {
    const element = this.topicScrollingWrapper.current;
    console.log("* scrollHeight: " + element.scrollHeight);
    console.log("* scrollTop: " + element.scrollTop);
    console.log("* clientHeight: " + element.clientHeight);

    const bottomScrollPercentage =
      (element.scrollTop * 100) / element.scrollHeight;
    const topScrollPercentage =
      (element.scrollTop * 100) / element.clientHeight;
    console.log("bottomScrollPercentage: " + bottomScrollPercentage);
    console.log("topScrollPercentage: " + topScrollPercentage);
    let showTop = false;
    let showBottom = true;
    if (topScrollPercentage > 30) {
      showTop = true;
    }
    if (bottomScrollPercentage > 70) {
      showBottom = false;
    }
    this.setState({
      showTop: showTop,
      showBottom: showBottom
    });
  }

  scrollTop() {
    const element = this.topicScrollingWrapper.current;
    console.log("scrollTop: " + element.scrollTop);
    element.scrollTop -= 100;
  }
  scrollBottom() {
    const element = this.topicScrollingWrapper.current;
    console.log("scrollBottom: " + element.scrollTop);
    element.scrollTop += 100;
    this.scrollChange();
  }

  onClose() {
    this.props.history.replace(
      "/practice/" + this.state.subject + "#" + this.state.index
    );
  }

  loadTopics(topic, progress, locked) {
    // console.log('Topic loadTopics: ' + JSON.stringify(this.state.topic.lessonList));
    const profile = getProfile();

    // create a new "State" object without mutating
    // the original State object.
    const newState = Object.assign({}, this.state, {
      topic: getTopic(topic, this.state.index),
      progress: progress,
      locked: locked,
      lessonList: topic.lessonList,
      profile: profile,
      notifications: null
    });

    // store the new state object in the component's state
    this.setState(newState);
  }

  showError(error) {
    // create a new "State" object without mutating
    // the original State object.

    if (!getProfile() || !getToken()) {
      this.props.history.push("/logout");
    }

    if (error && error.response && error.response.status === 401) {
      this.props.history.push("/logout");
    }
    console.log("ERROR + " + JSON.stringify(error));

    const newState = Object.assign({}, this.state, {
      showError: true,
      errorMessage: error.message,
      error: error
    });

    // store the new state object in the component's state
    this.setState(newState);
  }

  hideError() {
    this.setState({
      showError: false,
      errorMessage: ""
    });
  }

  showError(message, lessonId, lessonName) {
    this.setState({
      showError: true,
      errorMessage: message,
      errorLessonId: lessonId,
      errorLessonName: lessonName
    });
  }

  requestAssignement() {
    let assignPath = "/api/notifications/";
    console.log(
      "Assign request will be sent to " + assignPath + this.state.errorLessonId
    );

    axios
      .post(
        config.url.API_BASE_URL + assignPath,
        {
          from: this.state.profile.userid,
          to: this.state.profile.family.parents[0].userid,
          lessonId: this.state.errorLessonId,
          lessonName: this.state.errorLessonName,
          notificationType: "ASSIGN_CHALLENGE_REQUEST"
        },
        { headers: { Authorization: this.state.token } }
      )
      .then(response => {
        console.log(response);
        this.setState({
          showError: false,
          errorLessonKey: null,
          errorMessage: ""
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { progress, subject, topic, index } = this.state;
    if (this.props.previousTopic)
      console.log("Topic previousTopic: " + this.props.previousTopic.key);
    if (this.props.topic) console.log("Topic topic: " + this.props.topic.key);
    if (this.props.nextTopic)
      console.log("Topic nextTopic: " + this.props.nextTopic.key);

    return (
      <div id="student-container">
        <img src={getImage("cloud-over.png")} alt="" className="cloud-1" />

        <div className="header-bar math-header">
          <div className="sk-container">
            <ProfileWidget
              locationHash={this.props.location.hash}
              history={this.props.history}
            />
          </div>
        </div>

        {topic && (
          <div className={"main-choose-chapter " + subject.toLowerCase()}>
            <div className="addition-wrapper">
              <div className="addition-title">
                <span>CHAPTER {index + 1}</span>
                <p>{topic.name}</p>
              </div>
              <img src={getImage("c2.png")} alt="" className="top-cart" />
              <img
                src={getImage("close_btn.png")}
                alt=""
                className="clg-btn"
                onClick={() => this.onClose()}
              />
              <div className="addition-item">
                <div
                  className="topic-scrolling-wrapper"
                  onScroll={this.scrollChange}
                  ref={this.topicScrollingWrapper}
                >
                  {progress &&
                    this.state.lessonList.map(c => {
                      // console.log('Topic : ' + JSON.stringify(c));
                      if (!c) {
                        return <div />;
                      }
                      // console.log('Topic progress: ' + this.state.progress[c.key]);
                      const progressValue = progress
                        ? this.state.progress[c.lessonId]
                        : null;
                      // console.log('Topic : ' + JSON.stringify(progressValue));
                      return (
                        <LessonCard
                          lesson={c}
                          progress={progressValue}
                          locked={this.state.locked}
                          showError={this.showError}
                        />
                      );
                    })}
                </div>
              </div>
              {this.state.firstIncompleteLesson && (
                <NavLink
                  activeClassName="active"
                  to={
                    "/" +
                    this.state.firstIncompleteLesson.lessonType.toLowerCase() +
                    "/" +
                    this.state.firstIncompleteLesson.key
                  }
                >
                  {/* <img className="ply-btn" src={getImage('play_btn.png')} alt="" /> */}
                </NavLink>
              )}
            </div>
          </div>
        )}
        {this.state.topic && (
          <div className="path-area-bottom">
            <div className="sk-container">
              <div className="single-pth-itm pth-itm-2">
                <img
                  src={getImage("stone-4.png")}
                  alt=""
                  style={{ opacity: 0.3 }}
                />
                <div className="ine-path">
                  <img src={getImage("topic-line-path1.png")} alt="" />
                </div>
              </div>

              <div className="single-pth-itm">
                <img src={getImage(this.state.topic.platform)} alt="" />
                <div className="ine-path">
                  <img src={getImage("topic-line-path-2.png")} alt="" />
                </div>
              </div>

              <div className="single-pth-itm">
                <img
                  src={getImage("stone-4.png")}
                  alt=""
                  style={{ opacity: 0.3 }}
                />
                <div className="ine-path">
                  <img src={getImage("topic-line-path1.png")} alt="" />
                </div>
              </div>
            </div>
          </div>
        )}
        <ShowProblem report={this.report} />
        {this.state.showError && (
          <ErrorHandler
            onClose={() => this.hideError()}
            onAction={() => this.requestAssignement()}
            errorMessage={this.state.errorMessage}
            errorTitle="Lesson Is Locked"
          />
        )}
      </div>
    );
  }
}

Topic.propTypes = {
  topicId: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

function LessonCard(props) {
  //console.log('LessonCard props: ' + JSON.stringify(props));

  let lessonStarted = false;
  let showProgress = false;
  let percentage = {};
  let displayIcon = null;
  if (props.progress && props.progress.lessonReport) {
    const lessonReport = props.progress.lessonReport;

    console.log("LessonCard lessonReport: " + JSON.stringify(lessonReport));
    let displayReport = aggregate(lessonReport, props.lesson);
    if (displayReport.showIncomplete) {
      lessonStarted = true;
      showProgress = true;
      console.log(
        "LessonCard displayReport showIncomplete: " +
          JSON.stringify(displayReport)
      );
      percentage = {
        width:
          Math.round(
            (lessonReport.attemptedQuestions * 100) /
              lessonReport.totalQuestions
          ) + "%"
      };
    }
    if (displayReport.showAccuracy) {
      lessonStarted = true;
      // console.log("LessonCard showAccuracy: " + JSON.stringify(displayReport));
      displayIcon = "lesson-complete.svg";
      showProgress = false;
    }
  }

  // console.log(props.cardIndex + " of " + props.totalCards + " Loading Card: " + props.lesson.name );
  console.log(
    props.lesson + " LessonCard percentage: " + JSON.stringify(percentage)
  );
  // console.log(props.lesson.shortName + ' LessonCard shortname: ');
  let lessonName = props.lesson.name;
  if (props.lesson.shortName) {
    lessonName = props.lesson.shortName;
  }

  return (
    <div>
      {lessonStarted && (
        <NavLink
          activeClassName="active"
          to={"/questions/" + props.lesson.id}
        >
          <div className="single-addition">
            <div className="item-addition">
              <div className="addition-txt">
                <p>{displayName(lessonName)}</p>
              </div>
              {showProgress && (
                <div className="left progress-background">
                  <div className="progress-progress" style={percentage} />
                </div>
              )}
              {displayIcon && (
                <img src={getImage(displayIcon)} alt="" className="sk-badge" />
              )}
            </div>
          </div>
        </NavLink>
      )}
      {/* lesson is not started and topic is unlocked (soft lock => student can unlock) */}
      {!lessonStarted && !props.locked && (
        <NavLink
          activeClassName="active"
          to={"/questions/" + props.lesson.id}
        >
          <div className="single-addition">
            <div className="item-addition">
              <img
                src={getImage("start-lesson.svg")}
                alt=""
                className="sk-badge"
              />
              <div className="addition-txt">
                <p>{displayName(lessonName)}</p>
              </div>
            </div>
          </div>
        </NavLink>
      )}
      {/* // topic is locked (hard lock => student needs permission to unlock) */}
      {props.locked && (
        <NavLink
          activeClassName="active"
          to={"/questions/" + props.lesson.id}
        >
          <div
            className="single-addition lock-addition"
            // onClick={() =>
            //   props.showError(
            //     'Contact parent or teacher to unlock this lesson ' + lessonName,
            //     props.lesson.id,
            //     lessonName
            //   )
            // }
          >
            <div className="item-addition">
              <img src={getImage("lock_icon.svg")} alt="" className="sk-badge" />
              <div className="addition-txt">
                <p>{displayName(lessonName)}</p>
              </div>
            </div>
          </div>
        </NavLink>
      )}
    </div>
  );
}

export default requireAuthentication(Topic);
