import React, { useEffect, useRef } from 'react';
import { getImage } from '../../utils/image-utils';

const ShowDonutInfo = ({ onClose }) => {
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click do nothing
      return;
    }
    // outside click close card
    onClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div id="overlay-container">
      <div ref={node} class="kids-performance-modal">
        <div class="modal-title-area">
          <div class="modal-title">
            <h4>Progress Indicators</h4>
          </div>
          <div class="close-modal">
            <img src={getImage('Close.png')} onClick={() => onClose()} alt="" />
          </div>
        </div>
        <div class="modal-bodyy">
          <div class="single-modal-content">
            <div class="modal-left-text">
              <img src={getImage('grasp-1.png')} alt="" />
            </div>
            <div class="modal-right-text">
              <h4>Attempts</h4>
              <p>
                Numbers of times child has started challenge. This number in
                itself does not indicate strength or weakness and has to be
                viewed in conjuction with other metrics here to interpret
                behavior.{' '}
              </p>
            </div>
          </div>
          <div class="single-modal-content">
            <div class="modal-left-text">
              <img src={getImage('grasp-2.png')} alt="" />
            </div>
            <div class="modal-right-text">
              <h4>Questions Attempted</h4>
              <p>
                This is simple metric to show how many questions your child has
                attempted.{' '}
              </p>
            </div>
          </div>
          <div class="single-modal-content">
            <div class="modal-left-text">
              <img src={getImage('grasp-3.png')} alt="" />
            </div>
            <div class="modal-right-text">
              <h4>Exposure</h4>
              <p>
                Our lesssons are curated to ensure child is exposed to precise
                information required by state standards. Less exposure indicates
                child has not reviewed all provided content and may not be
                prepared for the test.{' '}
              </p>
            </div>
          </div>
          <div class="single-modal-content">
            <div class="modal-left-text">
              <img src={getImage('grasp-4.png')} alt="" />
            </div>
            <div class="modal-right-text">
              <h4>Confidence</h4>
              <p>
                This metric is derrived from activity seen during test questions
                only. It is not possible to judge child's confidence when
                reviewing material. At the moment we have limited test content.{' '}
              </p>
            </div>
          </div>
          <div class="single-modal-content">
            <div class="modal-left-text">
              <img src={getImage('grasp-5.png')} alt="" />
            </div>
            <div class="modal-right-text">
              <h4>Accuracy</h4>
              <p>
                Accuracy is a metric mesured in test mode. If children are
                confident their accuracy increases.{' '}
              </p>
            </div>
          </div>
          <div class="single-modal-content">
            <div class="modal-left-text">
              <img src={getImage('grasp-6.png')} alt="" />
            </div>
            <div class="modal-right-text">
              <h4>Score</h4>
              <p>
                Child's grasp of the material and system prediction of
                performance.{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowDonutInfo;
