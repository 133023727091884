import React from "react";
import { NavLink } from "react-router-dom";

import { getImage, getAvatarImage } from "../../utils/image-utils";
import { getActor, getName, getProfile } from "../../utils/profile-utils";

const DashboardHeader = () => {
  return (
    <div className="welcome-page-title">
      <div className="sk-container">
        <div className="welcome-page-left">
          <h2>
            Welcome, <span>{getName()}</span>
          </h2>
        </div>
        <div className="welcome-page-right">
          {/* <div className="message-box">
            <NavLink
              activeClassName="active"
              to={'/' + getActor() + '/messages'}
            >
              <img src={getImage('envelope.png')} alt="" />
            </NavLink>
          </div> */}
          <div className="techers-profiles">
            <NavLink
              activeClassName="active"
              to={"/" + getActor() + "/profile"}
            >
              <img src={getAvatarImage(getProfile())} alt="" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
