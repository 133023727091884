import React from "react";

import { getImage } from "../../utils/image-utils";
import { displaySubject } from "../../utils/display-utils";

const CreateChallengeHeader = ({ grade, subject, child, step, progress }) => {
  return (
    <header class="steps-header bg-white">
      <div class="container container-minify">
        <div class="row align-items-end">
          <div class="col-sm-7">
            <div class="d-flex align-items-start">
              <div class="back_btn">
                <a href={"/parent/recommendations/" + child}>
                  <img src={getImage("back.svg")} alt="icon" />
                </a>
              </div>
              <div class="steps-header-title">
                <h2>Create Challenge</h2>
                <div class="subheading text-gray text-uppercase">
                  <span>Grade {grade}</span> <span>{displaySubject(subject)}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="steps-header-progress">
              <div class="d-flex text-gray align-items-center justify-content-between mb-1">
                <span>STEPS</span>
                <span>
                  {step} <span class="text-muted">/ 3</span>
                </span>
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style={{ width: progress + "%" }}
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default CreateChallengeHeader;
