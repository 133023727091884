import React from 'react';
import PropTypes from 'prop-types';

import { hasLargeOptions } from '../../../utils/lesson-utils';
import requireAuthentication from '../../AuthenticatedComponent';

class MultipleChoiceContent extends React.Component {
  state = {
    hasTutorial: true,
    checked: [],
  };

  constructor(props) {
    super(props);

    let checked = [];
    if (!props.isReview) {
      checked[0] = false;
      checked[1] = false;
      checked[2] = false;
      checked[3] = false;
    } else {
      checked[0] = true;
      checked[1] = true;
      checked[2] = true;
      checked[3] = true;
    }

    this.state.checked = checked;

    this.checkAnswer = this.checkAnswer.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
    this.learn = this.learn.bind(this);
  }

  checkAnswer(optionIndex) {
    const answer = this.props.options[optionIndex];
    let checked = [];
    checked[0] = false;
    checked[1] = false;
    checked[2] = false;
    checked[3] = false;
    checked[optionIndex] = true;
    this.setState(
      {
        checked: checked,
      },
      () => {
        setTimeout(
          function() {
            console.log(
              'MultipleChoiceContent Check answer: MultipleChoiceContent ' +
                answer
            );

            if (typeof this.props.checkAnswer === 'function') {
              this.props.checkAnswer(answer);
            }
            checked[optionIndex] = false;
            this.setState({
              checked: checked,
            });
          }.bind(this),
          500
        );
      }
    );
  }

  learn() {
    console.log('Switch to learn mode ');
    if (typeof this.props.learn === 'function') {
      this.props.learn();
    }
  }

  handlePasswordChange(event) {
    console.log('MultipleChoiceContent  New value: ' + event.target.value);
    // check enter to submit answer
    this.setState({ answer: event.target.value });
    console.log('Changed: >>' + this.state.anwer + '<<');
  }

  keyPress(e) {
    console.log('MultipleChoiceContent KEY: ' + e.target.value);
    if (e.keyCode === 13) {
      console.log('CHECK ANSWER: ', this.state.answer);
      this.checkAnswer(this.state.answer);
      console.log('CLEAR ANSWER: ');
      this.setState({ answer: '' });
    }
  }

  submitAnswer(e) {
    console.log('MultipleChoiceContent SUBMIT ANSWER: ' + e.target.value);
    this.checkAnswer(this.state.answer);
    this.setState({ answer: '' });
  }

  render() {
    // console.log('MultipleChoiceContent render props: ' + JSON.stringify(this.props));
    const question = this.props.question;
    // use options because they are shuffled not question.options
    const options = this.props.options;

    let correctAnswer = null;
    if (question && question.options) {
      correctAnswer =
        (this.props.answer
          ? this.props.answer
          : question.options[question.answerIndex]) + '';
    }
    let answer0;
    let answer1;
    let answer2;
    let answer3;
    const buttonSize = hasLargeOptions(this.props.options)
      ? 'single-question-click-large single-question-click '
      : 'single-question-click ';
    let answerButton0 = buttonSize + ' left ';
    let answerButton1 = buttonSize;
    let answerButton2 = buttonSize + ' left ';
    let answerButton3 = buttonSize;
    // console.log('correctAnswer ' + correctAnswer);
    answer0 = options[0] === correctAnswer ? 'correct' : 'wrong';
    answer1 = options[1] === correctAnswer ? 'correct' : 'wrong';
    answer2 = options[2] === correctAnswer ? 'correct' : 'wrong';
    answer3 = options[3] === correctAnswer ? 'correct' : 'wrong';

    let checked0 = this.state.checked[0];
    let checked1 = this.state.checked[1];
    let checked2 = this.state.checked[2];
    let checked3 = this.state.checked[3];

    if (this.props.isReview && this.props.questionReport) {
      answerButton0 +=
        options[0] === correctAnswer ? 'correct-answer activee' : '';
      answerButton1 +=
        options[1] === correctAnswer ? 'correct-answer activee' : '';
      answerButton2 +=
        options[2] === correctAnswer ? 'correct-answer activee' : 'left ';
      answerButton3 +=
        options[3] === correctAnswer ? 'correct-answer activee' : '';

      // console.log('correctAnswer ' + correctAnswer);
      answerButton0 +=
        options[0] === this.props.questionReport.providedAnswer
          ? this.props.questionReport.isCorrect
            ? ' correct-answer activee'
            : ' incorrect-1 activee'
          : ' ';
      if (options[0] === this.props.questionReport.providedAnswer) {
        checked0 =
          options[0] === this.props.questionReport.providedAnswer
            ? true
            : false;
      } else {
        checked0 = options[0] === correctAnswer ? true : false;
      }
      // console.log("options[0] " + options[0]);
      answerButton1 +=
        options[1] === this.props.questionReport.providedAnswer
          ? this.props.questionReport.isCorrect
            ? ' correct-answer activee'
            : ' incorrect-1 activee'
          : ' ';
      if (options[1] === this.props.questionReport.providedAnswer) {
        checked1 =
          options[1] === this.props.questionReport.providedAnswer
            ? true
            : false;
      } else {
        checked1 = options[1] === correctAnswer ? true : false;
      }
      // console.log("options[1] " + options[1]);
      answerButton2 +=
        options[2] === this.props.questionReport.providedAnswer
          ? this.props.questionReport.isCorrect
            ? ' correct-answer activee'
            : ' incorrect-1 activee'
          : ' ';
      if (options[2] === this.props.questionReport.providedAnswer) {
        checked2 =
          options[2] === this.props.questionReport.providedAnswer
            ? true
            : false;
      } else {
        checked2 = options[2] === correctAnswer ? true : false;
      }
      // console.log("options[2] " + options[2]);
      answerButton3 +=
        options[3] === this.props.questionReport.providedAnswer
          ? this.props.questionReport.isCorrect
            ? ' correct-answer activee'
            : ' incorrect-1 activee'
          : ' ';
      // console.log("options[3] " + options[3]);
      if (options[3] === this.props.questionReport.providedAnswer) {
        checked3 =
          options[3] === this.props.questionReport.providedAnswer
            ? true
            : false;
      } else {
        checked3 = options[3] === correctAnswer ? true : false;
      }
    } else {
      answer0 = options[0] === correctAnswer ? 'correct' : 'wrong';
      answer1 = options[1] === correctAnswer ? 'correct' : 'wrong';
      answer2 = options[2] === correctAnswer ? 'correct' : 'wrong';
      answer3 = options[3] === correctAnswer ? 'correct' : 'wrong';
    }

    // console.log('answer0 => ' + answer0);
    // console.log('answer1 => ' + answer1);
    // console.log('answer2 => ' + answer2);
    // console.log('answer3 => ' + answer3);

    return (
      <div className="question-form question-checkbox">
        <span>QUESTION {this.props.questionIndex + 1}</span>
        <p dangerouslySetInnerHTML={{ __html: question.question }} />
        {this.props.displayImage && <img src={this.props.displayImage} style={{maxHeight: "20em"}}/>}
        <div className="questions-checkbox-warp">
          {options[0] && (
            <div className={answerButton0}>
              <label className="sk-container-ck">
                <p
                  className="click"
                  dangerouslySetInnerHTML={{ __html: options[0] }}
                />
                <input
                  className={answerButton0}
                  type="checkbox"
                  checked={checked0}
                  onClick={() => this.checkAnswer(0)}
                />
                <span className={'checkmark ' + answer0} />
              </label>
            </div>
          )}
          {options[0] && (
            <div className={answerButton1}>
              <label className="sk-container-ck ">
                {' '}
                <p
                  className="click"
                  dangerouslySetInnerHTML={{ __html: options[1] }}
                />
                <input
                  className={answerButton1}
                  type="checkbox"
                  checked={checked1}
                  onClick={() => this.checkAnswer(1)}
                />
                <span className={'checkmark ' + answer1} />
              </label>
            </div>
          )}
          {options[2] && (
            <div className={answerButton2}>
              <label className="sk-container-ck">
                <p
                  className="click"
                  dangerouslySetInnerHTML={{ __html: options[2] }}
                />
                <input
                  className={answerButton2}
                  type="checkbox"
                  checked={checked2}
                  onClick={() => this.checkAnswer(2)}
                />
                <span className={'checkmark ' + answer2} />
              </label>
            </div>
          )}
          {options[3] && (
            <div className={answerButton3}>
              <label className="sk-container-ck">
                <p
                  className="click"
                  dangerouslySetInnerHTML={{ __html: options[3] }}
                />
                <input
                  className={answerButton3}
                  type="checkbox"
                  checked={checked3}
                  onClick={() => this.checkAnswer(3)}
                />
                <span className={'checkmark ' + answer3} />
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }
}

MultipleChoiceContent.propTypes = {
  hasTutorial: PropTypes.bool,
  question: PropTypes.object.isRequired,
  checkAnswer: PropTypes.func,
  learn: PropTypes.func,
};

export default requireAuthentication(MultipleChoiceContent);
