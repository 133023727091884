import React, { useEffect } from "react";

import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import SiteNavigation from "../components/SiteNavigation";
import { getImage } from "../utils/image-utils";
import PrivacyPolicy from "../components/PrivacyPolicy";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";
import ServiceTerms from "../components/ServiceTerms";

const Terms = () => {

  return (
    <div id="wrapper" class="parent-home" style={{ background: "white" }}>
      <SiteHeader />

      <section className="ftco-section ftco-services">
        <div className="sk-container">
          <div className="row justify-content-center mb-5 pb-5">
            {/* <div className="col-md-7 text-center heading-section fadeInUp ftco-animated">
            </div> */}

            <div class="profiles-main-warpp">
              <SiteNavigation active="terms" />
              <ServiceTerms />
            </div>
          </div>
        </div>
      </section>
      <SiteFooter />
    </div>
  );
};

export default Terms;
