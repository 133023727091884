import React from "react";
import { NavLink } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import uniqueId from "react-html-id";

import axios from "axios";

import { config, ADULT_LOADING_IMAGES } from "../../Constants";
import AdminHeader from "../../components/Admin/AdminHeader";
import { getToken, isAdmin } from "../../utils/profile-utils";
import {
  mapChildReport,
  getActiveChildLessons
} from "../../utils/parent-dashboard-utils";
import { getChildReport } from "../../utils/teacher-dashboard-utils";
import Loader from "../../components/Loader";
import ShowProblem from "../../components/Student/LessonContent/ShowProblem";
import ChallengeHistoryItem from "../../components/Parent/recommendations/ChallengeHistoryItem";
import RecommendationRow from "../../components/Parent/recommendations/RecommendationRow";
import { getImage } from "../../utils/image-utils";

class StudentHomework extends React.Component {
  state = {
    loading: true
  };

  constructor(props) {
    super(props);
    this.state.token = getToken();
    this.state.child = props.match.params.child ? props.match.params.child : "eric";

    this.getReport = this.getReport.bind(this);
    this.publish = this.publish.bind(this);
    this.remove = this.remove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.loadData = this.loadData.bind(this);
    this.changeSubject = this.changeSubject.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    getChildReport(this.state.child, this.loadData);
  }

  loadData(response) {
    console.log("StudentHomework loadData: " + response);
    const { childProfile, childReport, recommendations } = response; 
    const subjects = Object.keys(childReport.homeworkBySubject);
    const anchor = this.props.location.hash.replace("#", "");
    const pickedSubject = anchor ? anchor : subjects[0];

    const homeworkReports = Object.values(childReport.homework).map(c => 
      mapChildReport(c)
    );
    homeworkReports.sort((h1, h2) =>
      h2.displayDateValue > h1.displayDateValue ? 1 : -1
    );
    
    const lessons = []
    childReport.homeworkBySubject[pickedSubject].forEach(homework => {
      lessons.push(childReport.homework[homework.value].lessonMetadata)
    });
    console.log("pickedSubject pickedSubject : " + pickedSubject);
    this.setState({
      childProfile: childProfile,
      recommendations: recommendations,
      activeChildReports: childReport,
      homeworkReports: homeworkReports,
      subjects: subjects,
      subject: pickedSubject,
      lessons: lessons,
      loading: false,
      showList: true
    });

  }

  getReport() {
    this.setState(
      {
        activeChildReports: null,
        homeworkReports: null,
        subjects: null,
        subject: null,
        lessons: null,
        loading: true
      },
      () => {
        this.props.history.push("/admin/students/" + this.state.child);
        getChildReport(this.state.child, this.loadData);
      }
    );
  }

  publish(lessonId) {
    const queryPath =
      "/api/admin/lesson/report/" + this.state.child + "/" + lessonId;
    console.log("pickedSubject queryPath : " + queryPath);

    axios
      .get(config.url.API_BASE_URL + queryPath, {
        headers: { Authorization: this.state.token }
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log(error));
  }

  remove(lessonId) {
    const queryPath =
      "/api/admin/lesson/remove/" + this.state.child + "/" + lessonId;
    console.log("pickedSubject queryPath : " + queryPath);

    axios
      .get(config.url.API_BASE_URL + queryPath, {
        headers: { Authorization: this.state.token }
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log(error));
  }

  handleChange(event) {
    // console.log("New value: " + event.target.value);
    this.setState({ child: event.target.value });
    // console.log("Changed: " + this.state.child);
  }

  handleKeyChange(event) {
    // console.log("New value: " + event.target.value);
    this.setState({ lessonId: event.target.value });
    // console.log("Changed: " + this.state.child);
  }

  handleTopicChange = (event) => {
    this.setState({ topicId: event.target.value });
  }

  indexAnalytics() {
    console.log("Indexing activity ");

    axios
      .get(config.url.API_BASE_URL + "/api/admin/lesson/index/analytics/", {
        headers: { Authorization: getToken() }
      })
      .then(response => {
        console.log(response);
        window.location.reload();
      })
      .catch(error => console.log(error));
  }

  // assignLesson(role) {
  //   console.log("Assign request was created by " + role);
  //   let assignPath = "/api/homework/assign/parent/" + this.state.child + "/";

  //   console.log(
  //     "Assign request will be sent to " + assignPath + this.state.lessonKey
  //   );

  //   axios
  //     .get(config.url.API_BASE_URL + assignPath + this.state.lessonKey, {
  //       headers: { Authorization: getToken() }
  //     })
  //     .then(response => {
  //       console.log(response);
  //       window.location.reload();
  //     })
  //     .catch(error => console.log(error));
  // }



  createChallenge = () => {
    const {lessonId, topicId, child} = this.state;

    var dueDateValue = new Date();
    var numberOfDaysToAdd = 7;
    dueDateValue.setDate(dueDateValue.getDate() + numberOfDaysToAdd); 

    console.log("Creating challenge for " + child);
    console.log("with lesson " + lessonId);
    console.log("and topic " + topicId);
    console.log("Due date " + dueDateValue);

    let assignPath = "";
    if (isAdmin()) {
      assignPath = "/api/v2/admin/assign/" + child + "/";
    }
    const payload = {
      userid: child,
      lessonId: lessonId,
      topicId: topicId,
      dueDate: dueDateValue.getTime()
    }
    // console.log("Assign request will be sent to " + assignPath);
    // console.log("Assign request payload " + JSON.stringify(payload));

    axios
      .post(config.url.API_BASE_URL + assignPath,
        payload,
        {
          headers: { Authorization: getToken() },
        })
      .then((response) => {
        console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        window.location.reload();
      });
  };

  changeSubject(subject) {
    const { subjects, activeChildReports } = this.state;
    const { anchorValue } = this.props.location.hash.replace("#", "");
    console.log(
      "changeSubject ParentDashboard: " + subject + " anchor " + anchorValue
    );
    let recent = false;
    if (subject === "Recent") {
      recent = true;
    }
    this.setState({
      subject: subject,
      recent: recent,
      lessons: getActiveChildLessons(subject, subjects, activeChildReports)
    });
  }

  render() {
    const {
      child,
      subject,
      subjects,
      recent,
      lessons,
      limitLessons,
      showList,
      activeChildReports
    } = this.state;

    const { homeworkReports, loading, childProfile, recommendations } = this.state;
    console.log("homeworkReports " + JSON.stringify(activeChildReports));
    const style = { border: "1px solid black" };
    return (
      <div id="wrapper" className="parent-dashboard">
        <AdminHeader child={child}/>
        <div className="sk-container">
          <div className="clear">
            <a href={"/admin/assign/" + child}>Assign Homework</a>
            <button
              className="cursor-pointer"
              onClick={() => this.indexAnalytics()}
            >
              Index Analytics
            </button>
            <NavLink to={"/admin/rescore/" + child}>Rescore Homework</NavLink>
          </div>
        </div>


        <div class="sk-card mt-4 card-reset card-challenge-created">
          <div class="card-header d-sm-flex flex-wrap justify-content-between bg-white">
            <div class="card_title">
              <h2>Challenges created</h2>
                </div>
            <div class="ml-sm-auto d-flex mt-3 mt-sm-0 flex-wrap justify-content-sm-end">
            </div>
          </div>
          <div class="card-body">
            <div class="challenge-list-created">
            {/* childReport.homeworkBySubject[pickedSubject].forEach(homework => {
      lessons.push(childReport.homework[homework.value].lessonMetadata)
    }); */}
              {homeworkReports && homeworkReports.map((c, index) => (
                <ChallengeHistoryItem
                  key={index}
                  lesson={c}
                  // lesson={activeChildReports.homework[c.lessonId].lessonMetadata}
                  child={childProfile}
                />
              ))}
            </div>
          </div>
        </div>




        <div class="sk-card mt-4 card-reset card-challenge-created">
          <div class="card-header d-sm-flex flex-wrap justify-content-between bg-white">
            <div class="card_title">
              <h2>Recommendations</h2>
            </div>
          </div>
          <div class="card-body">
            <div class="challenge-list-created">
              {recommendations && recommendations.map((c, index) => (
                <RecommendationRow
                  key={index}
                  recommendation={c}
                  lessonKey={c.lessonMetadata}
                  // child={childProfile}
                  child={{ userid: child, grade: 999 }}
                />
              ))}
            </div>
          </div>
        </div>


        <ShowProblem report={{ pageName: "Student Homework Dashboard" }} />

        {loading && (
          <Loader
            image={
              ADULT_LOADING_IMAGES[
              Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
              ]
            }
          />
        )}{" "}
      </div>
    );
  }
}

export default StudentHomework;
