import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "react-circular-progressbar/dist/styles.css";

import "./../../Teacher/RecentChallenges.css";
import "./../../../components/Parent/ChildChallenges.css";
import { displaySubject } from "../../../utils/display-utils";
import { getImage } from "../../../utils/image-utils";
import { sendNotification } from "../../../utils/notification-utils";
import Toast from "../../Student/Toast/Toast";
import { getFirstName } from "../../../utils/profile-utils";

const ProgressCard = ({ child, report, showToast }) => {
  const [toastText, setToastText] = useState(null);

  const completeRequest = (lesson) => {
    // console.log(child.userid + " ProgressCard completeRequest: " + JSON.stringify(lesson));
    const boundFunction = function () {
      showToast("Complete request was sent to " + getFirstName(child.name));
    }.bind(this);
    sendNotification(
      child.userid,
      lesson.lessonKey,
      lesson.shortName,
      "COMPLETE_REQUEST",
      boundFunction
    );
  };

  if (!report) {
    return <div />;
  }

  const {
    correctAnswers,
    attemptedQuestions,
    totalQuestions,
    assignDate,
    lesson,
    ref,
    displayDateValue,
  } = report;
  const { shortName, lessonKey, grade, subject } = lesson;

  // console.log("ProgressCard LessonReportCard lesson: " + JSON.stringify(lesson));

  // console.log(attemptedQuestions + ' <= attampted ProgressCard > LessonReportCard total => : ' + totalQuestions);
  let percentage = 0;
  let complete = false;
  if (totalQuestions > 0) {
    percentage = (attemptedQuestions * 100) / totalQuestions;
    complete = attemptedQuestions >= totalQuestions;
  }
  // console.log('ProgressCard > LessonReportCard percentage: ' + percentage);

  return (
    <div class="card-body card-report d-flex flex-column">
      <div class="card-report-head text-uppercase d-flex justify-content-between">
        <span>{displaySubject(subject).toUpperCase()}</span>
        <span>
          {new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "2-digit",
          }).format(displayDateValue)}
        </span>
      </div>
      <div class="card-report-body">
        {complete && <img src={getImage("stamp.svg")} class="stamp" />}
        <ul class="list-unstyled">
          <li>{shortName}</li>
        </ul>
      </div>
      <div class="card-report-footer mt-auto">
        {attemptedQuestions > 0 && <div class="progress-report mb-3">
          <div class="progress-title d-flex align-items-center mb-2">
            <strong>CHALLENGE SCORE</strong>
            <div class="ml-auto">
              <span>{correctAnswers}</span>/{totalQuestions}
            </div>
          </div>
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              style={{ width: percentage + "%" }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>}

        <div class="text-center">
          {attemptedQuestions > 0 && (
            <NavLink
              to={
                "/parent/report/child/" +
                child.userid +
                "/lesson/" +
                lessonKey
              }
              class="btn-link btn-text-purple"
            >
              VIEW REPORT
            </NavLink>
          )}
          {attemptedQuestions <= 0 && (
            <a
              className="btn-link btn-text-purple"
              onClick={() => completeRequest(lesson)}
            >
              ASK TO COMPLETE
            </a>
          )}
        </div>
      </div>
    </div>

    // <div class="card-body card-report d-flex flex-column">
    //   <div class="card-report-head text-uppercase d-flex justify-content-between">
    //     <span>{displaySubject(subject).toUpperCase()}</span>
    //     <span>
    //       {new Intl.DateTimeFormat("en-US", {
    //         month: "long",
    //         day: "2-digit"
    //       }).format(displayDateValue)}
    //     </span>
    //   </div>
    //   <div class="card-report-body">
    //     {complete && <img src={getImage("stamp.svg")} class="stamp" />}
    //     <ul class="list-unstyled">
    //       <li>{shortName}</li>
    //     </ul>
    //   </div>
    //   <div class="report-footer mt-auto">
    //     <div class="progress-report mb-3">
    //       <div class="progress-title d-flex align-items-center mb-2">
    //         <strong>CHALLENGE SCORE</strong>
    //         <div class="ml-auto">
    //           <span>{attemptedQuestions}</span>/{totalQuestions}
    //         </div>
    //       </div>
    //       <div class="progress">
    //         <div
    //           class="progress-bar"
    //           role="progressbar"
    //           style={{ width: percentage + "%" }}
    //           aria-valuenow="25"
    //           aria-valuemin="0"
    //           aria-valuemax="100"
    //         ></div>
    //       </div>
    //     </div>

    //     <div class="text-center">
    //       {attemptedQuestions > 0 && (
    //         <NavLink
    //           to={
    //             "/parent/report/child/" +
    //             child.userid +
    //             "/lesson/" +
    //             lessonKey
    //           }
    //           class="btn-link btn-text-purple"
    //         >
    //           VIEW REPORT
    //         </NavLink>
    //       )}
    //       {attemptedQuestions <= 0 && (
    //         <a
    //           className="btn-link btn-text-purple"
    //           onClick={() => completeRequest(lesson)}
    //         >
    //           ASK TO COMPLETE
    //         </a>
    //       )}
    //     </div>
    //   </div>
    // </div>
  );
};

ProgressCard.propTypes = {};

export default ProgressCard;
