import React, { Component } from "react";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
  getChildren,
  isParent,
  isTeacher,
  getStudents,
} from "../../utils/profile-utils";
import { displayLessonName } from "../../utils/display-utils";

class Step2 extends Component {
  // default State object
  state = {
    step: 1,
    startDate: new Date(),
    showNext: false,
    catalog: null,
    topicKey: null
  };

  constructor(props) {
    super(props);

    this.state.catalog = props.catalog;
    this.state.startDate = props.startDate;
    this.state.assignees = props.assignees;
    this.state.topicKey = props.topicKey;

    if (isParent()) {
      this.state.children = getChildren();
    } else if (isTeacher()) {
      this.state.students = getStudents();
    }

    if (
      props.startDate &&
      props.assignees &&
      props.topicKey &&
      props.topicKey.length > 0
    ) {
      this.state.showNext = true;
    }

    this.handleTopicChange = this.handleTopicChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleTopicChange(event) {
    const topicKey = event.target.value;
    // loadSubjectsByGrade(event.target.value, this.loadData, this.showError);

    console.log("Step2 pickLesson:" + topicKey);
    console.log("Step2 pickLesson assignees:" + this.state.assignees);
    let showNext = false;
    if (
      this.state.startDate &&
      this.state.assignees &&
      topicKey &&
      topicKey.length > 0
    ) {
      showNext = true;
    }
    this.setState({
      topicKey: topicKey,
      showNext: showNext
    });
  }

  handleChange(event) {
    console.log("Step2 handleChange:" + event);
    let showNext = false;
    if (
      this.state.startDate &&
      this.state.topicKey &&
      event.target.value &&
      event.target.value.length > 0
    ) {
      showNext = true;
    }
    console.log("assignees " + event.target.value);
    this.setState({
      assignees: event.target.value,
      showNext: showNext
    });
  }

  handleDateChange(date) {
    if (this.state.step === 1) {
      let showNext = false;
      if (
        date &&
        this.state.lessonKey &&
        this.state.assignees &&
        this.state.assignees.length > 0
      ) {
        showNext = true;
      }
      console.log("assignees: " + this.state.assignees);
      this.setState({
        startDate: date,
        showNext: showNext
      });
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const {
      startDate,
      topicKey,
      assignees,
      dateError,
      lessonError,
      childError,
      children,
      students,
      catalog
    } = this.state;
    return (
      <div>
        <div className="manage-grade">
          <div className="subject-select">
            <span>When should the challenge be done?</span>
            <div className="pr-tag">
              <span>
                <DatePicker
                  value={startDate}
                  selected={startDate}
                  onChange={this.handleDateChange}
                  minDate={new Date()}
                />
              </span>
              {dateError && <span className="error-text">{dateError}</span>}
            </div>
          </div>
        </div>
        <div className="subject-select">
          <span>Select challenge topic</span>
          <div className="pr-tag">
            <span>
              {/* <input value={lessonName} onClick={this.showCatalog} /> */}
              <select value={topicKey} onChange={this.handleTopicChange}>
                <option value="">Pick Topic</option>
                {catalog &&
                  catalog.topics.map(t => (
                    <option key={t.key} value={t.key}>
                      {displayLessonName(t)}
                    </option>
                  ))}
              </select>
            </span>
            {lessonError && <span className="error-text">{lessonError}</span>}
          </div>
        </div>
        <div className="subject-select">
          <span>Who will receive this challenge?</span>
          <div className="pr-tag">
            <span>
              {students && (
                <select value={assignees} onChange={this.handleChange}>
                  <option value=""> Pick One</option>
                  <option value="all"> All {students.length} students</option>
                  {students.map(c => (
                    <option value={c.userid}> {c.name} </option>
                  ))}
                </select>
              )}
              {children && (
                <select value={assignees} onChange={this.handleChange}>
                  <option value=""> Pick One</option>
                  {children.map(c => (
                    <option value={c.userid}> {c.name} </option>
                  ))}
                </select>
              )}
            </span>
            {childError && <span className="error-text">{childError}</span>}
          </div>
          <div className="next-step-btn manage-btn">
            <a onClick={() => this.props.previousStep(-1)}>BACK</a>
            {this.state.showNext && (
              <a
                className="remove"
                onClick={() =>
                  this.props.nextStep(1, startDate, topicKey, assignees)
                }
              >
                Next
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Step2;
