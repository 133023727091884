import React, { useState } from "react";
import { displaySubject, displayLessonType } from "../../utils/display-utils";
import { getImage } from "../../utils/image-utils";
import { SUBJECT_STYLES } from "../../Constants";

const TopicCards = ({ topic, active, handleTopicChange }) => {
  // const { topic } = props;
  console.log("StepTopic TopicCards topic id: " + JSON.stringify(topic.id));
  console.log("StepTopic TopicCards topic lessons: " + JSON.stringify(topic.lessonIds)); 
  console.log("StepTopic TopicCards topic productionReady: " + JSON.stringify(topic.productionReady));  
  // console.log("StepTopic TopicCards active: " + active);
  const [isShown, setIsShown] = useState(false);

  if (!topic) {
    return <div />;
  }

  const cardStyle = SUBJECT_STYLES[topic.subject];
  // console.log(topic.subject + " TopicCards cardStyle: " + JSON.stringify(cardStyle));

  const { name, grade, subject, description, id, productionReady } = topic;
  let activeClass = "";
  if (active) {
      activeClass = "selected";
  }

  const lessons = topic.lessonIds ? topic.lessons.lessonIds : null;

  return (
    <div class="col-sm-6 col-lg-4 mt-lg-4 mt-2">
      {/* <div class={"sk-choose-item " + (active ? "selected " : "") + (productionReady ? "" : "sk-card-error")}  */}
      <div class={"sk-choose-item " + (active ? "selected " : "")} 
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      onClick={() => handleTopicChange(id)} style={{ height: "22em", width: "16.5em" }}>
        <div
                  className={"choose-item-image " + activeClass}
          style={{ backgroundColor: cardStyle.topicBackgroundColor }}
        >
          {!isShown && <React.Fragment>
            <img
              src={getImage("subject-background.svg")}
              class="img-fluid"
              style={{ opacity: "0" }}
              alt="image"
            />
            <div class="choose-icon overlay">
              <img
                src={getImage(cardStyle.icon)}
                class="default-icon choose-icon"
                alt="icon"
              />
              {active && <img src={getImage("np_tick_lg.svg")} class="active-icon" alt="icon" />}
            </div>
            </React.Fragment>}
        </div>
        {!isShown && <div class="choose-item-text p-3">
          <div class="d-flex mb-3 w-100">
            <div class="card-tag d-flex mr-auto">
              <span>GRADE {grade}</span>
              <span>{displaySubject(subject)}</span>
            </div>
            {lessons ? <span class="text-gray">Lessons: {lessons}</span> : null}
          </div>
          <p>{name}</p>
        </div>}
        {isShown && <div class="choose-item-text p-3">
          <p>{name}</p>
          <span className="card-tag" dangerouslySetInnerHTML={{ __html: description }} />
        </div>}
      </div>
    </div>
  );

  /*


        <div
            className="single-clng-wrpp single-clng-2-warp"
            style={{ filter: productionReady ? "" : "grayscale(100%)" }}
            onClick={() => {
                console.log("CLICKED " + key);
                handleTopicChange(key);
            }}
        >
            <div className={"single-clng-item recommendation-card " + activeClass}>
               
<div className="clng-top-txt">
    <div className="challenge-date">
        <div className="date-text">
            <p>
                GRADE {grade} . {displaySubject(subject)}
            </p>
            <span>{name}</span>
        </div>
    </div>
</div>
    <div
        className="recent-plants-txt"
        style={{ border: "none", overflowY: "scroll", minHeight: "50%" }}
    >
        <p
            style={{ fontSize: "16px" }}
            dangerouslySetInnerHTML={{ __html: description }}
        />
    </div>
            </div >
        </div >

    */
};

export default TopicCards;
