import React, { Component, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

import { config } from "../Constants";
import Header from "../components/Header";
import SiteNavigation from "../components/SiteNavigation";
import { getImage } from "../utils/image-utils";
import PrivacyPolicy from "../components/PrivacyPolicy";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";
import { checkText, checkEmail } from "../utils/validation-utils";

class Help extends Component {
  state = {
    showThanks: false
  };

  constructor(props) {
    super(props);

    console.log(props);

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.checkName = this.checkName.bind(this);
    this.checkEmailAddress = this.checkEmailAddress.bind(this);
    this.onCaptcha = this.onCaptcha.bind(this);
    this.onCaptcha = this.onCaptcha.bind(this);
  }

  handleNameChange = event => {
    console.log("Name " + event.target.value);
    this.setState({
      name: event.target.value
    });
  };

  handleEmailChange = event => {
    console.log("Email " + event.target.value);
    this.setState({
      email: event.target.value
    });
  };

  handleMessageChange = event => {
    console.log("Message " + event.target.value);
    this.setState({
      message: event.target.value
    });
  };

  checkName = () => {
    const { captcha, emailError } = this.state;
    console.log("checkName +  captcha: " + captcha);
    console.log("checkName +  emailError: " + emailError);
    let valid = checkText(this.state.name);
    let nameError = null;
    if (!valid) {
      nameError = "Enter name";
    }
    const error = !valid && captcha !== null && emailError === null;
    this.setState({
      nameError: nameError,
      showSubmit: error
    });
    console.log("checkName +  showSubmit: " + error);
    return nameError;
  };

  checkEmailAddress = () => {
    const { captcha, nameError } = this.state;
    // console.log("checkEmailAddress +  captcha: " + captcha);
    // console.log("checkEmailAddress +  nameError: " + nameError);
    // console.log("Check email " + this.state.email);
    let emailError = checkEmail(this.state.email);
    let error = emailError ? false : true;
    error = error && captcha !== null && nameError === null;
    this.setState({
      emailError: emailError,
      showSubmit: error
    });
    console.log("checkEmailAddress +  showSubmit: " + error);
    return emailError;
  };

  onCaptcha = value => {
    const { nameError, emailError } = this.state;
    // console.log("onCaptcha +  nameError: " + nameError);
    // console.log("onCaptcha +  emailError: " + emailError);
    // console.log("Captcha value:", value);
    const error = true && nameError === null && emailError === null;
    this.setState({
      captcha: value,
      showSubmit: error
    });

    console.log("onCaptcha +  showSubmit: " + error);
  };

  submitMessage = e => {
    e.preventDefault();
    // console.log("Send Message " + this.state.name);
    // console.log("Send Message " + this.state.email);
    // console.log("Send Message " + this.state.message);

    let nameError = this.checkName();
    let emailError = this.checkEmailAddress();

    console.log("captcha value (state): " + this.state.captcha);

    if (!nameError && !emailError) {
      let report = this.report;
      console.log("Reporting action: PROBLEM");

      const payload = {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        captcha: this.state.captcha
      };
      console.log(payload);

      axios
        .post(config.url.API_BASE_URL + "/request/demo", payload)
        .then(result => {
          this.setState({
            showThanks: true
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  render() {
    const { showThanks } = this.state;
    return (
      <div id="wrapper" class="parent-home" style={{ background: "white" }}>
        <SiteHeader />

        <section className="ftco-section ftco-services">
          <div className="sk-container">
            <div className="row justify-content-center mb-5 pb-5">
              {/* <div className="col-md-7 text-center heading-section fadeInUp ftco-animated">
            </div> */}

              <div className="profiles-main-warpp">
                <SiteNavigation active="help" />

                <div className="right-content-warp">
                  <div className="right-content-inner">
                    <div className="help-content">
                      <div className="help-title">
                        <h4>Help</h4>
                      </div>

                      <div
                        className="modal-bodyy hero-text"
                        style={{ maxHeight: "-webkit-fill-available" }}
                      >
                        {!showThanks && (
                          <form onSubmit={this.submitMessage}>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.name}
                                onChange={this.handleNameChange}
                                onBlur={() => this.checkName()}
                                placeholder="Full Name"
                              />
                              {this.state.nameError && (
                                <span className="error-text">
                                  {this.state.nameError}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                                onBlur={() => this.checkEmailAddress()}
                                id="appointment_email"
                                placeholder="Email"
                              />
                              {this.state.emailError && (
                                <span className="error-text">
                                  {this.state.emailError}
                                </span>
                              )}
                            </div>
                            <div className="form-group">
                              <textarea
                                name=""
                                id="appointment_message"
                                value={this.state.message}
                                onChange={this.handleMessageChange}
                                onBlur={() => this.checkEmailAddress()}
                                className="form-control"
                                cols="30"
                                rows="7"
                                placeholder="Message"
                              />
                            </div>
                            <ReCAPTCHA
                              sitekey="6LfbObMUAAAAAI08gwiOp9hEiKWBIixOGkwAZCeb"
                              theme="light"
                              className="middle"
                              onChange={this.onCaptcha}
                            />
                            <br />
                            <div
                              className={
                                this.state.showSubmit
                                  ? "form-group login-button"
                                  : "form-group login-button stopTalking"
                              }
                            >
                              {/* <input
                                type="submit"
                                value="Request a Demo"
                                className="btn btn-primary"
                              /> */}
                              <button
                                className="cursor-pointer"
                                type="submit"
                                style={{
                                  pointerEvents: this.state.showSubmit
                                    ? ""
                                    : "none"
                                }}
                              >
                                SEND
                              </button>
                            </div>
                          </form>
                        )}
                        {showThanks && (
                          <div>
                            <p>
                              Thank you for your interest in SmartKids.io. We
                              have received your request. Support will contact
                              you shortly.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SiteFooter />
      </div>
    );
  }
}

export default Help;
