import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getImage } from "../../../utils/image-utils";
import { sendNotification } from "../../../utils/notification-utils";
import { getName, getChild, getFirstName } from "../../../utils/profile-utils";
import Toast from "../../Student/Toast/Toast";
import { displaySubject, getMinutesDisplay, getMinutesValue, millisToMinutesAndSeconds } from "../../../utils/display-utils";
import Attempts from "../LessonReport/Attempts";

const ParentAnalytics = ({ avatar, child, reports, averageTimeSpent }) => {
  console.log("ParentAnalytics reports: " + JSON.stringify(reports));
  console.log("ParentAnalytics child: " + child);
  console.log("ParentAnalytics averageTimeSpent: " + averageTimeSpent);
  const strokeWidth = "10";
  const strokeWidthLarge = "6";

  const [isApplause, setApplause] = useState(false);
  const [toastText, setToastText] = useState(null);

  const clap = (e) => {
    console.log("ChildReportCard clap: " + child);
    setApplause(true);
    const boundFunction = function () {
      setApplause(false);
      setToastText("You clapped for " + child.name);
    };
    sendNotification(child, getName(), "", "APPLAUSE", boundFunction);
  };

  const clapImage = isApplause ? "parent-clap-active.svg" : "parent-clap.svg";

  const showIntelligence =
    reports &&
    reports.derivedInsights &&
    reports.derivedInsights.includes("NEXT_LESSON")
      ? true
      : false;

  let incomplete =
    reports &&
    reports.derivedInsights &&
    reports.derivedInsights.includes("REQUEST_COMPLETE")
      ? true
      : false;

  if (
    !reports ||
    !reports.questionActivity ||
    reports.questionActivity.length <= 0
  ) {
    incomplete = true;
  }

  const childProfile = getChild(child);
  const firstName = childProfile ? getFirstName(childProfile.name) : child;

  const correctAnswers = reports.correctAnswers;
  const totalQuestions = reports.totalQuestions;
  const attemptedQuestions =
    reports && reports.questionActivity ? reports.questionActivity.length : 0;

  // TODO enable attempts
  const attempts = false;

  return (
    <div class="container container-minify">
      <div class="row">
        {attempts && <Attempts />}
        {/* <!-- /.col-sm-12 --> */}
        <div class="col-md-3 mb-sm-0 mb-4">
          <div class="child-profile h-100 d-flex flex-column text-gray">
            {avatar && <img src={avatar} class="profile-pic" alt="child" />}

            {showIntelligence && (
              <p>
                <strong>{firstName}</strong> did well on this the test, applaud
                his progress.{" "}
              </p>
            )}
            {showIntelligence && (
              <div class="mt-auto pt-4 clap d-flex align-items-center">
                <div class="icon">
                  <img
                    className={
                      isApplause ? "clap-badge img-fluid" : "img-fluid"
                    }
                    src={getImage("clap-icon.svg")}
                    alt="clap"
                  />
                </div>
                <strong>“Clap for {firstName}”</strong>
              </div>
            )}

            {incomplete && (
              <p>
                <strong>{firstName}</strong> has not completed this lesson.{" "}
              </p>
            )}
            {/* {incomplete && (
              <div class="mt-auto pt-4 clap d-flex align-items-center">
                <div class="request-btn bg-red">
                  <a class="complete-request">Ask to complete</a>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div class="col-md-9">
          <div class="section-heading">
            <h5>Performance analysis</h5>
          </div>

          <div class="sk-card card-shadow">
            <div class="card-body">
              <div class="row my-3">
                <div class="col-sm-5 mb-sm-0 mb-3 text-center">
                  <div class="graph-sm">
                    <CircularProgressbar
                      value={reports.score}
                      text={`${reports.scoreText}`}
                      strokeWidth={strokeWidthLarge}
                      styles={buildStyles({
                        textColor: "rgb(108, 124, 147)",
                        pathColor: "#4d65db",
                        trailColor: "rgb(221, 224, 229)",
                      })}
                    />
                  </div>
                  {/* <!-- /.graph-sm --> */}
                  <h4 class="text-gray mb-0">Score</h4>
                  {averageTimeSpent && <div class="time-stamp">
                    <strong class="text-dark">
                      {millisToMinutesAndSeconds(averageTimeSpent)}{" mins"}
                    </strong>
                    <span>Avg time per question</span>
                  </div>}
                </div>
                {/* <!-- /.col-sm-5 --> */}
                <div class="col-sm-7 border-left pl-md-5 pl-sm-2">
                  <table class="table text-gray table-borderless">
                    <tbody>
                      <tr>
                        <td width="25" class="px-0 py-2">
                          <img
                            src={getImage("correct-answer-green.svg")}
                            class="img-fluid"
                            alt="√"
                          />
                        </td>
                        <td>Correct</td>
                        <td class="text-right">{correctAnswers}</td>
                      </tr>
                      <tr>
                        <td width="25" class="px-0 py-2">
                          <img
                            src={getImage("wrong-answer-red.svg")}
                            class="img-fluid"
                            alt="X"
                          />
                        </td>
                        <td>Incorrect</td>
                        <td class="text-right">
                          {attemptedQuestions - correctAnswers}
                        </td>
                      </tr>
                      <tr>
                        <td width="25" class="px-0 py-2">
                          <img
                            src={getImage("unattempted.svg")}
                            class="img-fluid"
                            alt="-"
                          />
                        </td>
                        <td>Not attempted</td>
                        <td class="text-right">
                          {totalQuestions - attemptedQuestions}
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #eee;" }}>
                        <td></td>
                        <td>Total questions:</td>
                        <td class="text-right">{totalQuestions}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4 pt-3">
        <div class="col-md-3 pt-2 mb-sm-0 mb-4">
          {reports.recommendation && (
            <div
              class="card-body notes mt-4 rounded-0 card-smart-kid d-flex flex-column"
              style={{ backgroundColor: "#ffefe8;" }}
            >
              <div class="bright-tag mb-4 pb-2 d-flex align-items-center">
                <div class="icon">
                  <img src={getImage("bright.svg")} alt="icon" />
                </div>
                <h6>
                  Smartkids <br />
                  intelligence{" "}
                </h6>
              </div>
              <p class="text-dark-gray font-weight-normal">
                We recommend that you assign <strong>{firstName}</strong> <br />
                <strong>{reports.recommendation.lessonMetadata.name}</strong>
              </p>

              <a
                href={"/parent/recommendations/" + (childProfile ? childProfile.user : "")}
                class="btn btn-primary btn-lg mr-auto mt-4 text-normal px-2 btn-shadow"
              >
                View Recommendations
              </a>
            </div>
          )}
        </div>
        <div class="col-md-9">
          <div class="section-heading">
            <h5>Behavioural analysis </h5>
          </div>
          <div class="row">
            <div class="col-sm-4 mb-sm-0 mb-3">
              <div class="sk-card card-behaviour card-shadow">
                <div class="card-body font-weight-normal">
                  <div class="col-sm-6 p-1">
                    <CircularProgressbar
                      value={reports.accuracy}
                      text={`${reports.accuracy + "%"}`}
                      strokeWidth={strokeWidth}
                      styles={buildStyles({
                        textColor: "rgb(108, 124, 147)",
                        pathColor: "#a463f7",
                        trailColor: "rgb(221, 224, 229)",
                      })}
                    />
                  </div>

                  <h4>Accuracy</h4>
                  <p>
                    Measure of correct responses over all attempts within the
                    challenge.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-4 mb-sm-0 mb-3">
              <div class="sk-card card-behaviour card-shadow">
                <div class="card-body font-weight-normal">
                  <div class="col-sm-6 p-1">
                    <CircularProgressbar
                      value={reports.exposure}
                      text={`${reports.exposure + "%"}`}
                      strokeWidth={strokeWidth}
                      styles={buildStyles({
                        textColor: "rgb(108, 124, 147)",
                        pathColor: "#f43d6e",
                        trailColor: "rgb(221, 224, 229)",
                      })}
                    />
                  </div>
                  <h4>Exposure</h4>
                  <p>Measure of attempted questions within the challenge.</p>
                </div>
              </div>
            </div>
            <div class="col-sm-4 mb-sm-0 mb-3">
              <div class="sk-card card-behaviour card-shadow">
                <div class="card-body font-weight-normal">
                  <div class="col-sm-6 p-1">
                    <CircularProgressbar
                      value={reports.confidence}
                      text={`${reports.confidence + "%"}`}
                      strokeWidth={strokeWidth}
                      styles={buildStyles({
                        textColor: "rgb(108, 124, 147)",
                        pathColor: "#f05fca",
                        trailColor: "rgb(221, 224, 229)",
                      })}
                    />
                  </div>

                  <h4>
                    Confidence <strong style={{ color: "orange" }}><sup>Beta</sup></strong>
                  </h4>
                  <p>
                    Measure represents the closest prediction of expected score.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentAnalytics;

/**


    <section class="performance-analysis">
      {toastText && <Toast text={toastText} />}
      <div class="sk-container">
        <div class="row">
          <div class="col-sm-3">
            <div className="report-profiles">
              <a href="#">
                <img src={avatar} alt={child} />
              </a>
              {showIntelligence && (
                <p>
                  <b>{childProfile.name}</b> did well on this the test, applaud
                  his progress.{" "}
                </p>
              )}
              {showIntelligence && (
                <div class="child">
                  <a onClick={() => clap()}>
                    <img
                      className={
                        isApplause ? "clap-badge img-fluid" : "img-fluid"
                      }
                      src={getImage("clap.svg")}
                      alt=""
                    />
                  </a>
                  <p>“Clap for {firstName}”</p>
                </div>
              )}

              {incomplete && (
                <p>
                  <b>{childProfile.name}</b> has not completed this leson.{" "}
                </p>
              )}
              {incomplete && (
                <div class="child">
                  <div class="request-btn bg-red">
                    <a class="complete-request">Ask to complete</a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div class="col-md-7">
            <div class="analysis">
              <h3>Performance-analysis</h3>

              <div class="analysis-box">
                <div class="row">
                  <div class="col-md-2">
                    <div class="Test-Score">
                      <CircularProgressbar
                        value={reports.score}
                        text={`${reports.scoreText}`}
                        strokeWidth={strokeWidthLarge}
                        styles={buildStyles({
                          textColor: "rgb(108, 124, 147)",
                          pathColor: "#4d65db",
                          trailColor: "rgb(221, 224, 229)"
                        })}
                      />
                      <h4>Test Score</h4>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="analysis-table">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                              Correct
                            </td>
                            <td>{correctAnswers}</td>
                          </tr>
                          <tr>
                            <td>
                              <i
                                class="fa fa-times-circle"
                                aria-hidden="true"
                              ></i>{" "}
                              Wrong
                            </td>
                            <td>{attemptedQuestions - correctAnswers}</td>
                          </tr>
                          <tr>
                            <td>
                              <i
                                class="fa fa-minus-circle"
                                aria-hidden="true"
                              ></i>{" "}
                              Unattempted
                            </td>
                            <td>{totalQuestions - attemptedQuestions}</td>
                          </tr>
                          <tr class="total">
                            <td>Total questions:</td>
                            <td>{totalQuestions}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            {reports.recommendation && (
              <div class="report-profiles Smartkids">
                <div class="child">
                  <a href="#">
                    <img
                      class="img-fluid"
                      src={getImage("insights.svg")}
                      alt=""
                    />
                  </a>
                  <p>
                    Smartkids <br />
                    intelligence{" "}
                  </p>
                </div>

                <p>
                  We recommend that you assign {firstName} <br />
                  {reports.recommendation.name}
                </p>
                <a
                  class="recom"
                  href={
                    "/create/challenge/" +
                    grade +
                    "/" +
                    displaySubject(reports.recommendation.subject) +
                    "/" +
                    reports.recommendation.topicKey +
                    "/" +
                    reports.recommendation.lessonKey
                  }
                >
                  View Recommendation
                </a>
              </div>
            )}
          </div>
          <div class="col-sm-8">
            <div class="analysis">
              <h3>Behavioural analysis</h3>
              <div class="analysis-boxss">
                <div class="row">
                  <div class="col-md-3">
                    <div class="Test-Scoresss">
                      <CircularProgressbar
                        value={reports.accuracy}
                        text={`${reports.accuracy + "%"}`}
                        strokeWidth={strokeWidth}
                        styles={buildStyles({
                          textColor: "rgb(108, 124, 147)",
                          pathColor: "#a463f7",
                          trailColor: "rgb(221, 224, 229)"
                        })}
                      />
                      <h4>Accuracy</h4>
                      <p>
                        This space is to provide the definition of the above
                        KPI. To improve understanding.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="Test-Scoresss">
                      <CircularProgressbar
                        value={reports.exposure}
                        text={`${reports.exposure + "%"}`}
                        strokeWidth={strokeWidth}
                        styles={buildStyles({
                          textColor: "rgb(108, 124, 147)",
                          pathColor: "#f43d6e",
                          trailColor: "rgb(221, 224, 229)"
                        })}
                      />
                      <h4>Exposure</h4>
                      <p>
                        This space is to provide the definition of the above
                        KPI. To improve understanding.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="Test-Scoresss">
                      <CircularProgressbar
                        value={reports.confidence}
                        text={`${reports.confidence + "%"}`}
                        strokeWidth={strokeWidth}
                        styles={buildStyles({
                          textColor: "rgb(108, 124, 147)",
                          pathColor: "#f05fca",
                          trailColor: "rgb(221, 224, 229)"
                        })}
                      />
                      <h4>Confidence </h4>
                      <p>
                        This space is to provide the definition of the above
                        KPI. To improve understanding.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

 */
