import React from 'react';
import { NavLink } from 'react-router-dom';
import { getActor, getName } from '../../utils/profile-utils';
import { getImage, getAvatarImage } from '../../utils/image-utils';
import { sendNotification } from '../../utils/notification-utils';

const ChildReportHeader = ({ child, back }) => {
  const clap = e => {
    console.log('ChildReportCard clap: ' + child);
    sendNotification(child.userid, getName(), '', 'APPLAUSE', null);
  };

  // console.log(child);
  return (
    <div class="s-responses-top-area">
      <div class="sk-container">
        <div class="left back-btn">
          <a onClick={() => back()}>
            <img src={getImage('adult-arrow.svg')} alt="back" />
          </a>
        </div>
        <div class="left s-response-title">
          <img src={getAvatarImage(child)} alt="profile image" />
          <div class="left s-pro-txt">
            <h1>{child.name}</h1>
            <p>Grade {child.grade}</p>
          </div>
        </div>
        <div class="right student-pro-ms">
          <NavLink activeClassName="active" to={'/' + getActor() + '/messages'}>
            <img src={getImage('child-message.png')} alt="" />
          </NavLink>
          <a onClick={() => clap()}>
            <img src={getImage('child-clap.png')} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChildReportHeader;
