import React from 'react';
import PropTypes from 'prop-types';

import { isMuted } from '../../../utils/speech-utils';
import requireAuthentication from '../../AuthenticatedComponent';
import AlphaKeyboard from './AlphaKeyboard';

class SpellingTest extends React.Component {
  state = {
    hasTutorial: true,
    showKeyboard: true,
    answer: '',
  };

  constructor(props) {
    super(props);

    this.textInput = React.createRef();

    this.checkAnswer = this.checkAnswer.bind(this);
    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.showKeyboard = this.showKeyboard.bind(this);
    this.clearLastLetter = this.clearLastLetter.bind(this);
  }

  checkAnswer(answer) {
    console.log('SpellingTest Check answer: SpellingTest ' + answer);
    if (typeof this.props.checkAnswer === 'function') {
      this.props.checkAnswer(answer);
    }
  }

  showKeyboard(event) {
    console.log('SpellingTest: showKeyboard ');
    this.setState({ showKeyboard: this.props.isReview ? false : true });
  }

  handleAnswerChange(letter) {
    console.log('SpellingTest  New value: ' + letter);
    const answer = this.state.answer + letter;
    // check enter to submit answer
    this.setState({ answer: answer });
    console.log('Changed: >>' + answer + '<<');
  }

  clearLastLetter() {
    console.log('SpellingTest  New value: ' + this.state.answer);
    const answer = this.state.answer.substr(0, this.state.answer.length - 1);
    // check enter to submit answer
    this.setState({ answer: answer });
    console.log('Changed: >>' + answer + '<<');
  }

  keyPress(e) {
    console.log('SpellingTest KEY: ' + e.key);
    if (e.keyCode === 13 || e.key === 'Enter') {
      console.log('CHECK ANSWER: ', this.state.answer);
      this.checkAnswer(this.state.answer);
      console.log('CLEAR ANSWER: ');
      this.setState({ answer: '' });
    } else {
      this.handleAnswerChange(e.key);
    }
  }

  render() {
    const { questionReport, isReview, question } = this.props;
    const { answer } = this.state;
    console.log('SpellingTest render props: ' + JSON.stringify(this.props));
    let answerValue = answer && answer.length > 0 ? answer : '';
    let providedAnswer = '';
    let answerStyle = '';
    if (isReview && questionReport) {
      providedAnswer = questionReport.providedAnswer;
      console.log('NumericTest providedAnswer:  ' + providedAnswer);
      if (this.props.questionReport.isCorrect) {
        answerStyle = 'correct-answer activee';
      } else {
        answerStyle = 'incorrect-1 activee';
      }
    }
    const word = providedAnswer ? question.options[question.answerIndex] : null;
    return (
      <div class="question-tabs-content">
        <div class="sk-container-tab" id="tab1" style={{ display: 'block' }}>
          {!this.props.isReview && (
            <div class="question-form">
              <span>QUESTION {this.props.questionIndex + 1}</span>
              <p>
                {isMuted() && (
                  <span className="centered-wrapper">
                    This lesson requires you to unmute the volume in order to
                    continue.
                  </span>
                )}
              </p>
              <input
                ref={this.textInput}
                type="text"
                value={answerValue}
                placeholder="Enter Answer"
                onFocus={() => this.showKeyboard(this)}
                onKeyPress={e => this.keyPress(e)}
              />
            </div>
          )}
          {this.props.isReview && (
            <div class="question-form">
              <span>QUESTION {this.props.questionIndex + 1}</span>
              <p>Spell {word}</p>
              <input
                className={answerStyle}
                ref={this.textInput}
                type="text"
                value={providedAnswer}
                placeholder="No answer provided"
                onFocus={() => this.showKeyboard(this)}
              />
            </div>
          )}
        </div>
        {this.state.showKeyboard && (
          <AlphaKeyboard
            letters={this.props.question.letters}
            clearLastLetter={this.clearLastLetter}
            onLetter={this.handleAnswerChange}
            checkAnswer={this.checkAnswer}
          />
        )}
      </div>
    );
  }
}

SpellingTest.propTypes = {
  hasTutorial: PropTypes.bool,
  question: PropTypes.object.isRequired,
  checkAnswer: PropTypes.func,
  learn: PropTypes.func,
};

export default requireAuthentication(SpellingTest);
