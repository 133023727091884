import React from "react";
import uniqueId from "react-html-id";

import axios from "axios";
import LoadingBar from "react-top-loading-bar";

import { config } from "../../Constants";
import AdminHeader from "../../components/Admin/AdminHeader";
import { getToken } from "../../utils/profile-utils";
import { loadSubjectsByGrade } from "../../utils/student-utils";
import { displaySubject } from "../../utils/display-utils";
import ShowProblem from "../../components/Student/LessonContent/ShowProblem";
import AdminCatalogComponent from "../../components/Admin/AdminCatalogComponent";

class AdminCatalog extends React.Component {
  state = {
    token: null,
    catalog: null,
    showCatalog: false,
    standards: null,
    subjects: null,
    topic: null,
    loading: false
  };

  constructor(props) {
    super(props);
    this.state.token = getToken();

    this.state.grade = props.match.params.grade;
    this.state.standard = props.match.params.standard;
    this.state.subject = props.match.params.subject;
    this.state.topic = props.match.params.topic;

    this.loadCatalog = this.loadCatalog.bind(this);
    this.reloadCatalog = this.reloadCatalog.bind(this);
    this.pickLesson = this.pickLesson.bind(this);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleStandardChange = this.handleStandardChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleTopicChange = this.handleTopicChange.bind(this);
    this.loadSubjects = this.loadSubjects.bind(this);
    this.startLoadingData = this.startLoadingData.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    console.log("AdminCatalog: componentDidMount");
    this.startLoadingData();
  }

  componentWillUpdate() {
    console.log("AdminCatalog: componentWillUpdate");
  }

  startLoadingData() {
    console.log("AdminCatalog::startLoadingData");
    const { grade, subject, subjects, catalog, loading } = this.state;
    if (!loading && subject && !catalog) {
      console.log("AdminCatalog::startLoadingData Loading catalog " + subject);
      this.loadCatalog();
    }
    if (!loading && grade && !subjects) {
      console.log("AdminCatalog::startLoadingData Loading subjects " + grade);
      this.LoadingBar.add(10);
      const errorFunction = function(error) {
        console.log(error);
        this.LoadingBar.complete();
      }.bind(this);
      this.setState(
        {
          loading: true
        },
        () => loadSubjectsByGrade(grade, this.loadSubjects, errorFunction)
      );
    }
  }

  loadCatalog() {
    if (!this.state.loading) {
      console.log("AdminCatalog::loadCatalog");
      this.LoadingBar.add(10);
      this.setState({
        loading: true
      })
      const { grade, subject, topic, showCatalog } = this.state;
      if (!showCatalog) {
        this.LoadingBar.add(10);
        axios
          .get(
            config.url.API_BASE_URL +
              "/api/lesson/topics/grade/" +
              grade +
              "/subject/" +
              subject,
            { headers: { Authorization: getToken() } }
          )
          .then(response => {
            this.LoadingBar.add(50);
            console.log("AdminCatalog::loadCatalog" + response);
            this.setState({
              catalog: response.data.object.data.topic,
              showCatalog: topic ? true : false,
              loading: false
            });
            this.LoadingBar.complete();
          })
          .catch(error => {console.log(error)
            this.LoadingBar.complete();
          });
        this.LoadingBar.add(30);
      } else {
        console.log("Catalog will not load.......");
      }
    }
  }

  reloadCatalog() {
    console.log("AdminCatalog::reloadCatalog");
    this.setState(
      {
        catalog: null,
        showCatalog: false
      },
      () => this.loadCatalog()
    );
  }

  pickLesson(lessonId) {
    console.log("AdminCatalog::pickLesson");
    this.props.history.push("/questions/" + lessonId);
  }

  loadSubjects(data) {
    const {
      standard
    } = this.state;
    console.log("AdminCatalog::loadSubjects");
    const standards = Array.from(new Set(data.map(s => s.standard)));
    console.log(standard + " loadSubjects::standards : " + standards);
    let subjects = null;
    if (standard && standards) {
      subjects = Array.from(new Set(data.filter(s => s.standard === standard).map(s => s.subject)));
      console.log("loadSubjects: " + subjects);
    } 
    this.LoadingBar.add(50);
    this.setState({
      standards: standards,
      subjects: subjects,
      data: data,
      loading: false
    });
    this.LoadingBar.complete();
    console.log("ChooseSubject: filtered => " + subjects);
  }

  handleGradeChange(event) {
    console.log("AdminCatalog::handleGradeChange");
    this.setState(
      {
        grade: event.target.value,
        standards: null,
        catalog: null,
        subjects: null,
        topic: null,
        showCatalog: false,
        loading: false
      },
      () => {
          this.props.history.push("/admin/catalog/" + this.state.grade);
          this.startLoadingData();
      }
    );
    // this.props.history.push("/admin/catalog/" + event.target.value);
  }

  handleSubjectChange(event) {
    console.log("AdminCatalog::handleSubjectChange");
    this.setState(
      {
        subject: event.target.value,
        topic: null,
        catalog: null,
        showCatalog: false,
        loading: false
      },
      () => {
        this.props.history.push(
          "/admin/catalog/" + this.state.grade + "/" + this.state.standard + "/" + this.state.subject
        );
        this.startLoadingData();
      }
    );
  }

  handleStandardChange(event) {
    console.log("AdminCatalog::handleStandardChange to " + event.target.value);
      this.setState(
        {
          standard: event.target.value,
          subjects:null,
          subject: null,
          topic: null,
          catalog: null,
          showCatalog: false,
          loading: false
        },
        () => {
          this.props.history.push(
            "/admin/catalog/" + this.state.grade + "/" + this.state.standard
          );
          this.startLoadingData();
        }
      );
  }

  handleTopicChange(event) {
    console.log("AdminCatalog::handleTopicChange " + event.target.value);
    this.setState(
      {
        topic: event.target.value,
        showCatalog: true
      },
      () =>
        this.props.history.push(
          "/admin/catalog/" +
          this.state.grade +
          "/" +
          this.state.standard +
            "/" +
            this.state.subject +
            "/" +
            this.state.topic
        )
    );
  }

  render() {
    const {
      grade,
      subject,
      standard,
      gradeError,
      subjectError,
      dateError,
      showCatalog,
      topic,
      catalog,
      standards,
      subjects
    } = this.state;
    let name = "";
    if (topic && catalog) {
      let topicDetails = catalog.topics.filter(c => c.id === topic);
      if (topicDetails && topicDetails.length > 0) {
        name = topicDetails[0].name;
      }
    }
    return (
      <div className="sk-container admin-page" style={{ margin: 60 + "px" }}>
        <LoadingBar
          height={3}
          color="#f11946"
          onRef={ref => (this.LoadingBar = ref)}
        />
        <AdminHeader />
        <br />
        <div>
          <div className="step-1">
            <div className="title">Select a Grade</div>
            <div>
              <select value={grade} onChange={this.handleGradeChange}>
                <option>Pick Grade</option>
                <option value="1">Grade 1</option>
                <option value="2">Grade 2</option>
                <option value="3">Grade 3</option>
                <option value="4">Grade 4</option>
                <option value="5">Grade 5</option>
                <option value="6">Grade 6</option>
                <option value="7">Grade 7</option>
                <option value="8">Grade 8</option>
              </select>
            </div>
            {grade && standards && (
              <div>
                <div className="title">Select Standard</div>
                  <select value={standard} onChange={this.handleStandardChange}>
                    <option> Pick a Standard</option>
                    {standards.map(c => (
                      <option value={c} key={c}>{c}</option>
                    ))}
                  </select>
              </div>
            )}
            {grade && standard && (
              <div>
                <div className="title">Select Subject</div>
                {subjects && (
                  <select value={subject} onChange={this.handleSubjectChange}>
                    <option> Pick a Subject</option>
                    {subjects.map(c => (
                      <option value={c}>{displaySubject(c)}</option>
                    ))}
                  </select>
                )}
              </div>
            )}
            {subject && catalog && (
              <div>
                <div className="title">Select Topic</div>
                <select value={topic} onChange={this.handleTopicChange}>
                  <option> Pick a Topic</option>
                  {catalog.topics.map(t => (
                    <option value={t.id}>
                      {t.name ? t.name : t.shortName}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
        <div>
          {showCatalog && (
            <AdminCatalogComponent
              catalog={catalog}
              topic={topic}
              onClick={this.pickLesson}
              reload={this.reloadCatalog}
              LoadingBar={this.LoadingBar}
            />
          )}
        </div>
        <ShowProblem
          report={{
            pageName: "Admin Catalog " + name
          }}
        />
      </div>
    );
  }
}

export default AdminCatalog;
