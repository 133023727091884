import React, { Component } from "react";
import CanvasJSReact from '../../assets/js/canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class NewChart extends Component {

    learning = [];
    testing = [];

    constructor(props) {
        super();
        props.data.forEach( l => {
            // console.log(JSON.stringify(l))
            // { label: "Jan", y: 14 } 
            const learn = { "label": l.date, "y": l.Learning}
            const test = { "label": l.date, "y": l.Testing }
            this.learning.push(learn);
            this.testing.push(test);
        });
        console.log(JSON.stringify(this.learning))
        CanvasJS.addColorSet("time-spent", ["#feae91", "#ae97ff"]);

        this.toggleDataSeries = this.toggleDataSeries.bind(this);
    }
    toggleDataSeries(e) {
        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        }
        else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    }
    render() {
        const options = {
            width: this.props.graphWidth,
            height: this.props.graphHeight,
            animationEnabled: true,
            exportEnabled: false,
            title: {
                text: "",
                // fontFamily: "verdana"
            },
            axisX: {
                lineColor: "#E0E5EA",
                // lineDashType: "dash"
            },
            axisY: {
                title: "Sessions",
                lineColor: "#E0E5EA",
                gridThickness: 1,
                gridColor: "#E0E5EA",
                gridDashType: "dot"
                // prefix: "€",
                // suffix: "k"
            },
            dataPointWidth: 6,
            colorSet: "time-spent",
            // toolTip: {
            //     shared: true,
            //     reversed: true
            // },
            // legend: {
            //     verticalAlign: "center",
            //     horizontalAlign: "right",
            //     reversed: true,
            //     cursor: "pointer",
            //     itemclick: this.toggleDataSeries
            // },
            data: [
                {
                    type: "stackedColumn",
                    name: "Learning",
                    // showInLegend: true,
                    yValueFormatString: "#,###k",
                    backgroundColor: "#feae91",
                    dataPoints: this.learning
                },
                {
                    type: "stackedColumn",
                    name: "Testing",
                    // showInLegend: true,
                    yValueFormatString: "#,###k",
                    backgroundColor: "#ae97ff",
                    dataPoints: this.testing
                }
            ]
        }
        return (
            <div>
                <CanvasJSChart options={options}
                    onRef={ref => this.chart = ref}
                />
                {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            </div>
        );
    }
}
export default NewChart