import React, { PropTypes } from "react";
import {
    CircularProgressbarWithChildren,
    buildStyles,
} from "react-circular-progressbar";

const SubjectChallengeProgress = ({
    subject,
    subjectMetrics,
    learningMetrics,
    testingMetrics,
    lessonProgress
}) => {
    const lessonCardinality = subjectMetrics ? subjectMetrics.lessonCardinality : 0;

    // console.log("SubjectChallengeProgress lessonProgress: " + JSON.stringify(lessonProgress));
    console.log("SubjectChallengeProgress subjectMetrics: " + JSON.stringify(subjectMetrics));

    let allAttempedLessons,
        allCompletedLessons,
        learningLessons,
        learningCompleted,
        testingLessons,
        testingCompleted;
    if (subjectMetrics) {
        allAttempedLessons = subjectMetrics ? subjectMetrics.lessonCardinality : 0;
        allCompletedLessons = subjectMetrics ? subjectMetrics.completedLessons : 0;
        learningLessons = learningMetrics ? learningMetrics.lessonCardinality : 0;
        learningCompleted = learningMetrics ? learningMetrics.completedLessons : 0;
        testingLessons = testingMetrics ? testingMetrics.lessonCardinality : 0;
        testingCompleted = testingMetrics ? testingMetrics.completedLessons : 0;
    }

    const { subjects } = lessonProgress
    const allSubjectLessons = subjects[subject];
    const allLessons = allSubjectLessons > allAttempedLessons ? allSubjectLessons : allAttempedLessons;
    const inProgress = allAttempedLessons - allCompletedLessons;
    const pending = allLessons - allAttempedLessons;

    return (
        <div class="col-lg-6 mt-3">
            <div class="sk-card">
                <div class="card-body p-md-4 p-3 text-gray">
                    <h3 class="text-gray">Challenge summary</h3>
                    <div class="row align-items-center">
                        <div class="col-md-5 my-3">
                            <div class="pie-cart my-2 text-center">
                                <CircularProgressbarWithChildren
                                    value={allCompletedLessons + inProgress}
                                    maxValue={allLessons}
                                    text={`${""}`}
                                    strokeWidth="5"
                                    styles={buildStyles({
                                        textColor: "rgb(108, 124, 147)",
                                        pathColor: "#F5C818",// yellow #F5C818
                                        trailColor: "#F86456" // red #F86456
                                    })}
                                >
                                    <span>
                                        <CircularProgressbarWithChildren
                                            value={allCompletedLessons}
                                            maxValue={allLessons}
                                            strokeWidth="5.1"
                                            styles={buildStyles({
                                                textColor: "rgb(108, 124, 147)",
                                                pathColor: "#2CD89D", // green #2CD89D
                                                trailColor: "rgb(221, 224, 229, 0)"
                                            })}
                                        >
                                            <span>{allLessons} Total <br/> Challenges</span>
                                        </CircularProgressbarWithChildren>
                                    </span>
                                </CircularProgressbarWithChildren>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="table-responsive">
                                <table class="table table-borderless white-space text-gray table-middle">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="dot dot-green"></div>
                                                {allCompletedLessons}
                                      </td>
                                            <td>Completed</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="dot dot-yellow"></div>
                                        {inProgress}
                                      </td>
                                            <td class="text-left">In Progress</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="dot dot-red"></div>
                                        {pending}
                                      </td>
                                            <td class="text-left">Pending</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubjectChallengeProgress;