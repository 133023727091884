import React from 'react';
import PropTypes from 'prop-types';

import './ErrorHandler.css';

import { getImage } from '../../../utils/image-utils';
import { getFirstNotification } from '../../../utils/profile-utils';

const RequestComplete = ({ onClose }) => {
  const notification = getFirstNotification('COMPLETE_REQUEST');
  const handleClick = action => {
    onClose(action ? notification : null);
  };

  return (
    <div id="error-overlay-container">
      <div class="error-area">
        <div class="error-area-inner">
          <div class="sk-container">
            <div class="error-txt">
              <img src={getImage('connection-error.svg')} />
              <h4>Lesson Complete Request</h4>
              <p>
                {'You have received a request to complete ' +
                  notification.lessonName}
              </p>
              <a onClick={() => handleClick(true)}>COMPLETE</a>
              <a class="black btn" onClick={() => handleClick(false)}>
                CANCEL
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div id="error-overlay-container" className="error-handler">
    //   <div className="center">
    //     <img src={getImage('connection-error.svg')} />
    //     <div className="center">
    //       <h1>Lesson Complete Request</h1>
    //       <span className="wordwrap error-message">
    //         {'You have received a requested you to complete ' + notification.lessonName}
    //       </span>
    //       <div className="wide-login-button">
    //         <button class="wide-button cursor-pointer"
    //           onClick={() => handleClick(true)}>
    //           Complete Lesson
    //         </button>
    //       </div>
    //       <div class="cancel" onClick={() => handleClick(false)}>
    //         <span class="cursor-pointer">Cancel</span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

RequestComplete.propTypes = {
  onClose: PropTypes.func,
};

export default RequestComplete;
