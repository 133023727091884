import React from 'react';
import { NavLink } from 'react-router-dom';
import { getActor } from '../utils/profile-utils';
import { getImage } from '../utils/image-utils';

const Header = ({ title, back }) => {
  return (
    <div class="s-responses-top-area">
      <style>{'body { background-color: white; }'}</style>
      <div class="sk-container">
        <div class="back-btn">
          <NavLink to={'/' + getActor()}>
            <img src={getImage('adult-arrow.svg')} alt="back" />
          </NavLink>
        </div>
        <div class="s-response-title">
          <h1>{title}</h1>
          <div class="response-tags">
            <li />
          </div>
        </div>
        <div className="sign-option">
          <NavLink to={'/logout'}>sign out</NavLink>
        </div>
      </div>
    </div>
  );
};

export default Header;
