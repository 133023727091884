import React, { useState } from "react";

import "react-circular-progressbar/dist/styles.css";

import "./../../Teacher/RecentChallenges.css";
import "./../../../components/Parent/ChildChallenges.css";
import { displaySubject } from "../../../utils/display-utils";
import { getImage } from "../../../utils/image-utils";
import LessonPreview from "../LessonPreview";
import { getTopicMetadata, getTopicName, getTopicSubject } from "../../../services/ParentService";


const ChallengeHistoryItem = ({ child, lesson }) => {
  const [isPreview, setPreview] = useState(false);

  if (!lesson) {
    return <div />;
  }
  const {
    topicMetadata,
    lessonMetadata
  } = lesson;

  const {
    pending,
    inProgress,
    complete
  } = lesson;
  const {
    shortName,
    topicId,
    assignDate,
    subject
  } = lessonMetadata;

  console.log("ChallengeHistoryItem lesson: " + JSON.stringify(lesson));
  console.log("ChallengeHistoryItem child: " + JSON.stringify(child));

  let topicSubject = displaySubject(topicMetadata ? topicMetadata.subject : subject);

    return (
      <div class="challenge-list-item challenge-incompleted">
        {isPreview && (
          <LessonPreview
            topicId={topicMetadata.topicId}
            lessonId={lessonMetadata.lessonId}
            topicMetadata={topicMetadata}
            lessonMetadata={lessonMetadata}
            closePreview={() => setPreview(false)}
          />
        )}
        <div class="d-flex mb-2 align-items-center">
          <div class="text-muted text-extraBold text-uppercase">
            <span class="indicator"></span> Grade {lessonMetadata ? lessonMetadata.grade : topicMetadata.grade}{" . "}
                    {topicSubject}{" . "}
            {new Intl.DateTimeFormat("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric"
            }).format(assignDate)}
          </div>
          {/* <div class="dropdown ml-auto">
            <button
              class="sk-btn p-0 btn-dots btn-reset dropdown-toggle dropdown-noarrow"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={getImage("more.svg")} alt="icon" />
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton"
            >
              <a class="dropdown-item" href="#">
                Action
              </a>
              <a class="dropdown-item" href="#">
                Another action
              </a>
              <a class="dropdown-item" href="#">
                Something else here
              </a>
            </div>
          </div> */}
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-5 col-sm-8">
            <h3>{shortName}</h3>
            <p class="text-muted">Topic: {topicMetadata.shortName} &nbsp; Skills: {topicMetadata.skills}</p>
          </div>
          {/* <!-- /.col-lg-4 col-md-5 col-sm-8 --> */}
          {/* <div class="col-lg-2 ml-lg-auto mb-sm-0 mb-2 order-1-small col-md-3 col-sm-4">
            {pending && (
              <span class="text-uppercase badge">INCOMPLETED</span>
            )}
                    {inProgress && <span class="text-uppercase badge">in progress</span>}
          </div> */}
                {complete && <div class="col-lg-2 ml-lg-auto mb-sm-0 mb-2 order-1-small col-md-3 col-sm-4 challenge-completed">
                    <span class="text-uppercase badge">COMPLETED</span>
                </div>}
                {inProgress && <div class="col-lg-2 ml-lg-auto mb-sm-0 mb-2 order-1-small col-md-3 col-sm-4 challenge-progress">
                    <span class="text-uppercase badge">in progress</span>
                </div>}
                {pending && <div class="col-lg-2 ml-lg-auto mb-sm-0 mb-2 order-1-small col-md-3 col-sm-4">
            <span class="text-uppercase badge">  &nbsp;&nbsp;PENDING &nbsp; &nbsp;</span>
                </div>}
          {/* <!-- /.col-lg-3 col-md-2 col-sm-4 --> */}
          <div class="col-lg-5 col-md-4 col-sm-12">
            <div class="d-flex flex-wrap justify-content-md-end">
              {pending && <span onClick={() => setPreview(true)} class="sk-btn btn-lg mb-2 text-normal btn-default" style={{paddingTop: "0px"}}>
                <img src={getImage("np_play.svg")} class="icon-left" /> Preview
              </span>}
              {(pending || inProgress) && <a
                href="#"
                class="sk-btn btn-lg ml-3 mb-2 btn-primary text-normal" style={{ paddingTop: "0px" }} >
                Send Reminder
              </a>}
              {(complete || inProgress) && <a
                href={"/parent/report/child/" + child.userid + "/lesson/" + lessonMetadata.lessonId}
                class="sk-btn btn-lg ml-3 mb-2 btn-primary text-normal" style={{ paddingTop: "0px" }} >
                View Report
              </a>}
            </div>
          </div>
        </div>
      </div>
    );
};

ChallengeHistoryItem.propTypes = {};

export default ChallengeHistoryItem;
