import React from "react";
import axios from "axios";
import uniqueId from "react-html-id";
import { NavLink } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

import { config } from "../../Constants";

import "./Login.css";

import { getImage } from "../../utils/image-utils";
import ConnectionErrorHandler from "../../components/Student/ErrorHandler/ConnectionErrorHandler";
import { checkEmail } from "../../utils/validation-utils";
import Footer from "../../components/Footer";

class RequestAccess extends React.Component {
  state = {
    email: "",
    showError: false,
    errorMessage: null,
    emailError: null,
    showSuccess: false
  };

  constructor(props) {
    super(props);

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideError = this.hideError.bind(this);
    this.onCaptcha = this.onCaptcha.bind(this);

    this.checkEmail = this.checkEmail.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.STUDENT_PAGE_CLASS);
  }

  handleUsernameChange(event) {
    console.log(event);
    console.log("New value: " + event.target.value);
    this.setState({ email: event.target.value, emailError: null });
    console.log("Changed: " + this.state.email);
  }

  hideError() {
    this.setState({
      showError: false,
      errorMessage: ""
    });
  }

  checkEmail() {
    const fieldError = checkEmail(this.state.email);
    let isValid = true;
    if (fieldError) {
      isValid = false;
    }
    this.setState({
      emailError: fieldError,
      showSubmit: isValid
    });
    return isValid;
  }

  onCaptcha(value) {
    console.log("Captcha value:", value);
    this.setState({
      captcha: value,
      showSubmit: true
    });
  }

  handleSubmit(event) {
    console.log("A name was submitted: " + this.state.email);
    event.preventDefault();
    // get our form data out of state
    const { email, captcha } = this.state;

    console.log("Send Message " + this.state.name);
    console.log("Send Message " + this.state.email);
    console.log("Send Message " + this.state.message);

    const isValidEmail = this.checkEmail();

    if (
      isValidEmail &&
      this.state.showSubmit &&
      !this.state.emailError &&
      this.state.captcha
    ) {
      console.log("BASE URL: " + config.url.API_BASE_URL);

      axios
        .post(config.url.API_BASE_URL + "/request/demo", {
          email,
          captcha,
          message: "Access Request"
        })
        .then(result => {
          console.log("Login profile: " + result);
          this.setState({
            showSuccess: true
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({
            showError: true,
            errorMessage: error.message,
            emailError: null
          });
        });
    }
  }

  render() {
    const {
      email,
      emailError,
      showSuccess,
      showError,
      errorMessage
    } = this.state;
    return (
      <div id="login">
        <div id="sk-container">
          <div className="smart-kids on-blue">
            <NavLink className="cursor-pointer" to="/">
              SmartKids.io
            </NavLink>
          </div>
          <div className="login-wrapper">
            <div className="left login-mascot">
              <img
                className="center"
                src={getImage("parent-mascot.svg")}
                alt="mascot"
              />
            </div>
            <div className="login">
              {!showSuccess && (
                <form onSubmit={this.handleSubmit}>
                  <div className={emailError ? "enter error" : "enter"}>
                    <input
                      id={this.getUniqueId("login-email")}
                      className="input"
                      placeholder="PARENT EMAIL ADDRESS"
                      onBlur={() => this.checkEmail()}
                      value={email}
                      onChange={this.handleUsernameChange}
                    />
                    {emailError && (
                      <span className="error-text">{emailError}</span>
                    )}
                  </div>
                  <div className="captcha-containers center">
                    <ReCAPTCHA
                      sitekey="6LfbObMUAAAAAI08gwiOp9hEiKWBIixOGkwAZCeb"
                      theme="light"
                      className="g-recaptcha"
                      onChange={this.onCaptcha}
                    />
                  </div>
                  <div
                    id={this.getUniqueId("login-submit")}
                    className="login-button"
                  >
                    <button className="button cursor-pointer" type="submit">
                      SIGN UP
                    </button>
                  </div>
                </form>
              )}
              {showSuccess && (
                <NavLink to={"/login"}>
                  <div className="login-message center">
                    Thank you for your interest in SmartKids.io. You will
                    receive an email to activate your account shortly.
                  </div>
                </NavLink>
              )}
              <NavLink to={"/login"}>
                <div className="highlight">Login</div>
              </NavLink>
            </div>
          </div>
          {showError && (
            <ConnectionErrorHandler
              connectionError="true"
              onRetry={() => this.hideError()}
              errorMessage={errorMessage}
            />
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

export default RequestAccess;
