import React from "react";
import GraspReport from "./GraspReport";
import { getHomeworkReport } from "../../../services/ParentService";
import LessonInfo from "./LessonInfo";

const SyllabusReport = ({
  topic,
  lessonList,
  isClassReport,
  classCount,
  isActive,
  showTopic,
  child,
  reportPath
}) => {
  // console.log("SyllabusReport " + JSON.stringify(lessonList[0]));

  return (
    <div>
      <div className="single-degit-number">
        <div className="left-degit" onClick={() => showTopic(topic.key)}>
          <h4>{topic.name}</h4>
          <p>{lessonList ? lessonList.length : 0} LESSONS</p>
        </div>
      </div>
      {isActive &&
        lessonList.map((c, index) => {
          const report = c ? getHomeworkReport(child, c.lessonKey) : null;
          if (report) {
            return (
              <GraspReport
                key={c.lessonKey}
                report={report}
                child={child}
                classCount={classCount}
                totalQuestions={c.totalQuestions}
                isClassReport={isClassReport}
                reportPath={reportPath}
                isSub={true}
              />
            );
          } else if (c && c.availableForHomework) {
            c["lesson"] = c;
            console.log(c);
            return (
              <LessonInfo
                key={c.lessonKey}
                report={c}
                child={child}
                classCount={classCount}
                totalQuestions={c.totalQuestions}
                isClassReport={isClassReport}
                reportPath={reportPath}
                isSub={true}
              />
            );
          } else {
            return <div />;
          }
        })}
    </div>
  );
};

export default SyllabusReport;
