import React from "react";
import { NavLink } from "react-router-dom";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getClassCount } from "../../../utils/profile-utils";

const GraspReport = ({
  report,
  child,
  isClassReport,
  reportPath,
  isSub,
  minimal
}) => {
  if (!report) {
    return <div />;
  }

  const {
    attemptedQuestions,
    totalQuestions,
    attempts,
    exposure,
    confidence,
    accuracy,
    score,
    scoreText,
    studentsWithReports,
    lesson
  } = report;
  const { shortName, lessonKey } = lesson;
  const classCount = getClassCount();
  console.log(lesson.name + " GraspReport attempts: " + attempts);
  console.log(lesson.name + " GraspReport score: " + score);

  return (
    <div className={"single-degit-number " + (isSub ? "bg-gray" : "")}>
      <div className="left-degit">
        {!minimal && (
          <h4>
            {reportPath && (
              <NavLink to={reportPath + lessonKey}>{shortName}</NavLink>
            )}
            {!reportPath && <span>{shortName}</span>}
          </h4>
        )}
      </div>
      <div className="number-progress">
        {isClassReport && (
          <div className="single-progress">
            <CircularProgressbar
              value={studentsWithReports.length}
              maxValue={classCount}
              text={`${studentsWithReports.length + "/" + classCount}`}
              strokeWidth="18"
              styles={buildStyles({
                textColor: "rgb(108, 124, 147)",
                pathColor: "#ffc501",
                trailColor: "rgb(221, 224, 229)"
              })}
            />
          </div>
        )}
        {!isClassReport && (
          <div className="single-progress">
            <CircularProgressbar
              value={100}
              maxValue={100}
              text={`${attempts}`}
              strokeWidth="18"
              styles={buildStyles({
                textColor: "rgb(108, 124, 147)",
                pathColor: "#ffc501",
                trailColor: "rgb(221, 224, 229)"
              })}
            />
            <span className="metric-title">Attempts</span>
          </div>
        )}
        <div className="single-progress">
          <CircularProgressbar
            value={attemptedQuestions}
            maxValue={totalQuestions}
            text={`${attemptedQuestions + "/" + totalQuestions}`}
            strokeWidth="18"
            styles={buildStyles({
              textColor: "rgb(108, 124, 147)",
              pathColor: "#ff641e",
              trailColor: "rgb(221, 224, 229)"
            })}
          />
          <span className="metric-title">Questions</span>
        </div>
        <div className="single-progress">
          <CircularProgressbar
            value={exposure}
            text={`${exposure + "%"}`}
            strokeWidth="18"
            styles={buildStyles({
              textColor: "rgb(108, 124, 147)",
              pathColor: "#f43d6e",
              trailColor: "rgb(221, 224, 229)"
            })}
          />
          <span className="metric-title">Exposure</span>
        </div>
        <div className="single-progress">
          <CircularProgressbar
            value={confidence}
            text={`${confidence + "%"}`}
            strokeWidth="18"
            styles={buildStyles({
              textColor: "rgb(108, 124, 147)",
              pathColor: "#f05fca",
              trailColor: "rgb(221, 224, 229)"
            })}
          />
          <span className="metric-title">Confidence</span>
        </div>
        <div className="single-progress">
          <CircularProgressbar
            value={accuracy}
            text={`${accuracy + "%"}`}
            strokeWidth="18"
            styles={buildStyles({
              textColor: "rgb(108, 124, 147)",
              pathColor: "#a463f7",
              trailColor: "rgb(221, 224, 229)"
            })}
          />
          <span className="metric-title">Accuracy</span>
        </div>
        <div className="single-progress">
          <CircularProgressbar
            value={score}
            text={`${scoreText}`}
            strokeWidth="18"
            styles={buildStyles({
              textColor: "rgb(108, 124, 147)",
              pathColor: "#4d65db",
              trailColor: "rgb(221, 224, 229)"
            })}
          />
          <span className="metric-title">Score</span>
        </div>
      </div>
    </div>
  );
};

export default GraspReport;
