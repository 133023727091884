import React from 'react';
import { getImage } from '../../utils/image-utils';

import './Forms/AddChild.css';
import { registerChild } from '../../utils/request-utils';
import requireAuthentication from '../AuthenticatedComponent';
import EditChildForm from './Forms/EditChildForm';

class AddChildModal extends React.Component {
  // default State object
  state = {
    name: 'Smart Kid',
    username: 'smartkid',
    grade: 5,
    gender: 'female',
  };

  constructor(props) {
    super(props);

    console.log(props);

    this.state.lesson = null;

    this.addChild = this.addChild.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.onClose();
  }

  addChild(child) {
    console.log('Adding Child');
    const boundFunction = function() {
      window.location.reload();
    };
    const errorFunction = function(error) {
      this.setState({
        error: error,
      });
    }.bind(this);
    registerChild(child, boundFunction, errorFunction);
  }

  render() {
    const { error } = this.state;
    return (
      <div id="overlay-container" className="create-challenge">
        <div ref={this.node} className="kids-performance-modal">
          <div className="modal-title-area">
            <div className="modal-title">
              <h4>Add Child</h4>
            </div>
            <div className="close-modal">
              <a onClick={() => this.closeModal()}>
                <img src={getImage('Close.png')} alt="" />
              </a>
            </div>
          </div>
          <div className="modal-bodyy">
            {error && <div className="error-text">{error}</div>}
            <EditChildForm
              child={null}
              onClose={this.closeModal}
              onSave={this.addChild}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default requireAuthentication(AddChildModal);
