import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import uniqueId from 'react-html-id';

import './ProfileCard.css';
import { getToken, getProfile } from '../../../utils/profile-utils';

const ProfileCard = ({
  home,
  notifications,
  onSettings,
  onNotifications,
  onAchievements,
  needsUpdate,
  onClose,
  cardRight,
  getUniqueId,
}) => {
  if (!getProfile() || !getToken()) {
    this.props.history.push("/logout");
  }

  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click do nothing
      return;
    }
    // outside click close card
    onClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  console.log(cardRight + ' ProfileCard notifications# : ' + notifications);

  return (
    <div
      ref={node}
      className="profile-widget-card"
      style={{ right: cardRight }}
    >
      <ul>
        <li />
        <li id={getUniqueId('my-profile')} className="profile-link">
          <NavLink to="/profile">
            <span className="charcoal">My Profile</span>
          </NavLink>
        </li>
        {home && (
          <li id={getUniqueId('profile-challenges')} className="notification">
            <NavLink to="/challenges">
              <span className="charcoal">Homework</span>
            </NavLink>
          </li>
        )}
        <li id={getUniqueId('profile-notifications')} className="notifications">
          <a
            className="charcoal"
            onClick={() => onNotifications()}
            href="#notifications"
          >
            Notifications
          </a>
          {notifications > 0 && (
            <span className="notification-badge sk-badge">{notifications}</span>
          )}
        </li>
        <li id={getUniqueId('profile-messages')} className="message">
          <NavLink to="/messages">
            <span className="charcoal">Messages</span>
          </NavLink>
        </li>
        <li id={getUniqueId('profile-achievements')} className="achievements">
          <a
            className="charcoal cursor-pointer"
            onClick={() => onAchievements()}
            // href="#achievements"
          >
            <span className="charcoal">Achievements</span>
          </a>
        </li>
        {!home && (
          <li id={getUniqueId('profile-settings')} className="settings">
            <a
              className="charcoal"
              onClick={() => onSettings()}
              href="#settings"
            >
              Settings
            </a>
            {needsUpdate && <span className="update-badge sk-badge">!</span>}
          </li>
        )}
        <li />
        {/* <li className="parent">
            <NavLink to="/login">
              <span className="charcoal">Login as Parent</span>
            </NavLink>
          </li> */}
        <li id={getUniqueId('profile-logout')} className="logout">
          <NavLink to="/logout">
            <span className="charcoal">Logout</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default ProfileCard;
