import React, { Component } from 'react';
import axios from 'axios';

import { config } from '../../Constants';

import './../Dashboard/Dashboard.css';
import './ParentLessonReport.css';
import './../LessonReport/LessonReport.css';
import './ParentProfile.css';
import Upload from '../../components/upload/Upload';

import Header from '../../components/Header';
import ProfileNavigation from '../../components/ProfileNavigation';
import { getAvatarImage } from '../../utils/image-utils';
import {
  getProfile,
  getName,
  getEmail,
  getGrade,
  isTeacher,
  isParent,
  getChildren,
} from '../../utils/profile-utils';
import requireAuthentication from '../../components/AuthenticatedComponent';
import ShowProblem from '../../components/Student/LessonContent/ShowProblem';

class ParentProfile extends Component {
  // default State object
  state = {
    profile: '',
    token: '',
    showProfile: true,
    showChild: false,
    showSettings: false,
    showAbout: false,
    showHelp: false,
    showNotifications: false,
    showChildAchievements: true,
    showChildStatistics: false,
    showChildSettings: false,
  };

  report = {};

  chatClient;

  constructor(props) {
    super(props);

      if (!isParent()) {
        this.props.history.push("/");
      }

    console.log(props);
    // this.state.token = localStorage.getItem('token');
    this.state.profile = JSON.parse(localStorage.getItem('profile'));
    this.state.token = this.state.profile.token;

    this.report.pageName = 'Profile Dashboard';

    console.log(this.state.profile);
    // This binding is necessary to make `this` work in the callback
    this.goBack = this.goBack.bind(this);
    this.changeView = this.changeView.bind(this);
    this.changeChildView = this.changeChildView.bind(this);
    this.editChildSettings = this.editChildSettings.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.toggleUpload = this.toggleUpload.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    // this.setupChat();
  }

  createChannels() {
    if (this.state.profile.access[0] === 'STUDENT') {
      const teacher = this.state.profile.classroom.teacher;
      this.startWatch(teacher);

      const parents = this.state.profile.family.parents;
      if (parents) {
        for (var i = 0; i < parents.length; i++) {
          console.log(
            'Setting up communication with parent ' + parents[i].name
          );
          this.startWatch(parents[i]);
        }
      }
    }

    console.log(typeof this.channel);
    console.log('CREATE FINALLY CHANNEL: ' + this.channel);

    this.chatClient
      .queryChannels(this.state.filters, this.state.sort)
      .then(r => {
        console.log('CHANNELS: ' + r);
        this.setState({ channels: r });
        console.log('CREATE FINALLY STATE: ' + this.state.channels.length);
      });
  }

  startWatch(person) {
    this.channel = this.chatClient.channel(
      'messaging',
      person.userid + '-' + this.state.profile.userid,
      {
        name: this.state.profile.name + ' ' + person.name,
        members: [this.state.profile.userid, person.userid],
      }
    );

    console.log(typeof this.channel);
    console.log('CREATE CHANNEL: ' + this.channel);
    this.channel.watch().then(r => {
      console.log('WATCHING ' + r);
    });
  }

  changeChildView(childView) {
    let showChildAchievements = false;
    let showChildStatistics = false;
    let showChildSettings = false;
    if (childView === 'statistics') {
      showChildStatistics = true;
    } else if (childView === 'settings') {
      showChildSettings = true;
    } else {
      showChildAchievements = true;
    }
    this.setState({
      showChildAchievements: showChildAchievements,
      showChildStatistics: showChildStatistics,
      showChildSettings: showChildSettings,
    });
  }

  changeView(view, child) {
    let showProfile = false;
    let showChild = false;
    let showSettings = false;
    let showAbout = false;
    let showHelp = false;
    let showNotifications = false;
    if (view === 'settings') {
      showSettings = true;
      console.log('ParentProfile showSettings');
    } else if (view === 'child') {
      showChild = true;
      console.log('ParentProfile showChild');
    } else if (view === 'about') {
      showAbout = true;
      console.log('ParentProfile showAbout');
    } else if (view === 'help') {
      showHelp = true;
      console.log('ParentProfile showHelp');
    } else if (view === 'notifications') {
      showNotifications = true;
      console.log('ParentProfile showNotifications');
    } else {
      showProfile = true;
    }
    let birthday = new Date(0);
    let grade = 0;
    if (child && child.birthday) {
      birthday = new Date(child.birthday);
      grade = child.grade;
    }
    this.setState({
      page: view,
      showSettings: showSettings,
      showProfile: showProfile,
      showChild: showChild,
      child: child,
      dob: birthday,
      grade: grade,
      showAbout: showAbout,
      showHelp: showHelp,
      showNotifications,
      showNotifications,
    });
  }

  handleDateChange(date) {
    if (this.state.showChildSettings && this.state.child) {
      this.setState({
        dob: date,
      });
    }
  }

  handleGradeChange(event) {
    this.setState({
      grade: event.target.value,
    });
  }

  updateProfile() {
    let child = this.state.child;
    if (this.state.dob) {
      child.birthday = this.state.dob.getTime();
    }
    child.grade = this.state.grade;

    console.log('ParentProfile updateProfile: ' + JSON.stringify(child));
    console.log('ParentProfile token: ' + JSON.stringify(this.state.token));

    // TODO update profile on server
    axios
      .post(
        config.url.API_BASE_URL +
          '/api/profile/update/child/' +
          this.state.child.userid,
        child,
        { headers: { Authorization: this.state.token } }
      )
      .then(response => {
        this.setState({
          updateProfile: false,
          profile: response.data.object,
        });
        localStorage.setItem('profile', JSON.stringify(response.data));
      })
      .catch(error => console.log(error));
  }

  editChildSettings() {
    if (this.state.showChildSettings && this.state.child) {
      this.setState({
        editChildSettings: true,
      });
    }
  }

  toggleUpload() {
    this.setState({
      showUpload: !this.state.showUpload,
    });
  }

  render() {
    const { profile } = this.state;
    let filters = {
      type: 'messaging',
      members: { $in: [this.state.profile.userid] },
    };
    let sort = { last_message_at: -1 };

    // console.log('ParentProfile render profile: ' + JSON.stringify(profile));
    let children = null;
    if (profile.family && profile.family.children) {
      children = profile.family.children;
    }
    return (
      <div>
        <Header title="Profile" />
        <div class="profile-area">
          <div class="sk-container">
            <div class="profiles-main-warpp">
              <ProfileNavigation active={'profile'} />
              <div class="right-content-warp">
                <div class="right-content-inner">
                  <div class="help-content">
                    <div class="help-title">
                      <h4>Profile Information</h4>
                    </div>
                    <div class="profile-info">
                      <div class="pro-pic" onClick={() => this.toggleUpload()}>
                        <span>
                          <img src={getAvatarImage(getProfile())} alt="" />
                        </span>
                      </div>
                      <div class="pro-info">
                        <div class="single-pro-info">
                          <span>NAME</span>
                          <h4>{getName()}</h4>
                        </div>
                        <div class="single-pro-info">
                          <span>EMAIL</span>
                          <h4>{getEmail()}</h4>
                        </div>
                        {isTeacher() && (
                          <div class="single-pro-info">
                            <span>SCHOOL</span>
                            <h4>--</h4>
                          </div>
                        )}
                      </div>
                      {isTeacher() && (
                        <div class="pro-tags">
                          <span>GRADE</span>
                          <div class="pr-tag">
                            <button>GRADE {getGrade()}</button>
                            {/* <button>GRADE 2</button>
				                		<button>GRADE 3</button> */}
                          </div>
                        </div>
                      )}
                      {isTeacher() && (
                        <div class="pro-tags">
                          <span>SUBJECTS</span>
                          <div class="pr-tag">
                            <button>MATH</button>
                            <button>SCIENCE</button>
                          </div>
                        </div>
                      )}
                      {isParent() && (
                        <div class="pro-tags">
                          <span>CHILDREN</span>
                          <div class="pr-tag">
                            {getChildren().map(c => {
                              return <button key={c.userid}>{c.name}</button>;
                            })}
                            {/* <button>GRADE 2</button>
				                		<button>GRADE 3</button> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showUpload && <Upload toggleUpload={this.toggleUpload} />}

        <ShowProblem report={this.report} />
      </div>
    );
  }
}

// custom channel preview component
class MyChannelPreview extends React.Component {
  render() {
    const { setActiveChannel, channel } = this.props;
    const unreadCount = channel.countUnread();
    console.log(
      'MyChannelPreview channel.data: ' + JSON.stringify(channel.data)
    );
    console.log('MyChannelPreview setActiveChannel: ' + setActiveChannel);
    console.log('MyChannelPreview channel: ' + channel);
    return (
      <div className="channel_preview">
        <a href="#" onClick={e => setActiveChannel(channel, e)}>
          {channel.data.name}
        </a>

        <span>Unread messages: {unreadCount}</span>
      </div>
    );
  }
}

export default requireAuthentication(ParentProfile);
