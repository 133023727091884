import React from "react";
import PropTypes from "prop-types";

import "react-circular-progressbar/dist/styles.css";
import { getClassCount } from "../../utils/profile-utils";

class ClassChallengeQuestions extends React.Component {
  render() {
    const lessonReports = this.props.reports;
    console.log(
      "ClassChallengeQuestions  render lessonReports: " + lessonReports
    );
    return (
      <div class="challenge-questions-area">
        <div class="sk-container">
          <div class="clng-ques-warp">
            <div class="clng-ques-main-title">
              <h4>Challenge Questions</h4>
            </div>
            <div class="sub-title-ques">
              <div class="left-sub-title">
                <span>QUESTIONS {lessonReports.totalQuestions}</span>
              </div>
              <div class="right-sub-title">
                <span>CORRECT / ATTEMPTED</span>
              </div>
            </div>

            {lessonReports.questionReports.map((c, index) => (
              <QuestionReport
                report={c}
                index={index}
                classCount={this.props.classCount}
                lessonKey={lessonReports.lesson.lessonKey}
                lessonName={lessonReports.lesson.name}
                topicName={lessonReports.lesson.topicName}
                subject={lessonReports.lesson.subject}
              />
            ))}
          </div>
        </div>
      </div>
      // <div>
      //     <div className="gyan-recent">
      //         <div className="left questions-title">Questions {lessonReports.questionReports.length}</div>
      //         <div className="left questions-progress">Correct / Attempted</div>
      //         <div className="clear">
      //         {(lessonReports.questionReports).map(c =>
      //             <QuestionReport report={c}
      //             classCount={this.props.classCount}
      //             lessonKey={lessonReports.lesson.lessonKey}
      //             lessonName={lessonReports.lesson.name}
      //             subject={lessonReports.lesson.subject}/>)}
      //         </div>
      //     </div>
      // </div>
    );
  }
}

function QuestionReport(props) {
  const { index, report, lessonName, subject, topicName } = props;
  console.log("QuestionReport props: " + report);
  const percentage = {
    width: (report.attempts * 100) / getClassCount() + "%"
  };
  const subjectStyle = subject ? subject.toLowerCase() + "-indicator" : "";

  return (
    <div class="main-clng-ques">
      <div class="left-ques-text">
        <div class="left-comp-text">
          <h2>{index + 1}</h2>
          <div class="com-response-text">
            {/* <p>{report.question}</p> */}
            <p dangerouslySetInnerHTML={{ __html: report.question }} />
            <h5>
              <span class={"dot-complete " + subjectStyle} />
              {topicName} {/* > {lessonName} */}
            </h5>
          </div>
        </div>
      </div>
      <div class="right-ques-text">
        <p>{report.attempts + "/" + getClassCount()}</p>
        <div class="ques-progress">
          <span style={percentage} />
        </div>
      </div>
    </div>
  );
}

ClassChallengeQuestions.propTypes = {
  reports: PropTypes.object.isRequired
};

export default ClassChallengeQuestions;
