import React, { useState } from "react";

import axios from "axios";

import { config } from "../../Constants";

import "react-datepicker/dist/react-datepicker.css";

import LessonPreview from "../Parent/LessonPreview";
import { findLesson } from "../../utils/lesson-utils";
import LessonCard from "./LessonCard";
import { getImage } from "../../utils/image-utils";
import { getHomeworkReport, getFamilyReport, clearFamilyReport } from "../../services/ParentService";
import { isAdmin, isParent, getToken } from "../../utils/profile-utils";
import PickDate from "./PickChallengeDate";
import PickChallengeDate from "./PickChallengeDate";
import ConfirmAssignement from "./ConfirmAssignement";
import ConnectionErrorHandler from "../Student/ErrorHandler/ConnectionErrorHandler";

const StepLesson = ({ lessons, topic, assignees, previousStep }) => {
  const [isPreview, setPreview] = useState(false);
  const [isAssign, setAssign] = useState(false);
  const [isConfirmAssign, setConfirmAssign] = useState(false);
  const [lessonIdValue, setLessonIdValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [assigneeNameValue, setAssigneeNameValue] = useState(null);

  const createChallenge = (assigneeNameValue, dueDateValue) => {
    setAssigneeNameValue(assigneeNameValue);
    console.log("Creating challenge for " + assigneeNameValue);
    console.log("with lesson " + lessonIdValue);
    console.log("and topic " + topic.id);
    console.log("Due date " + dueDateValue);

    let assignPath = "";
    if (isParent()) {
      assignPath = "/api/v2/parent/assign/" + assigneeNameValue + "/";
    }
    const payload = {
      userid: assigneeNameValue,
      lessonId: lessonIdValue,
      topicId: topic.id,
      dueDate: dueDateValue.getTime()
    }
    // console.log("Assign request will be sent to " + assignPath);
    // console.log("Assign request payload " + JSON.stringify(payload));

    axios
      .post(config.url.API_BASE_URL + assignPath,
        payload,
        {
          headers: { Authorization: getToken() },
        })
      .then((response) => {
        console.log(response);
        setAssign(false);
        setConfirmAssign(true);
        // force reload family report
        clearFamilyReport();
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("(" + error.message + ")");
      });
  };

  return (
    <main class="steps-container py-4">
      {isPreview && (
        <LessonPreview
          topicId={topic.id}
          lessonId={lessonIdValue}
          closePreview={() => setPreview(false)}
        />
      )}

      {isAssign && (
        <PickChallengeDate
          assignees={assignees}
          finalize={createChallenge}
          dismiss={() => setAssign(false)}
        />
      )}
      {errorMessage && (
        <ConnectionErrorHandler
          onClose={() => setErrorMessage(null)}
          onRetry={() => setErrorMessage(null)}
          errorMessage={errorMessage}
          errorTitle="Lesson Is Locked"
        />
      )}
      {isConfirmAssign && <ConfirmAssignement assignees={assigneeNameValue} />}
      <div class="container container-minify">
        <div class="card border-0 card-shadow">
          <div class="card-body">
            <div class="steps_card-header pt-2 text-center">
              <h2>Select Lesson</h2>
            </div>
            <div class="pt-1 pb-3">
              <div class="steps_card_topics mb-4">

                <div class="topics-available pt-3 pb-4 px-3">
                  <h3 class="mb-0">Select from available lessons</h3>

                  <div class="row">
                    {lessons &&
                      lessons
                        .filter(
                          (l) => l && l.lessonMetadata // &&
                          // (l.lessonMetadata.availableForHomework || l.report)
                        )
                        .map((l) => {
                          // l.lessonMetadata = l;
                          const homework = getHomeworkReport(
                            assignees,
                            l.lessonMetadata.id
                          );
                          // console.log("Lesson: " + l)
                          console.log(lessonIdValue + " Lesson: " + JSON.stringify(l.lessonMetadata));
                          console.log("Homework: " + JSON.stringify(homework))
                          return (
                            <LessonCard
                              topic={topic}
                              active={
                                lessonIdValue === l.lessonMetadata.id
                              }
                              lesson={l}
                              homework={homework}
                              pickLesson={() =>
                                setLessonIdValue(l.lessonMetadata.id)
                              }
                            />
                          );
                        })}
                  </div>
                </div>
              </div>
              {/* <div class="footer-btns d-flex steps-btns align-items-center">
                <button
                  onClick={() => previousStep(-1)}
                  class="btn btn-lg text-uppercase btn-noshadow btn-default"
                >
                  back
                </button>
                <span onClick={() => setPreview(true)}>
                  <a
                    href="#"
                    className={
                      "btn btn-lg mr-3 ml-sm-auto " +
                      (lessonIdValue ? "btn-default" : "disabled")
                    }
                  >
                    <img src={getImage("np_play.svg")} class="icon-left" />{" "}
                    PREVIEW
                  </a>
                </span>
                <a
                  href="#"
                  onClick={() => setAssign(true)}
                  className={
                    "btn btn-lg text-uppercase ml-auto " +
                    (lessonIdValue ? "btn-primary" : "disabled")
                  }
                >
                  ASSIGN challenge{" "}
                </a>
              </div> */}
              <div class="d-flex sticky-bottom bg-white flex-wrap steps-btns align-items-center">
                <button onClick={() => previousStep(-1)} class="btn btn-lg mr-3 text-uppercase btn-noshadow btn-default">back</button>
                <a href="#" onClick={() => setPreview(true)}
                  className={"btn btn-lg my-2 mr-3 ml-sm-auto " + (lessonIdValue ? "btn-default" : "disabled")}>
                  <img src={getImage("np_play.svg")} class="icon-left" /> PREVIEW</a>
                <a href="#" onClick={() => setAssign(true)} className={"btn btn-lg my-2 " +
                  (lessonIdValue ? "btn-primary" : "disabled")} data-toggle="modal" data-target="#assignChallengeNext">ASSIGN challenge </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(isPreview || isAssign) && <div class="modal-backdrop fade show"></div>}
    </main>
  );
};

export default StepLesson;
