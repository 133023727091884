import React from 'react';
import PropTypes from 'prop-types';

import { getAvatarImage } from '../../utils/image-utils';

function Parents(props) {
  return (
    <div className="scrolling-wrapper">
      {props.parents.map((c, index) => (
        <Parent key={c.userid} parent={c} gender={c.gender} />
      ))}
    </div>
  );
}

Parents.propTypes = {
  parents: PropTypes.array.isRequired,
};

export default Parents;

function Parent(props) {
  console.log(props);

  let image = getAvatarImage(props.parent);
  let role = 'Mother';
  if (props.gender === 'male') {
    role = 'Father';
  }

  return (
    <div className="family-details">
      <img src={image} alt="Avatar" />
      <div className="family-details">
        <div className="header">{role}</div>
        <div className="header-value">{props.parent.name}</div>
      </div>
    </div>
  );
}

Parent.propTypes = {
  parent: PropTypes.object.isRequired,
  gender: PropTypes.string.isRequired,
};
