import React, { useEffect, useState, useRef } from 'react';

import '../Notifications/NotificationsWidget.css';

import { getImage } from '../../../utils/image-utils';
import { getMuted, setMuted } from '../../../utils/speech-utils';
import ShowProblem from '../LessonContent/ShowProblem';
import { getServerVersion } from '../../../utils/profile-utils';
import Toast from '../Toast/Toast';

const SettingsWidget = ({ needsUpdate, onClose }) => {
  const node = useRef();
  const [isMuted, mute] = useState(getMuted());

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click do nothing
      return;
    }
    // outside click close card
    onClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const stopTalking = () => {
    // add setting to local storage
    setMuted(!isMuted);
    mute(!isMuted);
  };

  const refresh = () => {
    setTimeout(function() {
      window.location.reload(true);
    }, 100);
  };

  const version = process.env.REACT_APP_GIT_SHA;
  const showVersionToast = version !== getServerVersion() ? true : false;

  return (
    <div id="overlay-container">
      {showVersionToast && (
        <Toast
          text={
            'Please refresh your app. Latest app version is ' +
            getServerVersion()
          }
        />
      )}
      <div className="settings-wrapper">
        <div ref={node} className="settings-box">
          <a href="#" onClick={() => onClose()}>
            <img className="close" src={getImage('Group-2208.png')} />
          </a>
          <h1>Settings</h1>
          <div className="settings-box-options notification-box-options">
            <ul>
              <li className="sound">
                <div>
                  <p className="left">Sound Effects</p>
                  {isMuted && (
                    <img
                      className="right stopTalking"
                      src={getImage('off-button.png')}
                      onClick={() => stopTalking()}
                    />
                  )}
                  {!isMuted && (
                    <img
                      className="right"
                      src={getImage('on-button.png')}
                      onClick={() => stopTalking()}
                    />
                  )}
                </div>
              </li>
              <li className="clear update">
                <div>
                  <p className="left">Version</p>
                  {needsUpdate && (
                    <img
                      className="right"
                      src={getImage('update-button.png')}
                      onClick={() => refresh()}
                    />
                  )}
                  {needsUpdate && (
                    <p className="clear left sub-text app-version">
                      Latest version {getServerVersion()}
                    </p>
                  )}
                  {!needsUpdate && (
                    <img
                      className="right spacer"
                      style={{ opacity: 0.1 }}
                      src={getImage('pixel.png')}
                      onClick={() => refresh()}
                    />
                  )}
                  {!needsUpdate && (
                    <span className="clear left sub-text app-version">
                      Latest
                    </span>
                  )}
                </div>
              </li>
            </ul>
          </div>
          <span className="app-version">
            Version {process.env.REACT_APP_GIT_SHA}
          </span>
          <ShowProblem report={{ pageName: 'settings' }} />
        </div>
      </div>
    </div>
  );
};

export default SettingsWidget;
