import React from "react";
import uniqueId from "react-html-id";

import axios from "axios";
import LoadingBar from "react-top-loading-bar";

import { config } from "../../Constants";
import AdminHeader from "../../components/Admin/AdminHeader";
import { getToken } from "../../utils/profile-utils";
import { loadSubjectsByGrade } from "../../utils/student-utils";
import { displaySubject } from "../../utils/display-utils";
import ShowProblem from "../../components/Student/LessonContent/ShowProblem";
import { NIL } from "uuid";

class AdminAssignLesson extends React.Component {
  state = {
    token: null,
    catalog: null,
    showCatalog: false,
    standards: null,
    standard: null,
    subjects: null,
    topicKey: null,
    lessonKey: null,
    loading: false,
    child: null
  };

  constructor(props) {
    super(props);
    this.state.child = props.match.params.child;
    this.state.grade = props.match.params.grade; 
    this.state.standard = props.match.params.standard;
    this.state.subject = props.match.params.subject;
    this.state.topicKey = props.match.params.topic;

    this.state.pageUrl = "/admin/assign/" + props.match.params.child;

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    console.log("AdminCatalog: componentDidMount");
    this.startLoadingData();
  }

  componentWillUpdate() {
    console.log("AdminCatalog: componentWillUpdate");
  }

  startLoadingData = () => {
    console.log("AdminCatalog::startLoadingData");
    const { grade, subject, subjects, catalog, loading } = this.state;
    if (!loading && subject && !catalog) {
      console.log("AdminCatalog::startLoadingData Loading catalog " + subject);
      this.loadCatalog();
    }
    if (!loading && grade && !subjects) {
      console.log("AdminCatalog::startLoadingData Loading subjects " + grade);
      this.LoadingBar.add(10);
      const errorFunction = function(error) {
        console.log(error);
        this.LoadingBar.complete();
      }.bind(this);
      this.setState(
        {
          loading: true
        },
        () => loadSubjectsByGrade(grade, this.loadSubjects, errorFunction)
      );
    }
  }

  loadCatalog = () => {
    if (!this.state.loading) {
      console.log("AdminCatalog::loadCatalog");
      this.LoadingBar.add(10);
      this.setState({
        loading: true
      })
      const { grade, subject, topicKey, showCatalog } = this.state;
      if (!showCatalog) {
        this.LoadingBar.add(10);
        axios
          .get(
            config.url.API_BASE_URL +
              "/api/lesson/topics/grade/" +
              grade +
              "/subject/" +
              subject,
            { headers: { Authorization: getToken() } }
          )
          .then(response => {
            this.LoadingBar.add(50);
            console.log(response);
            
            const catalog = response.data.object.data.topic;
            let topicDetails = NIL;
            const syllabusId = catalog.id;
            if (topicKey && catalog) {
              topicDetails = catalog.topics.filter(c => c.id === topicKey);
              if (topicDetails && topicDetails.length > 0) {
                topicDetails = topicDetails[0];
              }
            }

            this.setState({
              catalog: catalog,
              syllabusId: syllabusId,
              topicDetails: topicDetails,
              loading: false
            });
            this.LoadingBar.complete();
          })
          .catch(error => {console.log(error)
            this.LoadingBar.complete();
          });
        this.LoadingBar.add(30);
      } else {
        console.log("Catalog will not load.......");
      }
    }
  }

  reloadCatalog = () => {
    console.log("AdminCatalog::reloadCatalog");
    this.setState(
      {
        catalog: null,
        showCatalog: false
      },
      () => this.loadCatalog()
    );
  }

  loadSubjects = (data) => {
    console.log("AdminCatalog::loadSubjects");
    console.log("ChooseSubject: " + data);

    if (data) {
      const {
        standard,
      } = this.state;
      const standards = Array.from(new Set(data.map(s => s.standard)));
      let subjects = NIL;
      if (standard) {
        subjects = Array.from(new Set(data.filter(s => s.standard === standard).map(s => {
          return {
            syllabusId: s.id,
            subject: s.subject
          }
        })));
        console.log("loadSubjects: " + subjects);
      }
      this.LoadingBar.add(50);
      this.setState({
        standards: standards,
        subjects: subjects,
        loading: false
      });
      this.LoadingBar.complete();
      console.log("ChooseSubject: filtered standards => " + this.state.standards);
      console.log("ChooseSubject: filtered subjects => " + subjects);
    }
  }

  handleGradeChange = (event) => {
    console.log("AdminCatalog::handleGradeChange");
    this.setState(
      {
        grade: event.target.value,
        catalog: null,
        standards: null,
        subjects: null,
        topicKey: null,
        showCatalog: false,
        loading: false
      },
      () => {
          this.props.history.push(this.state.pageUrl + "/" + this.state.grade);
          this.startLoadingData();
      }
    );
    // this.props.history.push(this.state.pageUrl + "/" + event.target.value);
  }

  handleStandardChange = (event) => {
    console.log("AdminCatalog::handleGradeChange");
    this.setState(
      {
        standard: event.target.value,
        catalog: null,
        subjects: null,
        topicKey: null,
        showCatalog: false,
        loading: false
      },
      () => {
        this.props.history.push(this.state.pageUrl + "/" + this.state.grade + "/" + this.state.standard);
        this.startLoadingData();
      }
    );
    // this.props.history.push(this.state.pageUrl + "/" + event.target.value);
  }

  handleSubjectChange = (event) => {
    console.log("AdminCatalog::handleSubjectChange");

    const syllabusId = event.target.value;
    const subject = this.state.subjects.filter(s => s.syllabusId === syllabusId)[0];
    this.setState(
      {
        syllabusId: syllabusId,
        subject: subject.subject,
        topicKey: null,
        catalog: null,
        showCatalog: false,
        loading: false
      },
      () => {
        this.props.history.push(
          this.state.pageUrl + "/" + this.state.grade + "/" + this.state.standard + "/" + this.state.subject 
        );
        this.startLoadingData();
      }
    );
  }

  handleTopicChange = (event) => {
    console.log("AdminCatalog::handleTopicChange ");
    const { catalog } = this.state;
    const topicId = event.target.value;
    let topicDetails = NIL;
    if (topicId && catalog) {
      topicDetails = catalog.topics.filter(c => c.id === topicId);
      if (topicDetails && topicDetails.length > 0) {
        topicDetails = topicDetails[0];
      }
    }

    this.setState(
      {
        topic: event.target.value,
        topicDetails: topicDetails,
        showCatalog: true
      },
      () =>
        this.props.history.push(
          this.state.pageUrl + "/" + 
          this.state.grade + "/" + this.state.standard +
            "/" +
            this.state.subject +
            "/" +
            this.state.topic
        )
    );
  }

  handleLessonChange = (event) => {
    console.log("AdminCatalog::handleLessonChange ");
    this.setState(
      {
        lessonKey: event.target.value,
        showCatalog: true
      }
    );
  }

  createChallenge = () => {
    const { lessonKey, topicKey, syllabusId, child } = this.state;

    var dueDateValue = new Date();
    var numberOfDaysToAdd = 7;
    dueDateValue.setDate(dueDateValue.getDate() + numberOfDaysToAdd);

    console.log("Creating challenge for " + child);
    console.log("with lesson " + lessonKey);
    console.log("and topic " + topicKey);
    console.log("Due date " + dueDateValue);

    let assignPath = "/api/v2/admin/homework/assign/" + child + "/";
    const payload = {
      userid: child,
      lessonId: lessonKey,
      topicId: topicKey,
      syllabusId: syllabusId
    }
    // console.log("Assign request will be sent to " + assignPath);
    // console.log("Assign request payload " + JSON.stringify(payload));

    axios
      .post(config.url.API_BASE_URL + assignPath,
        payload,
        {
          headers: { Authorization: getToken() },
        })
      .then((response) => {
        console.log(response);
        // window.location.reload();
        // this.props.history.push('/admin/homework/' + child);
      })
      .catch((error) => {
        console.log(error);
        // window.location.reload();
      });
  };


  render() {
    const {
      grade,
      standard,
      standards,
      subject,
      topicDetails,
      child,
      topicKey,
      catalog,
      subjects,
      lessonKey
    } = this.state;
    let name = "";
    let c = null;
    // console.log("Picked Topic " + JSON.stringify(topicDetails));
    console.log("Picked Topic standards" + JSON.stringify(topicDetails));
    return (
      <div className="sk-container admin-page" style={{ margin: 60 + "px" }}>
        <LoadingBar
          height={3}
          color="#f11946"
          onRef={ref => (this.LoadingBar = ref)}
        />
        <AdminHeader />
        <br />
        <h1 className="clear">Assign {child} some homework</h1>
        <div>
          <div className="step-1">
            <div className="title">Select a Grade</div>
            {this.state.child && (<div>
              <select value={grade} onChange={this.handleGradeChange}>
                <option>Pick Grade</option>
                <option value="1">Grade 1</option>
                <option value="2">Grade 2</option>
                <option value="3">Grade 3</option>
                <option value="4">Grade 4</option>
                <option value="5">Grade 5</option>
                <option value="6">Grade 6</option>
                <option value="7">Grade 7</option>
                <option value="8">Grade 8</option>
              </select>
            </div>)}
            {this.state.grade && standards && (
              <div>
                <div className="title">Select Standard</div>
                {standards && (
                  <select value={standard} onChange={this.handleStandardChange}>
                    <option> Pick a Standard</option>
                    {standards.map(c => (
                      <option value={c}>{displaySubject(c)}</option>
                    ))}
                  </select>
                )}
              </div>
            )}
            {this.state.standard && this.state.grade && subjects && (
              <div>
                <div className="title">Select Subject</div>
                {subjects && (
                  <select value={subject} onChange={this.handleSubjectChange}>
                    <option> Pick a Subject</option>
                    {subjects.map(c => (
                      <option value={c.syllabusId}>{displaySubject(c.subject)}</option>
                    ))}
                  </select>
                )}
              </div>
            )}
            {subject && catalog && (
              <div>
                <div className="title">Select Topic</div>
                <select value={topicKey} onChange={this.handleTopicChange}>
                  <option> Pick a Topic</option>
                  {catalog.topics.map(t => (
                    <option value={t.id}>
                      {t.name ? t.name : t.shortName}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
        {subject && catalog && topicDetails && (
          <div>
            <div className="title">Select Topic</div>
            <select value={lessonKey} onChange={this.handleLessonChange}>
              <option> Pick a Lesson</option>
              {topicDetails.lessonList.filter(l => l !== null).map(l => (
                    <option value={l.id}>
                      {l.name ? l.name : l.shortName}
                    </option>
                  ))}
            </select>
            {/* {JSON.stringify(topicDetails.lessonList)} */}
          </div>
        )}
        {child && topicKey && lessonKey && <div>
          <button
            data-toggle="modal"
            data-target="#assignChallengeCreate"
            class="btn btn-lg mx-1 my-2 px-sm-5 px-4 btn-primary"
            onClick={() => this.createChallenge()} style={{ width: "140px" }}
          >
            Assign Challenge
                      </button>
          </div>}
        <ShowProblem
          report={{
            pageName: "Admin Catalog " + name
          }}
        />
      </div>
    );
  }
}

export default AdminAssignLesson;
