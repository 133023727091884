import React from "react";
import { config } from "../Constants";
import { getUserid } from "../utils/profile-utils";
import { getCall } from "../utils/rest-utils";
import { mapSubject, displayLessonName } from "../utils/display-utils";
import { getProgressImage, loadLessonCards } from "../utils/student-utils";

let _dueHomework = null;
let _homeworkHistory = {};

export function getDueHomework(force, callback, errorCallback) {
  if (_dueHomework && !force) {
    console.log("Return cached homework.....");
    callback(_dueHomework);
  } else {
    const boundFunction = function(response) {
      if (callback) {
        loadDueHomework(response.data.object.data);
        callback(_dueHomework);
      }
    };
    getCall(
      config.url.API_BASE_URL +
        "/api/v2/student/homework/pending/" +
        getUserid(),
      boundFunction
    );
  }
}

export function getHomeworkHistory(force, subject, callback, errorCallback) {
  if (_homeworkHistory[subject] && !force) {
    console.log("Return cached history..... " + subject);
    callback(subject, _homeworkHistory[subject]);
  } else {
    const boundFunction = function(response) {
      if (callback) {
        loadHomeworkHistory(subject, response.data.object.data);
        callback(response.data.object.data, _homeworkHistory[subject]);
      }
    };
    getCall(
      config.url.API_BASE_URL +
        "/api/v2/student/homework/" +
        getUserid() +
        "/subject/" +
        subject,
      boundFunction,
      errorCallback
    );
  }
}

export function refreshHomework() {
    _dueHomework = null;
}

function loadDueHomework(homework) {
    _dueHomework = loadHomework(homework);
}

function loadHomeworkHistory(subject, homework) {
    _homeworkHistory[subject] = loadHomework(homework);
}

function loadHomework(lessons) {
  const newCards = [];
  if (lessons && lessons.length > 0) {
    lessons.forEach(c => {
      if (!c.lessonMetadata) {
        return null;
      }
      // console.log('Today: ' + new Date().getTime() + ' assigned ' + c.assignDate);

      const millisecondsInADay = 24 * 60 * 60 * 1000;
      const assignDate = c.assignDate;
      // Adding 10 days to get due date
      const dueDate =
        c.dueDate > 0 ? c.dueDate : assignDate + 10 * millisecondsInADay;
      const pastDue = (new Date().getTime() - dueDate) / millisecondsInADay > 0;

      // if (pastDue) {
      //   console.log(c.lesson.name + ' is past due...' + (new Date().getTime() - dueDate) / millisecondsInADay);
      // }

      // console.log("LESSON lesson: " + JSON.stringify(c));
      // console.log('LESSON lessonId: ' + c.lessonId);
      // console.log('LESSON shortName: ' + c.lesson.shortName);
      // const type = c.lessonType ? c.lessonType.replace("_", "-").toLowerCase() : "";
      let lessonReport = c;
      let displayReport = {};
      let completed = 0;

      displayReport = aggregateDisplayReport(c, c);
      completed =
        (lessonReport.attemptedQuestions * 100) / lessonReport.totalQuestions;

      // console.log("SubjectCard lessonReport: " + JSON.stringify(lessonReport));
      // console.log("loadLessonCards > displayReport: " + JSON.stringify(displayReport));
      let percentage = {};
      if (displayReport) {
        if (displayReport.showIncomplete) {
          percentage = {
            width:
              (lessonReport.attemptedQuestions * 100) /
                lessonReport.totalQuestions +
              "%",
            height: "100%"
          };
        } else if (displayReport.showAccuracy) {
          percentage = {
            width:
              (displayReport.correctAnswers * 100) /
                lessonReport.totalQuestions +
              "%",
            height: "100%"
          };
        } else {
          displayReport = { mustStart: true };
        }
      }
      // console.log("loadLessonCards > displayReport: " + JSON.stringify(displayReport));
      console.log(
        "loadLessonCards > lessonMetadata: " + JSON.stringify(c.lessonMetadata)
      );

      newCards.push({
        id: c.id,
        ref: React.createRef(),
        subject:
          c.lessonMetadata && c.lessonMetadata.subject
            ? mapSubject(c.lessonMetadata.subject)
            : "",
        lessonId: c.lessonId,
        name: displayLessonName(c.lessonMetadata),
        // type: type,
        points:
          c.lessonMetadata && c.lessonMetadata.points
            ? c.lessonMetadata.points
            : 100,
        lessonReport: c,
        displayReport: displayReport,
        progressImage: getProgressImage(completed),
        percentage: percentage,
        assignDate: assignDate,
        dueDate: dueDate,
        pastDue: pastDue
      });
    });
  }

  return newCards;
}

export function aggregateDisplayReport(lesson) {
  const lessonReport = lesson;
  console.log("aggregate: " + JSON.stringify(lessonReport));
  let displayReport = {
    mustStart: false,
    showIncomplete: false,
    showAccuracy: false,
    score: 0,
    correctAnswers: 0,
    starImage: "saga-map-01.png"
  };

  if (lessonReport) {
    const { totalQuestions, attemptedQuestions, correctAnswers } = lessonReport;
    // if (lesson.reportedLessonProgress) {
    //   attemptedQuestions =
    //     attemptedQuestions > lesson.reportedLessonProgress
    //       ? attemptedQuestions
    //       : lesson.reportedLessonProgress;
    // }
    // TODO show class motivator status on pending status
    if (attemptedQuestions === 0) {
      displayReport.mustStart = true;
    } else if (attemptedQuestions < totalQuestions) {
      displayReport.showIncomplete = true;
      displayReport.correctAnswers = correctAnswers;
    } else {
      displayReport.showAccuracy = true;
      // Tutorials should be scored at 100% if all slided have been reviewed
      // TODO move this logic to backend

      displayReport.score = lessonReport.accuracy;
      displayReport.correctAnswers = 0;
      if (correctAnswers) {
        displayReport.correctAnswers = correctAnswers;
      } else if (lessonReport.questionActivity) {
        displayReport.correctAnswers = lessonReport.questionActivity.filter(
          q => q.correct === true
        ).length;
      }

      if (displayReport.correctAnswers) {
        displayReport.starImage = starImage(
          displayReport.correctAnswers,
          totalQuestions,
          true
        );
      }
    }
    // console.log('aggregated totalQuestions: ' + totalQuestions);
  } else {
    displayReport.mustStart = true;
  }
  // console.log('aggregated displayReport: ' + JSON.stringify(displayReport));
  return displayReport;
}

function starImage(correctAnswers, totalQuestions, saga) {
  let startDigit = Math.round((correctAnswers * 7) / totalQuestions);
  // console.log('Star digit: ' + startDigit);
  if (saga) {
    return "saga-map-0" + startDigit + ".png";
  } else {
    return "challenge-star-0" + startDigit + ".png";
  }
}
