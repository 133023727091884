import React, { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { getProfile, getToken, getSubjects } from "../../utils/profile-utils";
import { displaySubject } from "../../utils/display-utils";
import { getImage } from "../../utils/image-utils";
import { SUBJECT_STYLES } from "../../Constants";

const StepSubject = ({ grade, subject, nextStep }) => {
  const [showNext, setShowNext] = useState(false);
  const [gradeValue, setGradeValue] = useState(grade);
  const [subjectValue, setSubjectValue] = useState(subject);

  const handleGradeChange = (event) => {
    const g = event.target.value;
    setGradeValue(g);
    setShowNext(g && g > 0 && subjectValue && subjectValue.length > 0);
    console.log("handleGradeChange for next grade ? " + g);
    console.log("handleGradeChange for next subject ? " + subjectValue);
  };

  const handleSubjectChange = (s) => {
    // console.log("Change subject to ? " + s);
    setSubjectValue(s);
    setShowNext(gradeValue && gradeValue > 0 && s && s.length > 0);
    // console.log("handleSubjectChange for next grade ? " + gradeValue);
    // console.log("handleSubjectChange for next subject ? " + s);
  };

  return (

    <main class="steps-container py-4">
      <div class="container container-minify">
        <div class="card border-0 card-shadow">
          <div class="card-body">
            <div class="steps_card-header text-center">
              <h2>Select Subject</h2>
              <p>Your are creating challenge for:</p>
              <div class="mt-3">
                {/* <select class="btn text-left border-0 btn-lg btn-secondary"> */}
                <select
                  class="btn text-left border-0 btn-lg btn-secondary"
                  value={gradeValue ? gradeValue : grade}
                  onChange={handleGradeChange}
                >
                  {/* <option value="1">GRADE 1</option> */}
                  <option value="2">GRADE 2</option>
                  <option value="3">GRADE 3</option>
                  <option value="4">GRADE 4</option>
                  <option value="5">GRADE 5</option>
                  <option value="6">GRADE 6</option>
                  <option value="7">GRADE 7</option>
                  <option value="8">GRADE 8</option>
                </select>
              </div>
            </div>

            <div class="steps_card_area pt-4 pb-3">
              <div class="steps_card_area_inner">
                <div class="row mb-5">

                  <div class="col-sm-4">
                    <div
                      class="sk-choose-item"
                      onClick={() => handleSubjectChange("COMMON_CORE_MATH")}
                    >
                      <div
                        class="choose-item-image"
                        style={{
                          backgroundColor: SUBJECT_STYLES.MATH.backgroundColor,
                        }}
                      >
                        <img
                          src={getImage("subject-background.svg")}
                          class="img-fluid"
                          alt="math"
                        />
                        <img
                          src={getImage(
                            !subjectValue || subjectValue !== "COMMON_CORE_MATH"
                              ? SUBJECT_STYLES.MATH.icon
                              : "np_tick_lg.svg"
                          )}
                          class="default-icon choose-icon"
                          alt="icon"
                        />
                      </div>
                      <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
                        Common core math
                    </div>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div
                      class="sk-choose-item"
                      onClick={() => handleSubjectChange("SCIENCE")}
                    >
                      <div
                        class="choose-item-image"
                        style={{
                          backgroundColor: SUBJECT_STYLES.SCIENCE.backgroundColor,
                        }}
                      >
                        <img
                          src={getImage("subject-background.svg")}
                          class="img-fluid"
                          alt="science"
                        />
                        <img
                          src={getImage(
                            !subjectValue || subjectValue !== "SCIENCE"
                              ? SUBJECT_STYLES.SCIENCE.icon
                              : "np_tick_lg.svg"
                          )}
                          class="default-icon choose-icon"
                          alt="icon"
                        />
                      </div>
                      <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
                        Science
                    </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div
                      class="sk-choose-item"
                      onClick={() => handleSubjectChange("SOCIAL_STUDIES")}
                    >
                      <div
                        class="choose-item-image"
                        style={{
                          backgroundColor:
                            SUBJECT_STYLES.SOCIAL_STUDIES.backgroundColor,
                        }}
                      >
                        <img
                          src={getImage("subject-background.svg")}
                          class="img-fluid"
                          alt="image"
                        />
                        <img
                          src={getImage(
                            !subjectValue || subjectValue !== "SOCIAL_STUDIES"
                              ? SUBJECT_STYLES.SOCIAL_STUDIES.icon
                              : "np_tick_lg.svg"
                          )}
                          class="default-icon choose-icon"
                          alt="icon"
                        />
                      </div>
                      <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
                        Social Studies
                    </div>
                    </div>
                  </div>
                </div>
                {/* <div class="row mb-5">
                  <div class="col-sm-4">
                    <div class="sk-choose-item" onClick={() => handleSubjectChange("SOCIAL_STUDIES")}>
                      <div class="choose-item-image"
                        style={{
                          backgroundColor: SUBJECT_STYLES.MATH.backgroundColor,
                        }}>
                        <img src={getImage("subject-background.svg")} class="img-fluid" alt="bubbles" />
                        <img src={getImage("np_math.svg")} class="default-icon choose-icon" alt="icon" />
                        <img src={getImage("np_tick_lg.svg")} class="active-icon choose-icon" alt="icon" />
                      </div>
                      <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
                        maths
												</div>
                    </div>
                  </div>
                </div> */}

                {(gradeValue + "" === "3" || gradeValue + "" === "4") && (<div class="row mb-5">
                  <div class="col-sm-12">
                    <div
                      class="sk-choose-item"
                      onClick={() => handleSubjectChange("MATH")}
                    >
                      <div
                        class="choose-item-image"
                        style={{
                          backgroundColor: SUBJECT_STYLES.MATH.backgroundColor,
                        }}
                      >
                        <img
                          src={getImage("subject-background.svg")}
                          class="img-fluid"
                          alt="math"
                        />
                        <img
                          src={getImage(
                            !subjectValue || subjectValue !== "MATH"
                              ? SUBJECT_STYLES.MATH.icon
                              : "np_tick_lg.svg"
                          )}
                          class="default-icon choose-icon"
                          alt="icon"
                        />
                      </div>
                      <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
                        math
                      </div>
                    </div>
                  </div>
                </div>)}

                {gradeValue + "" === "5" && (<div class="row mb-5">
                  <div class="col-sm-6">
                    <div
                      class="sk-choose-item"
                      onClick={() => handleSubjectChange("MATH")}
                    >
                      <div
                        class="choose-item-image"
                        style={{
                          backgroundColor: SUBJECT_STYLES.MATH.backgroundColor,
                        }}
                      >
                        <img
                          src={getImage("subject-background.svg")}
                          class="img-fluid"
                          alt="math"
                        />
                        <img
                          src={getImage(
                            !subjectValue || subjectValue !== "MATH"
                              ? SUBJECT_STYLES.MATH.icon
                              : "np_tick_lg.svg"
                          )}
                          class="default-icon choose-icon"
                          alt="icon"
                        />
                      </div>
                      <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
                        math
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div
                      class="sk-choose-item"
                      onClick={() => handleSubjectChange("SCIENCE_REVIEW")}
                    >
                      <div
                        class="choose-item-image"
                        style={{
                          backgroundColor:
                            SUBJECT_STYLES.SCIENCE.backgroundColor,
                        }}
                      >
                        <img
                          src={getImage("subject-background.svg")}
                          class="img-fluid"
                          alt="math"
                        />
                        <img
                          src={getImage(
                            !subjectValue || subjectValue !== "SCIENCE_REVIEW"
                              ? SUBJECT_STYLES.SCIENCE.icon
                              : "np_tick_lg.svg"
                          )}
                          class="default-icon choose-icon"
                          alt="icon"
                        />
                      </div>
                      <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
                        Science Review
                      </div>
                    </div>
                  </div>
                </div>)}
              </div>

              <div class="d-flex sticky-bottom bg-white py-2 steps-btns align-items-center">
                <button
                  onClick={() => showNext ? nextStep(1, gradeValue, subjectValue) : null}
                  className={
                    "btn btn-lg text-uppercase ml-auto " +
                    (showNext ? "btn-primary" : "disabled")
                  }
                >
                  next</button>
              </div>
            </div>
            {/* <!-- /.steps_card_area --> */}
          </div>
          {/* <!-- /.card-body --> */}
        </div>
        {/* <!-- /.card --> */}
      </div>
      {/* <!-- /.container --> */}
    </main>
    // <main class="steps-container py-4">
    //   <div class="container container-minify">
    //     <div class="card border-0 card-shadow">
    //       <div class="card-body">
    //         <div class="steps_card-header text-center">
    //           <h2>Select Subject</h2>
    //           <p>Your are creating challenge for:</p>
    //           <div class="mt-3">
    //             <select
    //               class="btn text-left border-0 btn-lg btn-secondary"
    //               value={gradeValue ? gradeValue : grade}
    //               onChange={handleGradeChange}
    //             >
    //               {/* <option value="1">GRADE 1</option> */}
    //               <option value="2">GRADE 2</option>
    //               <option value="3">GRADE 3</option>
    //               <option value="4">GRADE 4</option>
    //               <option value="5">GRADE 5</option>
    //               <option value="6">GRADE 6</option>
    //               <option value="7">GRADE 7</option>
    //               <option value="8">GRADE 8</option>
    //             </select>
    //           </div>
    //         </div>
    //         <div class="steps_card_area pt-4 pb-3">
    //           <div class="row mb-5">

    //             <div class="col-sm-4">
    //               <div
    //                 class="sk-choose-item"
    //                 onClick={() => handleSubjectChange("COMMON_CORE_MATH")}
    //               >
    //                 <div
    //                   class="choose-item-image"
    //                   style={{
    //                     backgroundColor: SUBJECT_STYLES.MATH.backgroundColor,
    //                   }}
    //                 >
    //                   <img
    //                     src={getImage("subject-background.svg")}
    //                     class="img-fluid"
    //                     alt="math"
    //                   />
    //                   <img
    //                     src={getImage(
    //                       !subjectValue || subjectValue !== "COMMON_CORE_MATH"
    //                         ? SUBJECT_STYLES.MATH.icon
    //                         : "np_tick_lg.svg"
    //                     )}
    //                     class="default-icon choose-icon"
    //                     alt="icon"
    //                   />
    //                 </div>
    //                 <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
    //                   Common core math
    //                 </div>
    //               </div>
    //             </div>

    //             <div class="col-sm-4">
    //               <div
    //                 class="sk-choose-item"
    //                 onClick={() => handleSubjectChange("SCIENCE")}
    //               >
    //                 <div
    //                   class="choose-item-image"
    //                   style={{
    //                     backgroundColor: SUBJECT_STYLES.SCIENCE.backgroundColor,
    //                   }}
    //                 >
    //                   <img
    //                     src={getImage("subject-background.svg")}
    //                     class="img-fluid"
    //                     alt="science"
    //                   />
    //                   <img
    //                     src={getImage(
    //                       !subjectValue || subjectValue !== "SCIENCE"
    //                         ? SUBJECT_STYLES.SCIENCE.icon
    //                         : "np_tick_lg.svg"
    //                     )}
    //                     class="default-icon choose-icon"
    //                     alt="icon"
    //                   />
    //                 </div>
    //                 <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
    //                   Science
    //                 </div>
    //               </div>
    //             </div>
    //             <div class="col-sm-4">
    //               <div
    //                 class="sk-choose-item"
    //                 onClick={() => handleSubjectChange("SOCIAL_STUDIES")}
    //               >
    //                 <div
    //                   class="choose-item-image"
    //                   style={{
    //                     backgroundColor:
    //                       SUBJECT_STYLES.SOCIAL_STUDIES.backgroundColor,
    //                   }}
    //                 >
    //                   <img
    //                     src={getImage("subject-background.svg")}
    //                     class="img-fluid"
    //                     alt="image"
    //                   />
    //                   <img
    //                     src={getImage(
    //                       !subjectValue || subjectValue !== "SOCIAL_STUDIES"
    //                         ? SUBJECT_STYLES.SOCIAL_STUDIES.icon
    //                         : "np_tick_lg.svg"
    //                     )}
    //                     class="default-icon choose-icon"
    //                     alt="icon"
    //                   />
    //                 </div>
    //                 <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
    //                   Social Studies
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           {(gradeValue + "" === "3" || gradeValue + "" === "4") && ( <div class="row mb-5">
    //               <div class="col-sm-12">
    //                 <div
    //                 class="sk-choose-item"
    //                   onClick={() => handleSubjectChange("MATH")}
    //                 >
    //                   <div
    //                     class="choose-item-image"
    //                     style={{
    //                       backgroundColor: SUBJECT_STYLES.MATH.backgroundColor,
    //                     }}
    //                   >
    //                     <img
    //                       src={getImage("subject-background.svg")}
    //                       class="img-fluid"
    //                       alt="math"
    //                     />
    //                     <img
    //                       src={getImage(
    //                         !subjectValue || subjectValue !== "MATH"
    //                           ? SUBJECT_STYLES.MATH.icon
    //                           : "np_tick_lg.svg"
    //                       )}
    //                       class="default-icon choose-icon"
    //                       alt="icon"
    //                     />
    //                   </div>
    //                   <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
    //                     math
    //                   </div>
    //                 </div>
    //               </div>
    //           </div>)}

    //           {gradeValue + "" === "5" && (<div class="row mb-5">
    //             <div class="col-sm-6">
    //               <div
    //                 class="sk-choose-item"
    //                 onClick={() => handleSubjectChange("MATH")}
    //               >
    //                 <div
    //                   class="choose-item-image"
    //                   style={{
    //                     backgroundColor: SUBJECT_STYLES.MATH.backgroundColor,
    //                   }}
    //                 >
    //                   <img
    //                     src={getImage("subject-background.svg")}
    //                     class="img-fluid"
    //                     alt="math"
    //                   />
    //                   <img
    //                     src={getImage(
    //                       !subjectValue || subjectValue !== "MATH"
    //                         ? SUBJECT_STYLES.MATH.icon
    //                         : "np_tick_lg.svg"
    //                     )}
    //                     class="default-icon choose-icon"
    //                     alt="icon"
    //                   />
    //                 </div>
    //                 <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
    //                   math
    //                   </div>
    //               </div>
    //             </div>
    //               <div class="col-sm-6">
    //                 <div
    //                 class="sk-choose-item"
    //                   onClick={() => handleSubjectChange("SCIENCE_REVIEW")}
    //                 >
    //                   <div
    //                     class="choose-item-image"
    //                     style={{
    //                       backgroundColor:
    //                         SUBJECT_STYLES.SCIENCE.backgroundColor,
    //                     }}
    //                   >
    //                     <img
    //                       src={getImage("subject-background.svg")}
    //                       class="img-fluid"
    //                       alt="math"
    //                     />
    //                     <img
    //                       src={getImage(
    //                         !subjectValue || subjectValue !== "SCIENCE_REVIEW"
    //                           ? SUBJECT_STYLES.SCIENCE.icon
    //                           : "np_tick_lg.svg"
    //                       )}
    //                       class="default-icon choose-icon"
    //                       alt="icon"
    //                     />
    //                   </div>
    //                   <div class="choose-item-text text-uppercase d-flex align-items-center justify-content-center text-center">
    //                     Science Review
    //                   </div>
    //                 </div>
    //               </div>
    //           </div>)}

    //           <div class="footer-btns d-flex steps-btns align-items-center">
    //             <button
    //               onClick={() => showNext ? nextStep(1, gradeValue, subjectValue) : null}
    //               className={
    //                 "btn btn-lg text-uppercase ml-auto " +
    //                 (showNext ? "btn-primary" : "disabled")
    //               }
    //             >
    //               next
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </main>
  );
};

export default StepSubject;
