import Speech from "speak-tts";
import { getUserid, getToken } from "./profile-utils";
import { reportProblem } from "./report-utils";

let speech = new Speech();
let disableSpeech = false;
let muted = true;
let paused = false;
let setupFlag = false;
let voicesList = [];

export function setup() {
  muted = getMuted();
  speech
    .init({
      volume: 1,
      lang: "en-US",
      rate: 0.8,
      pitch: 1,
      // voice: "Moira",
      // voice: "Veena",
      voice: "Alex",
      // voice: "Samantha",
      splitSentences: true,
      listeners: {
        onvoiceschanged: voices => {
          // console.log('Event voiceschanged', voices);
          voicesList = voices;
        }
      }
    })
    .then(data => {
      // The "data" object contains the list of available voices and the voice synthesis params
      console.log("Speech is ready, voices are available", data);
    })
    .catch(e => {
      console.error("An error occured while initializing : ", e);
      try {
        if (voicesList) {
          // find us voice
          const filtered = voicesList.filter(v => v.lang === "en-US");
          speech.setVoice(filtered[0].name);
        }
      } catch (e) {
        console.error("An error occured while recovering : ", e);
        let report = {};
        report.pageName = "Speech Report Recovery";
        report.action = "PROBLEM";
        report.userid = getUserid();
        report.comment = JSON.stringify(e);
        reportProblem(report, getToken());
        disableSpeech = true;
        muted = true;
      }
    });
  console.log("Speech setup muted? " + muted);
  setupFlag = true;
}

export function isMuted() {
  if (!setupFlag) {
    setup();
  }
  return muted;
}

export function refreshMuted() {
  console.log("Refreshing muted value " + getMuted());
  getMuted();
}

export function getMuted() {
  const muted = localStorage.getItem("muted");
  return muted ? JSON.parse(muted) : true;
}

export function setMuted(muted) {
  console.log("Setting muted value to " + muted);
  localStorage.setItem("muted", muted);
}

export function isSpeechDisabled() {
  return disableSpeech;
}

export function toggleMute() {
  cancelSpeech();
  if (!speech.hasBrowserSupport() || disableSpeech) {
    console.log("speech synthesis supported");
    disableSpeech = true;
    muted = true;
  } else {
    muted = !muted;
  }
  console.log("IS MUTED " + muted);
}

export function cancelSpeech() {
  if (speech.speaking()) {
    console.log("IS TALKING WILL CANCEL");
    speech.cancel();
    console.log("IS TALKING CANCELED? " + speech.speaking());
  }
}

export function pauseSpeech() {
  if (speech.speaking()) {
    console.log("IS TALKING WILL PAUSE");
    paused = true;
    speech.pause();
    console.log(
      paused +
        " < code pause flag IS TALKING PAUSED? speech paused >" +
        speech.paused()
    );
    if (speech.speaking) {
      console.log("ERROR IS TALKING after pause? " + speech.speaking());
    }
  }
}

export function resumeSpeech() {
  if (speech.speaking() && paused) {
    console.log("IS TALKING WILL RESUME");
    paused = false;
    speech.resume();
    console.log("IS TALKING PAUSED? " + speech.speaking());
  }
}

export function read(speechText) {
  var text = speechText.shift();
  const boundFunction = function() {
    if (speechText.length > 0) {
      for (var i = 0; i < 100000000; i++) {
        // waste time
      }
      read(speechText);
    }
  };
  speak(text, boundFunction);
}

export function speak(text, speechCallback) {
  if (!muted && !paused) {
    // console.log("SPEAKING " + text);
    speech
      .speak({
        text: text,
        queue: false, // current speech will be interrupted
        listeners: {
          onstart: () => {
            // console.log("Start utterance")
          },
          onend: () => {
            // console.log("End utterance")
            if (speechCallback) {
              speechCallback();
            }
          } /* ,
              onresume: () => {
                  console.log("Resume utterance")
              },
              onboundary: (event) => {
                  console.log(event.name + ' boundary reached after ' + event.elapsedTime + ' milliseconds.')
              } */
        }
      })
      .then(() => {
        // console.log("Success ! " + text)
      })
      .catch(e => {
        console.error("An error occurred :", e);
      });
  } else {
    if (muted) {
      console.log("Speech is muted. Will not talk");
    }
    if (paused) {
      console.log("Speech is paused. Will not talk");
    }
  }
}
