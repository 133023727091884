import React, { useRef, useEffect, useState } from "react";

import { config, ADULT_LOADING_IMAGES } from "../../Constants";

import requireAuthentication from "../../components/AuthenticatedComponent";
import { displaySubject } from "../../utils/display-utils";
import QuestionAnalysis from "../../components/Parent/Analysis/QuestionAnalysis";
import { findLesson } from "../../utils/lesson-utils";
import Loader from "../Loader";
import { getTopicName } from "../../services/ParentService";
import LessonReportHeader from "./LessonReport/LessonReportHeader";
import { getLevelImage } from "../../utils/image-utils";
import McqDisplay from "./Analysis/McqDisplay";
import FlashcardDisplay from "./Analysis/FlashcardDisplay";
import NumericDisplay from "./Analysis/NumericDisplay";

const LessonPreview = ({ recommendation, topicId, lessonId, closePreview }) => {
  const [isLoading, setLoading] = useState(true);
  const [lesson, setLesson] = useState(null);

  console.log("LessonPreview get lessonData lessonId: " + lessonId);
  console.log("LessonPreview get lessonData recommendation: " + JSON.stringify(recommendation));

  if (!lesson) {
    console.log("LessonPreview get lessonData lessonId: " + lessonId);
    const boundFunction = function (lesson) {
      setLesson(lesson);
      setLoading(false);
    };
    findLesson(lessonId, boundFunction);
  }

  const { topicMetadata } = recommendation ? recommendation : {};
  const { subject, name, shortName, id, grade, questions, level, lessonType } = lesson ? lesson : {};
  const node = useRef();

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click do nothing
      return;
    }
    // outside click close card
    closePreview();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const assignRecommendation = () => {
    setLoading(true);
    console.log("assignRecommendation");
    const boundFunction = function (lesson) {
      setLoading(false);
      closePreview();
    };
    findLesson(recommendation.lessonId, boundFunction);
  };

  const topicName = topicMetadata ? topicMetadata.shortName : getTopicName(topicId);

  return (
    <div className="modal modalAssignChallenge fade parent-dashboard show" 
    style={{ paddingRight: "15px", display: "block", overflowY: "auto", height:""}}>
      <div ref={node} class="modal-dialog modal-lg">
        {isLoading && (
          <Loader
            image={
              ADULT_LOADING_IMAGES[
              Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
              ]
            }
          />
        )}
        {lesson && <div class="modal-content">
          <div class="modal-header">
            <div class="d-lg-flex w-100 flex-wrap align-items-center">
              <div class="modal-header-title">
                <h3>Addition, Numbers &amp; Subtraction</h3>
                <div class="modal-header-info d-flex flex-wrap align-items-center">
                  <span>Difficulty level</span>
                  <img
                    className="lesson-level"
                    src={getLevelImage(level)}
                    alt="Difficulty level"
                  />
                  <span>Grade {topicMetadata && topicMetadata.grade ? topicMetadata.grade : grade}</span>
                  <span>{displaySubject(topicMetadata && topicMetadata.subject ? topicMetadata.subject : subject)}</span>
                </div>
                { /* <!-- /.modal-header-info --> */}
              </div>
              { /* <!-- /.modal-header-title --> */}

              <button class="btn ml-lg-auto mt-3 mt-lg-0 btn-primary btn-lg btn-shadow" style={{ width: "auto" }}>
                ASSIGN challenge
              </button>
            </div>
            { /* <!-- /.d-md-flex --> */}
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" onClick={closePreview}>×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              {lesson.questions &&
                lesson.questions.map((question, index) => {
                  const { questionType } = question;

                  let isNumeric = false;
                  let isFlashCard = false;
                  let isMcq = false;
                  if (
                    questionType === "NUMERIC_QUESTIONS" ||
                    (question.numericEntry !== null && question.numericEntry)
                  ) {
                    isNumeric = true;
                  } else if (questionType === "FLASH_CARDS") {
                    isFlashCard = true;
                  } else {
                    isMcq = true;
                  }

                  return (

                    <div class="col-md-10 col-sm-11">
                      <div class="question-section">
                        <span class="ms-count">{index +
                          1}</span>

                        {question && question.question && (
                          <p dangerouslySetInnerHTML={{ __html: question.question }} />
                        )}

                        {topicName && (
                          <div class="topics mb-4">
                            Topics: <span>{topicName}</span>
                          </div>
                        )}
                        <h5>{isNumeric ? "DATA ENTRY QUESTION" : displaySubject(questionType)}</h5>

                        <h5>Skill: {question.skill} </h5>

                        {question.displayImage && (
                          <p>
                            <img className="question-image" src={question.displayImage} style={{ maxHeight: "20em", maxWidth: "80%" }} />
                          </p>
                        )}

                        {isMcq && <McqDisplay question={question} />}

                        {isFlashCard && <FlashcardDisplay question={question} />}

                        {isNumeric && <NumericDisplay question={question} />}
                      </div>
                      { /* <!-- /.question-section --> */}
                    </div>
                  );
                })}
            </div>
            { /* <!-- /.row --> */}
          </div>
          <div class="modal-footer">
            <button data-toggle="modal" data-target="#assignChallengeNext" class="btn ml-auto btn-primary btn-lg btn-shadow" style={{ width: "auto" }}>
              ASSIGN
            </button>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default requireAuthentication(LessonPreview);
