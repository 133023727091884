import React, { Component } from "react";

import { config, ADULT_LOADING_IMAGES } from "../../Constants";

import { reportActions, report } from "../../utils/report-utils";
import { requireAuthentication } from "../../components/AuthenticatedComponent";
import {
  getUserid,
  getChild,
  getChildren,
  getFirstName,
} from "../../utils/profile-utils";
import Loader from "../../components/Loader";
import { getChildDashboard } from "../../services/ParentService";
import {
  getMinutesValue,
  getMinutesDisplay,
  displaySubject,
} from "../../utils/display-utils";
import GraphHeader from "../../components/Performance/GraphHeader";
import ChallengeSummary from "../../components/dashboards/ChallengeSummary";
import TopicSummary from "../../components/dashboards/TopicSummary";
import ParentHeader from "../../components/Parent/ParentHeader";
import SideNav from "../../components/Parent/SideNav";
import { getImage } from "../../utils/image-utils";
import NewChart from "../../components/Performance/NewChart";
import SubjectWidget from "../../components/Performance/SubjectWidget";
import ParentStorage from "./ParentStorage";

class ParentPerformance extends Component {
  // default State object
  state = {
    limitLessons: true,
    showList: true,
    loading: true,
    days: 90,
    graphWidth: 963,
    graphHeight: 347,
    activeIndex: -1,
    showInfo: false,
    resized: false,
  };

  report = {};

  constructor(props) {
    super(props);

    const child = props.match.params.child;
    this.state.activeChild = child;
    this.state.childProfile = getChild(child);

    this.report.pageName = "Parent Dashboard";
    this.report.userid = getUserid();

    this.graphWrapper = React.createRef();
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
    this.report.action = reportActions.LOAD_PAGE;
    report(this.report);

    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate() {
    this.updateWindowDimensions(this.state.resized);
    if (this.props.lessons && !this.state.metrics) {

      const { activeChild } = this.state;

      const boundFunction = function (response, lessonProgress) {
        // console.log("ParentPerformance: componentDidMount " + response);
        // console.log("ParentPerformance: componentDidMount lessonProgress: " + lessonProgress);

        const metrics = response;
        const { learningHistogram, testingHistogram } = metrics;
        const data = [];
        if (
          metrics &&
          (Object.keys(learningHistogram).length > 0 ||
            Object.keys(testingHistogram).length > 0)
        ) {
          console.log(metrics + " => " + lessonProgress);
          Object.keys(learningHistogram).forEach(function (key) {
            const date = key.split("-")[2].split("T")[0];
            // console.log(key + " learningHistogram " + date);
            // console.log("learningHistogram " + learningHistogram[key]);
            data.push({
              date: date,
              dateValue: key.split("T")[0],
              Learning: getMinutesValue(learningHistogram[key]),
              Testing: getMinutesValue(testingHistogram[key]),
              learningDisplay: getMinutesDisplay(learningHistogram[key]),
              testingDisplay: getMinutesDisplay(testingHistogram[key]),
              amt: 24000,
            });
          });

          Object.keys(testingHistogram).forEach(function (key) {
            const date = key.split("-")[2].split("T")[0];
            // console.log(key + " learningHistogram " + date);
            // console.log("learningHistogram " + learningHistogram[key]);
            data.push({
              date: date,
              dateValue: key.split("T")[0],
              Learning: getMinutesValue(learningHistogram[key]),
              Testing: getMinutesValue(testingHistogram[key]),
              learningDisplay: getMinutesDisplay(learningHistogram[key]),
              testingDisplay: getMinutesDisplay(testingHistogram[key]),
              amt: 24000,
            });
          });
        }

        this.setState({
          metrics: metrics,
          lessonProgress: lessonProgress,
          data: data,
          loading: false,
        });
      }.bind(this);
      const startDate = new Date();
      const endDate = new Date().setTime(
        startDate.getTime() - 90 * 60 * 60 * 24 * 1000
      );
      const range = {
        startDate: startDate,
        endDate: endDate,
        days: this.state.days,
      };
      getChildDashboard(activeChild, range, boundFunction);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = (resized) => {
    if (!resized) {
      const element = this.graphWrapper.current;
      let graphWidth = 800;
      let graphHeight = 170;
      if (element) {
        console.log("graphWrapper clientWidth: " + element.clientWidth);
        graphWidth = element.clientWidth * 0.98;
        graphHeight = element.clientHeight * 0.48;

        this.setState({
          graphWidth: graphWidth,
          graphHeight: graphHeight,
          resized: true,
        });
      }
    }
  };

  handleSubjectChange = (event) => {
    const subject = event.target.value;
    this.props.history.push("/parent/performance/aarav/" + subject.toLowerCase());
  }

  render() {
    const {
      metrics,
      lessonProgress,
      data,
      activeChild,
      loading,
      childProfile,
      days,
      graphWidth,
      graphHeight,
      rowHeight,
    } = this.state;

    const hasActivity = data ? Object.keys(data).length > 0 : false;

    let overallMetrics,
      learningMetrics,
      testingMetrics,
      subjectMetrics,
      topicMetrics;
    if (metrics) {
      overallMetrics = metrics.overallMetrics;
      learningMetrics = metrics.learningMetrics;
      testingMetrics = metrics.testingMetrics;
      subjectMetrics = metrics.subjectMetrics;
      topicMetrics = metrics.topicMetrics;
    }

    console.log("render LOADING " + loading);
    const firstName = getFirstName(childProfile.name);

    return (
      <div id="wrapper" className="parent-dashboard">
        <ParentHeader page={"Performance"} />
        <SideNav
          child={childProfile}
          children={getChildren()}
          page={"Performance"}
          navPath="performance"
        />
        <main class="page-content">
          <div class="tab-body pt-2 mt-4">
            <div class="container">
              <div class="d-flex w-100 mb-3 align-items-center flex-wrap">
                <div class="d-flex flex-wrap">
                  {subjectMetrics && (
                    <select class="btn btn-lg text-normal mr-sm-3 mr-1 mb-1 ml-sm-auto bg-white" onChange={this.handleSubjectChange}> >
                      <option>All Subjects</option>
                      {Object.keys(subjectMetrics).map((s) => (
                        <option value={s}>{displaySubject(s)}</option>
                      ))}
                    </select>
                  )}
                  <select class="btn btn-lg text-normal mr-sm-3 mr-1 mb-1 ml-sm-auto bg-white">
                    {/* <option>Last 7 days</option> */}
                    {/* <option>30 Days</option> */}
                    <option>90 Days</option>
                  </select>
                </div>

                <div class="ml-auto user-name">
                  <span>INSIGHTS</span>
                  <img
                    src={getImage("logo-small.svg")}
                    class="rounded-circle"
                    alt="image"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-4" style={{height: "inherit"}}>
                  {subjectMetrics && (
                    <SubjectWidget subjectMetrics={subjectMetrics} firstName={firstName} child={childProfile.userid}/>
                  )}
                </div>
                <div class="col-md-6 mb-4">
                  {overallMetrics && (
                    <div class="sk-card h-100 card-reset overflow-hidden">
                      <div class="card-body px-sm-4 px-2 py-4">
                        <div class="d-flex mb-3">
                          <h3 class="mb-1">Time spent on platform</h3>
                        </div>

                        <div class="graph-area my-3" ref={this.graphWrapper}>
                          <GraphHeader
                            overallMetrics={overallMetrics}
                            learningMetrics={learningMetrics}
                            testingMetrics={testingMetrics}
                          />
                          <div
                            style={{
                              width: graphWidth + "px",
                              height: graphHeight + "px",
                            }}
                          >
                            <NewChart
                              data={data}
                              graphWidth={graphWidth}
                              graphHeight={graphHeight}
                            />
                          </div>
                        </div>

                        <div class="notes mt-4 d-flex align-items-center">
                          <div class="mr-3">
                            <img src={getImage("bright.svg")} alt="icon" />
                          </div>
                          <p>
                            {firstName}'s activity looks pretty good.
                            You can continue to assign challenges in coming weeks.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {metrics && (
                <ChallengeSummary
                  metrics={metrics}
                  lessonProgress={lessonProgress}
                />
              )}
              {metrics && topicMetrics && (
                <TopicSummary
                  childProfile={childProfile}
                  lessonProgress={lessonProgress}
                  topicMetrics={topicMetrics}
                  days={days}
                />
              )}
            </div>
          </div>
        </main> 
        {loading && (
          <Loader
            image={
              ADULT_LOADING_IMAGES[
                Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
              ]
            }
          />
        )}
      </div>
    );
  }
}

export default requireAuthentication(ParentStorage(ParentPerformance));
