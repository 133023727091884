import React from "react";

import { config } from "../Constants";
import { displayLessonName, displayName, mapSubject } from "./display-utils";
import { aggregate } from "./aggregation-utils";
import { getUserid, getGrade, getSubjects, isParent } from "./profile-utils";
import { getCall } from "./rest-utils";


export function loadSubjects(callback, errorCallback) {
  loadSubjectsByGrade(getGrade(), callback, errorCallback);
}

export function loadSubjectsByGrade(grade, callback, errorCallback) {
  const boundFunction = function(response) {
    callback(response.data.object);
  };
  getCall(
    config.url.API_BASE_URL + "/api/lesson/subjects/grade/" + grade,
    boundFunction,
    errorCallback
  );
}

export function loadSyllabus(subject, callback, errorCallback) {
  loadSyllabusWithGrade(getUserid(), subject, getGrade(), callback, errorCallback);
}

export function loadSyllabusWithGrade(child, subject, grade, callback, errorCallback) {
  const boundFunction = function (response) {
    // console.log(response);
    callback(
      response.data.object.data,
      response.data.object.profile.adultProfile,
      response.data.object.profile.notificationList
    );
  };
  const urlPath = (isParent() ?
    "/api/v2/parent/syllabus/child/" + child + "/grade/" + grade + "/subject/" + subject :
    "/api/v2/student/practice/grade/" + grade + "/subject/" + subject);
  console.log(" loadSyllabusWithGrade path: " + urlPath);
  getCall(
    config.url.API_BASE_URL + urlPath,
    boundFunction,
    errorCallback
  );
}
export function fetchTopic(topicId, callback, errorCallback) {
  const boundFunction = function(response) {
    try {
      // console.log(response);
      callback(
        response.data.object.topic,
        response.data.object.scores,
        response.data.object.locked
      );
    } catch (e) {
      console.log(e);
    }
  };

  getCall(
    config.url.API_BASE_URL + "/api/lesson/topics/id/" + topicId,
    boundFunction,
    errorCallback
  );
}

export function loadLessonCards(lessons) {
  const newCards = [];
  if (lessons && lessons.length > 0) {
    lessons.forEach(c => {
      // console.log('Today: ' + new Date().getTime() + ' assigned ' + c.assignDate);

      const millisecondsInADay = 24 * 60 * 60 * 1000;
      const assignDate = c.assignDate;
      // Adding 10 days to get due date
      const dueDate =
        c.dueDate > 0 ? c.dueDate : assignDate + 10 * millisecondsInADay;
      const pastDue = (new Date().getTime() - dueDate) / millisecondsInADay > 0;

      // if (pastDue) {
      //   console.log(c.lesson.name + ' is past due...' + (new Date().getTime() - dueDate) / millisecondsInADay);
      // }

      // console.log("LESSON lesson: " + JSON.stringify(c));
      // console.log('LESSON lessonId: ' + c.lessonId);
      // console.log('LESSON shortName: ' + c.lesson.shortName);
      const type = c.lessonType
        ? c.lessonType.replace("_", "-").toLowerCase()
        : "";
      let lessonReport = {};
      let displayReport = {};
      let completed = 0;
      if (c.lessonReport) {
        lessonReport = c.lessonReport;
        displayReport = aggregate(lessonReport, c);
        completed =
          (lessonReport.attemptedQuestions * 100) / lessonReport.totalQuestions;
      }
      // console.log("SubjectCard lessonReport: " + JSON.stringify(lessonReport));
      // console.log("loadLessonCards > displayReport: " + JSON.stringify(displayReport));
      let percentage = {};
      if (displayReport) {
        if (displayReport.showIncomplete) {
          percentage = {
            width:
              (lessonReport.attemptedQuestions * 100) /
                lessonReport.totalQuestions +
              "%",
            height: "100%"
          };
        } else if (displayReport.showAccuracy) {
          percentage = {
            width:
              (displayReport.correctAnswers * 100) /
                lessonReport.totalQuestions +
              "%",
            height: "100%"
          };
        } else {
          displayReport = { mustStart: true };
        }
      }
      // console.log("loadLessonCards > displayReport: " + JSON.stringify(displayReport));

      newCards.push({
        id: c.id,
        ref: React.createRef(),
        subject: mapSubject(c.subject),
        lessonId: c.lessonId,
        name: displayLessonName(c.lesson),
        type: type,
        points: c.lesson && c.lesson.points ? c.lesson.points : 100,
        lessonReport: c.lessonReport,
        displayReport: displayReport,
        progressImage: getProgressImage(completed),
        percentage: percentage,
        assignDate: assignDate,
        dueDate: dueDate,
        pastDue: pastDue
      });
    });
  }

  return newCards;
}

export function loadTopicCards(syllabus) {
  const newCards = [];
  if (syllabus && syllabus.topic && syllabus.topic.topics) {
    try {
      syllabus.topic.topics.map((c, index) => {
        newCards.push(getTopic(c, syllabus.scores[c.id], index));
      });
    } catch (e) {
      console.error(e);
    }
  }

  return newCards;
}

export function getTopic(topic, score, index) {
  // console.log("getTopic -> LESSONS: " + topic.lessonList);
  let topicName = displayName(topic.name);
  if (topic.shortName) {
    topicName = topic.shortName;
  }

  let started = 0;
  if (score) {
    started = score.length;
  }
  const total = topic.lessonsAvailableForHomework;

  let completed = 0;
  if (total === 0) {
    completed = 100;
  } else {
    completed = (started * 100) / total;
  }
  // console.log("PracticeCard render: " + props.lesson.name);
  // console.log("PracticeCard render completed: " + completed + " " + props.started + " " + props.total);
  const comingSoon = topic.lessonsAvailableForHomework > 0 ? false : true;

  return {
    index: index,
    subject: topic.subject,
    key: topic.id,
    name: topicName,
    started: started,
    total: total,
    completed: completed,
    platform: getProgressImage(completed),
    comingSoon: comingSoon,
    reference: React.createRef()
  };
}

export function getProgressImage(completed) {
  let platform = "stone-1.svg";
  if (completed > 10 && completed < 30) {
    platform = "stone-2.svg";
  } else if (completed >= 30 && completed < 50) {
    platform = "stone-3.svg";
  } else if (completed >= 50 && completed < 60) {
    platform = "stone-4.svg";
  } else if (completed >= 60 && completed < 80) {
    platform = "stone-5.svg";
  } else if (completed >= 80) {
    platform = "stone-6.svg";
  }
  return platform;
}
