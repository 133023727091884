import React from "react";
import { getImage } from "../../../utils/image-utils";
import { getToken, getUserid } from "../../../utils/profile-utils";
import { reportProblem } from "../../../utils/report-utils";

class ShowProblem extends React.Component {
  state = {
    showProblem: false,
    answer: ""
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.allowEntry = this.allowEntry.bind(this);
    this.dismiss = this.dismiss.bind(this);
    this.sendProblem = this.sendProblem.bind(this);
  }

  dismiss() {
    this.setState({
      showProblem: false
    });
  }

  sendProblem() {
    let report = this.props.report;
    // console.log('Reporting action: PROBLEM');
    report.action = "PROBLEM";
    report.userid = getUserid();
    report.comment = this.state.answer;
    reportProblem(report, getToken());
    this.setState({
      showProblem: false,
      answer: ""
    });
  }

  allowEntry(event) {
    // console.log('ShowProblem  New value: ' + event.target.value);
    // check enter to submit answer
    this.setState({ showProblem: true });
    // console.log('Changed: >>' + this.state.anwer + '<<');
  }

  handleChange(event) {
    // console.log('QuestionContent  New value: ' + event.target.value);
    // check enter to submit answer
    this.setState({ answer: event.target.value });
    // console.log('Changed: >>' + this.state.anwer + '<<');
  }

  render() {
    return (
      <div className="show-problem">
        {!this.state.showProblem && (
          <img
            className="attention-button"
            src={getImage("report-problem.png")}
            onClick={this.allowEntry}
            alt="Report a problem"
          />
        )}
        {this.state.showProblem && (
          <div class="single-challenge-wrap centered-wrapper-problem">
            <div class="single-clng-item">
              <div class="clng-top-txt">
                <div class="challenge-date">
                  <div class="left date-text">
                    <span>
                      {new Intl.DateTimeFormat("en-US", {
                        month: "long",
                        day: "2-digit",
                        year: "numeric"
                      }).format(new Date())}
                    </span>
                    <p>Report problem</p>
                  </div>
                </div>
                <div className="challenge-score" onClick={() => this.dismiss()}>
                  <img src={getImage("Close.png")} alt="close" />
                </div>
              </div>
              <div class="recent-plants-txt">
                <textarea
                  placeholder="Enter comments"
                  value={this.state.answer}
                  style={{
                    width: 100 + "%",
                    height: 100 + "%",
                    border: "1px solid #f2f2f2"
                  }}
                  onChange={this.handleChange}
                />
              </div>
              <div class="view-txt">
                <span
                  className="submit-button"
                  onClick={() => this.sendProblem()}
                >
                  {" "}
                  Submit
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ShowProblem;
