import React from 'react';
import { NavLink } from 'react-router-dom';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import './DayReportCard.css';
import { getClassCount } from '../../utils/profile-utils';

function DayReportCard(props) {
  // console.log('LessonReportCard props: ' + JSON.stringify(props));

  // const date = props.report.assignDate;

  const low = props.report.low === null ? 0 : props.report.low.length;
  const medium = props.report.medium === null ? 0 : props.report.medium.length;
  const high = props.report.high === null ? 0 : props.report.high.length;

  // let grade = '';
  // let subject = '';
  // let name = props.report.lessonKey;
  const lessonKey = props.report.lesson.lessonKey;
  // if (props.report.lesson && props.report.lesson.grade) {
  //   grade = props.report.lesson.grade;
  //   subject = props.report.lesson.subject;
  //   name = props.report.lesson.name;
  // }

  return (
    <div class="day-report-card" style={props.style}>
      <div className="Rectangle-8 ">
        <div className="Avg-Score-80">
          AVERAGE{' '}
          {new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 0,
          }).format(props.report.score)}
          {'%'}
        </div>
      </div>
      <div className="circle-rect">
        <div className="CHALLENGE">
          <span>{props.report.studentsWithReports.length}/</span>
          {getClassCount()} Participants
        </div>
        <div class="participant-wrap">
          <div class="participant-item">
            <CircularProgressbar
              value={100}
              text={`${low}`}
              strokeWidth="18"
              styles={buildStyles({
                textColor: 'rgb(108, 124, 147)',
                pathColor: '#ffcc00',
                trailColor: '#ffcc00',
              })}
            />
            <span>LOw</span>
          </div>
          <div class="participant-item">
            <CircularProgressbar
              value={100}
              text={`${medium}`}
              strokeWidth="18"
              styles={buildStyles({
                textColor: 'rgb(108, 124, 147)',
                pathColor: '#57d1ff',
                trailColor: '#57d1ff',
              })}
            />
            <span>medium</span>
          </div>
          <div class="participant-item">
            <CircularProgressbar
              value={100}
              text={`${high}`}
              strokeWidth="18"
              styles={buildStyles({
                textColor: 'rgb(108, 124, 147)',
                pathColor: '#73e96a',
                trailColor: '#73e96a',
              })}
            />
            <span>high</span>
          </div>
        </div>
      </div>
      <div class="Rectangle-5440">
        <NavLink
          activeClassName="active"
          to={'/teacher/report/lesson/' + lessonKey}
        >
          VIEW CHALLENGE
        </NavLink>
      </div>
    </div>
  );
}

export default DayReportCard;
