import React from 'react';
import { NavLink } from 'react-router-dom';

import { getImage, getAvatarImage } from '../../utils/image-utils';

const StudentMessage = ({ student }) => {
  return (
    <div class="student-singke-message">
      <NavLink to={'/teacher/report/child/' + student.userid}>
        <div class="student-name">
          <img src={getAvatarImage(student)} alt="" />
          <span>{student.name}</span>
        </div>
      </NavLink>
      <div class="student-sms-right">
        <div class="sms-itm">
          <a href="">
            <img src={getImage('profile_mom.jpg')} alt="" />
            <span>
              <img src={getImage('speech-bubble.png')} alt="" />
            </span>
          </a>
        </div>
        <div class="sms-itm">
          <a href="">
            <img src={getImage('profile_dad.jpg')} alt="" />
            <span>
              <img src={getImage('speech-bubble.png')} alt="" />
            </span>
          </a>
          <a href="" />
        </div>
        <a href="" />
        <div class="student-sms-info sms-itm">
          <a href="" />
          <a href="">
            <img src={getImage('information-icon.svg')} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default StudentMessage;
