import axios from "axios";

import { config } from "../Constants";
import { getToken, updateProfileFromResponse } from "./profile-utils";

export function registerUser(payload) {
  axios
    .post(config.url.API_BASE_URL + "/api/sign-up", payload, {
      headers: { Authorization: getToken() }
    })
    .then(result => {
      console.log("user registered: " + payload);
    })
    .catch(error => {
      console.log(error);
      // this.setState({
      //   showError: true,
      //   errorMessage: error.message,
      // });
    });
}

export function registerChild(payload, callback, errorCallBack) {
  const token = getToken();
  axios
    .post(config.url.API_BASE_URL + "/api/profile/add/child", payload, {
      headers: { Authorization: token }
    })
    .then(response => {
      // console.log('user registered: ' + JSON.stringify(reponse));
      if (response.data.object.profile) {
        updateProfileFromResponse(response.data.object.profile);
      }
      callback();
    })
    .catch(error => {
      console.log(error);
      try {
        errorCallBack(error.response.data.details);
      } catch (e) {
        errorCallBack(error.message);
      }
    });
}

export function updateChildProfile(payload, callback, errorCallBack) {
  axios
    .post(
      config.url.API_BASE_URL + "/api/profile/update/child/" + payload.userid,
      payload,
      { headers: { Authorization: getToken() } }
    )
    .then(response => {
      console.log(response);
      if (response.data.object.profile) {
        updateProfileFromResponse(response.data.object.profile);
      }
      callback();
    })
    .catch(error => {
      console.log(error);
      try {
        errorCallBack(error.response.data.details);
      } catch (e) {
        errorCallBack(error.message);
      }
    });
}

export function updateChildPassword(payload, callback, errorCallBack) {
  axios
    .post(
      config.url.API_BASE_URL +
        "/api/profile/update/child/password/" +
        payload.userid,
      payload,
      { headers: { Authorization: getToken() } }
    )
    .then(response => {
      console.log(response);
      if (response.data.object.profile) {
        updateProfileFromResponse(response.data.object.profile);
      }
      callback();
    })
    .catch(error => {
      console.log(error);
      try {
        errorCallBack(error.response.data.details);
      } catch (e) {
        errorCallBack(error.message);
      }
    });
}

export function resetPassword(payload) {
  axios
    .post(config.url.API_BASE_URL + "/request/reset", payload)
    .then(result => {
      console.log("user password reset start: " + payload);
    })
    .catch(error => {
      console.log(error);
    });
}

export function newPassword(payload) {
  axios
    .post(config.url.API_BASE_URL + "/request/newPassword", payload)
    .then(result => {
      console.log("user password reset: " + payload);
    })
    .catch(error => {
      console.log(error);
    });
}

export function welcomeEmail(payload) {
  axios
    .post(config.url.API_BASE_URL + "/api/admin/lesson/welcome", payload, {
      headers: { Authorization: getToken() }
    })
    .then(result => {
      console.log("user welcome start: " + payload);
    })
    .catch(error => {
      console.log(error);
    });
}
