export function checkText(text) {
  // console.log("Check name " + text);
  if (!text || text.trim().length === 0) {
    return false;
  }
  return true;
}

export function checkPassword(text) {
  console.log("Check password " + text);
  if (!text || text.trim().length < 6) {
    return false;
  }
  return true;
}

export function checkEmail(email) {
  // console.log("Check email " + email);
  let emailError = null;
  if (!email || email.length === 0) {
    emailError = "Enter email";
  } else {
    if (email.length < 5) {
      emailError = "Enter valid email";
    }
    if (email.split("").filter(x => x === "@").length !== 1) {
      emailError = "Enter valid email";
    }
    if (email.indexOf(".") === -1) {
      emailError = "Enter valid email";
    }
  }
  return emailError;
}

export function isNumeric(number) {
  // blank is not numeric
  if (!checkText(number + "")) {
    return false;
  }
  if (isNaN(number)) {
    return false;
  }
  return true;
}
