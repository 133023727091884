import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { displayName } from '../../utils/display-utils';

import { getImage, getAvatarImage } from '../../utils/image-utils';
import ShowProgressInfo from './ShowProgressInfo';
import { getStudents, getClassCount } from '../../utils/profile-utils';

class HomeworkPicker extends React.Component {
  state = {
    index: 0,
    lesson: null,
    showInfo: false,
    filteredStudents: getStudents(),
  };

  constructor(props) {
    super(props);

    this.state.lessonKey = props.lessonKey;

    this.loadData = this.loadData.bind(this);
    this.filterStudents = this.filterStudents.bind(this);
    this.updateDisplay = this.updateDisplay.bind(this);
    this.showInfo = this.showInfo.bind(this);
  }

  componentDidMount() {
    this.updateDisplay(this.props.index);
  }

  showInfo() {
    this.setState({
      showInfo: !this.state.showInfo,
    });
  }

  filterStudents(filter) {
    // console.log('HomeworkPicker ****filterStudents**** FILTER IN HomeworkPicker ' + filter);
    const lessonReport = this.state.lessonReport;
    let students = [];
    let showIncomplete, showLow, showMedium, showHigh;
    if (lessonReport) {
      // console.log(this.props.students);
      // console.log(lessonReport.reports);
      students = getStudents();
      // console.log('HomeworkPicker  HomeworkPicker Start students #:' + students.length);
      if (filter === 'incomplete') {
        // remove all that have done homwork
        // console.log('HomeworkPicker  REMOVE: ' + lessonReport.studentsWithReports);
        students = students.filter(
          s => !lessonReport.studentsWithReports.includes(s.userid)
        );
        showIncomplete = filter;
      } else if (filter === 'low') {
        // remove all other than low
        // console.log('HomeworkPicker  low KEEP: ' + lessonReport.low);
        students = students.filter(s => lessonReport.low.includes(s.userid));
        showLow = filter;
      } else if (filter === 'medium') {
        // remove all other than medium
        // console.log('HomeworkPicker medium KEEP: ' + lessonReport.medium);
        students = students.filter(s =>
          lessonReport.medium.includes(s.userid)
        );
        showMedium = filter;
      } else if (filter === 'high') {
        // remove all other than high
        // console.log('HomeworkPicker  high KEEP: ' + lessonReport.high);
        students = students.filter(s => lessonReport.high.includes(s.userid));
        showHigh = filter;
      }
    }
    // console.log('HomeworkPicker ****filterStudents**** Show students #:' + students.length);
    this.setState({
      filter: filter,
      filteredStudents: students,
      showIncomplete: showIncomplete,
      showLow: showLow,
      showMedium: showMedium,
      showHigh: showHigh,
      lessonKey: lessonReport.lesson.lessonKey,
    });
  }

  loadData(event) {
    // console.log('HomeworkPicker  HomeworkPicker SELECTED Lesson' + event.target.value);
    if (event) {
      this.updateDisplay(event.target.value, null);
    }
  }

  updateDisplay(value, lessonReport) {
    // console.log('HomeworkPicker  HomeworkPicker updateDisplay enter ' + value + ' report: ' + lessonReport);
    if (!value) {
      value = 0;
      // console.log('HomeworkPicker  StudentList updateDisplay default value:' + value);
    }
    if (this.props.isSingleLesson) {
      lessonReport = this.props.homework;
    } else if (!lessonReport) {
      lessonReport = this.props.homework[value];
    }
    // console.log('HomeworkPicker  StudentList updateDisplay - report' + JSON.stringify(lessonReport));

    this.setState(
      {
        index: value,
        lessonReport: lessonReport,
      },
      () => {
        this.filterStudents('incomplete');
      }
    );
  }

  render() {
    let { lessonKey } = this.state;
    const { lessonReport, filter, filteredStudents } = this.state;
    console.log(
      'HomeworkPicker  HomeworkPicker render LESSON REPORT: ' +
        JSON.stringify(lessonReport)
    );
    let incomplete = 0;
    let low = 0;
    let medium = 0;
    let high = 0;
    const classCount = getClassCount();
    if (lessonReport) {
      incomplete = classCount - lessonReport.studentsWithReports.length;
      low = lessonReport.low === null ? 0 : lessonReport.low.length;
      // console.log(lessonReport.low + ' size: ' + low);
      medium = lessonReport.medium === null ? 0 : lessonReport.medium.length;
      high = lessonReport.high === null ? 0 : lessonReport.high.length;
    }
    const isIncomplete = filter === 'incomplete' ? true : false;
    console.log(filter + ' HomeworkPicker render: ' + isIncomplete);

    // console.log(this.props.reports);
    // console.log(this.props.subject);
    const lessons = this.props.homework;
    if (!lessonKey & lessonReport) {
      lessonKey = lessonReport.lessonKey;
    }
    // console.log('HomeworkPicker  HomeworkPicker render lessonList: ' + JSON.stringify(lessons));
    // console.log('HomeworkPicker filteredStudents: ' + filteredStudents.length);
    // console.log('HomeworkPicker lessonKey: ' + lessonKey);
    return (
      <div className="sk-container">
        <div className="summary-box">
          <div className="smr-warp">
            <div className="summary-title">
              <h4 className="left">Performance Summary</h4>
              <div className="right info-wrap">
                <img
                  src={getImage('information-icon.svg')}
                  onClick={() => this.showInfo()}
                  alt="legend"
                />
              </div>
              {!this.props.isSingleLesson && (
                <div className="clear left summary-select">
                  <select
                    className="select-css cursor-pointer"
                    onChange={this.loadData}
                    value={this.state.index}
                  >
                    <option value="">Pick Homework</option>
                    {lessons.map((c, index) => (
                      <option key={index} value={index}>
                        {displayName(c.lesson.name)}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className="clear summary-performance">
              <div
                className="single-perform cursor-pointer"
                onClick={() => this.filterStudents('incomplete')}
              >
                <CircularProgressbar
                  value={incomplete}
                  maxValue={classCount}
                  text={`${incomplete}`}
                  strokeWidth="18"
                  styles={buildStyles({
                    textColor: 'rgb(108, 124, 147)',
                    pathColor: '#657d95',
                    trailColor: '#ececec',
                  })}
                />
              </div>
              <div
                className="single-perform cursor-pointer"
                onClick={() => this.filterStudents('low')}
              >
                <CircularProgressbar
                  value={low}
                  maxValue={classCount}
                  text={`${low}`}
                  strokeWidth="18"
                  styles={buildStyles({
                    textColor: 'rgb(108, 124, 147)',
                    pathColor: '#ffcc00',
                    trailColor: '#ececec',
                  })}
                />
              </div>
              <div
                className="single-perform cursor-pointer"
                onClick={() => this.filterStudents('medium')}
              >
                <CircularProgressbar
                  value={medium}
                  maxValue={classCount}
                  text={`${medium}`}
                  strokeWidth="18"
                  styles={buildStyles({
                    textColor: 'rgb(108, 124, 147)',
                    pathColor: '#57d1ff',
                    trailColor: '#ececec',
                  })}
                />
              </div>
              <div
                className="single-perform cursor-pointer"
                onClick={() => this.filterStudents('high')}
              >
                <CircularProgressbar
                  value={high}
                  maxValue={classCount}
                  text={`${high}`}
                  strokeWidth="18"
                  styles={buildStyles({
                    textColor: 'rgb(108, 124, 147)',
                    pathColor: '#73e96a',
                    trailColor: '#ececec',
                  })}
                />
              </div>
            </div>

            <div className="performance-tabs">
              <div className="performance-tab-list">
                <div className="tab">
                  <button
                    className={'tablinks ' + this.state.showIncomplete}
                    onClick={() => this.filterStudents('incomplete')}
                  >
                    <h4>INCOMPLETE</h4>
                    <p>STUDENTS</p>
                  </button>
                  <button
                    className={'tablinks ' + this.state.showLow}
                    onClick={() => this.filterStudents('low')}
                  >
                    <h4>LOW</h4>
                    <p>PERFORMERS</p>
                  </button>
                  <button
                    className={'tablinks ' + this.state.showMedium}
                    onClick={() => this.filterStudents('medium')}
                  >
                    <h4>MEDIUM</h4>
                    <p>PERFORMERS</p>
                  </button>
                  <button
                    className={'tablinks ' + this.state.showHigh}
                    onClick={() => this.filterStudents('high')}
                  >
                    <h4>HIGH</h4>
                    <p>PERFORMERS</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={'summary-tabs ' + filter}>
            {filteredStudents.length > 0 && (
              <div className="tab-contentt">
                <div id="INCOMPLETE" className="tabcontent">
                  <div className="recent-performer">
                    <div className="left-info-perfo scrolling-wrapper">
                      {!isIncomplete &&
                        filteredStudents.map(function(c) {
                          let avatarImage = getAvatarImage(c);
                          return (
                            <NavLink
                              key={c.userid}
                              to={
                                '/teacher/report/child/' +
                                c.userid +
                                '/lesson/' +
                                lessonKey
                              }
                            >
                              <div className="single-performar">
                                <img src={avatarImage} alt="" />
                                <span>{c.name.split(' ')[0]}</span>
                              </div>
                            </NavLink>
                          );
                        })}
                      {/* child image is not clicakbe if child has not started assignement */}
                      {isIncomplete &&
                        filteredStudents.map(function(c) {
                          let avatarImage = getAvatarImage(c);
                          return (
                            <div className="single-performar">
                              <img src={avatarImage} alt="" />
                              <span>{c.name.split(' ')[0]}</span>
                            </div>
                          );
                        })}
                    </div>
                    {this.state.showIncomplete && (
                      <div className="sent-alt-box">
                        <img src={getImage('send-button.svg')} alt="" />
                        <span>Sent alert</span>
                      </div>
                    )}
                    {this.state.showLow && (
                      <div className="sent-alt-box">
                        <img src={getImage('add-challenge.svg')} alt="" />
                        <span>Create Challenge</span>
                      </div>
                    )}
                    {this.state.showMedium && (
                      <div className="sent-alt-box">
                        <img src={getImage('clap-button.svg')} alt="" />
                        <span>Clap for All</span>
                      </div>
                    )}
                    {this.state.showHigh && (
                      <div className="sent-alt-box">
                        <img src={getImage('clap-button-high.png')} alt="" />
                        <span>Clap for All</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.showInfo && <ShowProgressInfo onClose={this.showInfo} />}
      </div>
    );
  }
}

HomeworkPicker.propTypes = {
  homework: PropTypes.array.isRequired,
};

export default HomeworkPicker;
