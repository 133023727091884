import React, { PropTypes } from "react";
import {
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import { getImage } from "../../../utils/image-utils";

const TopicMetrics = ({ topicMetrics, learningMetrics, testingMetrics }) => {

    return (
      <div class="col-lg-7 mt-3">
        <div class="sk-card">
          <div class="card-body p-md-4 p-3">
            <h3 class="text-gray">Overall topic score</h3>

            <div class="row text-center justify-content-between">
              <div class="col-md-4 col-sm-5 my-3">
                <div class="graph-sm">
                  <CircularProgressbar
                    value={testingMetrics ? testingMetrics.accuracy : 0}
                    maxValue={100}
                    text={`${
                      new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 0,
                      }).format(testingMetrics ? testingMetrics.accuracy : 0) +
                      "%"
                    }`}
                    strokeWidth="8"
                    styles={buildStyles({
                      textColor: "#657D95",
                      trailColor: "#fdeef5",
                      pathColor: "#b863fb",
                    })}
                  />
                </div>
                <h4 class="text-gray mb-0">Testing Accuracy</h4>
              </div>

                <div class="col-md-4 col-sm-5 my-3">
                  <div class="graph-sm">
                    <CircularProgressbar
                      value={learningMetrics ? learningMetrics.exposure : 0}
                      maxValue={100}
                      text={`${
                        new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 0,
                        }).format(
                          learningMetrics ? learningMetrics.exposure : 0
                        ) + "%"
                      }`}
                      strokeWidth="6"
                      styles={buildStyles({
                        textColor: "#657D95",
                        trailColor: "#fdeef5",
                        pathColor: "#ff5fce",
                      })}
                    />
                  </div>
                  <h4 class="text-gray mb-0">Learning Exposure</h4>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default TopicMetrics;