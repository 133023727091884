import React, { Component } from 'react';
import './Dropzone.css';
import { getImage } from '../../utils/image-utils';

class Dropzone extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    console.log('Dropzone: ' + JSON.stringify(files));
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
      this.setState({
        previewImage: URL.createObjectURL(evt.target.files[0]),
      });
    }
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({ hightlight: true });
  }

  onDragLeave(event) {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    const files = event.dataTransfer.files;
    let previewImage;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
      previewImage = URL.createObjectURL(event.dataTransfer.files[0]);
    }
    this.setState({
      hightlight: false,
      previewImage: previewImage,
    });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    return (
      <div
        className={`Dropzone ${this.state.hightlight ? 'Highlight' : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />
        {this.state.previewImage && (
          <img alt="upload" className="preview" src={this.state.previewImage} />
        )}
        {!this.state.previewImage && (
          <img
            alt="upload"
            className="Icon"
            src={getImage('baseline-cloud_upload-24px.svg')}
          />
        )}
        {!this.state.previewImage && <span>Upload Files</span>}
      </div>
    );
  }
}

export default Dropzone;
