import React, { Component } from 'react';

import Header from '../components/Header';
import ProfileNavigation from '../components/ProfileNavigation';
import { getImage } from '../utils/image-utils';

class Help extends Component {
  state = {};

  constructor(props) {
    super(props);

    console.log(props);

    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div>
        <Header title="Help" />
        <div class="profile-area">
          <div class="sk-container">
            <div class="profiles-main-warpp">
              <ProfileNavigation active={'profile'} />
              <div class="right-content-warp">
                <div class="right-content-inner">
                  <div class="help-content">
                    <div class="help-title">
                      <h4>Help</h4>
                    </div>
                    <div class="help-text">
                      <button class="accordion">
                        1. How is the High, Medium, Low Performance calculated?{' '}
                      </button>
                      <div class="panel active">
                        <div class="modal-bodyy">
                          <div class="single-modal-content">
                            <div class="modal-left-text">
                              <img src={getImage('oval-1.png')} alt="" />
                            </div>
                            <div class="modal-right-text">
                              <h4>Low Performance</h4>
                              <p>
                                Low performers are the ones who got less than
                                40% of the questions
                              </p>
                            </div>
                          </div>
                          <div class="single-modal-content">
                            <div class="modal-left-text">
                              <img src={getImage('oval-2.png')} alt="" />
                            </div>
                            <div class="modal-right-text">
                              <h4>Medium Performance</h4>
                              <p>
                                Medium performers are the ones who got between
                                40-80% of the questions.
                              </p>
                            </div>
                          </div>
                          <div class="single-modal-content">
                            <div class="modal-left-text">
                              <img src={getImage('oval-3.png')} alt="" />
                            </div>
                            <div class="modal-right-text">
                              <h4>High Performance</h4>
                              <p>
                                High performers are the ones who get more 80% of
                                the questions right.
                              </p>
                            </div>
                          </div>
                          <div class="single-modal-content">
                            <div class="modal-left-text">
                              <img src={getImage('oval-0.png')} alt="" />
                            </div>
                            <div class="modal-right-text">
                              <h4>Incomplete</h4>
                              <p>
                                Students who have partially completed or not
                                started the challenge.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button class="accordion">
                        2. What does the Colored Donuts mean?
                      </button>
                      <div class="panel">
                        <div class="single-modal-content">
                          <div class="modal-left-text">
                            <img src={getImage('oval-1.png')} alt="" />
                          </div>
                          <div class="modal-right-text">
                            <h4>Low Performance</h4>
                            <p>
                              Low performers are the ones who got less than 40%
                              of the questions
                            </p>
                          </div>
                        </div>
                        <div class="single-modal-content">
                          <div class="modal-left-text">
                            <img src={getImage('oval-2.png')} alt="" />
                          </div>
                          <div class="modal-right-text">
                            <h4>Medium Performance</h4>
                            <p>
                              Medium performers are the ones who got between
                              40-80% of the questions.
                            </p>
                          </div>
                        </div>
                        <div class="single-modal-content">
                          <div class="modal-left-text">
                            <img src={getImage('oval-3.png')} alt="" />
                          </div>
                          <div class="modal-right-text">
                            <h4>High Performance</h4>
                            <p>
                              High performers are the ones who get more 80% of
                              the questions right.
                            </p>
                          </div>
                        </div>
                        <div class="single-modal-content">
                          <div class="modal-left-text">
                            <img src={getImage('oval-0.png')} alt="" />
                          </div>
                          <div class="modal-right-text">
                            <h4>Incomplete</h4>
                            <p>
                              Students who have partially completed or not
                              started the challenge.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button class="accordion">
                      3. How do add new students to a subject?
                    </button>
                    <div class="panel">
                      <div class="single-modal-content">
                        <div class="modal-left-text">
                          <img src={getImage('oval-1.png')} alt="" />
                        </div>
                        <div class="modal-right-text">
                          <h4>Low Performance</h4>
                          <p>
                            Low performers are the ones who got less than 40% of
                            the questions
                          </p>
                        </div>
                      </div>
                      <div class="single-modal-content">
                        <div class="modal-left-text">
                          <img src={getImage('oval-2.png')} alt="" />
                        </div>
                        <div class="modal-right-text">
                          <h4>Medium Performance</h4>
                          <p>
                            Medium performers are the ones who got between
                            40-80% of the questions.
                          </p>
                        </div>
                      </div>
                      <div class="single-modal-content">
                        <div class="modal-left-text">
                          <img src={getImage('oval-3.png')} alt="" />
                        </div>
                        <div class="modal-right-text">
                          <h4>High Performance</h4>
                          <p>
                            High performers are the ones who get more 80% of the
                            questions right.
                          </p>
                        </div>
                      </div>
                      <div class="single-modal-content">
                        <div class="modal-left-text">
                          <img src={getImage('oval-0.png')} alt="" />
                        </div>
                        <div class="modal-right-text">
                          <h4>Incomplete</h4>
                          <p>
                            Students who have partially completed or not started
                            the challenge.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button class="accordion">
                    4. Assigning challenge before hand?
                  </button>
                  <div class="panel">
                    <div class="single-modal-content">
                      <div class="modal-left-text">
                        <img src={getImage('oval-1.png')} alt="" />
                      </div>
                      <div class="modal-right-text">
                        <h4>Low Performance</h4>
                        <p>
                          Low performers are the ones who got less than 40% of
                          the questions
                        </p>
                      </div>
                    </div>
                    <div class="single-modal-content">
                      <div class="modal-left-text">
                        <img src={getImage('oval-2.png')} alt="" />
                      </div>
                      <div class="modal-right-text">
                        <h4>Medium Performance</h4>
                        <p>
                          Medium performers are the ones who got between 40-80%
                          of the questions.
                        </p>
                      </div>
                    </div>
                    <div class="single-modal-content">
                      <div class="modal-left-text">
                        <img src={getImage('oval-3.png')} alt="" />
                      </div>
                      <div class="modal-right-text">
                        <h4>High Performance</h4>
                        <p>
                          High performers are the ones who get more 80% of the
                          questions right.
                        </p>
                      </div>
                    </div>
                    <div class="single-modal-content">
                      <div class="modal-left-text">
                        <img src={getImage('oval-0.png')} alt="" />
                      </div>
                      <div class="modal-right-text">
                        <h4>Incomplete</h4>
                        <p>
                          Students who have partially completed or not started
                          the challenge.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Help;
