import React, { useState } from "react";

import axios from "axios";

import { config } from "../../../Constants";
import { getToken } from "../../../utils/profile-utils";

import "react-circular-progressbar/dist/styles.css";

import "./../../Teacher/RecentChallenges.css";
import "./../../../components/Parent/ChildChallenges.css";
import { displaySubject } from "../../../utils/display-utils";
import LessonPreview from "../LessonPreview";
import { getTopicName, getTopicSubject } from "../../../services/ParentService";


const RecommendationRow = ({ child, recommendation }) => {

  if (!recommendation) {
    return <div />;
  }
  const {
    shortName,
    topicKey,
    assignDate,
    subject,
    lessonKey
  } = recommendation.lessonMetadata;

  const removeRecommendation = () => {

    if (!child) return;

    let assignPath = "/api/admin/lesson/remove/recommendation/" + child.userid + "/";

    console.log(
      "Remove request will be sent to " + assignPath + lessonKey
    );

    axios
      .get(config.url.API_BASE_URL + assignPath + lessonKey, {
        headers: { Authorization: getToken() }
      })
      .then(response => {
        console.log(response);
        window.location.reload();
      })
      .catch(error => console.log(error));
  }

  // console.log("RecommendationRow lesson: " + JSON.stringify(recommendation));

  let topicSubject = getTopicSubject(topicKey);
  topicSubject = displaySubject(topicSubject ? topicSubject : subject);

  return (
    <div class="challenge-list-item challenge-incompleted" style={{ background: "light-green" }}>
      <div class="d-flex mb-2 align-items-center">
        <div class="text-muted text-extraBold text-uppercase">
          <span class="indicator"></span> Grade {child ? child.grade : ""}{" . "}
          {topicSubject}{" . "}
          {new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "2-digit",
            year: "numeric"
          }).format(assignDate)}
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-5 col-sm-8">
          <h3>{shortName}</h3>
          <p class="text-muted">{getTopicName(topicKey)}</p>
        </div>
        <div class="col-lg-5 col-md-4 col-sm-12">
          <div class="d-flex flex-wrap justify-content-md-end">
            <a
              href="#"
              onClick={() => removeRecommendation()}
              class="sk-btn btn-lg ml-3 mb-2 btn-primary text-normal" style={{ paddingTop: "0px" }} >
              Remove
              </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendationRow;
