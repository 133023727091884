import React from "react";
import PropTypes from "prop-types";

import { getImage } from "../../../utils/image-utils";
import requireAuthentication from "../../AuthenticatedComponent";

const ReviewContent = ({
  number,
  question,
  questionIndex,
  previousCard,
  nextCard
}) => {
  const { options, displayImage } = question;
  const showHtml = options && options[1] ? true : false;
  const hasText = options && options[0] ? true : false;

  return (
    <div className="navigation-container">
      {questionIndex > 0 && (
        <div className="left button-container">
          <button
            className="cursor-pointer"
            className="card-button-tiny close"
            onClick={() => previousCard()}
          >
            <img src={getImage("previous.png")} alt="previous slide" />
          </button>
        </div>
      )}
      {questionIndex < number && (
        <div className="right button-container">
          <button
            className="cursor-pointer"
            className="card-button-tiny close"
            onClick={() => nextCard()}
          >
            <img src={getImage("next.png")} alt="next slide" />
          </button>
        </div>
      )}
      <div className="question-form question-checkbox">
        {number > 1 && <span>QUESTION {questionIndex + 1}</span>}
        <p dangerouslySetInnerHTML={{ __html: question.question }} />
      </div>

      <div className="questions-checkbox-warp lesson-card">
        {!showHtml && (
          <div className="flip-card-front">
            <table>
              <tr>
                {displayImage && (
                  <td>
                    <img src={displayImage} alt="flashcard" />
                  </td>
                )}
                {hasText && (
                  <td style={{ paddingLeft: "20px" }}>
                    {" "}
                    <span style={{color: "#2f3643"}}
                      dangerouslySetInnerHTML={{ __html: options[0] }}
                    />{" "}
                  </td>
                )}
              </tr>
            </table>
          </div>
        )}
        {showHtml && (
          <div className="flip-card-front">
            <span dangerouslySetInnerHTML={{ __html: options[1] }} />
          </div>
        )}
      </div>
    </div>
  );
};

ReviewContent.propTypes = {
  question: PropTypes.object.isRequired
};

export default requireAuthentication(ReviewContent);
