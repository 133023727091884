import axios from "axios";

import { config } from "../Constants";
import { getCall } from "./rest-utils";
import { getUserid } from "./profile-utils";

function loadLessonData(lessonId, callback, errorCallback) {
  // console.log("Questions LESSON: " + JSON.stringify(lesson));
  var boundFuction = function(response) {
    // console.log('loadLessonData: ' + response);
    callback(response.data.object);
  };
  getCall(
    config.url.API_BASE_URL + "/api/lesson/id/" + lessonId,
    boundFuction,
    errorCallback
  );
}

export function loadAttemptReport(attemptId, callback, errorCallback) {
  // console.log("Questions LESSON: " + JSON.stringify(lesson));
  var boundFuction = function (response) {
    // console.log('loadLessonData: ' + response);
    callback(response.data.object);
  };
  getCall(
    config.url.API_BASE_URL + "/api/report/attempt/" + attemptId,
    boundFuction,
    errorCallback
  );
}

export function findLessonSource(id, callback) {
  console.log("findLessonSource: " + id);
  const boundFunction = function(response) {
    callback(response.data.object);
  };
  getCall(
    config.url.API_BASE_URL + "/api/admin/lesson/source/" + id,
    boundFunction
  );
}

export function findLesson(lessonId, callback, errorCallback) {
  // find lesson in local storage
  const homework = JSON.parse(localStorage.getItem("homework"));

  if (homework) {
    // console.log(homework);
    let found = false;
    homework.find(c => {
      // console.log(lessonId + ' MATCH ' + c.lessonId);
      if (c.lessonId === lessonId) {
        found = true;
      }
      if (found) {
        // console.log(lessonId + ' FOUND MATCH ' + c.lessonId);
        callback(c.lesson);
      } else {
        // if lesson was not found check server
        return loadLessonData(lessonId, callback, errorCallback);
      }
    });
  } else {
    return loadLessonData(lessonId, callback, errorCallback);
  }
}

export function loadLessonReport(lessonId, callback) {
  const boundFunction = function(response) {
    callback(response.data.object);
  };
  getCall(
    config.url.API_BASE_URL +
      "/api/homework/id/" +
      lessonId +
      "/user/" +
      getUserid(),
    boundFunction
  );
}

export function loadLessonState(lesson) {
  // console.log('lesson-utils loadLessonState: ' + lesson);

  const isTest = isTestLesson(lesson);
  const isSpelling = isSpellingLesson(lesson);
  const isMcq = isMcqLesson(lesson);
  const questions = [];
  let newCards = [];
  lesson.questions.map((question, index) => {
    // console.log(index + ' loadLesson Question: ' + question.question);

    const showOptions = hasOptions(question);
    let options = [];
    if (showOptions) {
      options = getOptions(question);
    }

    // console.log("QUESTION loadCard question: " + JSON.stringify(question));
    // console.log('QUESTION loadCard options: ' + JSON.stringify(options));

    let letters = [];
    if (isSpelling) {
      letters = question.options[question.answerIndex].toLowerCase().split("");
      letters = letters
        .map(a => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map(a => a[1]);
    }

    let answer = null;
    if (isMcq && question.options) {
      answer = question.options[question.answerIndex];
    }

    let speechText = getSpeechText(lesson.subject, isSpelling, question);

    newCards.push({
      question: question,
      options: options,
      showOptions: showOptions,
      letters: letters,
      displayImage: question.displayImage,
      speech: question.speech,
      answer: answer,
      speechText: speechText
    });
  });

  // set new state properties
  return {
    lesson: lesson,
    hasTutorial: hasTutorial(lesson),
    numberOfQuestions: numberOfQuestions(lesson),
    isSpelling: isSpelling,
    isMcq: isMcq,
    isTest: isTest,
    questions: newCards,
    subject: lesson.subject
  };
}

export function getSpeechText(subject, isSpelling, question) {
  let speechText = [];
  if (isSpelling && question.speech) {
    speechText.push(...question.speech);
  } else if (question.speech) {
    speechText.push(...question.speech);
  } else {
    let questionText = question.question;
    // console.log('lesson-utils  => Question Text: ' + questionText);
    // For math only read first line
    if (subject === "MATH") {
      questionText = questionText.split("<br/>")[0];
    }
    speechText.push(questionText);
  }

  return speechText;
}

export function isCorrectAnswer(question, answer) {
  // console.log("QUESTION: " + JSON.stringify(question));
  // answer is always be string. To avoid parsing turn correct answer to string too.
  let correctAnswer =
    (question.answer
      ? question.answer
      : question.options[question.answerIndex]) + "";
  // console.log("STATE ANSWER: " + answer + " = TARGET ANSWER: " + correctAnswer);
  let isCorrect = true;
  if (answer != correctAnswer) {
    // check for commas and trim
    if (
      answer.replace(",", "").trim() !== correctAnswer.replace(",", "").trim()
    ) {
      isCorrect = false;
    }
  }
  return isCorrect;
}

export function isSpellingLesson(lesson) {
  if (lesson && lesson.subject === "WORD_STUDY") {
    console.log("lesson-utils LESSON loaded from server isSpellingLesson ");
    return true;
  }
  if (lesson && lesson.lessonType === "SPELLING_TEST_QUESTIONS") {
    console.log(
      "SPELLING_TEST_QUESTIONS LESSON loaded from server isSpellingLesson "
    );
    return true;
  }
  return false;
}

export function isMcqLesson(lesson) {
  if (isSpellingLesson(lesson)) {
    return false;
  } else if (lesson && lesson.lessonType === "NUMERIC_QUESTIONS") {
    return false;
  } else if (lesson && lesson.lessonType === "FLASH_CARDS") {
    return false;
  }
  // console.log("lesson-utils LESSON isMcqLesson YES ");
  return true;
}

export function isMcqQuestion(question) {
  if (question && question.numericEntry) {
    return false;
  }
  return isMcqLesson(question);
}

export function isTestLesson(lesson) {
  if (lesson && lesson.lessonType !== "FLASH_CARDS") {
    // console.log('lesson-utils LESSON loaded from server isTestLesson ');
    return true;
  }
  return false;
}

export function numberOfQuestions(lesson) {
  return lesson.questions.length;
}

export function hasOptions(question) {
  if (question && question.options && question.options.length > 1) {
    return true;
  }
  return false;
}

export function hasLargeOptions(options) {
  if (options) {
    return (
      options.filter(o => {
        return o.length > 15;
      }).length > 0
    );
  }
  return false;
}

export function getOptions(question) {
  // console.log("QUESTION with speech?: " + JSON.stringify(question));
  let options = [];
  if (hasOptions(question)) {
    options = [...question.options];
    // Shuffle options
    options = options
      .filter(a => {
        if (a && a.length > 0) {
          return true;
        }
        return false;
      })
      .map(a => [Math.random(), a])
      .sort((a, b) => a[0] - b[0])
      .map(a => a[1]);
  }
  // console.log('lesson-utils getOptions: ' + options);
  return options;
}

export function hasTutorial(lesson) {
  if (lesson && lesson.subject) {
    return lesson.subject === "WORD_STUDY" ? true : false;
  }
  return false;
}
