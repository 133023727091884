import React from "react";

import "./Messages.css";
import "./stream-override.css";

import { getImage } from "../../../utils/image-utils";

import { config, SOON_LOADING_IMAGES } from "../../../Constants";

import Loader from "../../../components/Loader";
import ProfileWidget from "../../../components/Student/ProfileWidget/ProfileWidget";
import requireAuthentication from "../../../components/AuthenticatedComponent";

class Messages extends React.Component {
  // default State object
  state = {};

  chatClient;

  constructor(props) {
    super(props);

    console.log(props);

    this.state.profile = JSON.parse(localStorage.getItem("profile"));
    // this.setupChat();

    console.log(this.state.profile);
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
  }

  handleClick() {
    this.props.history.goBack();
  }

  render() {
    // let filters = {
    //   type: 'messaging',
    //   members: { $in: [this.state.profile.userid] },
    // };
    // let sort = { last_message_at: -1 };

    return (
      <div id="student-container">
        <div className="header-bar math-header">
          <div className="sk-container">
            <div className=" header-back">
              <img
                src={getImage("student-back.svg")}
                alt="back"
                onClick={() => this.handleClick()}
              />
            </div>
            <div className="header-meddle">
              <h3>Messages</h3>
            </div>
            <ProfileWidget
              locationHash={this.props.location.hash}
              history={this.props.history}
            />
          </div>
        </div>
        <div className="messages-demo">
          <div className="wiggles">
            <Loader
              image={
                SOON_LOADING_IMAGES[
                  Math.floor(Math.random() * SOON_LOADING_IMAGES.length)
                ]
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default requireAuthentication(Messages);
