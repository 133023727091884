import React from "react";
import { NavLink } from "react-router-dom";
import { getActor } from "../utils/profile-utils";
import { getImage } from "../utils/image-utils";

const Footer = () => {
  return (
    <footer className="footer">
      <ul>
        <li>{new Date().getFullYear()} © SmartKids.io</li> {" | "}
        <li>
          <NavLink to="/privacy">Privacy Policy</NavLink>
        </li>{" "}
        {" | "}{" "}
        <li>
          <NavLink to="/terms">Terms and Conditions</NavLink>
        </li>{" "}
        {" | "}
        <li>
          <NavLink to="/help">Help</NavLink>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
