import React from "react";

import Header from "../components/Header";
import ProfileNavigation from "../components/ProfileNavigation";
import PrivacyPolicy from "../components/PrivacyPolicy";

const Privacy = () => {
  return (
    <div>
      <Header title="Privacy Policy" />
      <div className="profile-area">
        <div className="sk-container">
          <div className="profiles-main-warpp">
            <ProfileNavigation active={"privacy"} />
            <PrivacyPolicy />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
