import React from "react";
import { NavLink } from "react-router-dom";
import uniqueId from "react-html-id";

import axios from "axios";

import { config, ADULT_LOADING_IMAGES } from "../../Constants";
import AdminHeader from "../../components/Admin/AdminHeader";
import { getToken, isSuperAdmin } from "../../utils/profile-utils";
import { mapChildReport } from "../../utils/parent-dashboard-utils";
import { getChildReport } from "../../utils/teacher-dashboard-utils";
import Loader from "../../components/Loader";

class RescoreHomework extends React.Component {
  state = {
    loading: true
  };

  constructor(props) {
    super(props);

    if (!isSuperAdmin()) {
      this.props.history.push("/logout");
    }
    this.state.token = getToken();
    this.state.child = props.match.params.child;

    this.rescore = this.rescore.bind(this);
    this.remove = this.remove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.loadData = this.loadData.bind(this);
    this.assignLesson = this.assignLesson.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    getChildReport(this.state.child, this.loadData);
  }

  loadData(response) {
    console.log(response);
    const { childProfile, childReport, recommendations } = response; 
    const subjects = Object.keys(childReport.homeworkBySubject);
    const anchor = this.props.location.hash.replace("#", "");
    const pickedSubject = anchor ? anchor : subjects[0];

    const homeworkReports = Object.values(childReport.homework).map(c =>
      mapChildReport(c)
    );
    homeworkReports.sort((h1, h2) =>
      h2.displayDateValue > h1.displayDateValue ? 1 : -1
    );
    const lessons = homeworkReports.filter(
      l => l.lesson.subject === pickedSubject
    );
    console.log("pickedSubject pickedSubject : " + pickedSubject);
    this.setState({
      childProfile: childProfile,
      recommendations: recommendations,
      reports: childReport,
      homeworkReports: homeworkReports,
      subjects: subjects,
      subject: pickedSubject,
      lessons: lessons,
      loading: false
    });
  }

  rescore(lessonKey) {
    const queryPath =
      "/api/admin/lesson/report/" + this.state.child + "/" + lessonKey;
    console.log("pickedSubject queryPath : " + queryPath);

    axios
      .get(config.url.API_BASE_URL + queryPath, {
        headers: { Authorization: this.state.token }
      })
      .then(response => {
        console.log(response);
        window.location.reload();
      })
      .catch(error => console.log(error));
  }

  remove(lessonKey) {
    const queryPath =
      "/api/admin/lesson/remove/" + this.state.child + "/" + lessonKey;
    console.log("pickedSubject queryPath : " + queryPath);

    axios
      .get(config.url.API_BASE_URL + queryPath, {
        headers: { Authorization: this.state.token }
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log(error));
  }

  handleChange(event) {
    // console.log("New value: " + event.target.value);
    this.setState({ child: event.target.value });
    // console.log("Changed: " + this.state.child);
  }

  handleKeyChange(event) {
    // console.log("New value: " + event.target.value);
    this.setState({ lessonKey: event.target.value });
    // console.log("Changed: " + this.state.child);
  }

  assignLesson(role) {
    console.log("Assign request was created by " + role);
    let assignPath = "/api/homework/assign/parent/" + this.state.child + "/";

    console.log(
      "Assign request will be sent to " + assignPath + this.state.lessonKey
    );

    axios
      .get(config.url.API_BASE_URL + assignPath + this.state.lessonKey, {
        headers: { Authorization: getToken() }
      })
      .then(response => {
        console.log(response);
        window.location.reload();
      })
      .catch(error => console.log(error));
  }

  render() {
    const { homeworkReports, loading } = this.state;
    console.log("homeworkReports " + homeworkReports);
    const style = { border: "1px solid black" };
    return (
      <div className="admin-page">
        <AdminHeader />
        {homeworkReports && (
          <div>
            <table>
              <tr>
                <th style={style}>#</th>
                <th style={style}>Lesson Name</th>
                <th style={style}>Topic Name</th>
                <th style={style}>Score</th>
                <th style={style}>Assign Date</th>
                <th style={style}>Total Questions</th>
                <th style={style}>Attempts</th>
                <th style={style}>Exposure</th>
                <th style={style}>Confidence</th>
                <th style={style}>Accuracy</th>
                <th style={style}>Attempted Questions</th>
                <th style={style}>Correct Answers</th>
                <th style={style}>Activity</th>
                <th style={style}>Activity Date</th>
                <th style={style}>Rescore</th>
                <th style={style}>Remove</th>
              </tr>
              {homeworkReports.map((c, index) => (
                <tr>
                  <td style={style}>{index + 1}</td>
                  <td style={style}>
                    <NavLink
                      className="left"
                      to={
                        "/parent/report/child/" +
                        this.state.child +
                        "/lesson/" +
                        c.lesson.lessonKey
                      }
                    >
                      {c.lesson.name}
                    </NavLink>
                  </td>
                  {/* <td style={style}>{JSON.stringify(c)}</td> */}
                  <td style={style}>
                    <NavLink
                      to={
                        "/admin/catalog/" +
                        c.lesson.grade +
                        "/" +
                        c.lesson.subject +
                        "/" +
                        c.lesson.topicKey
                      }
                    >
                      {c.lesson.topicName}
                    </NavLink>
                  </td>
                  <td style={style}>{c.score}</td>
                  <td style={style}>{c.displayDate}</td>
                  <td style={style}>{c.totalQuestions}</td>
                  <td style={style}>{c.attempts}</td>
                  <td style={style}>{c.exposure}</td>
                  <td style={style}>{c.confidence}</td>
                  <td style={style}>{c.accuracy}</td>
                  <td style={style}>{c.attemptedQuestions}</td>
                  <td style={style}>{c.correctAnswers}</td>
                  <td style={style}>{c.questionActivity ? "YES" : "NO"}</td>
                  <td style={style}>
                    {c.reportDate ? c.displayDate : ""} &nbsp;
                    {c.recommendation && c.recommendation.lessonMetadata && (
                      <sub>{c.recommendation.lessonMetadata.lessonKey}</sub>
                    )}
                  </td>
                  <td style={style}>
                    <button
                      className="cursor-pointer"
                      onClick={() => this.rescore(c.lesson.lessonKey)}
                    >
                      Rescore
                    </button>
                  </td>
                  <td style={style}>
                    <button
                      className="cursor-pointer"
                      onClick={() => this.remove(c.lesson.lessonKey)}
                    >
                      remove
                    </button>
                  </td>
                  {/* <td style={style}>{JSON.stringify(c.lesson)}</td> */}
                  {/* <td style={style}>{JSON.stringify(c)}</td> */}
                </tr>
              ))}
            </table>
          </div>
        )}
        {loading && (
          <Loader
            image={
              ADULT_LOADING_IMAGES[
                Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
              ]
            }
          />
        )}{" "}
      </div>
    );
  }
}

export default RescoreHomework;
