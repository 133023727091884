import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import axios from "axios";

import { config } from "../../Constants";

import ReportHeader from "../../components/Parent/ReportHeader";
import { getActor, getToken, isSuperAdmin } from "../../utils/profile-utils";
import requireAuthentication from "../../components/AuthenticatedComponent";
import ShowProblem from "../../components/Student/LessonContent/ShowProblem";
import { findLesson, findLessonSource } from "../../utils/lesson-utils";
import { getImage } from "../../utils/image-utils";
import { getCall } from "../../utils/rest-utils";
import QuestionAnalysis from "../../components/Parent/Analysis/QuestionAnalysis";

class AdminLessonReport extends Component {
  // default State object
  state = {
    subject: ""
  };

  report = {};

  constructor(props) {
    super(props);

    console.log("AdminLessonReport::constructor " + JSON.stringify(props));
    this.state.lessonKey = props.match.params.lesson;

    this.report.pageName = "Admin Lesson Report - " + this.state.lessonKey;
    this.report.lessonKey = this.state.lessonKey;

    console.log("AdminLessonReport params: " + this.state.lessonKey);

    this.loadData = this.loadData.bind(this);
    this.loadSource = this.loadSource.bind(this);
    this.goBack = this.goBack.bind(this);
    // this.addTestLesson = this.addTestLesson.bind(this);
    this.publish = this.publish.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
    findLesson(this.state.lessonKey, this.loadData, null);
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
  }

  loadData(report) {
    findLessonSource(report.id, this.loadSource);
    this.setState({
      reports: report
    });
  }

  loadSource(source) {
    // console.log("loadSource: " + JSON.stringify(source));
    this.setState({
      lessonSource:
        source && source.contentSource ? source.contentSource : null,
      lessonReach: source.homework
    });
  }

  publish() {
    if (isSuperAdmin()) {
      const queryPath =
        "/api/v2/admin/content/publish/document/" + this.state.lessonKey;

      const boundFunction = function (response) {
        console.log(response);
        window.location.reload();
      }.bind(this);
      getCall(
        config.url.API_BASE_URL + queryPath,
        boundFunction,
        boundFunction
      );
    }
  }

  delete() {
    if (isSuperAdmin()) {
      console.log(this.state.reports);
      const queryPath = "/api/v2/admin/content/delete/document/" + this.state.reports.key;
      console.log(queryPath);

      axios
        .get(config.url.API_BASE_URL + queryPath, {
          headers: { Authorization: getToken() }
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => console.log(error));
    }
  }

  goBack() {
    // after login back should not take you to login button
    this.props.history.push(`/` + getActor());
  }

  // addTestLesson(questionIndex, testLessonKey) {
  //   console.log("adding question " + questionIndex + " to " + testLessonKey);
  //   const boundFunction = function (response) {
  //     console.log("Success " + questionIndex + " to " + testLessonKey);
  //     window.location.reload();
  //   };
  //   getCall(
  //     config.url.API_BASE_URL +
  //     "/api/admin/lesson/key/" +
  //     testLessonKey +
  //     "/question/" +
  //     questionIndex,
  //     boundFunction
  //   );
  // }

  render() {
    // console.log("AdminLessonReport render state: " + JSON.stringify(this.state));
    const { reports, lessonSource } = this.state;
    // console.log("AdminLessonReport render reports: " + JSON.stringify(reports));
    if (!reports) {
      return <span />;
    }
    const {
      subject,
      grade,
      name,
      shortName,
      id,
      questions,
      useSpeech,
      availableForHomework,
      useQuestionsInTest,
      skills,
      key
    } = reports;

    return (
      <div>
        {reports && (
          <ReportHeader
            lessonName={shortName}
            subject={subject}
            grade={grade}
            back={this.goBack}
          />
        )}
        <div className="completed-response">
          <div className="sk-container">
            <span className="left">
              <NavLink to={"/questions/" + id}>
                <h3>Student View</h3> &nbsp;&nbsp;&nbsp;&nbsp;
              </NavLink>
              {useSpeech && <span class="character char">&#x1F508;</span>}
              {!useSpeech && <span>&#x1F507;</span>}{" "}
              {!availableForHomework && (
                <p className="error-text">
                  Lesson is not available for homework
                </p>
              )}
              &nbsp;
              {availableForHomework != useQuestionsInTest && (
                <p className="">
                  <b>Fix lesson availability in Prismic</b>
                </p>
              )}
            </span>

            <span>
              <NavLink
                className="left"
                to={"/parent/report/child/admin/lesson/" + id}
              >
                <h3> &nbsp;&nbsp;&nbsp; Parent View</h3>
              </NavLink>
            </span>
            <span>
              {availableForHomework != useQuestionsInTest && (
                <span>
                  <b>Fix lesson availability in Prismic</b>
                </span>
              )}
            </span>
            {lessonSource && (
              <a className="right" href={lessonSource}>
                <h3>Content Source &nbsp;&nbsp;</h3>
              </a>
            )}
            <a
              className="right"
              href={
                "https://mrgflufz.prismic.io/documents~b=working&c=published&l=en-us/" +
                id
              }
            >
              <h3>Edit &nbsp;&nbsp;</h3>
            </a>
            <div className="right" onClick={() => this.publish()}>
              <h3>Republish &nbsp;&nbsp;</h3>
            </div>
            <div className="right" onClick={() => this.delete()}>
              <h3>Delete &nbsp;&nbsp;</h3>
            </div>
            <div className="clear">
              <span className="bold-text">
                Skills: {skills ? skills.join(", ") : "????"}
              </span>
            </div>
            <div className="spaced-container">
              {questions &&
                questions.map((c, index) => (
                  <QuestionReport
                    key={c.key}
                    index={index}
                    questonKey={c.key}
                    report={c}
                    lessonKey={id}
                    lessonName={name}
                    subject={subject}
                    // addTestLesson={this.addTestLesson}
                  />
                ))}
            </div>
          </div>
        </div>
        <ShowProblem report={this.report} />
      </div>
    );
  }
}

class QuestionReport extends Component {
  state = {
    testLessonKey: null
  };

  constructor(props) {
    super(props);

    // this.handleTestLessonKeyChange = this.handleTestLessonKeyChange.bind(this);
  }

  // handleTestLessonKeyChange(event) {
  //   this.setState({ testLessonKey: event.target.value, nameError: null });
  // }

  render() {
    const props = this.props;
    const { index, report } = this.props;
    const { level, lessonType, spiralReviews } = report;
    const subjectStyle = props.subject.toLowerCase() + "-indicator";
    // console.log("AdminLessonReport => QuestionReport props: " + JSON.stringify(props));
    return (
      <div className="complete-incomplete-area">
        <div className="single-com-item">
          {/* <div className="left-comp-text"> */}
          <QuestionAnalysis
            key={index}
            index={index}
            question={report}
            level={level}
            // topicName={topicMetadata.shortName}
            type={lessonType}
            preview={true}
          />
          {/* <div className="com-response-text"> */}
          <h5>
            <span className={"dot-complete " + subjectStyle} />
            {props.lessonName}
          </h5>
          {props.report.testLessonKey && (
            <div>
              {props.report.lessonKey != props.lessonKey && (
                <NavLink to={"/admin/lesson/" + props.report.lessonKey}>
                  {props.report.lessonKey}
                </NavLink>
              )}
              {props.report.testLessonKey != props.lessonKey && (
                <NavLink to={"/admin/lesson/" + props.report.testLessonKey}>
                  {props.report.testLessonKey}
                </NavLink>
              )}
              {/* <img
                src={getImage("about-icon-adult.svg")}
                onClick={() => props.addTestLesson(props.questonKey, null)}
              /> */}
            </div>
          )}
          {!props.report.testLessonKey && (
            <div>
              {spiralReviews ? <NavLink to={"/admin/lesson/" + spiralReviews}>{spiralReviews}</NavLink>  : "None"}
              {/* <input
                className="input"
                placeholder="Enter test lesson key"
                value={this.state.testLessonKey}
                onChange={this.handleTestLessonKeyChange}
              /> */}
              {/* <img
                src={getImage("about-icon-adult.svg")}
                onClick={() =>
                  props.addTestLesson(
                    props.questonKey,
                    this.state.testLessonKey
                  )
                }
              /> */}
            </div>
          )}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default requireAuthentication(AdminLessonReport);
