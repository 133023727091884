import React, { Component } from "react";

import { config } from "../../Constants";

import ReportHeader from "../../components/Parent/ReportHeader";
import requireAuthentication from "../../components/AuthenticatedComponent";
import { mapChildReport } from "../../utils/parent-dashboard-utils";
import { getChildReport } from "../../utils/teacher-dashboard-utils";
import ChallengeHistory from "../../components/Teacher/ChallengeHistory";

class LessonHistoryReport extends Component {
  // default State object
  state = {
    subject: ""
  };

  constructor(props) {
    super(props);

    console.log(props);
    this.state.subject = props.match.params.subject.toUpperCase();
    this.state.child = props.match.params.child;

    console.log(
      "LessonHistoryReport params: " +
        this.state.childId +
        " " +
        this.state.lessonKey
    );

    this.loadData = this.loadData.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
    getChildReport(this.state.child, this.loadData);
  }

  loadData(response) {
    const { subject } = this.state;
    console.log(response);

    const homeworkReports = Object.values(response.homework).map(c =>
      mapChildReport(c)
    );
    homeworkReports.sort((h1, h2) =>
      h2.displayDateValue > h1.displayDateValue ? 1 : -1
    );
    const lessons = homeworkReports.filter(l => l.lesson.subject === subject);
    console.log("pickedSubject pickedSubject : " + subject);
    this.setState({
      reports: response,
      homeworkReports: homeworkReports,
      lessons: lessons
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    // console.log('LessonHistoryReport render state: ' + JSON.stringify(this.state));
    // console.log(this.state);
    const { lessons, child } = this.state;
    return (
      <div>
        {lessons && (
          <ReportHeader
            lessonName={"Challenge History"}
            lessonCount={lessons.length}
            back={this.goBack}
          />
        )}
        {lessons && (
          <ChallengeHistory
            child={child}
            showAll={true}
            lessonLimit={lessons.length}
            homework={lessons}
            isClassReport={false}
          />
        )}
      </div>
    );
  }
}

export default requireAuthentication(LessonHistoryReport);
