import React, { useEffect, useState, useRef } from "react";
import { getImage, getAvatarImage } from "../../../utils/image-utils";
import {
  checkText,
  isNumeric,
  checkPassword
} from "../../../utils/validation-utils";

import "./AddChild.css";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

class EditChildForm extends React.Component {
  // default State object
  state = {
    showSubmit: true
  };

  constructor(props) {
    super(props);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleSchoolChange = this.handleSchoolChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleConfirmChange = this.handleConfirmChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.addChild = this.addChild.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  componentDidMount() {
    const { child, changePassword } = this.props;
    let isPasswordChange = false;
    if (changePassword) {
      isPasswordChange = true;
    }
    if (child) {
      this.setState({
        child: child,
        addChild: false,
        name: child.name,
        username: child.username,
        birthday: child.birthday,
        grade: child.grade,
        gender: child.gender,
        profileImage: child.profileImage,
        isPasswordChange: isPasswordChange
      });
    } else {
      this.setState({
        child: {},
        addChild: true,
        name: null,
        username: "",
        birthday: "",
        grade: "",
        gender: "female",
        isPasswordChange: false
      });
    }
  }

  closeModal() {
    this.props.onClose();
  }

  addChild() {
    const {
      child,
      name,
      addChild,
      username,
      birthday,
      grade,
      gender,
      school,
      password
    } = this.state;
    console.log("Checking Child grade " + grade);
    let valid = this.checkGrade();
    if (addChild && valid) {
      console.log("Checking username & password " + username + " " + password);
      valid =
        this.checkUsername() &&
        checkText(gender) &&
        this.checkConfirmPassword();
      if (valid) {
        child.password = password;
      }
    } else if (valid && name) {
      valid = this.checkName() && checkText(gender);
    }
    if (valid) {
      console.log("Saving Child");
      child.name = name;
      child.username = username;
      child.birthday = birthday;
      child.grade = grade;
      child.gender = gender;
      child.school = school;
      this.props.onSave(child);
    } else {
      console.log(name + " " + username + " " + grade + " " + gender);
    }
  }

  changePassword() {
    const { isPasswordChange, username, password } = this.state;
    console.log("Changing Child password " + username);
    let valid = isPasswordChange && this.checkConfirmPassword();
    if (valid) {
      console.log("Saving password ");
      this.props.changePassword({
        username: username,
        email: username,
        name: password
      });
    } else {
      console.log(" chagne password invalid" + username);
    }
  }

  handleGradeChange(event) {
    this.setState({
      grade: event.target.value
    });
  }

  handleDateChange(date) {
    this.setState({
      birthday: date.getTime()
    });
  }

  handleGenderChange(gender) {
    console.log("handleGenderChange: " + gender);
    this.setState({
      gender: gender
    });
  }

  handleNameChange(event) {
    console.log(event);
    console.log("New value: " + event.target.value);
    this.setState({ name: event.target.value, nameError: null });
    console.log("Changed: " + this.state.name);
  }

  handleUsernameChange(event) {
    console.log(event);
    console.log("New value: " + event.target.value);
    this.setState({ username: event.target.value, nameError: null });
    console.log("Changed: " + this.state.username);
  }

  handleSchoolChange(event) {
    console.log(event);
    console.log("New value: " + event.target.value);
    this.setState({ school: event.target.value, nameError: null });
    console.log("Changed: " + this.state.school);
  }

  checkUsername() {
    const error = checkText(this.state.username);
    let fieldError;
    if (!error) {
      fieldError = "Enter username";
    }
    this.setState({
      usernameError: fieldError,
      showSubmit: error
    });
    return error;
  }

  checkName() {
    const error = checkText(this.state.name);
    let fieldError;
    if (!error) {
      fieldError = "Enter name";
    }
    this.setState({
      nameError: fieldError,
      showSubmit: error
    });
    return error;
  }

  checkGrade() {
    console.log("Check grade: " + this.state.grade);
    const error = isNumeric(this.state.grade);
    let fieldError;
    if (!error) {
      fieldError = "Grade is required";
    }
    this.setState({
      gradeError: fieldError,
      showSubmit: error
    });
    return error;
  }

  handleConfirmChange(event) {
    console.log(event);
    console.log("New value: " + event.target.value);
    this.setState({ confirmPassword: event.target.value });
    console.log("Changed: " + this.state.confirmPassword);
  }

  handlePasswordChange(event) {
    console.log("New value: " + event.target.value);
    this.setState({ password: event.target.value });
    console.log("Changed: " + this.state.password);
  }

  checkPassword() {
    let error = checkText(this.state.password);
    let fieldError;
    if (!error) {
      fieldError = "Enter password";
    } else {
      error = checkPassword(this.state.password);
      if (!error) {
        fieldError = "Password must contain atleast 6 characters";
      }
    }
    this.setState({
      passwordError: fieldError,
      showSubmit: error
    });
  }

  checkConfirmPassword() {
    let error = checkText(this.state.confirmPassword);
    let fieldError;
    if (!error) {
      fieldError = "Confirm password";
    } else if (this.state.confirmPassword !== this.state.password) {
      fieldError = "Passwords must match";
      error = false;
    }
    this.setState({
      passwordConfirmError: fieldError,
      showSubmit: error
    });
    return error;
  }

  render() {
    const {
      child,
      addChild,
      gender,
      profileImage,
      showSubmit,
      isPasswordChange,
      name,
      nameError,
      username,
      usernameError,
      password,
      passwordError,
      confirmPassword,
      passwordConfirmError,
      birthday,
      grade,
      school,
      gradeError
    } = this.state;
    let boyActive, girlActive;
    if (gender) {
      boyActive = gender === "male" ? "active" : "";
      girlActive = gender === "female" ? "active" : "";
    }
    console.log("boyActive: " + boyActive + " girlActive: " + girlActive);

    return (
      <div>
        <div className="adult-editable single-pro-info">
          <div className="pro-info">
            {!addChild && !isPasswordChange && (
              <div className="single-pro-info">
                <span>Name</span>

                <span>
                  <input
                    className={nameError ? "input error" : "input"}
                    placeholder="Enter name"
                    onBlur={() => this.checkName()}
                    value={name}
                    onChange={this.handleNameChange}
                  />
                </span>
                {nameError && <span className="error-text">{nameError}</span>}
              </div>
            )}
            {addChild && (
              <div className="pro-tags">
                <span>Username</span>
                <input
                  className={usernameError ? "input error" : "input"}
                  placeholder="Enter username"
                  onBlur={() => this.checkUsername()}
                  value={username}
                  onChange={this.handleUsernameChange}
                />
                {usernameError && (
                  <span className="error-text">{usernameError}</span>
                )}
              </div>
            )}
            {(addChild || isPasswordChange) && (
              <div className="pro-tags">
                <span>Password</span>
                <input
                  className={passwordError ? "input error" : "input"}
                  type="password"
                  placeholder="Password"
                  onBlur={() => this.checkPassword()}
                  value={password}
                  onChange={this.handlePasswordChange}
                />
                {passwordError && (
                  <span className="error-text">{passwordError}</span>
                )}
              </div>
            )}
            {(addChild || isPasswordChange) && (
              <div className="pro-tags">
                <span>Confirm Password</span>
                <input
                  className={passwordConfirmError ? "input error" : "input"}
                  type="password"
                  placeholder="Confirm password"
                  onBlur={() => this.checkConfirmPassword()}
                  value={confirmPassword}
                  onChange={this.handleConfirmChange}
                />
                {passwordConfirmError && (
                  <span className="error-text">{passwordConfirmError}</span>
                )}
              </div>
            )}
            {!addChild && !isPasswordChange && child && (
              <div className="single-pro-info">
                <span>Birthday</span>
                <DatePicker
                  value={birthday}
                  selected={birthday}
                  onChange={this.handleDateChange}
                  maxDate={new Date()}
                />
              </div>
            )}
            {!addChild && !isPasswordChange && child && (
              <div className="single-pro-info">
                <span>School</span>
                <input
                  placeholder="Enter school name"
                  value={school}
                  onChange={this.handleSchoolChange}
                />
              </div>
            )}
            {!isPasswordChange && (
              <div className="pro-tags">
                <span>Select Grade</span>
                <div>
                  <select
                    className={gradeError ? "error" : ""}
                    value={grade}
                    onChange={this.handleGradeChange}
                    onBlur={() => this.checkGrade()}
                  >
                    <option>Pick Grade</option>
                    <option value="1">Grade 1</option>
                    <option value="2">Grade 2</option>
                    <option value="3">Grade 3</option>
                    <option value="4">Grade 4</option>
                    <option value="5">Grade 5</option>
                    <option value="6">Grade 6</option>
                    <option value="7">Grade 7</option>
                    <option value="8">Grade 8</option>
                  </select>
                  {gradeError && (
                    <span className="error-text">{gradeError}</span>
                  )}
                </div>
              </div>
            )}
            {!isPasswordChange && (
              <div className="pro-tags">
                <span>Profile Image:</span>
                <div style={{ display: "flex", padding: 20 + "px" }}>
                  {!profileImage && (
                    <div className={"gender-ic " + boyActive}>
                      <img
                        id="id-1-profile-picture"
                        src={getImage("profile_boy.jpg")}
                        alt="avatar-boy"
                        onClick={() => this.handleGenderChange("male")}
                      />
                    </div>
                  )}
                  {!profileImage && (
                    <div className={"gender-ic " + girlActive}>
                      <img
                        id="id-2-profile-picture"
                        src={getImage("profile_girl.jpg")}
                        alt="avatar-girl"
                        onClick={() => this.handleGenderChange("female")}
                      />
                    </div>
                  )}
                  {profileImage && child && (
                    <div className={"gender-ic " + girlActive}>
                      <img
                        id="id-3-profile-picture"
                        src={getAvatarImage(child)}
                        alt="avatar"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="clear manage-btn">
              {!this.props.hideCancel && (
                <a onClick={() => this.closeModal()}>CANCEL</a>
              )}
              {addChild && showSubmit && (
                <a onClick={() => this.addChild()} className="remove">
                  ADD
                </a>
              )}
              {!addChild && !isPasswordChange && showSubmit && (
                <a onClick={() => this.addChild()} className="remove">
                  Save
                </a>
              )}
              {isPasswordChange && showSubmit && (
                <a onClick={() => this.changePassword()} className="remove">
                  Change
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditChildForm;
