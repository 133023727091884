import React from 'react';
import { NavLink } from 'react-router-dom';
import { getImage } from '../../utils/image-utils';

const Notfound = () => {
  return (
    <div id="error-overlay-container">
      <div class="error-area">
        <div class="error-area-inner">
          <div class="sk-container">
            <div class="error-txt">
              <img src={getImage('connection-error.svg')} />
              <h4>Page Not Found</h4>
              <p>
                We can't seem to find the page you are looking for. You can
                return to the previous page, visit our homepage and report a
                problem.
              </p>
              <NavLink to="/">Visit Homepage</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notfound;
