import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "react-circular-progressbar/dist/styles.css";

import {
  displaySubject,
  showLeftArrow,
  showRightArrow,
  displayLessonType
} from "../../utils/display-utils";

import "./../Teacher/RecentChallenges.css";
import "./../../components/Parent/ChildChallenges.css";
import "./Recommendations.css";
import "./Recommendations-1.css";
import { getImage } from "../../utils/image-utils";

const Recommendations = ({ child, recommendations, subject }) => {
  // const [isPreview, setPreview] = useState(false);
  // showPreview(recommendation) {
  //   if (!preview) {
  //     const boundFunction = function(lesson) {
  //       this.setState({
  //         lesson: lesson
  //       });
  //     }.bind(this);
  //     findLesson(recommendation.recommendations.lessonKey, boundFunction);
  //   }
  //   this.setState({
  //     preview: !preview,
  //     recommendation: recommendation
  //   });
  // }

  return (
    <div class="col-md-7 col-lg-9 d-none d-md-block">
      <div class="card-scroller pb-2 pt-0 pl-1 pt-1">
        <div class="row card-spiral-row flex-nowrap">
          {recommendations &&
            recommendations.map((c, index) => {
              let style = {};
              if (index !== 0) {
                style = { paddingLeft: "-30px" };
              }
              return (
                <RecommendationCard
                  style={style}
                  key={index}
                  report={c.recommendations}
                  topicMetadata={c.topicMetadata}
                  child={child}
                  subject={subject}
                  recommendation={c}
                  // showPreview={this.showPreview}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

function RecommendationCard(props) {
  const {
    subject,
    report,
    child,
    topicMetadata,
    recommendation,
    showPreview
  } = props;
  const {
    attemptedQuestions,
    totalQuestions,
    assignDate,
    lesson,
    ref,
    displayDateValue,
    shortName,
    lessonKey
  } = report;

  // console.log("RecommendationCard report: " + JSON.stringify(report));
  // console.log("RecommendationCard topicMetadata: " + JSON.stringify(topicMetadata));

  const { grade } = child;
  const topics = 1;

  // console.log(attemptedQuestions + ' <= attampted ChildChallenges > LessonReportCard total => : ' + totalQuestions);
  let percentage = 0;
  if (totalQuestions > 0) {
    percentage = {
      width: (attemptedQuestions * 100) / totalQuestions + "%"
    };
  }
  // console.log('ChildChallenges > LessonReportCard percentage: ' + percentage);

  const complete = attemptedQuestions === totalQuestions ? true : false;

  return (
    <div class="col-sm-5">
      <div class="sk-card bg-transparent card-spiral-test border-0">
        <div class="card-header position-relative">
          <a href="" class="btn-link btn-spiral-text p-0">
            <img src={getImage("np_revision.svg")} alt="image" />
            {displayLessonType(report.lessonType)}
          </a>
          <div class="w-75">
            <div class="card-tag d-flex mb-2">
              <span>GRADE {grade}</span>
              <span>{displaySubject(topicMetadata.subject).toUpperCase()}</span>
            </div>
            <p class="mb-3">{topicMetadata.name}</p>
            {topics && topics > 1 && (
              <a href="#" class="btn btn-topic">
                + 3 topics
              </a>
            )}
          </div>
        </div>
        <div class="card-body">
          <div class="card-info-text mb-2 d-flex justify-content-between">
            <span>Questions : 15</span>
            <span>Time : 15</span>
          </div>
          <div class="card-body-content">
            <p>{shortName}</p>
          </div>

          <div class="card-btns">
            <a href="#" onClick={() => { alert("clicked") }} class="btn btn-default">
              <img src={getImage("np_play.svg")} class="icon-left" /> PREVIEW
            </a>
            <a href="#" class="btn ml-2 btn-primary">
              ASSIGN challenge{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

Recommendations.propTypes = {};

export default Recommendations;
