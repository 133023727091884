let _token = null;
let _profile = null;
let _notifications = null;
let _achievements = null;
let _serverVersion = process.env.REACT_APP_GIT_SHA;

export function updateProfileFromResponse(profile) {
  if (profile) {
    if (profile.adultProfile) {
      setProfile(profile.adultProfile);
    }
    if (profile.adultProfile.token) {
      setToken(profile.adultProfile.token);
    }
    if (profile.notificationList) {
      setNotifications(profile.notificationList);
    }
    if (profile.version) {
      _serverVersion = profile.version.split("-")[1];
    }
    if (profile.achievements) {
      _achievements = profile.achievements;
    }
  }
}

export function getProfile() {
  if (_profile) {
    return _profile;
  } else {
    return JSON.parse(localStorage.getItem("profile"));
  }
}

export function setProfile(profile) {
  _profile = profile;
  localStorage.setItem("profile", JSON.stringify(profile));
}

export function getToken() {
  return localStorage.getItem("token");
}

export function setToken(token) {
  localStorage.setItem("token", token);
}

export function getNotifications() {
  return _notifications;
}

export function getNewNotificationsCount() {
  if (_notifications) {
    return _notifications.filter(m => m.notificationStatus === "CREATED")
      .length;
  }
  return 0;
}

export function showNotification(notificationType) {
  if (_notifications) {
    const notification = getFirstNotification(notificationType);
    if (notification) {
      return true;
    }
  }
  return false;
}

export function getFirstNotification(notificationType) {
  if (_notifications) {
    const notificationList = _notifications.filter(
      n =>
        n.notificationType === notificationType &&
        n.notificationStatus === "CREATED"
    );
    if (notificationList && notificationList.length > 0) {
      return notificationList[0];
    }
  }
  return null;
}

export function setNotifications(notifications) {
  _notifications = notifications;
}

export function getAchievements() {
  return _achievements;
}

export function getServerVersion() {
  return _serverVersion;
}

export function getUsername() {
  if (getProfile()) {
    return getProfile().username;
  }
  return null;
}

export function getUserid() {
  if (getProfile()) {
    return getProfile().userid;
  }
  return null;
}

export function getName() {
  if (getProfile()) {
    return getProfile().name;
  }
  return null;
}

export function getScore() {
  if (getProfile()) {
    return getProfile().score;
  }
  return null;
}

export function getNextLevelRange() {
  if (getProfile()) {
    return getProfile().nextLevel.rangeLow;
  }
  return null;
}

export function getLevel() {
  if (getProfile()) {
    return getProfile().level;
  }
  return null;
}

export function getLevelIndex() {
  if (getProfile()) {
    return getLevel().index;
  }
  return null;
}

export function getEmail() {
  return getProfile().email;
}

export function getAvatar() {
  if (getProfile()) {
    return getProfile().avatar;
  }
  return null;
}

export function getFamilyId() {
  return getProfile().familyId;
}

export function getFamily() {
  return getProfile().family;
}

export function isParent() {
  return getProfile().access.includes("PARENT");
}

export function isAdmin() {
  // return getProfile() && getUsername() === "admin";
  return getProfile() && getProfile().access.includes("ADMIN");
}

export function isSuperAdmin() {
  return getProfile() && getUsername() === "admin";
}

export function hasChildren() {
  return getProfile() && getFamily() && getChildren();
}

export function getChildren() {
  return getFamily().children;
}

export function getChild(childId) {
  if (isParent() && childId !== "system") {
    return getChildren().filter(c => c.userid === childId)[0];
  }
  return null;
}

export function isTeacher() {
  return getProfile() && getClassroom();
}

export function hasStudents() {
  return getProfile() && getClassroom() && getStudents();
}

export function getGrade() {
  return getProfile().grade;
}

export function getBirthday() {
  return getProfile().birthday;
}

export function getClassroom() {
  return getProfile().classroom;
}

export function getClassroomId() {
  return getProfile().classroomId;
}

export function getStudents() {
  if (isTeacher()) {
    return getClassroom().students;
  }
  return null;
}

export function getClassCount() {
  if (isTeacher()) {
    return getClassroom().students.length;
  }
  return 0;
}

export function getStudent(childId) {
  if (isTeacher()) {
    return getStudents().filter(c => c.userid === childId)[0];
  }
  return null;
}

export function getActor() {
  if (getUsername() == "admin") {
    return getUsername();
  }
  if (isParent()) {
    return "parent";
  }
  if (isTeacher) {
    return "teacher";
  }
  return null;
}

export function getSubjects() {
  if (getProfile()) {
    return getProfile().subjects;
  }
  return null;
}

export function getFirstName(name) {
  let first = name.split(" ")[0];
  return first.charAt(0).toUpperCase() + first.slice(1);
}

export function logout() {
  localStorage.clear();
  _profile = null;
  _notifications = null;
  _token = null;
  _serverVersion = null;
}
