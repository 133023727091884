import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { displaySubject } from "../../../utils/display-utils";
import { getChildren, getSubjects } from "../../../utils/profile-utils";

const ChildPicker = ({
  child,
  subject,
  onChangeChild,
  onChangeSubject,
  createChallengePath
}) => {
  // console.log('Rendering ChildPicker props ' + JSON.stringify(this.props));

  // console.log('Rendering ChildPicker subjects: ' + subjects);

  const createChallengePathValue = createChallengePath ? createChallengePath : "/create/challenge";
  return (
    <div className="summary-main-tab">
      <div className="sk-container">
        <div className="main-tabs-summary">
          {/* <li className="active">Grade 1</li>
                    <li>Grade 2</li>
                    <li>Grade 3</li> */}
          {getChildren().map(c => (
            <li className={child === c.userid ? "active" : ""}>
              <a
                key={c.userid}
                onClick={() => {
                  console.log('SELECT CHILD in ChildPicker: ' + c.userid);
                  onChangeChild(c.userid);
                }}
              >
                {c.name}
              </a>
            </li>
          ))}
        </div>
      </div>
      <div className="summary-sub-tab">
        <div className="sk-container">
          <div className="sub-tab-list">
            {/* <li className="active">MATHS 33</li>
                        <li>SCIENCE</li>
                        <li>ENGLISH</li> */}
            <li key={"Recent"} className={subject === "Recent" ? "active" : ""}>
              <a
                href={"#" + "Recent"}
                onClick={() => {
                  // console.log('SELECT CHILD in ChildPicker: ' + child.userid);
                  onChangeSubject("Recent");
                }}
              >
                {displaySubject("Recent")}
              </a>
            </li>
            {getSubjects().map(c => (
              <li key={c} className={subject === c ? "active" : ""}>
                <a
                  href={"#" + c}
                  onClick={() => {
                    // console.log('SELECT CHILD in ChildPicker: ' + child.userid);
                    onChangeSubject(c);
                  }}
                >
                  {displaySubject(c)}
                </a>
              </li>
            ))}
          </div>

          <div className="tab-info-dot">
            <NavLink activeClassName="active" to={createChallengePathValue}>
              <div className="tab-menu-dot">
                <span />
                <span />
                <span />
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

ChildPicker.propTypes = {
  onChangeChild: PropTypes.func.isRequired,
  onChangeSubject: PropTypes.func.isRequired
};

export default ChildPicker;
