import React from "react";
import PropTypes from "prop-types";

import requireAuthentication from "../../AuthenticatedComponent";
import NumericKeyboard from "./NumericKeyboard";
import { getImage } from "../../../utils/image-utils";

class NumericTest extends React.Component {
  state = {
    hasTutorial: true,
    // showKeyboard: true,
    answer: ""
  };

  constructor(props) {
    super(props);

    this.textInput = React.createRef();

    this.checkAnswer = this.checkAnswer.bind(this);
    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.keyClear = this.keyClear.bind(this);
    this.showKeyboard = this.showKeyboard.bind(this);
    this.clearLastLetter = this.clearLastLetter.bind(this);
  }

  checkAnswer() {
    console.log("NumericTest Check answer: NumericTest " + this.state.answer);
    if (typeof this.props.checkAnswer === "function") {
      this.props.checkAnswer(this.state.answer);
      this.setState({ answer: "" });
    }
  }

  showKeyboard(event) {
    // console.log('NumericTest: showKeyboard ');
    // this.setState({ showKeyboard: !this.state.showKeyboard });
  }

  handleAnswerChange(letter) {
    console.log("NumericTest  New value: " + letter);
    const answer = (this.state.answer + letter).replace(/,/g, "");
    // check enter to submit answer
    this.setState({ answer: answer });
    console.log("Changed: >>" + answer + "<<");
  }

  clearLastLetter() {
    console.log("NumericTest  New value: " + this.state.answer);
    const answer = this.state.answer.substr(0, this.state.answer.length - 1);
    // check enter to submit answer
    this.setState({ answer: answer });
    console.log("Changed: >>" + answer + "<<");
  }

  keyPress(e) {
    const { answer } = this.state;
    console.log("NumericTest KEY: " + e.key);
    if (e.keyCode === 13 || e.key === "Enter") {
      console.log("CHECK ANSWER: ", this.state.answer);
      this.checkAnswer(this.state.answer);
      console.log("CLEAR ANSWER: ");
      this.setState({ answer: "" });
    } else {
      this.handleAnswerChange(e.key);
    }
  }

  keyClear(e) {
    const { answer } = this.state;
    console.log("NumericTest KEY: " + e.key);
    if (e.key === "Delete" || e.key === "Backspace") {
      if (answer && answer.length > 0) {
        this.setState({ answer: answer.substr(0, answer.length - 1) });
      }
    }
  }

  render() {
    const { questionReport, isReview, question } = this.props;
    const { answer } = this.state;
    console.log("NumericTest render props: " + JSON.stringify(this.props));
    let answerValue = answer && answer.length > 0 ? answer : "";
    let providedAnswer = "";
    let answerStyle = "";
    if (isReview && questionReport) {
      providedAnswer = questionReport.providedAnswer;
      // console.log('NumericTest providedAnswer:  ' + providedAnswer);
      if (questionReport.isCorrect) {
        answerStyle = "correct-answer activee";
      } else {
        answerStyle = "incorrect-1 activee";
      }
    }
    const displayImage = this.props.question.displayImage
      ? this.props.question.displayImage
      : getImage("pixel.png");
    return (
      <div class="question-tabs-content">
        <div class="sk-container-tab" id="tab1" style={{ display: "block" }}>
          {!this.props.isReview && (
            <div>
              <div class="question-form" style={{ maxWidth: "95%" }}>
                <span>QUESTION {this.props.questionIndex + 1}</span>
                <p
                  dangerouslySetInnerHTML={{
                    __html: (this.props.question.displayHtml ? this.props.question.displayHtml : this.props.question.question )
                  }}
                />
                <p>
                  <img src={displayImage} alt="content-image" />
                </p>
                <input
                  ref={this.textInput}
                  type="text"
                  value={answerValue}
                  placeholder="Enter Answer"
                  // onFocus={() => this.showKeyboard(this)}
                  onKeyPress={e => this.keyPress(e)}
                  onKeyUp={e => this.keyClear(e)}
                />
              </div>
              <div
                onClick={() => this.checkAnswer()}
                className="question-submit cursor-pointer"
              >
                <img alt="submit" src={getImage("question-submit.svg")} />
              </div>
            </div>
          )}
          {this.props.isReview && (
            <div class="question-form">
              <span>QUESTION {this.props.questionIndex + 1}</span>
              <p
                dangerouslySetInnerHTML={{
                  __html: this.props.question.question
                }}
              />
              <p>
                <img src={displayImage} alt="content-image" />
              </p>
              <input
                className={answerStyle}
                ref={this.textInput}
                type="text"
                value={providedAnswer}
                placeholder="No answer provided"
              />
            </div>
          )}
        </div>
        {this.state.showKeyboard && (
          <NumericKeyboard
            onLetter={this.handleAnswerChange}
            clearLastLetter={this.clearLastLetter}
            dismissKeyboard={this.showKeyboard}
            checkAnswer={this.checkAnswer}
          />
        )}
      </div>
    );
  }
}

NumericTest.propTypes = {
  hasTutorial: PropTypes.bool,
  question: PropTypes.object.isRequired,
  checkAnswer: PropTypes.func,
  learn: PropTypes.func
};

export default requireAuthentication(NumericTest);
