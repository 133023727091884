import React, { useEffect, useRef } from 'react';
import './NotificationsWidget.css';
import {
  getLevelIndex,
  getLevelNotification,
  getNotifications,
  getFirstNotification,
} from '../../../utils/profile-utils';
import ShowProblem from '../LessonContent/ShowProblem';
import { getImage } from '../../../utils/image-utils';

const LevelNotification = ({ onClose }) => {
  const node = useRef();

  const notification = getFirstNotification('LEVEL_UP');
  // const handleClick = e => {
  //   if (node.current.contains(e.target)) {
  //     // inside click do nothing
  //     return;
  //   }
  //   // outside click close card
  //   onClose(notification);
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClick);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClick);
  //   };
  // }, []);
  // console.log('LevelNotification level: ' + notification.lessonKey);
  // console.log('LevelNotification level: ' + getLevelIndex());
  return (
    <div className="lessonFinished">
      <div className="quit-challenge-container">
        <div className="error-area">
          <div className="error-area-inner">
            <div className="sk-container">
              <div className="complete-level">
                <img
                  className="happy-character"
                  src={getImage('character-happy.svg')}
                  alt="complete challenge"
                />
                <div className="level-box">
                  <div>
                    <p>
                      Congratulations! <br /> You are now
                    </p>
                    <p>
                      <span className="level-value">{getLevelIndex()}</span>
                    </p>
                    <p>LEVEL</p>
                  </div>
                  <a onClick={() => onClose(notification)}>Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShowProblem report={{ pageName: 'level-notification' }} />
    </div>
  );
};

export default LevelNotification;
