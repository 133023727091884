import React, { Component } from "react";

import axios from "axios";

import { config, ADULT_LOADING_IMAGES } from "../../Constants";
import {
  getUserid,
  getName,
  getChild,
  getFirstName,
  getChildren,
  isParent,
  getToken
} from "../../utils/profile-utils";
import { reportActions, report } from "../../utils/report-utils";
import requireAuthentication from "../../components/AuthenticatedComponent";
import ParentStorage from "./ParentStorage";
import ParentHeader from "../../components/Parent/ParentHeader";
import { getImage } from "../../utils/image-utils";
import SideNav from "../../components/Parent/SideNav";
import ProgressCard from "../../components/Parent/recommendations/ProgressCard";
import Loader from "../../components/Loader";
import RecommendationCard from "../../components/Parent/recommendations/RecommendationsCard";
import Toast from "../../components/Student/Toast/Toast";
import ProgressCarousel from "../../components/Parent/recommendations/ProgressCarousel";
import RecommendationsCarousel from "../../components/Parent/recommendations/RecommendationCarousel";
import LessonPreview from "../../components/Parent/LessonPreview";
import PickChallengeDate from "../../components/CreateChallenge/PickChallengeDate";
import ConnectionErrorHandler from "../../components/Student/ErrorHandler/ConnectionErrorHandler";
import ConfirmAssignement from "../../components/CreateChallenge/ConfirmAssignement";
import { clearFamilyReport } from "../../services/ParentService";

class ParentRecommendations extends Component {
  state = {
    toastText: null,
    isPreview: false,
    isAssign: false,
  };
  report = {};

  constructor(props) {
    super(props);

    this.state.childProfile = getChild(props.activeChild);

    this.report.pageName = "Parent Recommendations";
    this.report.userid = getUserid();
  }

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
    this.report.action = reportActions.LOAD_PAGE;
    report(this.report);
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
  }

  setPreview = (preview, recommendation) => {
    this.setState({
      isPreview: preview,
      recommendation: recommendation
    })
  }

  setAssign = (assign, recommendation) => {
    this.setState({
      isAssign: assign,
      recommendation: recommendation
    })
  }

  confirmAssign = (confirm) => {
    this.setState({
      isConfirmAssign: confirm
    })
  }

  setErrorMessage = (message) => {
    this.setState({
      errorMessage: message
    })
  }

  showToast = (text) => {
    this.setState({
      toastText: text
    })
  }

  createChallenge = (assigneeNameValue, dueDateValue) => {

    const { recommendation } = this.state;

    // setAssigneeNameValue(assigneeNameValue);
    console.log("Creating challenge for " + assigneeNameValue);
    console.log("with recommendation " + JSON.stringify(recommendation.recommendations));
    console.log("with lesson " + recommendation.recommendations.key);
    console.log("and topic " + recommendation.recommendations.topicId);
    console.log("Due date " + dueDateValue);

    let assignPath = "";
    if (isParent()) {
      assignPath = "/api/v2/parent/assign/" + assigneeNameValue + "/";
    }
    console.log("Assign request will be sent to " + assignPath);

    axios
      .post(config.url.API_BASE_URL + assignPath,
        {
          userid: assigneeNameValue,
          lessonId: recommendation.recommendations.key,
          topicId: recommendation.recommendations.topicId,
          dueDate: dueDateValue.getTime()
        },
        {
          headers: { Authorization: getToken() },
        })
      .then((response) => {
        console.log(response);
        this.setAssign(false);
        this.setState({
          assignees: assigneeNameValue
        }, () => this.confirmAssign(true));
        // force reload family report
        clearFamilyReport();
      })
      .catch((error) => {
        console.log(error);
        // setErrorMessage("(" + error.message + ")");
      });
  }

  render() {
    const { activeChild, loading, lessons, recommendations } = this.props;
    const { childProfile, toastText, isPreview, recommendation, isAssign, isConfirmAssign, errorMessage} = this.state;

    const childFirstName = getFirstName(childProfile.name);

    let complete = 0;
    let inProgress = 0;
    let pending = 0;
    if (lessons) {
      complete = lessons.filter(l => l.complete).length;
      inProgress = lessons.filter(l => l.inProgress).length;
      pending = lessons.filter(l => l.pending).length;
    }

    // recommendations are provided by subject flatten them out
    let recommendationsCards= [];
    if (recommendations) {
      Object.keys(recommendations).map(function(key) {
        recommendationsCards = recommendations[key];
        return recommendations[key];
      });
    }

    // console.log("ParentRecommendations render recommendationsCards: " + recommendationsCards.length);

    const children = getChildren();
    return (
      <div id="wrapper" class="parent-dashboard">
        {toastText && <Toast text={toastText} />}
        {isPreview && (
          <div class="modal modalAssignChallenge fade parent-dashboard show" 
            id="previewChallenge" tabindex="-1" role="dialog" 
            aria-labelledby="assignChallengeLabel" style={{ paddingRight: "15px", display: "block", overflowY: "auto" }}>
            <LessonPreview
              recommendation={recommendation.recommendations}
              lessonId={recommendation.recommendations.key}
            closePreview={() => this.setPreview(false)}
          /></div>
        )}
        {isAssign && (
          <PickChallengeDate
            assignees={activeChild}
            finalize={this.createChallenge}
            dismiss={() => this.setAssign(false)}
          />
        )}
        {isConfirmAssign && <ConfirmAssignement assignees={activeChild} />}
        {errorMessage && (
          <ConnectionErrorHandler
            onClose={() => this.setErrorMessage(null)}
            onRetry={() => this.setErrorMessage(null)}
            errorMessage={errorMessage}
            errorTitle="Error"
          />
        )}
        <ParentHeader page={"Recomendations"} />
        <SideNav
          child={childProfile}
          children={children}
          page={"Recommendation"}
          navPath="recommendations"
        />
        <main id="parent-recommendations" class="page-content">
          <div class="tab-body mt-4">
            <div class="container">
              <div class="welcome-well overflow-hidden pl-md-4 pr-0 pt-4 pl-3 pr-3 pb-3">
                <div class="row no-gutters">
                  <div class="col-md-4 col-lg-3">
                    <div class="pl-lg-3 mt-2">
                      <h3>👋 Welcome back {getFirstName(getName())},</h3>
                      {lessons && <h2>Here is what you have missed</h2>}

                      <ul class="list-unstyled list-tick mb-0">
                        {complete && complete > 0 ? (
                          <li>
                            {childFirstName} has completed {complete}{" "}
                            {complete === 1 ? "lesson" : "lessons"}
                          </li>
                        ) : null}
                        {pending && pending > 0 ? (
                          <li>
                            {pending}{" "}
                            {pending === 1 ? "test is " : "tests are "}
                            pending
                          </li>
                        ) : null}
                        {inProgress && inProgress > 0 ? (
                          <li>
                            {inProgress}{" "}
                            {inProgress === 1 ? "test is " : "tests are "}
                            incomplete{" "}
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                  {lessons && (
                    <ProgressCarousel
                      lessons={lessons}
                      childProfile={childProfile}
                      showToast={this.showToast}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {recommendations && (
            <div class="mt-4 pt-3">
              <div class="container">
                <div class="row mb-3">
                  <div class="col-sm-12">
                    <h3>Recommended challenges for "{childFirstName}"</h3>
                  </div>
                </div>
              </div>

              {recommendations && (
                <div class="">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-5 col-lg-3">
                        <div class="card-body card-smart-kid d-flex flex-column">
                          <div class="bright-tag mb-3 d-flex align-items-center">
                            <div class="icon">
                              <img src={getImage("bright.svg")} alt="icon" />
                            </div>
                            <h6>Smartkids Intelligence </h6>
                          </div>

                          <div class="card-smart-kid-body">
                            <p>
                              Based on our learnings,{" "}
                              we recommendation the following for  <strong>{childFirstName}</strong> 
                              {/* <strong>"Number System",</strong> */}
                            </p>
                          </div>

                          <div class="card-smart-kid-footer mt-auto">
                            <a href="#" class="arrow-right">
                              <img
                                src={getImage("np_arrow-right.svg")}
                                alt="icon"
                              />
                            </a>
                            <h5>
                              These are {recommendationsCards.length}{" "}
                              recommendation(s).
                            </h5>
                            <a
                              href="#"
                              class="sk-btn btn-sm btn-radius btn-light-purple"
                            >
                              Learn more
                            </a>
                          </div>
                        </div>
                      </div>

                      {recommendations && (
                        <RecommendationsCarousel
                          recommendations={recommendations}
                          childProfile={childProfile}
                          recommendationsCards={recommendationsCards}
                          preview={this.setPreview}
                          assign={this.setAssign}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {(isPreview || loading || isAssign) && <div class="modal-backdrop fade show"></div>}
        </main>
        <footer class="footer"></footer>
        {loading && (
          <Loader
            image={
              ADULT_LOADING_IMAGES[
                Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
              ]
            }
          />
        )}{" "}
      </div>
    );
  }
}

export default requireAuthentication(ParentStorage(ParentRecommendations));
