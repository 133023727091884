import { config } from "../Constants";
import { getCall } from "./rest-utils";
import { displayName } from "./display-utils";

export function getLessonReportForChild(childId, lessonId, callback) {
  const boundFunction = function(response) {
    callback(childId, response.data.object);
  };
  getCall(
    config.url.API_BASE_URL +
      "/api/report/child/" +
      childId +
      "/lesson/" +
      lessonId,
    boundFunction
  );
}

export function mapLesson(lesson) {
  if (!lesson) return null;
  
  let topicName = lesson.topicId;
  if (lesson.topic) {
    topicName = lesson.topic.shortName
      ? lesson.topic.shortName
      : displayName(lesson.topic.name);
  }
  const temp = {
    key: lesson.id,
    // lessonKey: lesson.lessonKey,
    name: lesson.name,
    grade: lesson.grade,
    subject: lesson.subject,
    questions: lesson.questions,
    shortName: lesson.shortName ? lesson.shortName : displayName(lesson.name),
    topicId: lesson.topicId,
    topicName: topicName,
    level: lesson.level,
    lessonType: lesson.lessonType
  };

  return temp;
}
