import React, { Component } from "react";

import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import SiteNavigation from "../components/SiteNavigation";
import { getImage } from "../utils/image-utils";
import PrivacyPolicy from "../components/PrivacyPolicy";
import SiteHeader from "../components/SiteHeader";

const SiteFooter = () => {
  return (


    <div class="home-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <h4>SmartKids.io</h4>
            <a href="/homeschool">Online Pilot</a>
          </div>
          <div class="col-md-4">
            <h4>In works..</h4>
            <ul>
              <li><a href="/about">About</a></li>
              <li><a href="/privacy">Privacy Policy</a></li>
              <li><a href="/terms">Terms and Conditions</a></li>
              <li><a href="/help">Help</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h4>Contact Info</h4>
            <a href="#">info at smartkids.io</a>
          </div>
        </div>
      </div>
      <p class="text-center mt-4"><small>Copyright © {new Date().getFullYear()} All rights reserved.</small></p>
    </div>
    // <footer className="ftco-footer ftco-bg-dark ftco-section">
    //   <div className="sk-container">
    //     <div className="row mb-5">
    //       <div className="col-md-4">
    //         <div className="ftco-footer-widget mb-4">
    //           <h2 className="ftco-heading-2">SmartKids.io</h2>
    //           <p>
    //             <NavLink to="/homeschool">Online Pilot</NavLink>
    //           </p>
    //         </div>
    //       </div>
    //       <div className="col-md-4">
    //         <div className="ftco-footer-widget mb-4 ml-md-5">
    //           <h2 className="ftco-heading-2">In works..</h2>
    //           <ul className="list-unstyled">
    //             <li>
    //               <span className="py-2 d-block">
    //                 <NavLink to="/about">About</NavLink>
    //               </span>
    //             </li>
    //             <li>
    //               <span className="py-2 d-block">
    //                 <NavLink to="/privacy">Privacy Policy</NavLink>
    //               </span>
    //             </li>
    //             <li>
    //               <span className="py-2 d-block">
    //                 <NavLink to="/terms">Terms and Conditions</NavLink>
    //               </span>
    //             </li>
    //             <li>
    //               <span className="py-2 d-block">
    //                 <NavLink to="help">Help</NavLink>
    //               </span>
    //             </li>
    //             {/* <li>
    //               <span className="py-2 d-block">GDPR</span>
    //             </li> */}
    //           </ul>
    //         </div>
    //       </div>
    //       <div className="col-md-4">
    //         <div className="ftco-footer-widget mb-4">
    //           <h2 className="ftco-heading-2">Contact Info</h2>
    //           <div className="block-23 mb-3">
    //             <ul>
    //               {/* <li>
    //                     <span className="fas fa-map-marker" />
    //                     <span className="text"> &nbsp; </span>
    //                   </li>
    //                   <li>
    //                     <span className="fas fa-phone" />
    //                     <span className="text"> &nbsp; </span>
    //                   </li> */}
    //               <li>
    //                 <span className="fas fa-envelope" />
    //                 <span className="text"> &nbsp; info at smartkids.io</span>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div className="col-md-12 text-center">
    //         <p>Copyright © {new Date().getFullYear()} All rights reserved.</p>
    //       </div>
    //     </div>
    //   </div>
    // </footer>
  );
};

export default SiteFooter;
