import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GraspWidgetHeader from './GraspWidgetHeader';
import TopicReportCard from './TopicReportCard';
import GraspReport from './GraspReport';
import SyllabusReport from './SyllabusReport';

const GraspWidget = ({
  syllabus,
  showReportsByTopic,
  lessonReports,
  classCount,
  isClassReport,
  child,
  reportPath,
}) => {
  {
    const [activeTopic, setActiveTopic] = useState(null);

    const showTopic = topicKey => {
      // console.log('GraspWidget serving: ' + topicKey);
      if (topicKey === activeTopic) {
        // console.log('GraspWidget clearing: ' + topicKey);
        topicKey = null;
      }
      setActiveTopic(topicKey);
    };

    // console.log('ALL LESSONS ' + lessonReports);
    let groups;
    if (showReportsByTopic) {
      // console.log('GraspWidget DISPLAY LESSONS ' + JSON.stringify(lessonReports));

      groups = lessonReports.reduce((objectsByKeyValue, obj) => {
        if (obj) {
          let lesson = obj['lesson'] ? obj['lesson'] : '';
          // console.log(lesson.shortName + " => " + lesson.topicKey);
          let value = lesson.topicKey ? lesson.topicKey : '';
          value = value.toLowerCase().trim();
          objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(
            obj
          );
        }
        return objectsByKeyValue;
      }, {});
      // console.log(groups);
      // for subject change check if topic is in the list
      const isInList =
        Object.keys(groups).filter(t => t === activeTopic).length > 0
          ? true
          : false;
      if ((!activeTopic && groups) || !isInList) {
        const topicKey = Object.keys(groups).filter(
          k => k != null && k.trim().length > 0
        )[0];
        // setActiveTopic(topicKey);
        console.log('GraspWidget new  activeTopic: ' + topicKey);
      }
    } else {
      console.log('GraspWidget lessons card: ' + lessonReports.length);
    }

    // console.log("GraspWidget syllabus card: " + JSON.stringify(syllabus));

    return (
      <div className="challenge-history">
        <div className="sk-container">
          <div className="history-box">
            <GraspWidgetHeader />
            <div className="digit-number">
              {/* display grouped by topic */}
              {/* {showReportsByTopic &&
                lessonReports &&
                Object.entries(groups).map(([key, value], index) => {
                  // console.log(key);
                  return (
                    <div>
                      <TopicReportCard
                        key={value[0].lesson.topicKey}
                        topicKey={key}
                        lessonList={value}
                        child={child}
                        isActive={activeTopic === key}
                        classCount={classCount}
                        isClassReport={isClassReport}
                        showTopic={showTopic}
                        reportPath={reportPath}
                      />
                    </div>
                  );
                })} */}
              {syllabus && lessonReports && syllabus.map(value => {
                // console.log(value);
                return (
                  <div>
                    <SyllabusReport
                      key={value.id}
                      topic={value}
                      lessonList={value.lessonList}
                      child={child}
                      isActive={activeTopic === value.key}
                      classCount={classCount}
                      isClassReport={isClassReport}
                      showTopic={showTopic}
                      reportPath={reportPath}
                    />
                  </div>
                );
              })}
              {!showReportsByTopic &&
                lessonReports.map(value => {
                  console.log(value);
                  return (
                    <div>
                      <GraspReport
                        key={value.lesson.key}
                        report={value}
                        child={child}
                        classCount={classCount}
                        isClassReport={isClassReport}
                        reportPath={reportPath}
                        isSub={false}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

GraspWidget.propTypes = {
  lessonReports: PropTypes.array.isRequired,
};

export default GraspWidget;
