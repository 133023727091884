import React from 'react';
import { getImage } from '../../../utils/image-utils';

const NumericKeyboard = ({
  answer,
  onLetter,
  clearLastLetter,
  checkAnswer,
}) => {
  return (
    <div className="keybord-area keyboard-page">
      <div class="sk-container">
        <div class="keyboard numeric">
          <ul>
            <li>
              <a onClick={() => onLetter(1)}>1</a>
            </li>
            <li>
              <a onClick={() => onLetter(2)}>2</a>
            </li>
            <li>
              <a onClick={() => onLetter(3)}>3</a>
            </li>
            <li>
              <a onClick={() => onLetter(4)}>4</a>
            </li>
            <li>
              <a onClick={() => onLetter(5)}>5</a>
            </li>
            <li>
              <a onClick={() => onLetter(6)}>6</a>
            </li>
            <li>
              <a onClick={() => onLetter(7)}>7</a>
            </li>
            <li>
              <a onClick={() => onLetter(8)}>8</a>
            </li>
            <li>
              <a onClick={() => onLetter(9)}>9</a>
            </li>
            <li>
              <a onClick={() => onLetter(0)}>0</a>
            </li>
            <li>
              <a class="cross" onClick={() => clearLastLetter()}>
                <img src={getImage('delete.svg')} alt="" />
              </a>
            </li>
            <li>
              <a class="enter" onClick={() => checkAnswer(answer)}>
                <img src={getImage('checked.svg')} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NumericKeyboard;
