import React, { PropTypes } from "react";
import { getImage } from "../../../utils/image-utils";
import { getMinutesDisplay } from "../../../utils/display-utils";

const TopicTimeSpent = ({ topicMetrics, learningMetrics, testingMetrics }) => {
  return (
    <div class="col-lg-6 mt-3">
      <div class="sk-card">
        <div class="card-body">
          <div class="row mt-3 mb-4 align-items-center">
            <div class="col-auto">
              <div class="time-stamp">
                {/* <strong>22 hr : 30min</strong> */}
                {topicMetrics && (
                  <strong>{getMinutesDisplay(topicMetrics ? topicMetrics.timeSpent : 0)} Minutes</strong>
                )}
                <span>Time Spent on topic</span>
              </div>
            </div>
            <div class="col-auto ml-auto">
              <img src={getImage("np_time.svg")} alt="time" />
            </div>
          </div>

          <div class="row text-gray label-time align-items-center">
            <div class="col-auto">
              <div class="learning-label my-sm-0 my-1">
                Learning
                <strong style={{marginLeft: "1em"}}>
                  {/* 01 <small>hr</small> : 30 */}
                  {getMinutesDisplay(learningMetrics ? learningMetrics.timeSpent : 0)} {" "}
                  <small>min</small>
                </strong>
              </div>
            </div>
            <div class="col-auto ml-sm-auto">
              <div class="testing-label my-sm-0 my-1">
                Testing
                <strong style={{marginLeft: "1em"}}>
                  {/* 00 <small>hr</small> :{" "} */}
                  {getMinutesDisplay(testingMetrics ? testingMetrics.timeSpent : 0)} {" "}
                  <small>min</small>
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicTimeSpent;