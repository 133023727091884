import React, { Component } from "react";
import requireAuthentication from "../../components/AuthenticatedComponent";
import { getChild, getUserid, getChildren, getFirstName } from "../../utils/profile-utils";
import { report, reportActions } from "../../utils/report-utils";
import { config, ADULT_LOADING_IMAGES, SUBJECT_STYLES } from "../../Constants";
import ParentHeader from "../../components/Parent/ParentHeader";
import SideNav from "../../components/Parent/SideNav";
import Loader from "../../components/Loader";
import { getImage } from "../../utils/image-utils";
import ParentStorage from "./ParentStorage";
import { getMinutesValue, getMinutesDisplay, displaySubject } from "../../utils/display-utils";
import { getChildDashboard } from "../../services/ParentService";
import SubjectChallengeTypes from "../../components/dashboards/subject/SubjectChallengeTypes";
import SubjectChallengeProgress from "../../components/dashboards/subject/SubjectChallengeProgress";
import SubjectTopicSummary from "../../components/dashboards/subject/SubjectTopicSummary";

class SubjectPerformance extends Component {
  state = {
    loading: false,
  };

  report = {};

  constructor(props) {
    super(props);

    const child = props.match.params.child;
    this.state.subject = props.match.params.subject;
    this.state.activeChild = child;
    this.state.childProfile = getChild(child);

    this.report.pageName = "Parent Dashboard";
    this.report.userid = getUserid();
    } 
    
    componentDidMount() {

        document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
        document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
        document.body.classList.add(config.class.ADULT_PAGE_CLASS);
        this.report.action = reportActions.LOAD_PAGE;
        report(this.report);
    
          const { activeChild, subject } = this.state;

          const boundFunction = function (response, lessonProgress) {
            // console.log("SubjectPerformance: componentDidMount " + JSON.stringify(response));
            // console.log("SubjectPerformance: componentDidMount lessonProgress: " + JSON.stringify(lessonProgress));

            const metrics = response;

            const topicMetrics =
              Object.keys(metrics.topicMetrics).map((m) => {
                const value = metrics.topicMetrics[m];
                console.log(m + " SubjectPerformance  topic: " + value.subject);
                if (value && value.subject === subject) {
                  console.log(value.subject + " ####SubjectPerformance filter topics: " + JSON.stringify(value));
                  value.key = m;
                  return value;
                }
                return null;
              });

            this.setState({
              metrics: metrics,
              subjectMetrics: metrics.subjectMetrics[subject.toUpperCase()],
              learningMetrics: metrics.learningSubjectMetrics[subject.toUpperCase()],
              testingMetrics: metrics.testingSubjectMetrics[subject.toUpperCase()],
              topicMetrics: topicMetrics,
              lessonProgress: lessonProgress,
              loading: false,
            });
          }.bind(this);
          const startDate = new Date();
          const endDate = new Date().setTime(
            startDate.getTime() - 90 * 60 * 60 * 24 * 1000
          );
          const range = {
            startDate: startDate,
            endDate: endDate,
            days: this.state.days,
          };
          getChildDashboard(activeChild, range, boundFunction);
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
  }

  render() {
      const { loading, activeChild, subject, childProfile, subjectMetrics, learningMetrics, 
        testingMetrics, topicMetrics, lessonProgress } = this.state;

    //   console.log("SubjectPerformance topicMetrics: " + JSON.stringify(topicMetrics))
 
    const subjectStyle = SUBJECT_STYLES[subject.toUpperCase()]
    return (
      <div id="wrapper" className="parent-dashboard">
        <ParentHeader page={"Performance"} />
        <SideNav
          child={childProfile}
          children={getChildren()}
          page={"Performance"}
          navPath="performance"
        />

        <main class="page-content">
            
          <div class="tab-body pt-2 mt-4">
            <div class="container">
              <div class="d-flex w-100 mb-3 align-items-center flex-wrap">
                <ol class="breadcrumb p-0 bg-transparent mb-sm-0">
                  <li class="breadcrumb-item">
                  <a href={"/parent/performance/" + activeChild}>{getFirstName(childProfile.name)}</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="#">{displaySubject(subject)}</a>
                  </li>
                </ol>

                <div class="ml-auto user-name">
                  <img
                    src={getImage("logo-small.svg")}
                    class="rounded-circle"
                    alt="image"
                  />
                  <span>INSIGHTS</span>
                </div>
                {/* <!-- /.ml-auto --> */}
              </div>
              {/* <!-- /.d-flex w-100 mb-3 --> */}

              <div class="sk-card mt-4 bg-white card-reset overflow-hidden">
                <div
                  class="card-header card-header-rounded p-md-4 p-3 text-white"
                  style={{ backgroundColor: subjectStyle.backgroundColor }}
                >
                  <div class="row align-items-center">
                    <div class="col-sm-7">
                      <a href={"/parent/performance/" + activeChild}>
                        <h2 class="text-white">
                          <img src={getImage("np_back.svg")} alt="back" /> Subject
                          performance
                        </h2>
                      </a>
                    </div>
                    {/* <div class="col-sm-5">
                      <div class="d-flex flex-wrap">
                        <select class="btn btn-lg text-normal ml-sm-3 mr-1 mb-1 ml-sm-auto bg-white">
                          <option>All Subjects</option>
                          <option>Action</option>
                          <option>Another action</option>
                        </select>
                        <select class="btn btn-lg text-normal mb-1 ml-sm-1 bg-white">
                          <option>Last 7 days</option>
                          <option>Action</option>
                          <option>Another action</option>
                        </select>
                      </div>
                    </div> */}
                  </div>

                  {(learningMetrics || testingMetrics) && <div class="row mb-3">

                                    <SubjectChallengeTypes subjectMetrics={subjectMetrics}
                                        learningMetrics={learningMetrics}
                                        testingMetrics={testingMetrics} />
                                    <SubjectChallengeProgress 
                                    subject={subject}
                                    subjectMetrics={subjectMetrics}
                                        learningMetrics={learningMetrics}
                                        testingMetrics={testingMetrics} 
                      lessonProgress={lessonProgress} />

                  </div>}
                </div>
              </div>

                        
                        {topicMetrics && <SubjectTopicSummary subjectStyle={subjectStyle} childProfile={childProfile}
                            subject={subject}
                            topicMetrics={topicMetrics}/>}

            </div>
            {/* <!-- /.container --> */}
          </div>
          {/* <!-- /.tab-body mt-4 --> */}
        </main>

        {loading && (
          <Loader
            image={
              ADULT_LOADING_IMAGES[
                Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
              ]
            }
          />
        )}
      </div>
    );
  }
}

export default requireAuthentication(ParentStorage(SubjectPerformance));
