import React, { useRef, useEffect, useState } from "react";
import { getImage } from "../../utils/image-utils";
import { displaySubject } from "../../utils/display-utils";
import { SUBJECT_STYLES } from "../../Constants";

const SubjectBar = ({ subject, child, metrics }) => {
    console.log("SubjectBar metrics: " + JSON.stringify(metrics));
    const subjectStyle = SUBJECT_STYLES[subject.toUpperCase()]
    return (
        <div
            class="strip-content p-sm-3 p-2 text-white d-flex align-items-center mb-2"
            style={{ backgroundColor: subjectStyle.backgroundColor }}
        >
            <div class="strip-count">{new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 0,
            }).format(metrics.score)}%</div>
            <div class="strip-info pl-sm-3 pl-2 flex-grow-1">
                <h4>{displaySubject(subject)}</h4>

                <div class="d-flex si-bottom flex-wrap align-items-center">
                    {/* <div class="strip-pill mr-2">+ 20%</div>
                    <strong>Performance up</strong> */}

                    <a href={"/parent/performance/"+child+"/"+subject} class="btn ml-auto btn-sm btn-strip">
                        Analysis{" "}
                        <img
                            src={getImage("np_arrow_white.svg")}
                            class="icon-right"
                            alt="arrow"
                        />
                    </a>
                </div>
            </div>
        </div>

    );
};

export default SubjectBar;
