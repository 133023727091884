import React from 'react';

import './ProgressBar.css';

function ProgressBar(props) {
  const { number, current } = props;

  // console.log("ProgressBar props:" + JSON.stringify(props));

  const renderWidgets = [...Array(number)].map((e, i) => {
    // let styles = "progress-widgets";
    let styles = '';
    let linkStyles = 'cursor-pointer inactive';
    const minRange = current - 5 > 0 ? current - 5 : 0;
    const maxRange = minRange + 9;
    if (i === current) {
      // styles += " active";
      styles += ' att';
      linkStyles += ' active';
    } else if (i < current) {
      styles += ' complete';
    } else {
      styles += ' incomplete';
    }

    // console.log(minRange + " ProgressBar range: " + maxRange);
    if ((i < minRange && i !== 0) || (i > maxRange && i !== number - 1)) {
      // console.log(i + " ProgressBar hide style: " + styles);
      styles += ' hide';
    } else if (i === maxRange) {
      // console.log(i + ' ProgressBar last style: ' + styles);
      styles += ' last';
    }
    if ((i < minRange && i === 0) || (i > maxRange && i === number - 1)) {
      linkStyles += ' highlight';
      styles += ' highlight';
    }

    const questionReport = props.questionReport[i];
    // console.log(i + " ProgressBar questionReview was correct?:" + props.questionReport[i]);
    if (questionReport) {
      if (props.questionReport[i].isCorrect) {
        styles += ' correct';
      } else {
        styles += ' incorrect';
      }
    } else {
      if (props.isReview) {
        // in review mode all unattempted questions will be marked as incorrect
        styles += ' incorrect';
      } else if (i < props.current) {
        // in test mode all unattempted questions before the current question will be marked as incorrect
        styles += ' incorrect';
      }
    }

    return (
      <li className={styles} key={i} onClick={() => props.pickCard(i)}>
        <span className={linkStyles}>{i + 1}</span>
      </li>
    );
  });

  return (
    <div className="questions-tab-list border-active">
      <ul id="tabs" className="siblings">
        {renderWidgets}
      </ul>
    </div>
  );
}

export default ProgressBar;
