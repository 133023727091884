import React from "react";

import { Route, Redirect } from "react-router-dom";
import { isAdmin } from "../utils/profile-utils";

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAdmin() === true ? (
        <Component {...props} />
      ) : (
        <Redirect to="/notFound" />
      )
    }
  />
);

export default AdminRoute;
