import React, { useState } from "react";
import { getImage, getLevelImage } from "../../../utils/image-utils";
import { ReportBase } from "istanbul-lib-report";
import { getNumber, displaySubject } from "../../../utils/display-utils";
import QuestionTime from "./QuestionTime";

const NumericDisplay = ({ question, questionType, report, unique }) => {
  // console.log("NumericDisplay::question " + question);
  const answer = question.options ? question.options[0] : question.answer;
  return (
    <React.Fragment>
      <ul class="subjective-answer list-unstyled">
        {unique && unique.length > 0 ? unique.map((o) => {
          return (
            <li className={o === answer ? "correct" : "wrong"}>
              {o}
            </li>
          );
        }) : <li class="default">NA</li>}
      </ul>
      <div class="result pt-3">
        CORRECT ANSWER: <span class="number pass">{answer}</span>
      </div>
    </React.Fragment>
  );
};

export default NumericDisplay;
