// TODO use loglevel-plugin-remote
import axios from 'axios';
import { v4 } from 'uuid';
import { config } from '../Constants';
import { getToken, logout, getUserid } from './profile-utils';

const sessionId = v4();

export const reportActions = {
  LOAD_PAGE: 'LOAD_PAGE',
  START_LESSON: 'START_LESSON',
  LOAD_QUESTION: 'LOAD_QUESTION',
  CORRECT_ANSWER: 'CORRECT_ANSWER',
  INCORRECT_ANSWER: 'INCORRECT_ANSWER',
  SCORE_LESSON: 'SCORE_LESSON',
  END_LESSON: 'END_LESSON',
};

export function report(report) {
  report.sessionId = sessionId;
  report.eventId = v4();
  report.userid = getUserid();
  report.timestamp = new Date().getTime();
  report.version = process.env.REACT_APP_GIT_SHA;
  var reports = [report];
  axios
    .post(config.url.API_BASE_URL + '/api/report/', reports, {
      headers: { Authorization: getToken() },
    })
    .then(response => {
      // console.log(JSON.stringify(reports));
    })
    .catch(error => {
      console.log(JSON.stringify(error) + '\n' + JSON.stringify(reports));
      if (error && error.response) {
        if (error.response.status === 401) {
          logout();
        }
      }
    });
}

export function reportProblem(report, token) {
  report.sessionId = sessionId;
  report.eventId = v4();
  report.timestamp = new Date().getTime();
  report.version = process.env.REACT_APP_GIT_SHA;
  var reports = [report];
  axios
    .post(config.url.API_BASE_URL + '/api/problem/report/', reports, {
      headers: { Authorization: token },
    })
    .then(response => {
      console.log(JSON.stringify(reports));
    })
    .catch(error => {
      console.log(JSON.stringify(error) + '\n' + JSON.stringify(reports));
      if (error && error.response) {
        if (error.response.status === 401) {
          logout();
        }
      }
    });
}
