import React, { useState } from "react";
import { getImage, getLevelImage } from "../../../utils/image-utils";
import { ReportBase } from "istanbul-lib-report";
import { getNumber, displaySubject } from "../../../utils/display-utils";
import QuestionTime from "./QuestionTime";

const FlashcardDisplay = ({question, report}) => {
    return (
      <React.Fragment>
        {question && question.questionRenderType ? (
          <span class="flash-card-list">
            {question.questionRenderType === 'HTML' ? (
              <p dangerouslySetInnerHTML={{ __html: question.contentMarkup }} />
            ) : null}
            {question.questionRenderType === 'VIDEO' ? (
              <div>
                <video controls>
                  <source type="video/mp4"
                    src={question.contentMarkup} />
                </video>
              </div>
            ) : null}
            {question.questionRenderType === 'IMAGE' ? (
              <div>
                <img 
                  src={question.displayImage} />
              </div>
            ) : null}
          </span>
        ) : null}
      </React.Fragment>
    );
}

export default FlashcardDisplay;