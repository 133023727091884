import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { getImage, getAvatarImage } from "../utils/image-utils";
import {
  getProfile,
  getChildren,
  isParent,
  getActor,
  isTeacher,
  getGrade,
  getName,
  getEmail
} from "../utils/profile-utils";
import AddChildModal from "./Parent/AddChildModal";
import "../containers/CreateChallenge/CreateChallenge.css";

const SiteNavigation = ({ active }) => {
  const [isShowing, setIsShowing] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log('SiteNavigation window size ' + window.innerWidth);
  if (!showMenu && window.innerWidth > 480) {
    toggleMenu();
  }

  function toggle() {
    setIsShowing(!isShowing);
  }

  function toggleMenu() {
    setShowMenu(!showMenu);
    // console.log('SiteNavigation hideMenu? ' + showMenu);
  }

  let children = null;
  let actor = getActor();
  if (isParent()) {
    children = getChildren();
  }
  let grade = null;
  if (isTeacher()) {
    grade = getGrade();
  }

  // console.log('SiteNavigation actor: ' + actor);
  return (
    <div>
      <div className="menu-target" onClick={() => toggleMenu()}>
        <img src={getImage("menu-button.svg")} alt="menu" />
      </div>
      {isShowing && (
        <div className="adult-page">
          <AddChildModal onClose={toggle} />
        </div>
      )}
      <div className={"main-pro " + (showMenu ? "active" : "")}>
        <div className="main-profile-inner">
          <div className="profile-grade">
            <ul className="setting-option">
              <li className={active === "schooling" ? "active" : ""}>
                <img src={getImage("help-icon-adult.png")} alt="" />
                <NavLink to={"/homeschool"}>Online Pilot</NavLink>
              </li>
            </ul>
          </div>
          <div className="profile-grade">
            <ul className="setting-option">
              {/* <li /> */}
              <li className={active === "about" ? "active" : ""}>
                <img src={getImage("about-icon-adult.svg")} alt="" />
                <NavLink to={"/about"}>About</NavLink>
              </li>
            </ul>
          </div>
          <div className="profile-grade">
            <ul className="setting-option">
              <li className={active === "privacy" ? "active" : ""}>
                <img src={getImage("about-icon-adult.svg")} alt="" />
                <NavLink to={"/privacy"}>Privacy Policy</NavLink>
              </li>
            </ul>
          </div>
          <div className="profile-grade">
            <ul className="setting-option">
              <li className={active === "terms" ? "active" : ""}>
                <img src={getImage("about-icon-adult.svg")} alt="" />
                <NavLink to={"/terms"}>Terms and Conditions</NavLink>
              </li>
            </ul>
          </div>
          <div className="profile-grade">
            <ul className="setting-option">
              <li className={active === "help" ? "active" : ""}>
                <img src={getImage("help-icon-adult.png")} alt="" />
                <NavLink to={"/help"}>Help</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteNavigation;
