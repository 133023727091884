import React, { Component } from "react";

import { config, ADULT_LOADING_IMAGES } from "../../Constants";
import { getUserid, getChild, getChildren } from "../../utils/profile-utils";
import { reportActions, report } from "../../utils/report-utils";
import requireAuthentication from "../../components/AuthenticatedComponent";
import ParentStorage from "./ParentStorage";
import ParentHeader from "../../components/Parent/ParentHeader";
import { getImage } from "../../utils/image-utils";
import SideNav from "../../components/Parent/SideNav";
import Loader from "../../components/Loader";
import Toast from "../../components/Student/Toast/Toast";
import {
  getLessonProgress,
  getHomeworkReport,
} from "../../services/ParentService";
import ChallengeHistoryItem from "../../components/Parent/recommendations/ChallengeHistoryItem";
import { displaySubject } from "../../utils/display-utils";

class ParentChallengeHistory extends Component {
  state = {
    toastText: null,
  };
  report = {};

  constructor(props) {
    super(props);

    this.state.childProfile = getChild(props.activeChild);
    this.state.lessons = props.lessons;

    this.report.pageName = "Parent Challenge History";
    this.report.userid = getUserid();
  }

  componentDidMount() {
    const { childProfile } = this.state;
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.add(config.class.ADULT_PAGE_CLASS);
    this.report.action = reportActions.LOAD_PAGE;
    report(this.report);

    let lessons = {};

    const reportData = getLessonProgress(
      this.state.childProfile.userid,
      null
    );
    if (reportData) {
      let subjectsList = [];
      reportData.homeworkKeys.forEach((l) => {
        // console.log("ParentChallengeHistory map homeworkKey: " + l);
        const lesson = getHomeworkReport(childProfile.userid, l);
        lessons[l] = lesson;
        subjectsList.push(displaySubject(lesson.lesson.subject));
        // console.log("ParentChallengeHistory subject: " + JSON.stringify(lesson));
      });
      const subjects = Array.from(new Set(subjectsList));
      console.log("ParentChallengeHistory subjects: " + subjects);
      this.setState({
        report: reportData,
        subjects: subjects,
        lessons: lessons,
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
  }

  changeSubject = (event) => {
    const subject = event.target.value;
    this.setState({
      subject: subject && subject.length > 0 ? subject : null,
    });
  };

  changeStatus = (event) => {
    const status = event.target.value;
    this.setState({
      status: status && status.length > 0 ? status : null,
    });
  };

  render() {
    const { loading } = this.props;
    const {
      childProfile,
      toastText,
      report,
      allLessons,
      lessons,
      subject,
      subjects,
    } = this.state;

    // console.log("ParentChallengeHistory render report: " + report);

    // console.log("ParentChallengeHistory render lessons: " + JSON.stringify(lessons));

    return (
      <div id="wrapper" className="parent-dashboard">
        {toastText && <Toast text={toastText} />}
        <ParentHeader page={"Challenge History"} />
        <SideNav
          child={childProfile}
          children={getChildren()}
          page={"Challenge History"}
          navPath="challenges"
        />
        <main id="challenge-summary" class="page-content">
          <div class="tab-body mt-4">
            <div class="container">
              <div
                class="card card-reset overflow-hidden card-challenge-summary"
                style={{ borderRadius: "12px" }}
              >
                <div class="card-body p-0">
                  <div class="d-sm-flex align-items-center mb-3 flex-wrap justify-content-between">
                    <h2 class="mb-1">Challenge Summary</h2>

                    {subjects && (
                      <select
                        class="btn btn-lg text-normal btn-default mb-1 ml-sm-auto"
                        value={this.state.subject}
                        onChange={this.changeSubject}
                      >
                        <option value="">All Subjects</option>
                        {subjects.map((s) => (
                          <option value={s}>{displaySubject(s)}</option>
                        ))}
                      </select>
                    )}
                  </div>

                  <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3 mt-3">
                      <div class="sk-card card-reset overflow-hidden card-challenge">
                        <div class="card-body">
                          <div class="cc-icon mt-2">
                            <img
                              src={getImage("icon-created.svg")}
                              alt="icon"
                            />
                          </div>
                          <div class="d-flex justify-content-between">
                            <div class="cc-name">
                              <span class="d-block">Challenges</span>
                              Created:
                            </div>
                            {report && (
                              <div class="cc-status">{report.created}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3 mt-3">
                      <div class="sk-card card-reset overflow-hidden card-challenge">
                        <div class="card-body">
                          <div class="cc-icon mt-2">
                            <img
                              src={getImage("icon-completed.svg")}
                              alt="icon"
                            />
                          </div>
                          <div class="d-flex justify-content-between">
                            <div class="cc-name">
                              <span class="d-block">Challenges</span>
                              Completed
                            </div>
                            {report && (
                              <div class="cc-status">{report.complete}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3 mt-3">
                      <div class="sk-card card-reset overflow-hidden card-challenge">
                        <div class="card-body">
                          <div class="cc-icon mt-2">
                            <img
                              src={getImage("icon-pending.svg")}
                              alt="icon"
                            />
                          </div>
                          <div class="d-flex justify-content-between">
                            <div class="cc-name">
                              <span class="d-block">Challenges</span>
                              Pending
                            </div>
                            {report && (
                              <div class="cc-status">{report.incomplete}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3 mt-3">
                      <div class="sk-card card-reset overflow-hidden card-challenge">
                        <div class="card-body">
                          <div class="cc-icon mt-2">
                            <img
                              src={getImage("icon-inprogress.svg")}
                              alt="icon"
                            />
                          </div>
                          <div class="d-flex justify-content-between">
                            <div class="cc-name">
                              <span class="d-block">Challenges</span>
                              In Progress
                            </div>
                            {report && (
                              <div class="cc-status">{report.inProgress}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sk-card mt-4 card-reset card-challenge-created">
                <div class="card-header d-sm-flex flex-wrap justify-content-between bg-white">
                  <div class="card_title">
                    <h2>Challenges created</h2>
                    {report && (
                      <div class="text-muted text-uppercase progress-status">
                        Showing : {report.created}/{report.all}
                      </div>
                    )}
                  </div>
                  <div class="ml-sm-auto d-flex mt-3 mt-sm-0 flex-wrap justify-content-sm-end">
                    {/* <select
                      class="btn btn-lg btn-default mb-1 mr-sm-3 mr-1"
                      value={this.state.status}
                      onChange={this.changeStatus}
                    >
                      <option>Showing all</option>
                      <option value="completed">Completed</option>
                      <option value="inProgress">In Progress</option>
                      <option value="pending">Pending</option>
                    </select> */}
                  </div>
                </div>
                <div class="card-body">
                  <div class="challenge-list-created">
                    {report &&
                      report.homeworkKeys.map((l, index) => {
                        console.log("ParentChallengeHistory => Lesson: " + JSON.stringify(lessons[l]));
                        let showLesson = !subject || subject === displaySubject(lessons[l].lesson.subject);
                        return showLesson ? (
                          <ChallengeHistoryItem
                            key={index}
                            lesson={lessons[l]}
                            child={childProfile}
                          />
                        ) : null;
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {loading && (
          <Loader
            image={
              ADULT_LOADING_IMAGES[
                Math.floor(Math.random() * ADULT_LOADING_IMAGES.length)
              ]
            }
          />
        )}{" "}
      </div>
    );
  }
}

export default requireAuthentication(ParentStorage(ParentChallengeHistory));
