import React, { Component } from "react";
import { v4 } from "uuid";

import { config } from "../../../Constants";
import {
  read,
  cancelSpeech,
  isMuted,
  toggleMute,
  resumeSpeech,
  pauseSpeech,
  refreshMuted,
  isSpeechDisabled
} from "../../../utils/speech-utils";
import {
  reportActions,
  report,
  reportProblem
} from "../../../utils/report-utils";

import LessonHeader from "../../../components/Student/LessonContent/LessonHeader";
import ChallengeComplete from "./../../../components/Student/LessonContent/ChallengeComplete";
import MultipleChoiceContent from "../../../components/Student/LessonContent/MultipleChoiceContent";
import QuitChallenge from "../../../components/Student/LessonContent/QuitChallenge";
import ShowProblem from "../../../components/Student/LessonContent/ShowProblem";
import ProgressBar from "../../../components/Student/LessonContent/ProgressBar";
import ReviewContent from "../../../components/Student/LessonContent/ReviewContent";
import "./Questions.css";

import SpellingReview from "../../../components/Student/LessonContent/SpellingReview";
import SpellingTest from "../../../components/Student/LessonContent/SpellingTest";
import { getImage } from "../../../utils/image-utils";
import {
  findLesson,
  loadLessonState,
  isCorrectAnswer,
  getSpeechText,
  loadLessonReport,
  loadAttemptReport,
  isMcqLesson,
  isMcqQuestion
} from "../../../utils/lesson-utils";
import requireAuthentication from "../../../components/AuthenticatedComponent";
import {
  getToken,
  getProfile,
  getUserid
} from "../../../utils/profile-utils";
import Toast from "../../../components/Student/Toast/Toast";
import NumericTest from "../../../components/Student/LessonContent/NumericTest";
import Tutorial from "../../../components/Student/LessonContent/Tutorial";
import { refreshHomework } from "../../../services/HomeworkService";

class Questions extends Component {
  report = {
    lessonId: "",
    userid: "",
    showKeyboard: false,
    answer: "",
    toastText: null
  };

  // default State object
  state = {
    lessonId: "",
    questionIndex: 0,
    options: [],
    showLessonFinished: false,
    showToast: false,
    isCorrect: false,
    showLessonQuit: false,
    isReview: false,
    questionReport: {},
    isSpelling: false,
    letterIndex: -1,
    lessonFinished: false
  };

  constructor(props) {
    super(props);

    // console.log(JSON.stringify(props.history));

    if (!getProfile() || !getToken()) {
      this.props.history.push("/logout");
    }

    // console.log('PROPS: ' + JSON.stringify(props));
    this.state.lessonId = props.match.params.lesson;

    this.state.questionIndex = props.match.params.id
      ? parseInt(props.match.params.id)
      : 0;

    // console.log(this.state.homework);
    // console.log(this.state.lessonId);

    this.report.lessonId = this.state.lessonId;
    // this helps track individual lesson attempts for scoring
    this.report.attemptId = v4();
    this.report.isReview = false;

    // console.log("Attempt ID " + this.report.attemptId);

    // This binding is necessary to make `this` work in the callback
    this.loadLesson = this.loadLesson.bind(this);
    this.closeLesson = this.closeLesson.bind(this);
    this.pickCard = this.pickCard.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.learn = this.learn.bind(this);
    this.quitLesson = this.quitLesson.bind(this);
    this.resumeLesson = this.resumeLesson.bind(this);
    this.reviewLesson = this.reviewLesson.bind(this);
    this.toggleMute = this.toggleMute.bind(this);
    this.sendReport = this.sendReport.bind(this);
    this.nextLetterIndex = this.nextLetterIndex.bind(this);
    this.nextCard = this.nextCard.bind(this);
    this.previousCard = this.previousCard.bind(this);
    this.showKeyboard = this.showKeyboard.bind(this);
    this.dismissKeyboard = this.dismissKeyboard.bind(this);
    this.onLetter = this.onLetter.bind(this);
    this.clearLastLetter = this.clearLastLetter.bind(this);
    this.addLessonReport = this.addLessonReport.bind(this);
    this.showError = this.showError.bind(this);
    this.scoreFlashcards = this.scoreFlashcards.bind(this);
    this.isFlashCardLessons = this.isFlashCardLessons.bind(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.STUDENT_QUESTION_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
    findLesson(this.state.lessonId, this.loadLesson, this.showError);
    loadLessonReport(this.state.lessonId, this.addLessonReport);
    this.sendReport(reportActions.START_LESSON);
    // Restore mute settings
    refreshMuted();
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.STUDENT_QUESTION_CLASS);
    // stop talking before exit
    cancelSpeech();
  }

  loadLesson(lesson) {
    // console.log('Questions loadLesson LESSON: ' + JSON.stringify(lesson));

    // create a new "State" object without mutating
    // the original State object.
    const newState = Object.assign({}, this.state, loadLessonState(lesson));

    // console.log("Question Index is: " + this.state.questionIndex);
    // store the new state object in the component's state
    this.setState(newState, () => {
      // use load card for analytics (load card sets state shoule be called after component mount)
      this.loadCard(this.state.questionIndex);
    });
  }

  addLessonReport(report) {
    // console.log('addLessonReport ' + JSON.stringify(report));
    this.setState({
      lessonReport: report
    });
  }

  showError(error) {
    // create a new "State" object without mutating
    // the original State object.

    if (!getProfile() || !getToken()) {
      this.props.history.push("/logout");
    }

    if (error && error.response && error.response.status === 401) {
      this.props.history.push("/logout");
    }
    // console.log("ERROR + " + JSON.stringify(error));

    const newState = Object.assign({}, this.state, {
      showError: true,
      errorMessage: error.message,
      error: error
    });

    // store the new state object in the component's state
    this.setState(newState);
  }

  toggleMute() {
    const { lesson } = this.state;
    const useSpeech = lesson ? lesson.useSpeech : true;
    console.log(
      lesson.useSpeech + " Questions: toggleMute => Use speech: " + useSpeech
    );
    if (isSpeechDisabled()) {
      this.setState({
        toastText:
          "You browser does not support speech. Please report the problem so we can resolve the issue."
      });
    } else {
      if (useSpeech) {
        toggleMute();
        this.loadCard(this.state.questionIndex);
      }
    }
  }

  closeLesson() {
    // console.log("Questions closeLesson history: " + JSON.stringify(this.props.history));
    if (!isMuted()) {
      toggleMute();
    }
    this.sendReport(reportActions.END_LESSON);
    refreshHomework();
    try {
      // stop talking before exit
      cancelSpeech();
      const value = this.props.history.length;
      if (value <= 1) {
        // helps with new tab navigation
        // console.log("History back value is missing..");
        this.props.history.push("/challenges");
      } else {
        this.props.history.goBack();
        // console.log("History back somewhere....." + value);
      }
    } catch (e) {
      console.error("An error occured while recovering : ", e);
      let report = {};
      report.pageName = "Lesson back error";
      report.action = "PROBLEM";
      report.userid = getUserid();
      report.comment = JSON.stringify(e);
      reportProblem(report, getToken());
    }
  }

  quitLesson() {
    const { lessonFinished } = this.state;
    console.log("FlashCard quitLesson: lessonFinished => " + lessonFinished);
    pauseSpeech();

    if (this.isFlashCardLessons()) {
      this.scoreFlashcards();
    }

    const showLessonQuit = lessonFinished ? false : true;
    console.log("FlashCard quitLesson: showLessonQuit => " + lessonFinished);

    this.setState({
      showLessonQuit: showLessonQuit,
      showLessonFinished: lessonFinished
    });
    if (this.isFlashCardLessons()) {
      // console.log("FlashCard nextCard SCORE_LESSON");
      this.sendReport(reportActions.SCORE_LESSON);
    }
  }

  resumeLesson() {
    // console.log("resumeLesson ");
    this.setState({
      showLessonQuit: false,
      showLessonFinished: false
    });
    resumeSpeech();
  }

  reviewLesson() {
    const isFlashCardLessons = this.isFlashCardLessons() ? true : false;
    const isReview = isFlashCardLessons ? false : true;
    this.report.isReview = isReview;
    this.setState({
      questionIndex: 0,
      isReview: isReview,
      showLessonFinished: false,
      question: this.state.questions[0]
    });
  }

  showKeyboard() {
    this.setState({
      showKeyboard: true
    });
  }

  dismissKeyboard() {
    this.setState({
      showKeyboard: false
    });
  }

  learn() {
    this.sendReport(reportActions.END_LESSON);
    // stop talking before exit
    cancelSpeech();
    this.props.history.push("/flash-cards/" + this.state.lessonId);
  }

  pickCard(index) {
    // console.log('Questions pickCard: ' + index);
    this.loadCard(index);
  }

  onLetter(letter) {
    this.setState({
      answer: this.state.answer ? this.state.answer + letter : letter
    });
  }

  clearLastLetter() {
    const answer = this.state.answer;
    this.setState({
      answer: answer ? answer.substring(0, answer.length - 1) : ""
    });
  }

  checkAnswer(answer) {
    cancelSpeech();
    const {
      isReview,
      questionReport,
      lesson,
      lessonFinished,
      lessonReport
    } = this.state;
    // console.log("CHECKING ANSWER: " + answer);
    // console.log("Questions max score: " + lessonReport.maxScore);

    if (isReview) {
      return;
    }

    const question = this.state.question.question;
    const isCorrect = isCorrectAnswer(question, answer);
    this.report.answer = answer;
    if (isCorrect) {
      this.sendReport(reportActions.CORRECT_ANSWER);
    } else {
      this.sendReport(reportActions.INCORRECT_ANSWER);
      // console.log("INCORRECT ANSWER ");
    }

    const points = isCorrect ? question.pointsPerQuestion : 0;
    questionReport[this.state.questionIndex] = {
      isCorrect: isCorrect,
      providedAnswer: answer,
      points: points
    };
    // console.log("****** Progress report " + JSON.stringify(questionReport));

    // do not move to next question if enter code question is incorrect
    let newCardIndex = this.state.questionIndex;
    if (question && question.numericEntry && !isCorrect) {
      newCardIndex = this.state.questionIndex;
    } else {
      newCardIndex = this.state.questionIndex + 1;
    }

    let improved = false;
    let showLessonFinished = false;
    let maxScore = 0;
    if (lessonReport && lessonReport.maxScore) {
      maxScore = lessonReport.maxScore;
    }
    let scoredPoints = 0;
    let updateLessonFinished = lessonFinished;
    if (newCardIndex >= lesson.questions.length) {
      updateLessonFinished = true;
      // console.log("CHALLENGE COMPLETE: " + newCardIndex);
      // stop talking before exit
      this.setState({
        isTalking: false
      });
      cancelSpeech();
      showLessonFinished = true;

      var keys = Object.keys(questionReport);
      // console.log("answered questions: " + keys.length);
      // const correctQuestion = keys.filter(function(key) {
      //   console.log(key, questionReport[key]);
      //   return questionReport[key].isCorrect;
      // }).length;
      // console.log("Questions max score: " + maxScore);

      // console.log("lesson points: " + lesson.points);
      // console.log("lesson questions: " + lesson.questions.length);
      // const pointsPerQuestion = lesson.pointsPerQuestion;
      // console.log('Question points: ' + pointsPerQuestion);
      // const scoredPoints = pointsPerQuestion * correctQuestion;
      scoredPoints = keys
        .map(key => questionReport[key].points)
        .reduce((a, b) => {
          // console.log(a + " + " + b);
          // console.log(a + b);
          return a + b;
        }, 0);
      console.log("Scored points: " + scoredPoints);
      if (scoredPoints > maxScore) {
        if (maxScore > 0) {
          improved = true;
        }
        scoredPoints = scoredPoints - maxScore;
        console.log("Additional points: " + scoredPoints);
      } else {
        scoredPoints = 0;
      }
      scoredPoints += 10; // 10 attempt points
      this.report.score = scoredPoints;
      // console.log("With attempt points: " + points);
    } else {
      // console.log("Questions -> moving to next card: " + newCardIndex);
    }

    // console.log("QUESTONS showing toast correct? " + isCorrect);
    // show toast and load next card
    this.setState({
      showToast: true,
      isCorrect: isCorrect,
      questionIndex: newCardIndex,
      showLessonFinished: showLessonFinished,
      points: scoredPoints,
      improved: improved,
      lessonFinished: updateLessonFinished
    });
    let boundFunction = function() {
      this.setState({ showToast: false });
      if (!this.state.showLessonFinished) {
        this.loadCard(newCardIndex);
      } else {
        if (points > 0) {
          // console.log("Previous points: " + maxScore);
          // console.log("Additional points: send report? " + points);
          this.sendReport(reportActions.SCORE_LESSON);
        }
      }
    }.bind(this);
    setTimeout(boundFunction, 1000);
  }

  loadCard(index) {
    const { questionReport, questionIndex, lessonId } = this.state;
    this.report.question = index;
    this.report.questionStartTime = new Date().getTime();
    if (this.isFlashCardLessons()) {
      this.scoreFlashcards();
    }
    // console.log("Questions loadCard index: " + index);
    cancelSpeech();
    if (this.state.questions) {
      const question = this.state.questions[index];

      this.setState(
        {
          question: question,
          questionIndex: index,
          letterIndex: -1,
          answer: "",
          questionReport: questionReport,
          toastText: null
        },
        () => {
          this.sendReport(reportActions.LOAD_QUESTION);
        }
      );
      // console.log('showOptions: ' + question.showOptions);

      const speechText = getSpeechText(
        this.state.subject,
        this.state.isSpelling,
        question.question
      );
      // console.log("OPTIONS: " + this.state.options);
      // console.log('READ QUESTION ' + speechText);
      read(speechText);
      // console.log("Replace history " + questionIndex);
      this.props.history.replace("/questions/" + lessonId + "/" + index);
    }
  }

  nextLetterIndex() {
    // console.log("Questions nextLetterIndex: " + this.state.letterIndex);
    this.setState({
      letterIndex: this.state.letterIndex + 1
    });
  }

  sendReport(action) {
    // console.log("Reporting action: " + action);
    const { questionReport, questionIndex } = this.state;

    this.report.action = action;
    if (questionReport) {
      this.report.progress = Object.keys(questionReport).length;
    }
    this.report.question = questionIndex;
    console.log("Sending report: " + JSON.stringify(this.report));
    report(this.report);
    if (action === reportActions.END_LESSON) {
      loadAttemptReport(this.report.attemptId);
    }
  }

  nextCard() {
    if (this.isFlashCardLessons()) {
      // console.log('FlashCard nextCard before: ' + this.state.questionIndex);
      if (this.state.questionIndex + 1 >= this.state.lesson.questions.length) {
        // console.log('FlashCard nextCard SCORE_LESSON');
        this.scoreFlashcards();
        this.setState({
          questionIndex: 0,
          showLessonFinished: true,
          lessonFinished: true
        });

        this.sendReport(reportActions.SCORE_LESSON);
      } else {
        let questionIndex =
          (this.state.questionIndex + 1) % this.state.lesson.questions.length;
        // console.log('FlashCard nextCard: ' + questionIndex);
        this.loadCard(questionIndex);
      }
    }
  }

  previousCard() {
    if (this.isFlashCardLessons()) {
      let questionIndex =
        (this.state.questionIndex - 1) % this.state.lesson.questions.length;
      if (questionIndex < 0) {
        questionIndex = 0;
      }
      // console.log('PREVIOUS ' + questionIndex);
      this.loadCard(questionIndex);
    }
  }

  isFlashCardLessons() {
    return this.state.lesson.lessonType === "FLASH_CARDS";
  }

  scoreFlashcards() {
    const { questionReport, questionIndex, questions } = this.state;
    console.log(
      "Questions nextCard questionReport: " + JSON.stringify(questionReport)
    );
    if (this.isFlashCardLessons()) {
      const question = questions[questionIndex];
      questionReport[questionIndex] = {
        isCorrect: true,
        points: question.question.pointsPerQuestion
      };

      console.log(
        "Questions nextCard questionReport: " + JSON.stringify(questionReport)
      );

      var keys = Object.keys(questionReport);
      const scoredPoints = keys
        .map(key => questionReport[key].points)
        .reduce((a, b) => {
          // console.log(a + " + " + b);
          // console.log(a + b);
          return a + b;
        }, 0);

      this.setState({
        points: scoredPoints + 10,
        questionReport: questionReport
      });
    }
  }

  render() {
    // console.log("Questions render state " + JSON.stringify(this.state));
    const {
      showToast,
      isReview,
      isTest,
      isSpelling,
      questionReport,
      toastText,
      lesson,
      question,
      isCorrect,
      questionIndex
    } = this.state;
    // console.log('Questions render lesson: ' + JSON.stringify(lesson));
    let lessonName = "";
    let useSpeech = "";
    if (lesson) {
      lessonName = lesson.shortName ? lesson.shortName : lesson.name;
      useSpeech = lesson.useSpeech;
    }
    // MCQ was assigned at question level. It is now be determined at question level
    console.log("Rendering question " + JSON.stringify(question));
    const isMcq =question && question.question ? isMcqQuestion(question.question) : false;

    // console.log('Questions render questionReport: ' + JSON.stringify(questionReport));
    var keys = Object.keys(questionReport);
    const score = keys.filter(function(key) {
      // console.log(key, questionReport[key]);
      return questionReport[key].isCorrect;
    }).length;

    // console.log('isTest ' +isTest +' question ' +question +' isSpelling ' +this.state.isSpelling);
    // console.log("FlashCard render showLessonQuit " + this.state.showLessonQuit);
    const providedAnswer = questionReport[questionIndex];
    const showToastWtihReview = isReview ? true : showToast;
    // console.log(isCorrect + " " + JSON.stringify(providedAnswer));

    // in review mode refer to provided answer.

    const showCorrectAnswer = isReview
      ? providedAnswer
        ? providedAnswer.isCorrect
        : false
      : isCorrect;
    // console.log(showCorrectAnswer + "  showCorrectAnswer " + JSON.stringify(providedAnswer));
    const wrongAnswerMascot = isReview
      ? providedAnswer
        ? "oops-mascot@3x.png"
        : "no-answer-mascot@3x.png"
      : "oops-mascot@3x.png";
    let correctVisiblity =
      showToastWtihReview && showCorrectAnswer ? "" : "hidden";
    let correctAnimation =
      showToastWtihReview && showCorrectAnswer ? "slide-left" : "";
    let incorrectVisiblity =
      showToastWtihReview && !showCorrectAnswer ? "" : "hidden";
    let incorrectAnimation =
      showToastWtihReview && !showCorrectAnswer ? "slide-right" : "";
    // console.log(incorrectVisiblity + " incorrectVisiblity " + incorrectAnimation + " => " + wrongAnswerMascot);
    // console.log(correctVisiblity + " correctVisiblity " + correctAnimation);

    return (
      <div
        className="main-question-area quize-option"
        // style={{ height: 100 + "vh" }}
      >
        {toastText && <Toast text={toastText} />}
        <LessonHeader
          lessonName={lessonName}
          useSpeech={useSpeech}
          onClose={this.quitLesson}
          pickCard={this.pickCard}
          toggleMute={this.toggleMute}
        />

        <div className="question-tabs-warp">
          <div className="sk-container">
            {this.state.numberOfQuestions > 1 && (
              <ProgressBar
                number={this.state.numberOfQuestions}
                isReview={this.state.isReview}
                questionReport={questionReport}
                pickCard={this.pickCard}
                current={this.state.questionIndex}
              />
            )}
            {!isTest && question && !this.state.isSpelling && (
              <ReviewContent
                number={this.state.numberOfQuestions}
                questionIndex={this.state.questionIndex}
                question={question.question}
                previousCard={this.previousCard}
                nextCard={this.nextCard}
              />
            )}
            {!isTest && question && isSpelling && (
              <SpellingReview
                questionIndex={this.state.questionIndex}
                question={question.question}
                previousCard={this.previousCard}
                nextCard={this.nextCard}
                letterIndex={this.state.letterIndex}
                nextLetterIndex={this.nextLetterIndex}
              />
            )}
            {isTest && question && isMcq && (
              <MultipleChoiceContent
                questionIndex={this.state.questionIndex}
                hasTutorial={question.hasTutorial}
                question={question.question}
                answer={question.answer}
                questionReport={providedAnswer}
                // use options because they are shuffled
                options={question.options}
                displayImage={question.displayImage}
                showOptions={question.showOptions}
                isReview={this.state.isReview}
                checkAnswer={this.checkAnswer}
              />
            )}
            {isTest && question && !isMcq && !isSpelling && (
              <NumericTest
                questionIndex={this.state.questionIndex}
                hasTutorial={question.hasTutorial}
                question={question.question}
                answer={this.state.answer}
                isReview={this.state.isReview}
                questionReport={providedAnswer}
                checkAnswer={this.checkAnswer}
                showKeyboard={this.showKeyboard}
              />
            )}
            {isTest && question && isSpelling && (
              <SpellingTest
                questionIndex={this.state.questionIndex}
                hasTutorial={question.hasTutorial}
                question={question}
                answer={this.state.answer}
                isReview={this.state.isReview}
                questionReport={providedAnswer}
                checkAnswer={this.checkAnswer}
                showKeyboard={this.showKeyboard}
              />
            )}
          </div>
        </div>

        <div
          className="wrong-question "
          style={{ visibility: incorrectVisiblity }}
        >
          <img
            className={"opps " + incorrectAnimation}
            src={getImage(wrongAnswerMascot)}
            alt=""
          />
        </div>

        <div
          className="correct-question"
          style={{ visibility: correctVisiblity }}
        >
          <img
            className={"yoohoo " + correctAnimation}
            src={getImage("yoohoo-mascot.svg")}
            alt=""
          />
        </div>

        {!(this.state.isReview && question.question.sampleSolution) && (
          <ShowProblem report={this.report} />
        )}
        {this.state.isReview && question.question.sampleSolution && (
          <Tutorial question={question} />
        )}
        {!this.state.showToast && this.state.showLessonFinished && (
          <ChallengeComplete
            value={score}
            total={lesson.questions.length}
            points={this.state.points}
            improved={this.state.improved}
            onClose={this.closeLesson}
            onReview={this.reviewLesson}
          />
        )}
        {/* {this.state.showToast && <Toast correct={isCorrect} />} */}
        {!this.state.showToast && this.state.showLessonQuit && (
          <QuitChallenge
            onClose={() => this.closeLesson}
            onCancel={() => this.resumeLesson}
          />
        )}
      </div>
    );
  }
}

export default requireAuthentication(Questions);
