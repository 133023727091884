import React from 'react';
import PropTypes from 'prop-types';

import { getImage } from '../../../utils/image-utils';
import {
  cancelSpeech,
  speak,
  isMuted,
} from '../../../utils/speech-utils';

class SpellingReview extends React.Component {
  constructor(props) {
    super(props);

    this.readCard = this.readCard.bind(this);
    this.nextLetter = this.nextLetter.bind(this);
  }

  componentDidMount() {
    this.readCard();
  }

  componentDidUpdate() {
    // console.log("SpellingReview componentWillUpdate: " + JSON.stringify(this.props));
    if (this.props.letterIndex < 0) {
      this.readCard();
    } else {
      this.nextLetter();
    }
  }

  readCard() {
    // console.log("SpellingReview readCard: " + JSON.stringify(this.props));
    const word = this.props.question.options[0];
    if (isMuted()) {
      speak(word + ' is spelt as ');
    } else {
      speak(word + ' is spelt as ', this.nextLetter);
    }
  }

  nextLetter() {
    // console.log("SpellingReview Speaking letterIndex: " + this.props.letterIndex + " " + JSON.stringify(this.props));
    if (this.props.letterIndex >= 0) {
      const word = this.props.question.options[0];
      const letters = word.split('');
      if (this.props.letterIndex < letters.length) {
        const boundFunction = function() {
          this.props.nextLetterIndex();
        }.bind(this);
        // console.log("SpellingReview Speaking letter: " + letters[this.props.letterIndex]);
        speak(letters[this.props.letterIndex].toLowerCase(), boundFunction);
      } else {
        speak(word.toUpperCase());
      }
    } else {
      this.props.nextLetterIndex();
    }
  }

  componentWillUnmount() {
    // stop talking before exit
    cancelSpeech();
  }

  render() {
    // console.log("SpellingReview render action: " + JSON.stringify(this.props));
    const question = this.props.question.speech[0];
    const word = this.props.question.options[0];
    const letters = word.split('');
    return (
      <div>
        <div className="left button-container">
          <button
            className="card-button-tiny close"
            onClick={() => this.props.previousCard()}
          >
            <img src={getImage('previous.png')} alt="previous slide"/>
          </button>
        </div>
        <div className="right button-container">
          <button
            className="card-button-tiny close"
            onClick={() => this.props.nextCard()}
          >
            <img src={getImage('next.png')} alt="next slide"/>
          </button>
        </div>
        <div class="question-form question-checkbox">
          <span>QUESTION {this.props.questionIndex + 1}</span>
          <p dangerouslySetInnerHTML={{ __html: question }} />
          {this.props.displayImage && <img src={this.props.displayImage} alt="flashcard"/>}
          <div class="questions-checkbox-warp lesson-card">
            <div className="center flip-card-front">
              {letters &&
                letters.map((c, index) => {
                  if (index === this.props.letterIndex) {
                    return (
                      <Letter
                        letter={c}
                        active="spell-letter spell-letter-active"
                      />
                    );
                  } else {
                    return <Letter letter={c} active="spell-letter" />;
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function Letter(props) {
  return <h1 className={'left ' + props.active}>{props.letter}</h1>;
}

SpellingReview.propTypes = {
  question: PropTypes.object.isRequired,
  previousCard: PropTypes.func,
  nextCard: PropTypes.func,
};

export default SpellingReview;
