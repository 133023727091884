import React, { Component } from "react";
import { displayLessonName, displaySubject, displayLessonType } from "../../utils/display-utils";
import { getImage, getLevelImage } from "../../utils/image-utils";
import { LESSON_TYPE_STYLE } from "../../Constants";

const LessonCard = ({ topic, active, lesson, homework, pickLesson }) => {

  console.log("LessonCard homework: " + JSON.stringify(homework));

  if (!topic) {
    return <div />;
  }

  const selectLesson = () => {
    if (!homework && pickLesson) {
      pickLesson(lesson.lessonMetadata.id);
    }
  }

  const {
    shortName,
    lessonType,
    subject,
    level,
    id,
    numberOfQuestions,
    timeRequired,
    useQuestionsInTest
  } = lesson.lessonMetadata;

  let activeClass = "";
  if (active === id) {
    activeClass = "recommendation-card-active";
  }

  const {
    attemptedQuestions,
    totalQuestions,
    pending,
    score,
    inProgress
  } = homework ? homework : {};

  const topiclength = 0;

  let percentage = 0;
  let complete = false;
  if (totalQuestions > 0) {
    percentage = (attemptedQuestions * 100) / totalQuestions;
    complete = attemptedQuestions >= totalQuestions;
  }

  const lessonCardStyles = LESSON_TYPE_STYLE[lesson.lessonMetadata.lessonType];

  return (
    <div class="col-sm-6 col-lg-4 mt-4" onClick={() => selectLesson()}>
      <div class={"sk-card test-card flash-test sk-choose-item " + (active ? " selected" : "border-0")} style={{ height: "20em", width: "16.5em"}}>
        <div class="card-header d-flex overflow-hidden p-0 position-relative" style={{height: "5em"}}>
          <div class="card-header-title w-75" style={{backgroundColor : lessonCardStyles ? lessonCardStyles.typeBackgroundColor : ""}}>
            <div class="card-tag d-flex mb-">
              {(topic && topic.grade) ? <span>GRADE {topic.grade}</span> : null}
              <span>{displaySubject(topic.subject).toUpperCase()}</span>
            </div>
            <p class="m-0" style={{ fontSize: "12px" }}>
              {topic.shortName}
            </p> 
            {topiclength > 0 && <a href="#" class="btn btn-topic">
              + 3 topics
            </a>}
          </div>
          <span class="d-flex h-100 btn-test p-2" style={{ width: "5em", textAlign: "center", 
            background: lessonCardStyles ? lessonCardStyles.backgroundColor : "",
            right: 0}}>
            <span class="m-auto">
              <img
                src={getImage(lessonCardStyles ? lessonCardStyles.icon: "np_knowledge.svg")}
                class="d-block mb-2"
                alt="image"
              />
              {displayLessonType(lesson.lessonMetadata.lessonType)}
            </span>
          </span>
        
        </div>
        <div class={"card-body rounded-0 p-0 " + (useQuestionsInTest ? "" : " sk-card-error")} style={{ height: "6em" }}>
          {complete && <img src={getImage("Intersection.svg")} class="completed-icon" alt=""></img>}
          <div class="card-body-content p-3">
            <p>{lesson.lessonMetadata.shortName}</p>
          </div>
        </div>
        <div class={"card-footer p-3 " + (useQuestionsInTest ? " bg-white " : " sk-card-error")}>
          <div class="mb-3 difficulty-level text-uppercase">
            <span>Difficulty level</span>
            <ul><li><img src={getLevelImage(lesson.lessonMetadata.level)} alt="Difficulty level"/></li></ul>
          </div>
          <div class="d-flex w-100 text-uppercase justify-content-around pt-1 test-cells">
            {(numberOfQuestions && numberOfQuestions > 0) ? <div class="text-center">
              <strong>{numberOfQuestions}</strong>
              <span>Questions</span>
            </div> : null}
            {(timeRequired && timeRequired > 0) ? <div class="text-center">
              <strong>{timeRequired}</strong>
              <span>Time (mins)</span>
            </div> : null}
            {score && score>0 && <div class="text-center">
              <strong>{score}<sup>%</sup></strong>
              <span>Score</span>
            </div>}
            {(!score || score<=0) && homework && <div class="text-center">
              <strong><img src={getImage("correct-answer-green.svg")} alt="assigned" style={{ width: "1em" }} /></strong>
              <span>Assigned</span>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonCard;
