import axios from "axios";
import React from "react";
import uniqueId from "react-html-id";
import { NavLink } from "react-router-dom";

import ConnectionErrorHandler from "../../components/Student/ErrorHandler/ConnectionErrorHandler";
import { config } from "../../Constants";
import { getImage } from "../../utils/image-utils";
import { checkText, checkPassword } from "../../utils/validation-utils";
import "./Login.css";

class NewPassword extends React.Component {
  state = {
    confirmPassword: null,
    password: null,
    token: null,
    showError: false,
    errorMessage: null,
    showLogin: true,
    showForgotPassword: false,
    showRegister: false,
    showSuccess: false
  };

  constructor(props) {
    super(props);

    this.state.token = this.props.match.params.token;
    console.log("NewPassword this.state.token: " + this.state.token);
    this.handleConfirmChange = this.handleConfirmChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideError = this.hideError.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.STUDENT_PAGE_CLASS);
  }

  handleConfirmChange(event) {
    console.log(event);
    console.log("New value: " + event.target.value);
    this.setState({ confirmPassword: event.target.value });
    console.log("Changed: " + this.state.confirmPassword);
  }

  handlePasswordChange(event) {
    console.log("New value: " + event.target.value);
    this.setState({ password: event.target.value });
    console.log("Changed: " + this.state.password);
  }

  hideError() {
    this.setState({
      showError: false,
      errorMessage: ""
    });
  }

  checkPassword() {
    let error = checkText(this.state.password);
    let fieldError;
    if (!error) {
      fieldError = "Enter password";
    } else {
      error = checkPassword(this.state.password);
      if (!error) {
        fieldError = "Password must contain atleast 6 characters";
      }
    }
    this.setState({
      passwordError: fieldError,
      showSubmit: error
    });
  }

  checkConfirmPassword() {
    let error = checkText(this.state.confirmPassword);
    let fieldError;
    if (!error) {
      fieldError = "Confirm password";
    } else if (this.state.confirmPassword !== this.state.password) {
      fieldError = "Passwords must match";
      error = false;
    }
    this.setState({
      passwordConfirmError: fieldError,
      showSubmit: error
    });
  }

  onCaptcha(value) {
    console.log("Captcha value:", value);
    this.setState({
      captcha: value,
      showSubmit: true
    });
  }

  handleSubmit(event) {
    console.log("A name was submitted: " + this.state.confirmPassword);
    console.log("A password was submitted: " + this.state.password);
    event.preventDefault();
    // get our form data out of state
    const { password, token } = this.state;

    this.checkPassword();
    this.checkConfirmPassword();

    if (
      this.state.showSubmit &&
      !this.state.passwordError &&
      !this.state.passwordConfirmError
    ) {
      console.log("BASE URL: " + config.url.API_BASE_URL);

      axios
        .post(config.url.API_BASE_URL + "/request/newPassword", {
          message: token,
          name: password
        })
        .then(result => {
          console.log("Login profile: " + result);
          this.setState({
            showSuccess: true
          });
        })
        .catch(error => {
          console.log(error);
          let errorMessage = error.message;
          let passwordMessage = null;
          if (error && error.response && error.response.status === 401) {
            passwordMessage = error.message;
            errorMessage = null;
          }
          this.setState({
            showError: true,
            errorMessage: errorMessage,
            passwordMessage: passwordMessage
          });
        });
    }
  }

  render() {
    const {
      showSuccess,
      password,
      passwordError,
      confirmPassword,
      passwordConfirmError,
      showError,
      errorMessage,
      passwordMessage
    } = this.state;
    return (
      <div>
        <div className="smart-kids">Smart Kids</div>
        <div className="login-wrapper">
          <div className="left login-mascot">
            <img
              className="center"
              src={getImage("login-mascot@3x.png")}
              alt="mascot"
            />
          </div>
          <div className="login">
            {!showSuccess && (
              <form onSubmit={this.handleSubmit}>
                <div className={passwordError ? "enter error" : "enter"}>
                  <input
                    id={this.getUniqueId("login-password")}
                    className="input"
                    type="password"
                    placeholder="Password"
                    onBlur={() => this.checkPassword()}
                    value={password}
                    onChange={this.handlePasswordChange}
                  />
                  {passwordError && (
                    <span className="error-text">{passwordError}</span>
                  )}
                </div>
                <div className={passwordConfirmError ? "enter error" : "enter"}>
                  <input
                    id={this.getUniqueId("login-confirmPassword")}
                    className="input"
                    type="password"
                    placeholder="Confirm password"
                    onBlur={() => this.checkConfirmPassword()}
                    value={confirmPassword}
                    onChange={this.handleConfirmChange}
                  />
                  {passwordConfirmError && (
                    <span className="error-text">{passwordConfirmError}</span>
                  )}
                </div>
                <div
                  id={this.getUniqueId("login-submit")}
                  className="wide-login-button"
                >
                  <button className="wide-button cursor-pointer" type="submit">
                    Change Password
                  </button>
                </div>
              </form>
            )}
            {showSuccess && (
              <NavLink to={"/login"}>
                <div className="login-message center">
                  Your password was successfully changed. Please login with your
                  new password.
                </div>
              </NavLink>
            )}
            <NavLink to={"/login"}>
              <div className="highlight">Login</div>
            </NavLink>
          </div>
        </div>
        {showError && (
          <ConnectionErrorHandler
            connectionError="true"
            onRetry={() => this.hideError()}
            passwordMessage={passwordMessage}
            errorMessage={errorMessage}
          />
        )}
      </div>
    );
  }
}

export default NewPassword;
