import React from "react";
import { NavLink } from "react-router-dom";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { displaySubject } from "../../utils/display-utils";
import { getClassCount } from "../../utils/profile-utils";

function LessonReportCard(props) {
  const {
    lowCount,
    mediumCount,
    highCount,
    reportCount,
    displayDate,
    score,
    reference
  } = props.report;
  const { grade, subject, shortName, lessonKey } = props.report.lesson;
  console.log("LessonReportCard lessonKey: " + lessonKey);

  return (
    <div ref={reference} className="single-challenge-wrap">
      <div className="single-clng-item">
        <div className="clng-top-txt">
          <div className="challenge-date">
            <div className="date-text">
              <span>{displayDate}</span>
              <p>
                GRADE {grade} . {displaySubject(subject)}
              </p>
            </div>
          </div>
          <div className="challenge-score">
            <h4>
              {score} <sub>%</sub>
            </h4>
            <p>Score</p>
          </div>
        </div>
        <div className="recent-plants-txt">
          <ul>
            <li className="wordwrap">{shortName}</li>
          </ul>
        </div>
        <div className="Participant">
          <h4 className="center">
            <span>{reportCount}/</span>
            {getClassCount()} ParticipantS
          </h4>
          <div className="participant-wrap">
            <div className="participant-item">
              <CircularProgressbar
                value={100}
                text={`${lowCount}`}
                strokeWidth="18"
                styles={buildStyles({
                  textColor: "rgb(108, 124, 147)",
                  pathColor: "#ffcc00",
                  trailColor: "#ffcc00"
                })}
              />
              <span>LOw</span>
            </div>
            <div className="participant-item">
              <CircularProgressbar
                value={100}
                text={`${mediumCount}`}
                strokeWidth="18"
                styles={buildStyles({
                  textColor: "rgb(108, 124, 147)",
                  pathColor: "#57d1ff",
                  trailColor: "#57d1ff"
                })}
              />
              <span>medium</span>
            </div>
            <div className="participant-item">
              <CircularProgressbar
                value={100}
                text={`${highCount}`}
                strokeWidth="18"
                styles={buildStyles({
                  textColor: "rgb(108, 124, 147)",
                  pathColor: "#73e96a",
                  trailColor: "#73e96a"
                })}
              />
              <span>high</span>
            </div>
          </div>
        </div>
        <div className="view-txt">
          <NavLink
            activeClassName="active"
            to={"/teacher/report/lesson/" + lessonKey}
          >
            View challenge
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default LessonReportCard;
