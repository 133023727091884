import React from 'react';
import { NavLink } from 'react-router-dom';

import uniqueId from 'react-html-id';

import { config, CHILD_LOADING_IMAGES } from '../../../Constants';
import { reportActions, report } from '../../../utils/report-utils';

import ProfileWidget from '../../../components/Student/ProfileWidget/ProfileWidget';
import ShowProblem from '../../../components/Student/LessonContent/ShowProblem';

import './Practice.css';

import { getImage } from '../../../utils/image-utils';
import { displaySubject, displayName } from '../../../utils/display-utils';
import { loadSyllabus, loadTopicCards } from '../../../utils/student-utils';
import Loader from '../../../components/Loader';
import requireAuthentication from '../../../components/AuthenticatedComponent';
import {
  getUserid,
  getToken,
  getProfile,
} from '../../../utils/profile-utils';

class Practice extends React.Component {
  report = {};

  // default State object
  state = {
    cards: null,
    showLeft: false,
    showRight: true,
    allowScroll: false,
  };

  constructor(props) {
    super(props);

    if (!getProfile() || !getToken()) {
      this.props.history.push('/logout');
    }

    // console.log(props);

    // console.log('PROPS: ' + JSON.stringify(props));
    this.state.subject = props.match.params.subject;
    this.state.anchor = this.props.location.hash.replace('#', '');

    this.report.pageName = 'Practice';
    this.report.userid = getUserid();
    this.report.subject = this.state.subject;

    this.scrollingWrapper = React.createRef();

    // console.log(this.state.profile);
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.showLessons = this.showLessons.bind(this);
    this.scrollChange = this.scrollChange.bind(this);
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.loadData = this.loadData.bind(this);
    this.showError = this.showError.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
    loadSyllabus(this.state.subject, this.loadData, this.showError);
    // console.log('Reporting action: ' + reportActions.LOAD_PAGE);
    this.report.action = reportActions.LOAD_PAGE;
    // console.log('Sending report: ' + JSON.stringify(this.report));
    report(this.report);
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
  }

  handleClick() {
    this.props.history.push('/challenges');
  }

  componentDidUpdate() {
    // console.log('Practice: componentDidUpdate');
    if (!this.state.allowScroll && this.state.anchor && this.state.cards) {
      const element = this.scrollingWrapper.current;
      // console.log('* scrollWidth: ' + element.scrollWidth);
      // console.log('* scrollLeft: ' + element.scrollLeft);
      // console.log('* clientWidth: ' + element.clientWidth);

      const topicCard = this.state.cards[this.state.anchor];
      if (topicCard && element) {
        const cardLocation = topicCard.reference.current.getBoundingClientRect();

        // console.log('* cardLocation: ' + JSON.stringify(cardLocation));

        element.scrollLeft = cardLocation.x - 200;

        this.setState({
          allowScroll: true,
        });
      }
    }
  }

  scrollChange() {
    const { firstCard, lastCard } = this.state;
    const f_card = firstCard ? firstCard.current.getBoundingClientRect() : null;
    const l_card = lastCard ? lastCard.current.getBoundingClientRect() : null;
    const y = window.innerHeight || document.documentElement.clientHeight;
    const x = window.innerWidth || document.documentElement.clientWidth;
    // console.log('Window x: ' + x + ' y: ' + y);
    // if (f_card) console.log('First Card: ' + JSON.stringify(f_card));
    // if (l_card) console.log('Last Card: ' + JSON.stringify(l_card));

    let lastCardEnd = x * 10; // always show right arrow
    if (l_card) {
      lastCardEnd = l_card.x + f_card.width;
      // console.log('Last Card end: ' + lastCardEnd);
    }

    let showLeft = false;
    let showRight = true;
    if (f_card.x < 0) {
      // card is atlease partially out of screen
      showLeft = true;
    }
    if (lastCardEnd < x) {
      // card is completely in screen
      showRight = false;
    }
    this.setState({
      showLeft: showLeft,
      showRight: showRight,
    });
  }

  scrollLeft() {
    const element = this.scrollingWrapper.current;
    // console.log('scrollLeft: ' + element.scrollLeft);
    element.scrollLeft -= 100;
    this.scrollChange();
  }
  scrollRight() {
    const element = this.scrollingWrapper.current;
    // console.log('scrollRight: ' + element.scrollLeft);
    element.scrollLeft += 100;
    this.scrollChange();
  }

  showLessons(topicId, index) {
    // console.log('showLessons IN Practice ' + topicId);
    this.props.history.push(
      '/topic/' + this.state.subject + '/' + index + '/' + topicId
    );
  }

  loadData(syllabus, profile, notifications) {
    const newCards = loadTopicCards(syllabus);
    const firstCard =
      newCards && newCards.length > 0 ? newCards[0].reference : null;
    const lastCard =
      newCards && newCards.length > 1
        ? newCards[newCards.length - 1].reference
        : null;

    // create a new "State" object without mutating
    // the original State object.
    const newState = Object.assign({}, this.state, {
      cards: newCards,
      firstCard: firstCard,
      lastCard: lastCard,
      practice: syllabus,
      profile: profile,
      notifications: notifications,
      score: syllabus.scores,
    });

    // store the new state object in the component's state
    this.setState(newState);
  }

  showError(error) {
    // create a new "State" object without mutating
    // the original State object.

    if (!getProfile() || !getToken()) {
      this.props.history.push("/logout");
    }

    if (error && error.response && error.response.status === 401) {
      this.props.history.push('/logout');
    }
    // console.log('ERROR + ' + JSON.stringify(error));

    const newState = Object.assign({}, this.state, {
      showError: true,
      errorMessage: error.message,
      error: error,
    });

    // store the new state object in the component's state
    this.setState(newState);
  }

  render() {
    // if (this.state.previousCard)
    //   console.log('Practice previousCard: ' + this.state.previousCard.key);
    // if (this.state.activeCard)
    //   console.log('Practice activeCard: ' + this.state.activeCard.key);
    // if (this.state.nextCard)
    //   console.log('Practice nextCard: ' + this.state.nextCard.key);
    return (
      <div id="student-container">
        <img src={getImage('cloud-over.png')} alt="" className="cloud-1" />

        <div className="header-bar math-header">
          <div className="sk-container">
            <div className="header-back">
              <NavLink to={'/choose/practice'}>
                <img src={getImage('student-back.svg')} alt="back" />
              </NavLink>
            </div>
            <div className="header-meddle">
              <h3>Choose Chapter</h3>
            </div>
            <ProfileWidget
              locationHash={this.props.location.hash}
              history={this.props.history}
            />
          </div>
        </div>

        <div className="choose-chapter-area">
          <div className="full-container">
            <div
              id={this.getUniqueId('practice-scroll')}
              ref={this.scrollingWrapper}
              onScroll={() => this.scrollChange()}
              className="choose-chapter-item scrolling-wrapper"
            >
              {!this.state.cards && (
                <Loader
                  image={
                    CHILD_LOADING_IMAGES[
                      Math.floor(Math.random() * CHILD_LOADING_IMAGES.length)
                    ]
                  }
                />
              )}
              {this.state.cards &&
                this.state.cards.map(c => {
                  return (
                    <PracticeCard
                      topic={c}
                      index={c.index}
                      subject={this.state.subject}
                      onClick={this.showLessons}
                      uniqueId={this}
                    />
                  );
                })}

              {this.state.showLeft && (
                <img
                  className="more-cards more-left"
                  onClick={() => this.scrollLeft()}
                  src={getImage('arrow-left.png')}
                  alt="more cards"
                />
              )}
              {this.state.showRight && (
                <img
                  className="more-cards more-right"
                  onClick={() => this.scrollRight()}
                  src={getImage('arrow-right.png')}
                  alt="less cards"
                />
              )}
            </div>
          </div>
        </div>
        <ShowProblem report={this.report} />

        <div className="mathtext">
          <div className="sk-container">
              <h3>
              <NavLink to={'/choose/practice'}>
                <span className="cursor-pointer">
                  {displaySubject(this.state.subject)}
                </span>
              </NavLink>
              </h3>
          </div>
        </div>

        <img src={getImage('cloud-right.png')} alt="" className="cloud-2" />
      </div>
    );
  }
}

function PracticeCard(props) {
  // console.log("PracticeCard render platform: " + platform);

  let platformClass = 'single-path';
  if (props.index % 2 === 1) {
    platformClass = 'single-path single-path-2';
  }

  return (
    <div
      id={props.uniqueId.nextUniqueId()}
      className={'choose-item ' + props.subject}
      ref={props.topic.reference}
      in={() => {
        // console.log(props.index + ' Showing card ' + props.topic.key);
      }}
    >
      <div className="choose-item-inner">
        <div
          className={props.topic.comingSoon ? '' : 'cursor-pointer'}
          onClick={() => {
            if (!props.topic.comingSoon) {
              props.onClick(props.topic.key, props.index);
            }
          }}
        >
          <div className="chapure-title">
            <span id={props.uniqueId.nextUniqueId()}>
              chapter {props.index + 1}
            </span>
          </div>
          <div className="addition-txte">
            <span id={props.uniqueId.nextUniqueId()}>
              {displayName(props.topic.name)}
            </span>
          </div>
          {!props.topic.comingSoon && (
            <div className="choose-main-txt">
              <p id={props.uniqueId.nextUniqueId()}>
                <span>{props.topic.started}</span>/{props.topic.total}
                <br />
                <span className="mastered">skills mastered</span>
              </p>
            </div>
          )}
        </div>
        {props.topic.comingSoon && (
          <div className="choose-main-txt">
            <p id={props.uniqueId.nextUniqueId()}>Coming Soon..</p>
          </div>
        )}
      </div>
      {props.topic && props.topic.platform && (
        <div id={props.uniqueId.nextUniqueId()} className={platformClass}>
          <img src={getImage(props.topic.platform)} alt="" />
        </div>
      )}
    </div>
  );
}

export default requireAuthentication(Practice);
