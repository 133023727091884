import React, { Component } from "react";
import PropTypes from "prop-types";

import "./ChallengeComplete.css";

import { getImage } from "../../../utils/image-utils";
import { starImage } from "../../../utils/aggregation-utils";

class ChallengeComplete extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleReview = this.handleReview.bind(this);
  }

  handleReview() {
    console.log(
      "ChallengeComplete handleReview toc: " + JSON.stringify(this.props)
    );
    if (typeof this.props.onReview === "function") {
      this.props.onReview();
    }
  }

  handleClose() {
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  render() {
    let improvedClass = "";
    if (this.props.improved) {
      improvedClass = "improve";
    }

    const displayImage = starImage(this.props.value, this.props.total, false);
    console.log("ChallengeComplete starImage: " + displayImage);
    console.log("ChallengeComplete points: " + this.props.points);

    return (
      <div className="lessonFinished">
        <div className="quit-challenge-container">
          <div className="error-area">
            <div className="error-area-inner">
              <div className="sk-container">
                <h1>Challenge Complete</h1>
                <div className="complete-challenge">
                  <img
                    className="happy-character"
                    src={getImage("character-happy.svg")}
                    alt="complete challenge"
                  />
                  <div className="challenge-box">
                    <img
                      className="stars"
                      src={getImage(displayImage)}
                      alt="progress"
                    />
                    <div className={"points " + improvedClass}>
                      <p>POINTS</p>
                      <p className="points-scored">+{this.props.points}</p>
                      <p>
                        <span className="questions-correct">
                          {this.props.value}
                        </span>{" "}
                        / {this.props.total}
                      </p>
                      <p>Correct Responses</p>
                    </div>
                    <a
                      className="cursor-pointer"
                      onClick={() => this.handleReview()}
                    >
                      Review
                    </a>
                  </div>
                  <div className="cancle-btn cursor-pointer">
                    <a onClick={this.handleClose}>Skip</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChallengeComplete.propTypes = {
  value: PropTypes.number,
  total: PropTypes.number,
  points: PropTypes.number,
  onClose: PropTypes.func,
  onReview: PropTypes.func
};

export default ChallengeComplete;
