import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { displaySubject, displayName } from "../../../utils/display-utils";
import { getImage } from "../../../utils/image-utils";

function SubjectCard(props) {
  const { uniqueId, lesson } = props;
  const {
    ref,
    subject,
    lessonReport,
    key,
    displayReport,
    progressImage
  } = lesson;
  // console.log("SubjectCard displayReport: " + JSON.stringify(displayReport));
  return (
    <div
      ref={ref}
      id={uniqueId.nextUniqueId()}
      className={"single-item " + subject.toLowerCase()}
    >
      <CardContent
        lesson={props.lesson}
        linkTo={props.linkTo}
        lessonReport={lessonReport}
        displayReport={displayReport}
      />
      <div className="clip text-center">
        <img
          id={props.uniqueId.nextUniqueId()}
          src={getImage(progressImage)}
          alt=""
        />
      </div>
    </div>
  );
}

SubjectCard.propTypes = {
  lesson: PropTypes.object.isRequired
};

function CardContent(props) {
  const { displayReport, lessonReport, lesson } = props;
  const { subject, name, percentage, points, lessonId } = lesson;
  // const  {totalQuestions, attemptedQuestions} = lessonReport;
  // console.log("CardContent name: " + name);
  // console.log("CardContent displayReport: " + JSON.stringify(displayReport));
  // console.log("CardContent lessonReport: " + JSON.stringify(lessonReport));
  return (
    <div className="main-item item-1">
      <div className="point">
        <span>+{points} pts</span>
      </div>
      <NavLink activeClassName="active" to={"/questions/" + lessonId}>
        <div className="skills-list">
          <li>{displaySubject(subject)}</li>
          <li>{displayName(name)}</li>
        </div>
      </NavLink>
      {displayReport && displayReport.mustStart && (
        <div className="skill-btn">
          <p>
            <span>Pending Challenge</span>
          </p>
        </div>
      )}
      {displayReport && displayReport.showIncomplete && (
        <div className="skill-btn skill-btn-incomplete">
          <p style={percentage}>&nbsp;</p>
          <span>
            {lessonReport.attemptedQuestions} / {lessonReport.totalQuestions}{" "}
            <br /> questions done
          </span>
        </div>
      )}
      {displayReport && displayReport.showAccuracy && (
        <div className="skill-btn skill-btn-incomplete">
          <p style={percentage}>&nbsp;</p>
          <span>
            {displayReport.correctAnswers} / {lessonReport.totalQuestions}{" "}
            <br /> correct answers
          </span>
        </div>
      )}
      {!displayReport && (
        <div className="skill-btn">
          <p style={percentage}>
            <span>
              {props.started} / {props.total} <br /> Lessons Done
            </span>
          </p>
        </div>
      )}
    </div>
  );
}

export default SubjectCard;
