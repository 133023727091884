import React from 'react';
import { config } from '../Constants';
import { getClassroomId } from './profile-utils';
import { getCall } from './rest-utils';
import { mapLesson } from './dashboard-utils';

export function getClassReport(callback) {
  const boundFunction = function(response) {
    console.log(response);
    callback(
      response.data.object,
      response.data.object.teacher.classroom,
      Object.keys(response.data.object.subject)[0]
    );
  }.bind(this);

  getCall(
    config.url.API_BASE_URL + '/api/report/class/' + getClassroomId(),
    boundFunction
  );
}

export function getClassReportByLesson(lessonId, callback) {
  const boundFunction = function(response) {
    console.log(response);
    callback(response.data.object);
  }.bind(this);

  getCall(
    config.url.API_BASE_URL +
      '/api/report/class/' +
      getClassroomId() +
      '/lesson/' +
      lessonId,
    boundFunction
  );
}

export function getChildReport(child, callback) {
  const boundFunction = function(response) {
    console.log(response);
    callback(response.data.object);
  };

  getCall(
    config.url.API_BASE_URL + '/api/report/child/' + child,
    boundFunction
  );
}

export function loadReportCards(reports) {
  return reports.homeworkReports;
}

export function loadReportCardsByHomework(reports) {
  const cards = loadReportCards(reports);
  return Object.keys(cards).map(function(key) {
    return mapClassReport(cards[key]);
  });
}

export function loadReportCardsBySubject(reportCardsByHomework, subject) {
  return reportCardsByHomework.filter(function(report) {
    return report && report.lesson && report.lesson.subject === subject;
  });
}

export function homeworkBySubject(reports, lessons) {
  const cards = loadReportCards(reports);
  return lessons.map(c => mapClassReport(cards[c.lessonId]));
}

export function mapClassReport(lessonReport) {
  const exposure = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  }).format(lessonReport.exposure ? lessonReport.exposure : 0);
  const confidence = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  }).format(lessonReport.confidence);
  const accuracy = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  }).format(lessonReport.accuracy);
  const score = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  }).format(lessonReport.score);

  return {
    reference: React.createRef(),
    lesson: mapLesson(lessonReport.lesson),
    low: lessonReport.low,
    lowCount: lessonReport.low === null ? 0 : lessonReport.low.length,
    medium: lessonReport.medium,
    mediumCount: lessonReport.medium === null ? 0 : lessonReport.medium.length,
    high: lessonReport.high,
    highCount: lessonReport.high === null ? 0 : lessonReport.high.length,
    studentsWithReports: lessonReport.studentsWithReports,
    reportCount: lessonReport.studentsWithReports.length,
    score: score,
    scoreText: score + '%',
    assignDate: lessonReport.assignDate,
    displayDate: new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    }).format(lessonReport.assignDate),
    exposure: exposure,
    confidence: confidence,
    accuracy: accuracy,
    attemptedQuestions: lessonReport.attemptedQuestions,
    totalQuestions: lessonReport.totalQuestions,
    questionReports: lessonReport.questionReports,
  };
}
