import React, { useState } from "react";

import { config } from "../../../Constants";

import "react-circular-progressbar/dist/styles.css";

import "./../../Teacher/RecentChallenges.css";
import "./../../../components/Parent/ChildChallenges.css";
import { displaySubject, displayLessonType } from "../../../utils/display-utils";
import { getImage } from "../../../utils/image-utils";
import LessonPreview from "../LessonPreview";
import { LESSON_TYPE_STYLE } from "../../../Constants";
import PickChallengeDate from "../../CreateChallenge/PickChallengeDate";
import ConnectionErrorHandler from "../../Student/ErrorHandler/ConnectionErrorHandler";
import ConfirmAssignement from "../../CreateChallenge/ConfirmAssignement";
import { isParent, getToken } from "../../../utils/profile-utils";
import { clearFamilyReport } from "../../../services/ParentService";

const RecommendationCard = ({ child, report, recommendation, preview, assign, confirmAssign }) => {

  // console.log("RecommendationCard report: " + JSON.stringify(report));
  // console.log("RecommendationCard recommendation: " + JSON.stringify(recommendation));

  if (!report) {
    return <div />;
  }

  const { topicMetadata, lessonMetadata } = recommendation;
  const {
    attemptedQuestions,
    totalQuestions,
    assignDate,
    lesson,
    ref,
    displayDateValue,
    shortName,
    lessonKey
  } = report;

  console.log("RecommendationCard report: " + JSON.stringify(report));
  // console.log("RecommendationCard topicMetadata: " + JSON.stringify(topicMetadata));

  const { grade } = child;
  const topics = 1;

  // console.log(attemptedQuestions + ' <= attampted ChildChallenges > LessonReportCard total => : ' + totalQuestions);
  let percentage = 0;
  if (totalQuestions > 0) {
    percentage = {
      width: (attemptedQuestions * 100) / totalQuestions + "%"
    };
  }
  // console.log('ChildChallenges > LessonReportCard percentage: ' + percentage);

  // const complete = attemptedQuestions === totalQuestions ? true : false;
  const lessonCardStyles = LESSON_TYPE_STYLE[report.lessonType];
  console.log(report.lessonType + ' ChildChallenges > LessonReportCard lessonCardStyles: ' + JSON.stringify(lessonCardStyles));
  return (
    <div class="col-sm-8">
      <div class="sk-card bg-transparent card-spiral-test border-0 mb-1" style={{ width : "18em"}}>
        <div class="card-header position-relative" style={{backgroundColor : lessonCardStyles ? lessonCardStyles.typeBackgroundColor : ""}}>
          <a href="#" class="btn-link btn-spiral-text px-1 py-2" style={{background: lessonCardStyles ? lessonCardStyles.backgroundColor : ""}} >
            <img src={getImage(lessonCardStyles ? lessonCardStyles.icon : "np_knowledge.svg")} alt={report.lessonType} />
            {displayLessonType(report.lessonType)}
          </a>
          <div class="w-75">
            <div class="card-tag d-flex mb-2">
              {topicMetadata && topicMetadata.grade ? <span>GRADE {topicMetadata.grade}</span>: null}
              <span>{displaySubject(topicMetadata.subject).toUpperCase()}</span>
            </div>
            {topicMetadata && <p class="mb-3">{topicMetadata.name}</p>}
            {topics && topics > 1 && (
              <a href="#" class="btn btn-topic">
                + 3 topics
              </a>
            )}
          </div>
        </div>
        <div class="card-body" style={{height: "13em"}}>
          <div class="card-info-text mb-3 d-flex justify-content-between">
            {lessonMetadata && lessonMetadata.numberOfQuestions ? (<span>Questions : {lessonMetadata.numberOfQuestions}</span>) : <span>&nbsp;</span>}
            {lessonMetadata && lessonMetadata.timeRequired ? (<span>Time : {lessonMetadata.timeRequired} mins</span>) : null}
          </div>
          <div class="card-body-content">
            <p>{shortName}</p>
          </div>

          <div class="card-btns">
            <a href="#" onClick={() => preview(true, recommendation)} class="btn btn-default">
              <img src={getImage("np_play.svg")} class="icon-left" alt="preview" /> PREVIEW
            </a>
            <a
              href="#"
              class="btn ml-2 btn-primary"
              onClick={() => assign(true, recommendation)}
            >
              ASSIGN challenge{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendationCard;

{/* <div
        class="sk-card bg-transparent card-spiral-test border-0"
        style={{ width: "16em", margin: " 0px  0px 20px" }}
      >
        <div class="card-header position-relative">
          <a href="" class="btn-link btn-spiral-text px-2 py-3">
            <img src={getImage("np_revision.svg")} alt="image" />
            {displayLessonType(report.lessonType)}
          </a>
          <div class="w-75">
            <div class="card-tag d-flex mb-2">
              {topicMetadata && topicMetadata.grade ? (
                <span>GRADE {topicMetadata.grade}</span>
              ) : null}
              <span>{displaySubject(topicMetadata.subject).toUpperCase()}</span>
            </div>
            <p class="mb-3">{topicMetadata.name}</p>
            {topics && topics > 1 && (
              <a href="#" class="btn btn-topic">
                + 3 topics
              </a>
            )}
          </div>
        </div>
        <div class="card-body">
          <div class="card-info-text mb-2 d-flex justify-content-between">
            {lessonMetadata && lessonMetadata.numberOfQuestions && (
              <span>Questions : {lessonMetadata.numberOfQuestions}</span>
            )}
            {lessonMetadata && lessonMetadata.timeRequired && (
              <span>Time : {lessonMetadata.timeRequired} mins</span>
            )}
          </div>
          <div class="card-body-content">
            <p>{shortName}</p>
          </div>

          <div class="card-btns">
            <a
              href="#"
              onClick={() => setPreview(true)}
              class="btn btn-default"
            >
              <img src={getImage("np_play.svg")} class="icon-left" /> PREVIEW
            </a>
            <a
              href="#"
              class="btn ml-2 btn-primary"
              onClick={() => setAssign(true)}
            >
              ASSIGN challenge{" "}
            </a>
          </div>
        </div>
      </div>
    </div> */}
