import React, { Component } from 'react';

import Header from '../components/Header';
import ProfileNavigation from '../components/ProfileNavigation';
import {
  getGrade,
  getStudents,
  isParent,
  getChildren,
  isTeacher,
} from '../utils/profile-utils';
import StudentMessage from '../components/Teacher/StudentMessage';
import requireAuthentication from '../components/AuthenticatedComponent';

class Grade extends Component {
  state = {};

  constructor(props) {
    super(props);

    console.log(props);

    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    let children = null;
    if (isParent()) {
      children = getChildren();
    }
    let grade = null;
    if (isTeacher()) {
      grade = getGrade();
    }

    this.setState({
      children: children,
      grade: grade,
    });
  }

  render() {
    const { grade } = this.state;
    return (
      <div>
        <Header title={'Grade ' + grade} />
        <div class="profile-area">
          <div class="sk-container">
            <div class="profiles-main-warpp">
              <ProfileNavigation active={'grade'} />
              <div class="right-content-warp">
                <div class="right-content-inner">
                  <div class="help-content">
                    <div class="help-title">
                      <h4>Grade {getGrade()}</h4>
                    </div>
                    <div class="alessandra-text">
                      <div class="staric-list">
                        <ul>
                          <li class="active">
                            <a href="">MATH</a>
                          </li>
                          <li>
                            <a href="">SCIENCE</a>
                          </li>
                          <li>
                            <a href="">ENGLISH</a>
                          </li>
                        </ul>
                      </div>
                      <div class="points-area">
                        <div class="message-search">
                          <div class="search-boxx">
                            <input
                              type="search"
                              placeholder="Search a student"
                            />
                            <button>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.5"
                                height="16"
                                viewBox="0 0 15.5 16"
                              >
                                <defs>
                                  {/* <style>
										            .cls-1{fill:#cfd7db}
										        </style> */}
                                </defs>
                                <path
                                  id="search"
                                  d="M16.248 14.573l-3.821-3.961a6.5 6.5 0 1 0-1.248 1.138l3.85 3.991a.844.844 0 0 0 1.219-1.168zM7.466 1.685A4.775 4.775 0 1 1 2.675 6.46a4.788 4.788 0 0 1 4.791-4.775z"
                                  class="cls-1"
                                  transform="translate(-.984)"
                                />
                              </svg>
                            </button>
                          </div>
                          <div class="all-msg-btn">
                            <button>MESSAGE ALL</button>
                          </div>
                          <div class="add-option">
                            <div class="tab-info-dot">
                              <div class="tab-menu-dot">
                                <span />
                                <span />
                                <span />
                              </div>
                              <div class="tab-menu">
                                <ul>
                                  <li>
                                    <a href="">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="15.969"
                                        viewBox="0 0 16 15.969"
                                      >
                                        <defs>
                                          {/* <style>
													            .cls-1{fill:#657d95}
													        </style> */}
                                        </defs>
                                        <g
                                          id="add-user"
                                          transform="translate(0 -.499)"
                                        >
                                          <g
                                            id="Group_2660"
                                            data-name="Group 2660"
                                            transform="translate(0 .499)"
                                          >
                                            <g
                                              id="Group_2659"
                                              data-name="Group 2659"
                                            >
                                              <path
                                                id="Path_5048"
                                                d="M8.01.5a4.612 4.612 0 0 0-2.62 8.42A8 8 0 0 0 0 16.468h1.252A6.755 6.755 0 0 1 8.01 9.731 4.616 4.616 0 1 0 8.01.5zm0 7.984a3.368 3.368 0 1 1 3.38-3.369 3.378 3.378 0 0 1-3.38 3.368z"
                                                class="cls-1"
                                                data-name="Path 5048"
                                                transform="translate(0 -.499)"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            id="Group_2662"
                                            data-name="Group 2662"
                                            transform="translate(9.591 10.058)"
                                          >
                                            <g
                                              id="Group_2661"
                                              data-name="Group 2661"
                                            >
                                              <path
                                                id="Path_5049"
                                                d="M308.226 306.494v-2.588h-1.233v2.588H304.4v1.233h2.588v2.588h1.233v-2.588h2.588v-1.233z"
                                                class="cls-1"
                                                data-name="Path 5049"
                                                transform="translate(-304.405 -303.906)"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                      Add STUDENT
                                    </a>
                                  </li>
                                  <li>
                                    <a href="">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                      >
                                        <defs>
                                          {/* <style>
												            .cls-1{fill:#657d95}
												        </style> */}
                                        </defs>
                                        <path
                                          id="minus"
                                          d="M8 16A8 8 0 0 1 2.343 2.342a8 8 0 0 1 11.313 11.314A7.948 7.948 0 0 1 8 16zM8 1.249A6.75 6.75 0 1 0 14.749 8 6.758 6.758 0 0 0 8 1.249zm3.437 6.124H4.562v1.25h6.875zm0 0"
                                          class="cls-1"
                                          transform="translate(0 .001)"
                                        />
                                      </svg>
                                      REMOVE STUDENT
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div class="student-message-warp">
                            <div class="student-ms-title">
                              <h4>{getStudents().length} STUDENTS</h4>
                            </div>

                            <div class="student-message">
                              {getStudents().map(s => (
                                <StudentMessage student={s} />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default requireAuthentication(Grade);
