import React from "react";
import { NavLink } from "react-router-dom";

import "./ChooseSubject.css";
import { config } from "../../../Constants";

import { getImage } from "../../../utils/image-utils";
import requireAuthentication from "../../../components/AuthenticatedComponent";
import { getSubjects } from "../../../utils/profile-utils";

class ChooseSubject extends React.Component {
  // default State object

  state = {
    subject: []
  };

  constructor(props) {
    super(props);

    // console.log('PROPS: ' + JSON.stringify(props));

    this.state.mode = props.match.params.mode;

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.body.classList.add(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
    this.setState({
      subjects: getSubjects()
    });
  }

  componentWillUnmount() {
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
  }

  handleClick() {
    this.props.onClose();
  }


  render() {
    const { mode, subjects } = this.state;
    return (
      <div id="student-container" className="choose-subject-playground">
        <div className="header-bar math-header">
          <div className="sk-container">
            <div className=" header-back">
              <NavLink to={"/challenges"}>
                <img src={getImage("student-back.svg")} alt="back" />
              </NavLink>
            </div>
            {subjects && (
              <div className="header-meddle">
                <h3>Choose Subject</h3>
              </div>
            )}
          </div>
        </div>
        <div className="choose-subject-card-wrapper">
          <ul>
            {subjects && subjects.includes("SCIENCE") && (
              <NavLink to={"/" + mode + "/science"}>
                <li className="subject-science cursor-pointer">
                  <div className="marque">
                    <img src={getImage("science-icon.svg")} alt="science" />
                  </div>
                  <span>Science</span>
                </li>
              </NavLink>
            )}
            {subjects && subjects.includes("MATH") && (
              <NavLink to={"/" + mode + "/math"}>
                <li className="subject-math cursor-pointer">
                  <div className="marque">
                    <img src={getImage("math-icon.svg")} alt="math" />
                  </div>
                  <span>Math</span>
                </li>
              </NavLink>
            )}
            {subjects && subjects.includes("WORD_STUDY") && (
              <NavLink to={"/" + mode + "/word_study"}>
                <li className="subject-english  cursor-pointer">
                  <div className="marque">
                    <img src={getImage("english-icon.svg")} alt="english" />
                  </div>
                  <span>Word Study</span>
                </li>
              </NavLink>
            )}
          </ul>
          <ul>
            {subjects && subjects.includes("SOCIAL_STUDIES") && (
              <NavLink to={"/" + mode + "/social_studies"}>
                <li className="subject-history  cursor-pointer">
                  <div className="marque">
                    <img
                      src={getImage("subject-icon.svg")}
                      alt="socal studies"
                    />
                  </div>
                  <span>Social Studies</span>
                </li>
              </NavLink>
            )}
            {subjects && subjects.includes("VOCABULARY") && (
              <NavLink to={"/" + mode + "/vocabulary"}>
                <li className="subject-vocabulary  cursor-pointer">
                  <div className="marque">
                    <img
                      src={getImage("vocabulary-icon.svg")}
                      alt="vocabulary"
                    />
                  </div>
                  <span>vocabulary</span>
                </li>
              </NavLink>
            )}
            {subjects && subjects.includes("SCIENCE_REVIEW") && (
              <NavLink to={"/" + mode + "/science_review"}>
                <li className="subject-science  cursor-pointer">
                  <div className="marque">
                    <img
                      src={getImage("science-icon.svg")}
                      alt="science review"
                    />
                  </div>
                  <span>Science Review</span>
                </li>
              </NavLink>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default requireAuthentication(ChooseSubject);
