import React, { Component } from "react";

import { displayLessonName } from "../../utils/display-utils";
import { getActor } from "../../utils/profile-utils";
import { findLesson } from "../../utils/lesson-utils";

class Step3 extends Component {
  // default State object
  state = {
    step: 1,
    startDate: new Date(),
    showNext: false,
    catalog: null,
    topicKey: null
  };

  constructor(props) {
    super(props);

    this.state.catalog = props.catalog;
    this.state.topicKey = props.topicKey;

    console.log("Step 3: " + props.topicKey);

    this.handleLessonChange = this.handleLessonChange.bind(this);
    this.loadLesson = this.loadLesson.bind(this);
  }

  handleLessonChange(event) {
    const lessonKey = event.target.value;
    // loadSubjectsByGrade(event.target.value, this.loadData, this.showError);

    console.log("Step3 pickLesson:" + lessonKey);
    let showNext = false;
    if (lessonKey && lessonKey.length > 0) {
      showNext = true;
    }
    this.setState(
      {
        lessonKey: lessonKey,
        showNext: showNext
      },
      () => findLesson(this.state.lessonKey, this.loadLesson, null)
    );
  }

  loadLesson(lesson) {
    this.setState({
      lesson: lesson
    });
  }

  render() {
    const { topicKey, lessonError, catalog, lessonKey, lesson } = this.state;
    let topic;
    if (catalog && topicKey) {
      const topicList = catalog.topics.filter(t => t.key === topicKey);
      if (topicList && topicList.length > 0) {
        topic = topicList[0];
      }
    }

    const questions = lesson ? lesson.questions : [];
    const subject = lesson ? lesson.subject : topic.subject;
    let name = lesson ? displayLessonName(lesson) : null;

    return (
      <div>
        <div className="subject-select">
          <span>Select challenge lesson</span>
          <div className="pr-tag">
            <span>
              {/* <input value={lessonName} onClick={this.showCatalog} /> */}
              <select value={lessonKey} onChange={this.handleLessonChange}>
                <option value="">Pick Lesson</option>
                {topic &&
                  topic.lessonList &&
                  topic.lessonList.map(t => (
                    <option value={t.lessonKey}>{displayLessonName(t)}</option>
                  ))}
              </select>
            </span>
            {lessonError && <span className="error-text">{lessonError}</span>}
          </div>
        </div>
        <div className="subject-select challenge-scrolling-wrapper">
          {questions &&
            questions.map((c, index) => (
              <QuestionReport
                key={c.key}
                index={index}
                questonKey={c.key}
                report={c}
                lessonKey={lessonKey}
                lessonName={name}
                subject={subject}
                addTestLesson={this.addTestLesson}
              />
            ))}
        </div>
        <div className="subject-select">
          <div className="next-step-btn manage-btn">
            <a onClick={() => this.props.previousStep(-1)} className="no-see">
              BACK
            </a>
            {this.state.showNext && (
              <a
                className="remove large"
                onClick={() => this.props.assignLesson(lessonKey, getActor())}
              >
                ASSIGN CHALLENGE
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class QuestionReport extends Component {
  state = {
    testLessonKey: null
  };

  constructor(props) {
    super(props);

    this.handleTestLessonKeyChange = this.handleTestLessonKeyChange.bind(this);
  }

  handleTestLessonKeyChange(event) {
    this.setState({ testLessonKey: event.target.value, nameError: null });
  }

  render() {
    const props = this.props;
    const subjectStyle = props.subject.toLowerCase() + "-indicator";
    // console.log("LessonReview => QuestionReport props: " + JSON.stringify(props));
    return (
      // <div className="complete-incomplete-area">
      <div className="single-com-item">
        <div className="left-comp-text">
          <h2>{props.index + 1}</h2>
          <div className="com-response-text">
            <p dangerouslySetInnerHTML={{ __html: props.report.question }} />
            {props.report.displayImage && (
              <p>
                <img src={props.report.displayImage} />
              </p>
            )}
            <h5>
              <span className={"dot-complete " + subjectStyle} />
              {props.lessonName}
            </h5>
          </div>
        </div>
      </div>
      // </div>
    );
  }
}

export default Step3;
