import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import {
  getTopicName,
  getTopicSubject,
  getTopicMetadata,
} from "../../services/ParentService";
import { getImage } from "../../utils/image-utils";
import { displaySubject } from "../../utils/display-utils";
import TopicRow from "./TopicRow";

const TopicSummary = ({
  childProfile,
  topicMetrics,
  days,
}) => {
  const [isExpanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    console.log("TopicSummary NEW isExpanded: " + !isExpanded);
    setExpanded(!isExpanded);
  };
  const topicKeys = Object.keys(topicMetrics);
  console.log("TopicSummary topicKeys: " + topicKeys);

  return (
    <div class="sk-card mt-4 card-reset overflow-hidden">
      <div
        class="card-header p-md-4 p-3 d-block d-sm-flex strip-content text-white align-items-center"
        style={{ backgroundColor: "#a463f7" }}
      >
        <div class="strip-count my-sm-0 mb-2">
          {Object.keys(topicMetrics).length}
        </div>
        <div class="strip-info pl-sm-3 pl-2 flex-grow-1 my-sm-0 mb-2">
          <h4>Topic(s) attempted in last {days} days</h4>

          <div class="d-flex si-bottom flex-wrap align-items-center">
          </div>
        </div>
        {!isExpanded && (
          <h1 onClick={toggleExpand}>
            <a
              data-toggle="collapse"
              href="#hideTopic"
              role="button"
              aria-expanded="false"
              aria-controls="hideTopic"
              class="btn ml-auto btn-md text-normal btn-strip"
            >
              Show Topics{" "}
              <img
                src={getImage("np_arrow_up.svg")}
                class="icon-right arrow-up"
                alt="arrow"
              />
            </a>
          </h1>
        )}
        {isExpanded && (
          <h1 onClick={toggleExpand}>
            <a
              data-toggle="collapse"
              href="#hideTopic"
              role="button"
              aria-expanded="false"
              aria-controls="hideTopic"
              class="btn ml-auto btn-md text-normal btn-strip"
            >
              Hide Topics{" "}
              <img
                src={getImage("np_arrow_up.svg")}
                class="icon-right arrow-up"
                alt="arrow"
              />
            </a>
          </h1>
        )}
      </div>
      {isExpanded && (
        <div
          class="card-body collapse show px-md-4 px-2 py-md-5 py-4"
          id="hideTopic"
        >
          <div class="challenge-listheading pb-md-3 mb-4 d-none d-md-block">
            <div class="row">
              <div class="col-lg-3 col-md-4">
                <span>Topics</span>
              </div>
              <div class="col-lg-2 col-md-2 text-center">
                <span>Accuracy</span>
              </div>
              <div class="col-lg-2 col-md-2 text-center">
                <span>Exposure</span>
              </div>
              <div class="col-lg-3 col-md-4">
                <span>Challenges completed</span>
              </div>
            </div>
          </div>
          {Object.keys(topicMetrics).map((m) => {
            const metrics = topicMetrics[m];
            const topicMetadata = getTopicMetadata(m);
            return <TopicRow topicMetadata={topicMetadata} metrics={metrics} childProfile={childProfile}
              topicId={m}/>
          })}
        </div>
      )}
    </div>
  );
};

export default TopicSummary;
