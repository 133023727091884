import React from "react";
import { NavLink } from "react-router-dom";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getClassCount } from "../../../utils/profile-utils";

const LessonInfo = ({
  report,
  child,
  isClassReport,
  reportPath,
  isSub,
  minimal
}) => {
  if (!report) {
    return <div />;
  }

  const {
    attemptedQuestions,
    totalQuestions,
    attempts,
    exposure,
    confidence,
    accuracy,
    score,
    scoreText,
    studentsWithReports,
    lesson
  } = report;
  const { shortName, lessonKey } = lesson;
  const classCount = getClassCount();
  console.log(lesson.name + " LessonInfo attempts: " + attempts);
  console.log(lesson.name + " LessonInfo score: " + score);

  return (
    <div className={"single-degit-number " + (isSub ? "bg-gray" : "")}>
      <div className="left-degit">
        {!minimal && (
          <h4>
            {reportPath && (
              <NavLink to={reportPath + lessonKey}>{shortName}</NavLink>
            )}
            {!reportPath && <span>{shortName}</span>}
          </h4>
        )}
      </div>
    </div>
  );
};

export default LessonInfo;
