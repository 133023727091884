import React, { useEffect } from "react";

import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import SiteNavigation from "../components/SiteNavigation";
import { getImage } from "../utils/image-utils";
import PrivacyPolicy from "../components/PrivacyPolicy";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";

const About = () => {
  return (
    // <div id="site-home-container" className={"site-home "}>
    <div id="wrapper" class="parent-home" style={{background: "white"}}>
      <SiteHeader />

      <section className="ftco-section ftco-services">
        <div className="sk-container">
          <div className="row justify-content-center mb-5 pb-5">
            {/* <div className="col-md-7 text-center heading-section fadeInUp ftco-animated">
            </div> */}

            <div class="profiles-main-warpp">
              <SiteNavigation active="about" />
              <div class="right-content-warp">
                <div class="right-content-inner">
                  <div class="help-content">
                    <div class="help-title">
                      <h4>About Us</h4>
                    </div>
                    <div class="help-text about-text">
                      <div class="panel active">
                        <div class="modal-bodyy">
                          <div class="single-modal-content">
                            <div class="modal-right-text">
                              <p>
                                SmartKids creates calibrated learning paths for
                                every student while providing educators and
                                parents with advanced, intuitive analytics.
                              </p>
                            </div>
                          </div>
                          <div class="single-modal-content">
                            <div class="modal-right-text">
                              <h4>For Students</h4>
                              <p>
                                Students intuitively work on educator and parent
                                assigned challenges within speech enabled mobile
                                and web experience. Machine learning algorithms
                                provide personalized growth path for each
                                student
                              </p>
                            </div>
                          </div>
                          <div class="single-modal-content">
                            <div class="modal-right-text">
                              <h4>For Educators</h4>
                              <p>
                                Educators get daily feedback on classroom
                                instruction to gauge understanding and plan new
                                material. Advanced analytics highlight class and
                                individual weaknesses and brings predictability
                                to lesson planning and teaching.
                              </p>
                            </div>
                          </div>
                          <div class="single-modal-content">
                            <div class="modal-right-text">
                              <h4>For Parents</h4>
                              <p>
                                Parents get a customized platform on web and app
                                that empowers to calibrate and improve the
                                overall learning experience. Fully automated
                                platform to push challenges and revisions with
                                minimal effort.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <button class="accordion">Terms of Use</button> */}
                    </div>

                    {/* <button class="accordion">Privacy Policy</button> */}
                  </div>

                  {/* <button class="accordion">Third-Party Notices</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SiteFooter />
    </div>
  );
};

export default About;
