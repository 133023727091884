import React, { PropTypes } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

const TopicChallengeSummary = ({ topicMetrics, lessons }) => {

  let totalLessons = lessons ? lessons.length : 0;
  const completedLessons = topicMetrics ? topicMetrics.completedLessons : 0;
  const attemptedLessons = topicMetrics ? topicMetrics.lessonCardinality : 0;
  const pendingLessons =  totalLessons - attemptedLessons;

  return (
    <div class="col-lg-5 mt-3">
      <div class="sk-card">
        <div class="card-body p-md-4 p-3 text-gray">
          <h3 class="text-gray text-center">Challenge summary</h3>

          <div class="my-3">
            <div class="graph-sm">
              <CircularProgressbarWithChildren
                value={completedLessons}
                maxValue={totalLessons}
                text={`${""}`}
                strokeWidth="5"
                styles={buildStyles({
                  textColor: "rgb(108, 124, 147)",
                  pathColor: "#F5C818", // yellow #F5C818
                  trailColor: "#F86456", // red #F86456
                })}
              >
                <CircularProgressbarWithChildren
                  value={attemptedLessons}
                  maxValue={totalLessons}
                  strokeWidth="5.1"
                  styles={buildStyles({
                    textColor: "rgb(108, 124, 147)",
                    pathColor: "#2CD89D", // green #2CD89D
                    trailColor: "rgb(221, 224, 229, 0)",
                  })}
                >
                  <span>
                    {lessons ? lessons.length : 0} Total
                    Challenges
                  </span>
                </CircularProgressbarWithChildren>
              </CircularProgressbarWithChildren>
            </div>
          </div>

          <div class="row justify-content-between">
            <div class="col-auto">
              <div class="label-complete">
                {topicMetrics ? topicMetrics.completedLessons : 0} <br />
                Complete
              </div>
            </div>
            <div class="col-auto">
              <div class="label-incomplete">
                {pendingLessons > 0 ? pendingLessons : 0}
                <br />
                Pending
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopicChallengeSummary;
