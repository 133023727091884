
import React, { PropTypes } from "react";
import { getImage } from "../../utils/image-utils";
import { getMinutesDisplay } from "../../utils/display-utils";

const GraphHeader = ({ overallMetrics, testingMetrics, learningMetrics }) => {
    return (<div class="graph-values d-flex flex-wrap align-items-start mb-4 ">
    <div class="gv-item mb-2 mr-auto">
        <div>
            <strong>80</strong>{" "}
            <sub>
                <img src={getImage("up.png")} alt="icon" />{" "}
                                  20%
                                </sub>
        </div>
        <div class="gv-name">Sessions</div>
    </div>
    {overallMetrics && (
        <div class="gv-item mb-2">
            <div>
                {getMinutesDisplay(overallMetrics.timeSpent)}{" "}
                <sub>mins</sub>
            </div>
            <div class="gv-name">Total time spent</div>
        </div>
    )}
    {learningMetrics && (
        <div class="gv-item mb-2">
            <div>
                {getMinutesDisplay(learningMetrics.timeSpent)}{" "}
                <sub>mins</sub>
            </div>
            <div class="gv-name learning-label">
                Learning
                                </div>
        </div>
    )}
    {testingMetrics && (
        <div class="gv-item mb-2">
            <div>
                {getMinutesDisplay(testingMetrics.timeSpent)}{" "}
                <sub>mins</sub>
            </div>
            <div class="gv-name testing-label">Testing</div>
        </div>
    )}
    </div>
    );
};

export default GraphHeader;

