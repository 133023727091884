import React from "react";

import SiteNavigation from "../components/SiteNavigation";
import PrivacyPolicy from "../components/PrivacyPolicy";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";

const Privacy = () => {

  return (
    <div id="wrapper" class="parent-home" style={{ background: "white" }}>
      <SiteHeader />

      <section className="ftco-section ftco-services">
        <div className="sk-container">
          <div className="row justify-content-center mb-5 pb-5">
            <div class="profiles-main-warpp">
              <SiteNavigation active="privacy" />
              <PrivacyPolicy />
            </div>
          </div>
        </div>
      </section>
      <SiteFooter />
    </div>
  );
};

export default Privacy;
