import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'react-html-id';
import { NavLink } from 'react-router-dom';

import ProfileCard from '../ProfileCard/ProfileCard';

import './ProfileWidget.css';
import { getImage, getAvatarImage } from '../../../utils/image-utils';
import { getProfile, getToken } from '../../../utils/profile-utils';

class ProfileHomeWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      isBurgerToggleOn: false,
      isSettingsToggleOn: false,
      isNotificationsToggleOn: false,
    };

    if (!getProfile() || !getToken()) {
      this.props.history.push('/logout');
    }

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleBurgerClick = this.handleBurgerClick.bind(this);
    this.dismiss = this.dismiss.bind(this);

    uniqueId.enableUniqueIds(this);
  }

  handleClick() {
    console.log('ProfileWidget handleClick');
    this.setState(state => ({
      isToggleOn: !state.isToggleOn,
      isBurgerToggleOn: false,
      isSettingsToggleOn: false,
      isNotificationsToggleOn: false,
    }));
  }

  handleBurgerClick() {
    console.log('ProfileWidget handleBurgerClick');
    this.setState(state => ({
      isBurgerToggleOn: !state.isBurgerToggleOn,
      isSettingsToggleOn: false,
      isNotificationsToggleOn: false,
    }));
  }

  dismiss() {
    console.log('ProfileWidget dismiss');
    this.setState({
      isSettingsToggleOn: false,
      isNotificationsToggleOn: false,
    });
  }

  render() {
    let avatarImage = getAvatarImage(getProfile());

    return (
      <div className="" style={{width: "100px"}}>
        <div className="">
          {this.props.isStudent && (
            <div className="pro-ic" onClick={() => this.handleClick()}>
              <img
                id={this.getUniqueId('profile-picture')}
                className=""
                src={avatarImage}
                alt="avatar"
              />
            </div>
          )}
          {this.props.isParent && (
            <div className="pro-ic">
              <NavLink to="/parent/profile">
                <img
                  id={this.getUniqueId('profile-picture')}
                  className=""
                  src={avatarImage}
                  alt="avatar"
                />
              </NavLink>
            </div>
          )}
          {this.props.isTeacher && (
            <div className="pro-ic">
              <NavLink to="/teacher/profile">
                <img
                  id={this.getUniqueId('profile-picture')}
                  className=""
                  src={avatarImage}
                  alt="avatar"
                />
              </NavLink>
            </div>
          )}
        </div>
        <div className="berger-menu">
          <div className="menubar" onClick={() => this.handleBurgerClick()}>
            <span />
            <span />
            <span />
          </div>
          {this.state.isBurgerToggleOn && (
            <div className="menu">
              <ul>
                <li>
                  <img src={getImage('my-profile-icon.svg')} alt="" />
                  <NavLink to="/profile">My Profile</NavLink>
                </li>
                <li>
                  <img src={getImage('message-icon.svg')} alt="" />
                  <NavLink to="/messages">Message</NavLink>
                </li>
                <li>
                  <img src={getImage('parents-icon.svg')} alt="" />
                  <NavLink to="/login">Parent Login</NavLink>
                </li>
                <li>
                  <img src={getImage('logout-icon.svg')} alt="" />
                  <NavLink to="/login">Logout</NavLink>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div>
          {!this.state.isToggleOn && this.props.isStudent && (
            <ProfileCard
              home="true"
              getUniqueId={this.getUniqueId}
              onClose={this.handleClick}
            />
          )}
        </div>
      </div>
    );
  }
}

ProfileHomeWidget.propTypes = {
  onSettings: PropTypes.func,
  onNotifications: PropTypes.func,
};

export default ProfileHomeWidget;
