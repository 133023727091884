import React, { useRef, useEffect, useState } from "react";

import { getImage, getAvatarImage } from "../../utils/image-utils";

const SideNav = ({ child, children, page, navPath }) => {
  const [isExpanded, setExpanded] = useState(false);

  // console.log("SideNav child: " + child);

  const node = useRef();

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click do nothing
      return;
    }
    // outside click close card
    setExpanded(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <aside className="sidebar d-flex flex-sm-column">
      <a href="/" className="logo d-none d-sm-block">
        <img style={{ padding: "0px 40px 13px 13px" }} 
          className="d-none d-xl-inline-block"
          src={getImage("logo.svg")}
          alt="logo"
        />
        <img className="d-block d-xl-none" src={getImage("logo-small.svg")} alt="logo" />
      </a>

      <ul className="navbar p-0 list-unstyled mb-0">
        <li class={page === "Recommendation" ? "active" : " "}>
          <a href={"/parent/recommendations/" + child.userid}>
            <span className="nav-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28.713"
                height="32.815"
                viewBox="0 0 28.713 32.815"
              >
                <g
                  id="prefix__Group_11575"
                  data-name="Group 11575"
                  transform="translate(-1.822 1.002)"
                >
                  <path
                    id="prefix__np_light_1570737_000000"
                    d="M22.356 2a.683.683 0 0 0-.684.684v2.734a.684.684 0 1 0 1.367 0V2.684A.683.683 0 0 0 22.356 2zm-9.667 4.006a.684.684 0 0 0-.481 1.164l1.934 1.93a.68.68 0 0 0 .961-.961l-1.933-1.93a.678.678 0 0 0-.481-.2zm19.334 0a.678.678 0 0 0-.481.2l-1.933 1.936a.68.68 0 0 0 .961.961L32.5 7.17a.684.684 0 0 0-.481-1.164zm-9.667 2.147c-4.168 0-8.2 3.234-8.2 7.809 0 5.935 4.444 7.178 4.444 11.333h7.52c0-4.155 4.444-5.4 4.444-11.333-.004-4.575-4.039-7.809-8.208-7.809zm-2.1 2.072h.011a.356.356 0 0 1 .053 0 .684.684 0 0 1 .235 1.325 5.135 5.135 0 0 0-3.237 3.9.684.684 0 1 1-1.346-.246 6.475 6.475 0 0 1 4.091-4.924.687.687 0 0 1 .192-.053zM8.683 15.673a.684.684 0 1 0 0 1.367h2.735a.684.684 0 1 0 0-1.367zm24.611 0a.684.684 0 1 0 0 1.367h2.735a.684.684 0 1 0 0-1.367zM18.6 28.662v1.368h7.52v-1.368zm0 2.735a1.37 1.37 0 0 0 1.367 1.367h4.785a1.37 1.37 0 0 0 1.364-1.364zm2.393 2.051a1.367 1.367 0 0 0 2.735 0z"
                    transform="translate(-6.178 -3.002)"
                  ></path>
                </g>
              </svg>
            </span>
            <span className="nav-text">Recommendation</span>
          </a>
        </li>
        <li class={page === "Performance" ? "active" : " "}>
          <a href={"/parent/performance/" + child.userid}>
            <span className="nav-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.373"
                height="22.245"
                viewBox="0 0 22.373 22.245"
              >
                <g
                  id="prefix__np_analytics_136078_000000"
                  transform="translate(-5 -5.258)"
                >
                  <path
                    d="M29.285 42.191h4.262v13.063h-4.262z"
                    className="prefix__cls-1"
                    data-name="Path 5360"
                    transform="translate(-18.248 -27.752)"
                  ></path>
                  <path
                    d="M53.57 56.43h4.262v9.524H53.57z"
                    className="prefix__cls-1"
                    data-name="Path 5361"
                    transform="translate(-36.496 -38.452)"
                  ></path>
                  <path
                    d="M77.855 32.4h4.262v15.5h-4.262z"
                    className="prefix__cls-1"
                    data-name="Path 5362"
                    transform="translate(-54.744 -20.397)"
                  ></path>
                  <path
                    d="M5 62.02h4.262v8.134H5z"
                    className="prefix__cls-1"
                    data-name="Path 5363"
                    transform="translate(0 -42.652)"
                  ></path>
                  <path
                    d="M8.2 16.171A1.775 1.775 0 0 0 9.977 14.4a1.757 1.757 0 0 0-.129-.656l3.418-2.79a1.758 1.758 0 0 0 2.158-.169l3.126 1.839a1.732 1.732 0 0 0-.049.4 1.776 1.776 0 1 0 3.349-.809L25.492 8.6a1.754 1.754 0 0 0 .822.211 1.8 1.8 0 1 0-1.573-.966L21.1 11.453a1.734 1.734 0 0 0-2.006.251l-3.128-1.839a1.8 1.8 0 0 0 .049-.4 1.776 1.776 0 1 0-3.551 0 1.754 1.754 0 0 0 .129.656l-3.417 2.79a1.775 1.775 0 1 0-.976 3.26z"
                    className="prefix__cls-1"
                    data-name="Path 5364"
                    transform="translate(-1.071)"
                  ></path>
                </g>
              </svg>
            </span>
            <span className="nav-text">Performance</span>
          </a>
        </li>
        <li class={page === "Challenge History" ? "active" : " "}>
          <a href={"/parent/challenges/" + child.userid}>
            <span className="nav-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.764"
                height="21.764"
                viewBox="0 0 21.764 21.764"
              >
                <g transform="translate(-20.391 -20.391)">
                  <path
                    d="M24.874 22.625a2.236 2.236 0 1 1-2.235-2.235 2.236 2.236 0 0 1 2.235 2.235"
                    className="prefix__cls-1"
                    data-name="Path 5365"
                    transform="translate(-.007)"
                  ></path>
                  <path
                    d="M24.862 46.3a2.236 2.236 0 1 1-2.237-2.237 2.236 2.236 0 0 1 2.237 2.237"
                    className="prefix__cls-1"
                    data-name="Path 5366"
                    transform="translate(0 -15.03)"
                  ></path>
                  <path
                    d="M24.885 69.981a2.236 2.236 0 1 1-2.237-2.235 2.236 2.236 0 0 1 2.237 2.235"
                    className="prefix__cls-1"
                    data-name="Path 5367"
                    transform="translate(-.014 -30.063)"
                  ></path>
                  <path
                    d="M39.693 24.893h11.425a2.231 2.231 0 1 0 0-4.463H39.693a2.231 2.231 0 1 0 0 4.463z"
                    className="prefix__cls-1"
                    data-name="Path 5368"
                    transform="translate(-11.195 -.025)"
                  ></path>
                  <path
                    d="M51.117 44.051H39.692a2.232 2.232 0 0 0 0 4.464h11.425a2.231 2.231 0 1 0 0-4.461z"
                    className="prefix__cls-1"
                    data-name="Path 5369"
                    transform="translate(-11.194 -15.021)"
                  ></path>
                  <path
                    d="M51.117 67.711H39.692a2.231 2.231 0 1 0 0 4.463h11.425a2.231 2.231 0 1 0 0-4.463z"
                    className="prefix__cls-1"
                    data-name="Path 5370"
                    transform="translate(-11.194 -30.041)"
                  ></path>
                </g>
              </svg>
            </span>
            <span className="nav-text">Challenge History</span>
          </a>
        </li>
      </ul>

      <div className="mt-sm-auto text-center">
        <a href={"/parent/create/challenge/" + child.userid} className="icon-purple text-center">
          <img src={getImage("np_plus.svg")} alt="logo" />
          <span className="ml-1">New Challenge</span>
        </a>
      </div>
      <div className="text-center ur-profile">
        <div className="dropdown dropup">
          <button
            onClick={() => setExpanded(!isExpanded)}
            className="nav-pic text-left d-flex align-items-center w-100 dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="npic">
              <img
                src={getAvatarImage(child)}
                className="img-fluid m-0 rounded-circle"
                alt="pic"
              />
            </span>
            <span className="npic-text">
  <strong>{child.name}</strong><br/>
              <span>Switch kid's view</span>
            </span>
          </button>
          <ul ref={node}
            className="dropdown-menu dropdown-profile-list" 
            aria-labelledby="dropdownMenuButton"
            x-placement="top-start"
            style={{
              position: "absolute",
              willChange: "transform",
              bottom: "0px",
              left: "0px",
              transform: "translate3d(36px, -16px, 0px)",
              display: isExpanded ? "block": "none",
              backgroundColor: '#f5f5f5'
            }}
          >
            <li className="d-block text-center d-sm-none">
              <h3>Switch Account</h3>
              <button className="btn btn-dropdown-close btn-reset p-0">×</button>
            </li>
            {children.map(c => { 
              const activeChild = c.userid === child.userid;
              return (
                <li>
                  <a href={"/parent/" + navPath + "/" + c.userid} className={"d-flex align-items-center " + (activeChild ? "active_user" : "")}>
                    {activeChild && <img src={getImage("np_tick_purple.svg")} className="active_icon position-absolute ml-1" alt="icon" />}
                      <span className="npic">
                        <img src={getAvatarImage(c)} className="img-fluid m-0 rounded-circle" alt="child pic" />
							        </span>
                      <span className="npic-text text-truncate">
                        <strong>{c.name}</strong>
                      </span>
					    	  </a>
                </li>
              );})}
           </ul>
        </div>
      </div>
    </aside>
  );
};

export default SideNav;
