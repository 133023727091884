import React, { useRef, useEffect, useState } from "react";
import { getImage } from "../../utils/image-utils";
import { displaySubject } from "../../utils/display-utils";
import { SUBJECT_STYLES } from "../../Constants";
import SubjectBar from "./SubjectBar";

const SubjectWidget = ({ subjectMetrics, firstName, child }) => {
    console.log("SubjectWidget metrics: " + JSON.stringify(subjectMetrics));
    return (
        <div class="sk-card h-100 card-reset overflow-hidden" style={{ height: "inherit" }}>
            <div class="card-body px-sm-4 px-2 py-4" style={{ height: "inherit" }}>
                <div class="d-flex align-items-center mb-3 flex-wrap justify-content-between">
                    <h3 class="mb-1">{firstName}’s subject wise score</h3>

                    {/* <a
                        href="#"
                        class="btn btn-purple ml-auto btn-md btn-rounded"
                    >
                        View All{" "}
                        <img
                            src={getImage("np_arrow_purple.svg")}
                            class="icon-right"
                            alt="arrow"
                        />
                    </a> */}
                </div>

                <div
                    class="subject-list scrollable"
                    tabindex="1"
                    style={{ overflow: "hidden", outline: "none" }}
                >
                    {subjectMetrics &&
                        Object.keys(subjectMetrics).map((s) => (
                            <SubjectBar
                                subject={s}
                                child={child}
                                metrics={subjectMetrics[s]}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};

export default SubjectWidget;
