import React from "react";
import { NavLink } from "react-router-dom";

import { getImage } from "../utils/image-utils";
import ProfileHomeWidget from "../components/Student/ProfileWidget/ProfileHomeWidget";
import { getProfile } from "../utils/profile-utils";

class SiteHeader extends React.Component {
  state = {
    showMenu: false
  };

  constructor(props) {
    super(props);
    // console.log(props);
    this.showMenu = this.showMenu.bind(this);
  }

  showMenu() {
    console.log("SiteHeader showMenu: " + this.state.showMenu);
    this.setState({
      showMenu: !this.state.showMenu
    });
  }

  render() {
    const profile = getProfile();
    const isLoggedIn = profile ? true : false;

    let showHomework = false;
    let showTeacher = false;
    let showParent = false;
    let showAdmin = false;
    if (profile) {
      if (profile.access[0] === "STUDENT") {
        showHomework = true;
      } else if (profile.access[0] === "TEACHER") {
        showTeacher = true;
      } else if (profile.access[0] === "PARENT") {
        showParent = true;
      }
      if (profile.access[0] === "ADMIN") {
        showAdmin = true;
      }
    }

    return (

      <header class="header-home">
        <div class="container">
          <nav class="navbar navbar-expand-md px-0">
            <a class="navbar-brand" href="/"><img src={getImage("logo.svg")} alt="logo" /></a>
            <button class="navbar-toggler p-0 collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon" onClick={() => this.showMenu()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false">
                  <title>Menu</title><path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"></path></svg>
              </span>
            </button>

            <div class={"navbar-collapse collapse " + (this.state.showMenu ? "show" : "")} id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                  <a class="nav-link" href="/">Home</a>
                </li>
                <li class="nav-item">
                  {!isLoggedIn && <a class="nav-link" href="/login">Login</a>}
                  {showAdmin && <a class="nav-link" href="/login">Dashboard</a>}
                  {showParent && <a class="nav-link" href="/parent">Dashboard</a>}
                  {showTeacher && <a class="nav-link" href="/login">Dashboard</a>}
                  {showHomework && <a class="nav-link" href="/challenges">Dashboard</a>}
                </li>
                {!this.state.showMenu && profile && <li>
                  <ProfileHomeWidget
                    avatar={profile.avatar}
                    gender={profile.gender}
                    isParent={showParent}
                    isTeacher={showTeacher}
                    isStudent={showHomework}
                  />
                </li>}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default SiteHeader;
