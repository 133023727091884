import React from "react";

import uniqueId from "react-html-id";
import { NavLink } from "react-router-dom";
import { Slide } from "react-slideshow-image";

import { getImage } from "../utils/image-utils";

import { config } from "../Constants";
import Background1 from "../assets/images/work-1.jpg";
import Background2 from "../assets/images/work-2.jpg";
import Background3 from "../assets/images/work-3.jpg";
import Background4 from "../assets/images/work-4.jpg";
import Background5 from "../assets/images/work-5.jpg";
import Background6 from "../assets/images/work-6.jpg";
import Slide1 from "../assets/images/student-home.png";
import Slide2 from "../assets/images/parent-home.png";
import Slide3 from "../assets/images/teacher-home.png";
import Pixel from "../assets/images/pixel.png";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";
import { getProfile, getUserid } from "../utils/profile-utils";

import "./Home.css";

class Home extends React.Component {
  slideWidth = 42 + "em";
  slideHeight = 30 + "em";
  backgroundSize = "contain";
  state = {
    showDemo: false,
    name: "",
    email: "",
    message: "",
    showSubmit: false
  };

  report = {
    pageName: "Home"
  };

  componentDidMount() {
    document.body.classList.remove(config.class.PRACTICE_PAGE_CLASS);
    document.body.classList.remove(config.class.STUDENT_PAGE_CLASS);
    document.body.classList.remove(config.class.ADULT_PAGE_CLASS);
    document.body.classList.remove(config.class.CHALLENGE_PAGE_CLASS);

    // scroll to top when footer links are clicked
    window.scrollTo(0, 0);
  }

  showDemo = () => {
    this.setState({
      showDemo: !this.state.showDemo,
      nameError: null,
      emailError: null,
      showSubmit: false
    });
  };

  render() {
    const profile = this.state.profile;
    // const slideImages = [Slide1, Slide2, Slide3];
    const slideImages = [Pixel, Pixel, Pixel];
    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true
      // onChange: (oldIndex, newIndex) => {
      //     console.log(`slide transition from ${oldIndex} to ${newIndex}`);
      // }
    };

    let addDemoClass = "";
    if (this.state.showDemo) {
      addDemoClass = "modal-open";
    }
    return (
      <div id="wrapper" class="parent-home">
        <SiteHeader />

        <div class="home-banner">
          <div class="container">
            <div class="row mb-4 justify-content-center text-center">
              <div class="col-md-10 banner-text pt-5 pb-md-4">
                <h1>Calibrated Learning &amp; Analytics.</h1>
                <h4>SmartKids creates calibrated learning paths for every student while providing educators and parents with advanced, intuitive analytics.</h4>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="banner-text py-4">
                  <ul>
                    <li>Advanced Machine Learning for curated learning path</li>
                    <li>Comprehensive content across Math, Science and Social Studies</li>
                    <li>Immersive learning via speech and text with recommendations and rewards</li>
                  </ul>

                  <a href="/access" class="btn-sign">SIGN UP</a>
                </div>
              </div>
              <div class="col-md-6">
                <div id="carouselHome" class="carousel home-carousel slide" data-ride="carousel">
                  {/* <ol class="carousel-indicators">
                    <li data-target="#carouselHome" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselHome" data-slide-to="1" class=""></li>
                    <li data-target="#carouselHome" data-slide-to="2" class=""></li>
                  </ol> */}
                  {/* <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src={getImage("parent-home.png")} class="d-block w-100" alt="image" />
                    </div>
                    <div class="carousel-item">
                      <img src={getImage("parent-home.png")} class="d-block w-100" alt="image" />
                    </div>
                    <div class="carousel-item">
                      <img src={getImage("parent-home.png")} class="d-block w-100" alt="image" />
                    </div>
                  </div> */}
                  {/* <a class="carousel-control-prev" href="#carouselHome" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carouselHome" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a> */}



                  <Slide {...properties}>
                    <div className="each-slide">
                      <div
                        className="img slide-image"
                        style={{
                          backgroundImage: `url(${slideImages[0]})`
                        }}
                      >
                        <span>For Students</span>
                        <img src={getImage("student-home.png")} class="d-block w-100 my-3" alt="image" />
                      </div>
                    </div>
                    <div className="each-slide">
                      <div
                        className="img slide-image"
                        style={{
                          backgroundImage: `url(${slideImages[1]})`
                        }}
                      >
                        <span>For Parents</span>
                        <img src={getImage("parent-home.png")} class="d-block w-100 my-3" alt="image" />
                      </div>
                    </div>
                    <div className="each-slide">
                      <div
                        className="img slide-image"
                        style={{
                          backgroundImage: `url(${slideImages[2]})`
                        }}
                      >
                        <span>For Educators</span>
                        <img src={getImage("teacher-home.png")} class="d-block w-100 my-3" alt="image" />
                      </div>
                    </div>
                  </Slide>



                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="home-strip">
          <div class="container">
            <p>Curated challenges for students.</p>
          </div>
        </div>

        <div class="informative-area text-center py-5 bg-white">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-6 mb-2">
                <h2>Informative, Actionable Insights for Parents and Educators.</h2>
                <p><strong>Recommendations to keep children engaged.</strong></p>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-4 mb-4">
                <img src={getImage("achievement-icon.svg")} class="info-icons" alt="icon" />
                <h3>For Students</h3>
                <p>Students intuitively work on educator and parent assigned challenges within speech enabled mobile and web experience. Machine learning algorithms provide personalized growth path for each student</p>
              </div>
              <div class="col-md-4 mb-4">
                <img src={getImage("calibration.svg")} class="info-icons" alt="icon" />
                <h3>For Educators</h3>
                <p>Educators get daily feedback on classroom instruction to gauge understanding and plan new material. Advanced analytics highlight class and individual weaknesses and brings predictability to lesson planning and teaching.</p>
              </div>
              <div class="col-md-4 mb-4">
                <img src={getImage("parent.svg")} class="info-icons" alt="icon" />
                <h3>For Parents</h3>
                <p>Parents get a customized platform on web and app that empowers to calibrate and improve the overall learning experience. Fully automated platform to push challenges and revisions with minimal effort.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="informative-area text-center py-5">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-6 mb-2">
                <h2>Features</h2>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-sm-4 mb-4">
                <div class="fr_image" style={{ backgroundImage: `url(${Background1})` }}>
                  <div class="fr_overlay"><div class="m-auto"><span>Students</span><h3>Daily Challenges</h3></div></div>
                </div>
              </div>
              <div class="col-sm-4 mb-4">
                <div class="fr_image" style={{ backgroundImage: `url(${Background2})` }}>
                  <div class="fr_overlay"><div class="m-auto"><span>Parents</span><h3>Realtime Progress Reports</h3></div></div>
                </div>
              </div>
              <div class="col-sm-4 mb-4">
                <div class="fr_image" style={{ backgroundImage: `url(${Background3})` }}>
                  <div class="fr_overlay"><div class="m-auto"><span>Educators</span><h3>Class Perfomance Dashboard</h3></div></div>
                </div>
              </div>
              <div class="col-sm-4 mb-4">
                <div class="fr_image" style={{ backgroundImage: `url(${Background4})` }}>
                  <div class="fr_overlay"><div class="m-auto"><span>Students</span><h3>Immersive, Speech Enabled Platform</h3></div></div>
                </div>
              </div>
              <div class="col-sm-4 mb-4">
                <div class="fr_image" style={{ backgroundImage: `url(${Background5})` }}>
                  <div class="fr_overlay"><div class="m-auto"><span>Parents</span><h3>Granular Visibility</h3></div></div>
                </div>
              </div>
              <div class="col-sm-4 mb-4">
                <div class="fr_image" style={{ backgroundImage: `url(${Background6})` }}>
                  <div class="fr_overlay"><div class="m-auto"><span>Educators</span><h3>Actionable Insights and Recommendations</h3></div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SiteFooter />
      </div>
      // <div id="site-home-container" className={"site-home " + addDemoClass}>
      //   <SiteHeader />
      //   <div className="hero-wrap">
      //     <div className="overlay" />
      //     <div className="sk-container">
      //       <div
      //         className="slider-text d-md-flex align-items-center"
      //         data-scrollax-parent="true"
      //       >
      //         <div className="hero-text">
      //           <h1 className="mb-4"> Calibrated Learning &amp; Analytics.</h1>
      //           <p
      //             className="mb-md-5 mb-sm-3"
      //             data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
      //           >
      //             SmartKids creates calibrated learning paths for every student
      //             while providing educators and parents with advanced, intuitive
      //             analytics.
      //           </p>
      //           <ul className="un-styled my-5">
      //             <li>
      //               <span className="fas fa-check"> &nbsp; </span>Advanced
      //               Machine Learning for curated learning path
      //             </li>
      //             <li>
      //               <span className="fas fa-check"> &nbsp; </span>Comprehensive
      //               content across Math, Science and Social Studies
      //             </li>
      //             <li>
      //               <span className="fas fa-check"> &nbsp; </span>Immersive
      //               learning via speech and text with recommendations and
      //               rewards
      //             </li>
      //           </ul>
      //           <div className="login-button">
      //             <NavLink to="/access">
      //               <button>SIGN UP</button>
      //             </NavLink>
      //           </div>
      //         </div>
      //         <div
      //           className="slid-container align-self-md-end"
      //           style={{ width: this.slideWidth, height: this.slideHeight }}
      //         >
      //           <Slide {...properties}>
      //             <div className="each-slide">
      //               <div
      //                 className="img"
      //                 style={{
      //                   backgroundImage: `url(${slideImages[0]})`,
      //                   width: this.slideWidth,
      //                   height: this.slideHeight
      //                 }}
      //               >
      //                 {/* <span>For Students</span> */}
      //               </div>
      //             </div>
      //             <div className="each-slide">
      //               <div
      //                 className="img"
      //                 style={{
      //                   backgroundImage: `url(${slideImages[1]})`,
      //                   width: this.slideWidth,
      //                   height: this.slideHeight
      //                 }}
      //               >
      //                 <span>For Parents</span>
      //               </div>
      //             </div>
      //             <div className="each-slide">
      //               <div
      //                 className="img"
      //                 style={{
      //                   backgroundImage: `url(${slideImages[2]})`,
      //                   width: this.slideWidth,
      //                   height: this.slideHeight
      //                 }}
      //               >
      //                 <span>For Educators</span>
      //               </div>
      //             </div>
      //           </Slide>
      //         </div>
      //       </div>
      //     </div>
      //   </div>

      //   <section className="ftco-section ftco-section-2">
      //     <div className="overlay" />
      //     <div className="sk-container">
      //       <div className="row">
      //         <div className="col-md-8">
      //           <h3 className="heading-white">
      //             Curated challenges for students.
      //           </h3>
      //         </div>
      //       </div>
      //     </div>
      //   </section>

      //   <section className="ftco-section ftco-services">
      //     <div className="sk-container-fluid">
      //       <div className="row justify-content-center mb-5 pb-5">
      //         <div className="col-md-7 text-center heading-section fadeInUp ftco-animated">
      //           <h2 className="mb-2">
      //             Informative, Actionable Insights for Parents and Educators.
      //           </h2>
      //           <span className="subheading">
      //             Recommendations to keep children engaged.
      //           </span>
      //         </div>
      //       </div>
      //       <div className="row">
      //         <div className="col-md-4 d-flex align-self-stretch fadeInUp ftco-animated">
      //           <div className="media block-6 services d-block text-center">
      //             <div className="d-flex justify-content-center">
      //               <div className="icon">
      //                 <img
      //                   src={getImage("achievement-icon.svg")})}
      //                   style={{ width: 60 + "px" }}
      //                 />
      //               </div>
      //             </div>
      //             <div className="media-body p-2 mt-3">
      //               <h3 className="heading">For Students</h3>
      //               <p>
      //                 Students intuitively work on educator and parent assigned
      //                 challenges within speech enabled mobile and web
      //                 experience. Machine learning algorithms provide
      //                 personalized growth path for each student
      //               </p>
      //             </div>
      //           </div>
      //         </div>
      //         <div className="col-md-4 d-flex align-self-stretch fadeInUp ftco-animated">
      //           <div className="media block-6 services d-block text-center">
      //             <div className="d-flex justify-content-center">
      //               <div className="icon">
      //                 <img
      //                   src={getImage("calibration.svg")})}
      //                   style={{ width: 60 + "px" }}
      //                 />
      //               </div>
      //             </div>
      //             <div className="media-body p-2 mt-3">
      //               <h3 className="heading">For Educators</h3>
      //               <p>
      //                 Educators get daily feedback on classroom instruction to
      //                 gauge understanding and plan new material. Advanced
      //                 analytics highlight class and individual weaknesses and
      //                 brings predictability to lesson planning and teaching.
      //               </p>
      //             </div>
      //           </div>
      //         </div>
      //         <div className="col-md-4 d-flex align-self-stretch fadeInUp ftco-animated">
      //           <div className="media block-6 services d-block text-center">
      //             <div className="d-flex justify-content-center">
      //               <div className="icon">
      //                 <img
      //                   src={getImage("parent.svg")})}
      //                   style={{ width: 60 + "px" }}
      //                 />
      //               </div>
      //             </div>
      //             <div className="media-body p-2 mt-3">
      //               <h3 className="heading">For Parents</h3>
      //               <p>
      //                 Parents get a customized platform on web and app that
      //                 empowers to calibrate and improve the overall learning
      //                 experience. Fully automated platform to push challenges
      //                 and revisions with minimal effort.
      //               </p>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </section>

      //   <section className="ftco-section ftco-work bg-light">
      //     <div className="sk-container-fluid">
      //       <div className="row justify-content-center pb-5">
      //         <div className="col-md-7 text-center heading-section fadeInUp ftco-animated">
      //           <h2 className="mb-2">Features</h2>
      //         </div>
      //       </div>
      //       <div className="row">
      //         <div className="col-md-4 fadeInUp ftco-animated">
      //           <div className="work-entry">
      //             <div
      //               className="img"
      //               style={{
      //                 backgroundImage: `url(${Background1})`,
      //                 backgroundSize: this.backgroundSize
      //               }}
      //             >
      //               <div className="text d-flex justify-content-center align-items-center">
      //                 <div className="p-3">
      //                   <span>Students</span>
      //                   <h3>Daily Challenges</h3>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //         <div className="col-md-4 fadeInUp ftco-animated">
      //           <div className="work-entry">
      //             <div
      //               className="img"
      //               style={{
      //                 backgroundImage: `url(${Background2})`,
      //                 backgroundSize: this.backgroundSize
      //               }}
      //             >
      //               <div className="text d-flex justify-content-center align-items-center">
      //                 <div className="p-3">
      //                   <span>Parents</span>
      //                   <h3>Daily Progress Reports</h3>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //         <div className="col-md-4 fadeInUp ftco-animated">
      //           <div className="work-entry">
      //             <div
      //               className="img"
      //               style={{
      //                 backgroundImage: `url(${Background3})`,
      //                 backgroundSize: this.backgroundSize
      //               }}
      //             >
      //               <div className="text d-flex justify-content-center align-items-center">
      //                 <div className="p-3">
      //                   <span>Educators</span>
      //                   <h3>Class Perfomance Dashboard</h3>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //         <div className="col-md-4 fadeInUp ftco-animated">
      //           <div className="work-entry">
      //             <div
      //               className="img"
      //               style={{
      //                 backgroundImage: `url(${Background4})`,
      //                 backgroundSize: this.backgroundSize
      //               }}
      //             >
      //               <div className="text d-flex justify-content-center align-items-center">
      //                 <div className="p-3">
      //                   <span>Students</span>
      //                   <h3>Immersive, Speech Enabled Platform</h3>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //         <div className="col-md-4 fadeInUp ftco-animated">
      //           <div className="work-entry">
      //             <div
      //               className="img"
      //               style={{
      //                 backgroundImage: `url(${Background5})`,
      //                 backgroundSize: this.backgroundSize
      //               }}
      //             >
      //               <div className="text d-flex justify-content-center align-items-center">
      //                 <div className="p-3">
      //                   <span>Parents</span>
      //                   <h3>Granular Visibility</h3>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //         <div className="col-md-4 fadeInUp ftco-animated">
      //           <div className="work-entry">
      //             <div
      //               className="img"
      //               style={{
      //                 backgroundImage: `url(${Background6})`,
      //                 backgroundSize: this.backgroundSize
      //               }}
      //             >
      //               <div className="text d-flex justify-content-center align-items-center">
      //                 <div className="p-3">
      //                   <span>Educators</span>
      //                   <h3>Actionable Insights and Recommendations</h3>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </section>

      //   <SiteFooter />
      // </div>
    );
  }
}

export default Home;
